// 广告位添加
export interface IBannerPositionUpdate {
    id?: number
    name: string // 广告位名称
    attr: number // 广告位属性：0-系统默认；1-自定义
    size: string // 广告位尺寸
    terminal: number // 终端：1-移动端；2-PC端
    tag: string //标签
    subtitle?: string //副标题
}

export interface IBannerPositionItem {
    id: number
    terminal: number
    name: string
    attr: number
    width: string
    height: string
    status: number
    subtitle: string
    create_time: string
    update_time: string
    del: number
    app_id: string
    tag: string
}

export interface IBannerPositionDetail extends IBannerPositionItem {
    size: string
}

// 广告列表item
export interface IBannerItem {
    id: number
    pid: number
    title: string
    terminal: number
    image: string
    link_type: number
    link: string
    category_id: number
    status: number
    start_time: number
    end_time: number
    sort: number
    create_time: number
    update_time: string
    del: number
    app_id: string
    pname: string
    terminal_desc: string
    image_background: string; //背景图片
    image_active: string //点击状态图片
    linkdata: {
        id: number
        name: string
        params: any
        link: string
    }
}

export interface IBannerDetail {
    id: number
    pid: number
    title: string
    terminal: number
    image: string
    link_type: 0 | 1 | 2 | 3 //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型
    link: string
    category_id: number
    status: number
    start_time: number
    end_time: number
    sort: number
    create_time: number
    update_time: number
    del: number
    app_id: string
    goods: []
    image_background: string; //背景图片
    image_active: string //点击状态图片
    target: number
    linkdata: {
        id: number
        name: string
        params: any
        link: string
    }
    params?: any
}

// 新增/编辑广告
export interface IBannerUpdateItem {
    id?: number;
    pid: number | string //广告位id
    title: string //广告标题
    terminal: number //终端：1-移动端；2-PC端, 3-小程序
    image: string //图片地址
    link_type: 0 | 1 | 2 | 3 //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型
    category_id: number //商品分类（pc端广告）
    start_time?: number //投放时间
    end_time?: number //结束时间
    url: number | string //链接id
    sort: number //排序
    time?: any[]
    status: number;
    image_background: string; //背景图片
    image_active: string //点击状态图片
    target: number,// 跳转方式 0-内部跳转；1-新窗口
    params: any
}

// 终端
export const terminalTypes = [
    {name: "移动端", id: 1},
    {name: "PC端", id: 2},
    {name: "小程序", id: 3},
]
// 链接类型
export const linkTypes = [
    // {name: "无", id: 0},
    {name: "商城页面", id: 1},
    {name: "商品页面", id: 2},
    {name: "自定义", id: 3},
]

export const linkTypesArr = ['无', '商城页面', '商品页面', '自定义类型']
