
// 上传组件
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';
import {defineComponent, reactive, watch, toRefs} from 'vue';
import {message} from "ant-design-vue/es";
import {baseUrl} from "@/utils/config";
import UploadUtil from "@/utils/upload_util";

interface IState {
  loading: boolean;
  sourceUrl: string;
  fileList: any[]
}

export default defineComponent({
  name: "UploadComponent",
  props: {
    // 初始化资源地址
    sourceUrl: {
      type: String,
      default: ''
    },
    // 上传类型 image图片/video视频/audio音频
    type: {
      type: String,
      default: 'image'
    },
    // 是否校验文件类型
    isCheckFile:{
      type: Boolean,
      default: true
    },
    onChange: Function
  },
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  setup(props) {
    let uploadType = props.type
    let uploadText = '上传图片'
    let uploadAction = baseUrl + '/admin/upload/image'
    let uploadFormData = {cid: 10}
    if (uploadType === 'video') {
      uploadText = '上传视频'
      uploadAction = baseUrl + '/admin/upload/video'
      uploadFormData = {cid: 11}
    }

    if (uploadType === 'audio') {
      uploadText = '上传音频'
      uploadAction = baseUrl + '/admin/upload/voice'
      uploadFormData = {cid: 12}
    }

    const state: IState = reactive({
      loading: false,
      sourceUrl: props.sourceUrl,
      fileList: props.sourceUrl ? [{
        url: props.sourceUrl,
        uid: new Date().getTime()
      }] : []
    })

    const uploadHeaders = UploadUtil.uploadHeaders()

    watch(() => props.sourceUrl, (newVal, oldVal) => {
      state.sourceUrl = newVal || ''
      if (props.type === 'image') {
        state.fileList = newVal ? [{
          url: newVal,
          uid: new Date().getTime()
        }] : []
      }
    })

    // 图片
    const beforeUploadImage = (file: File) => {
      if(props.isCheckFile){
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
          message.destroy()
          message.warning('请上传jpeg,jpg,png格式的图片')
          return false
        }

        if (file.size / 1024 / 1024 > 5) {
          message.destroy()
          message.warning('请上传小于5M的图片')
          return false
        }
      }

      return true;
    }

    // 视频
    const beforeUploadVideo = (file: File) => {
      if (!file.type.includes('video/')) {
        message.destroy()
        message.warning('请上传mp4,3gp,m3u8格式的视频')
        return false
      }
      // if (file.size / 1024 / 1024 > 100) {
      //   message.destroy()
      //   message.warning('请上传小于100M的视频')
      //   return false
      // }
      return true;
    }

    // 音频
    const beforeUploadAudio = (file: File) => {
      if (!file.type.includes('audio/')) {
        message.destroy()
        message.warning('请上传mp3,wav,m4a格式的音频')
        return false
      }
      // if (file.size / 1024 / 1024 > 30) {
      //   message.destroy()
      //   message.warning('请上传小于10M的音频')
      //   return false
      // }
      return true;
    }

    // 音、视频上传
    const handleChange = ({file}: any) => {
      if (file.status === 'done' && file.response) {
        const res = file.response
        if (res && res.error_code === 0) {
          const data = res.data
          state.sourceUrl = data.uri
          if (props.onChange) {
            props.onChange(data.uri)
          }
        }
      }
    }

    // 图片上传
    const handleImageChange = ({file, fileList}: any) => {
      if (!file.status || file.status === 'removed') {
        state.fileList = []
        state.sourceUrl = ''
        if (props.onChange) {
          props.onChange('')
        }
      } else {
        state.fileList = fileList
        if (file.status === 'done' && file.response) {
          const res = file.response
          if (res && res.error_code === 0) {
            const data = res.data
            state.sourceUrl = data.uri
            if (props.onChange) {
              props.onChange(data.uri)
            }
          }
        }
      }
    }

    const reset = () => {
      state.sourceUrl = ''
      state.fileList = []
    }

    return {
      ...toRefs(state),
      uploadHeaders,
      uploadType,
      uploadText,
      uploadAction,
      uploadFormData,
      beforeUploadImage,
      beforeUploadVideo,
      beforeUploadAudio,
      handleChange,
      handleImageChange,
      reset,
    };
  }
})
