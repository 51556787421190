
    import {defineComponent, onMounted, reactive, toRefs} from 'vue';
    import {message} from "ant-design-vue/es";
    import apiSetting from "@/request/apis/api_setting";
    interface IState {
        config:any
    }
    export default defineComponent({
        setup() {
            const state: IState = reactive({
                config:''
            });

            const getData = async()=>{
                const res = await apiSetting.BasicWithdraw();
                if (res && res.error_code === 0) {
                    state.config = res.data.config
                }else{
                    message.error(res.msg)
                }
            }

            const onFinish = async(values: any) => {
                const res = await apiSetting.BasicSetWithdraw(state.config );
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            };

            onMounted(() => {
                getData();
            });

            return {
                ...toRefs(state),
                getData,
                onFinish
            };
        },
    });
