import { createApp  } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.css'
import * as echarts from 'echarts'
import '../src/assets/icons/icontfont/iconfont.css';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import print from 'vue3-print-nb'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
const app = createApp(App)
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
app.component('QuillEditor', QuillEditor)
app.config.globalProperties.$echarts = echarts
app.use(store).use(router).use(Antd).use(print).use(VideoPlayer).use(vue3videoPlay).mount('#app')

