<template>
  <div class="img_view">
    <a-popover placement="right" v-if="imageUrl">
      <template #content>
        <img :src="imageUrl" style="width: 300px"/>
      </template>
      <div class="img_item" :style="`width: ${width}px; height: ${height}px;`">
        <a-image :src="imageUrl" :width="width" :height="height" />
      </div>
    </a-popover>
    <div class="img_item" :style="`width: ${width}px; height: ${height}px;`" v-else>
      <a-image src="http://jjyyimage.ynjjyy.cn/1000618860/uploads/images/20220110153802e8e278512.png" :width="width" :height="height" />
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "ImageView",
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    }
  }
})
</script>

<style lang="less" scoped>
.img_view{
  .img_item{
    width: 50px; height: 50px;
    overflow: hidden;
    :deep(.ant-image img){
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

</style>
