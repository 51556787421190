
    import {defineComponent, onMounted,onBeforeUnmount, reactive, toRefs} from 'vue';
    import Headers from './Headers.vue'
    import {menus} from "@/utils/menus";
    import router from "@/router";
    import {onBeforeRouteUpdate, useRoute} from "vue-router";
    import {IMenu, NetMenu} from "@/models/menu_model";
    import {ArrowLeftOutlined, HomeOutlined, CloseOutlined} from '@ant-design/icons-vue';
    import StorageUtil from "@/utils/storage_util";
    import {appId, appTitle} from "@/utils/config";
    import ApiAdmin from "@/request/apis/api_admin";
    // import MenuTree from "@/components/MenuTree.vue";

    interface IState {
        menusList:any
        openKeys: string[]
        selectedKeys: string[]
        tabKeys: IMenu[]
        activeTabKey: string,
        menuList: NetMenu[],
        currentPathName: string,
        navId: any,
        secondMenu: any,
        secondId: any,
        iconList: any
    }

    export default defineComponent({
        name: 'Layout',
        components: {Headers, HomeOutlined, ArrowLeftOutlined, CloseOutlined},
        setup() {
            const route = useRoute()
            const state: IState = reactive({
                menusList: [] as any [],
                openKeys: [],
                selectedKeys: [],
                tabKeys: [],
                activeTabKey: '',
                menuList: [],
                navId: '',
                secondMenu: [],
                secondId: '',
                iconList: [],
                currentPathName: "/home"
            });

            const goPage = (item: any) => {
                // 清除存储的搜索参数
                StorageUtil.clearParams()
                const keys = state.tabKeys.map((e:any) => e.url)
                if (!keys.includes(item.url)) {
                    state.tabKeys = state.tabKeys.concat([item])
                }
                state.activeTabKey = item.url
                if (state.tabKeys.length) {
                    localStorage.setItem('tabs', JSON.stringify(state.tabKeys))
                } else {
                    localStorage.removeItem('tabs')
                }
                router.push(item.url)
            }

            const setSelectedKeys = () => {
                setMenuSelected(route.path)
            }

            // 路由监听
            onBeforeRouteUpdate((to) => {
                setMenuSelected(to.path)
                state.currentPathName = to.path;
            });

            const setMenuSelected = (path: string) => {
                state.menusList.map(e => {
                    if (e.path === path) {
                        state.selectedKeys = [e.key]
                        state.activeTabKey = e.key
                        state.openKeys = []
                    } else {
                        if (e.children && e.children.length) {
                            e.children.map(m => {
                                if (m.path === path) {
                                    state.selectedKeys = [m.key]
                                    state.activeTabKey = m.key
                                    // state.openKeys = [e.key]
                                    if (!state.openKeys.includes(e.key)) {
                                        state.openKeys = state.openKeys.concat([e.key])
                                    }
                                }
                            })
                        }
                    }
                })
            }


            // 删除所有tab
            const closeAllTabs = () => {
                state.tabKeys = []
                localStorage.removeItem("tabKeys ")
                // router.push('/home')
            }

            // 删除tab
            const removeTab = (targetKey: string) => {
                let lastIndex = 0;
                state.tabKeys.forEach((pane:any, i) => {
                    if (pane.url === targetKey) {
                        lastIndex = i - 1;
                    }
                });
                state.tabKeys = state.tabKeys.filter((e:any) => e.url !== targetKey)
                localStorage.setItem("tabKeys",JSON.stringify(state.tabKeys))
                if (state.tabKeys.length && state.activeTabKey === targetKey) {
                    if (lastIndex >= 0) {
                        goPage(state.tabKeys[lastIndex])
                    } else {
                        goPage(state.tabKeys[0])
                    }
                }
            };

            // 切换tab
            const onTabChange = (key: string) => {
                const data = state.menusList
                console.log(data)
                data.forEach((item,index)=>{
                    console.log(item)
                    if(item.children){
                        item.children.forEach((item2,index)=>{
                            if(item2.front_url == key){
                                state.secondMenu = item.children
                                state.secondId = item2.meta.title
                                state.navId = item.meta.title
                            }
                        })
                    }
                })
                localStorage.setItem("secondId",state.secondId)
                localStorage.setItem("navId",state.navId)
                localStorage.setItem("activeTabKey",key)
                localStorage.setItem("secondMenu",JSON.stringify(state.secondMenu))
                StorageUtil.clearParams()
                state.activeTabKey = key || '/' +key
                console.log(state.activeTabKey)
                if(key.substr(0,1) == '/'){
                    router.push( key)
                }else{
                    router.push( '/' +key)
                }
            };

            // 获取当前菜单
            const getCurrentMenus = async () => {
                if (appId.includes('1000618870')|| appId.includes('1000618860')|| appId.includes('1000618811')) {
                    state.menusList = menus
                    getMenu(state.menusList)
                } else {
                    const res = await ApiAdmin.getMenus();
                    if (res && res.error_code === 0) {
                        state.menusList = res.data || [];
                        let obj = {};
                        state.menusList = state.menusList.reduce((newArr, next) => {
                            obj[next.name] ? "" : (obj[next.name] = true && newArr.push(next));
                            return newArr;
                        }, []);
                    }
                }
                const navId = localStorage.getItem("navId")
                const secondId = localStorage.getItem("secondId")
                const second: any = localStorage.getItem("secondMenu")
                const secondMenu = JSON.parse(second)
                if (navId == undefined || navId == null) {
                    state.navId = state.menusList[0].meta.title
                } else {
                    state.navId = navId
                }
                if (secondMenu == undefined || secondMenu == null) {
                    const data: any = state.menusList[0]
                    state.secondMenu = data.children

                } else {
                    state.secondMenu = secondMenu
                }
                if (secondId == undefined || secondId == null) {
                    state.secondId = state.secondMenu[0].meta.title
                } else {
                    state.secondId = secondId
                }

                const tabs: any = localStorage.getItem("tabKeys");
                state.tabKeys = JSON.parse(tabs)

                if(state.tabKeys == null){
                    state.tabKeys = []
                }
                const activeTabKey:any = localStorage.getItem("activeTabKey");
                state.activeTabKey = activeTabKey

            }

            const getMenu = (val) => {
                const data:any = []
                val.forEach((item,index)=>{
                    const obj:any = {name:"",path:"",meta:{title:"",front_url:""},children:[]}
                    obj.meta.title = item.name
                    item.children.forEach((item2,index2)=>{
                        const obj2= {"name":"","path":"","front_url":"","meta":{"title":"","front_url":""}}
                        obj2.name = item2.name
                        obj2.path = item2.name
                        obj2.front_url = item2.path
                        obj2.meta.title = item2.name
                        obj2.meta.front_url = item2.path
                        obj.children.push(obj2)
                    })
                    data.push(obj)

                })
                state.menusList = data
            }

            const goBack = () => {
                router.back()
            }

            const changeNav = (item) => {
                StorageUtil.clearParams()
                state.secondMenu = item.children
                state.navId = item.front_url
                const data = item.children[0]
                if(data.children && data.children.length>0){
                    state.navId = item.meta.title
                    state.secondId = item.children[0].children[0].meta.title
                    const rout = item.children[0].children[0].front_url
                    if(rout.substr(0, 1).indexOf('/') == -1){
                        router.push("/" + item.children[0].children[0].front_url)
                    }else{
                        router.push( item.children[0].children[0].front_url)
                    }
                    const tabs:any = {name:"",url:""}
                    tabs.name =  state.secondId
                    tabs.url =  item.children[0].children[0].front_url
                    state.tabKeys.push(tabs)
                    state.activeTabKey = item.children[0].children[0].front_url
                    localStorage.setItem("activeTabKey",state.activeTabKey)
                }else{
                    state.navId = item.meta.title
                    state.secondId = item.children[0].meta.title
                    const rout = item.children[0].front_url
                    if(rout.substr(0, 1).indexOf('/') == -1){
                        router.push("/" + item.children[0].front_url)
                    }else{
                        router.push( item.children[0].front_url)
                    }
                    const tabs:any = {name:"",url:""}
                    tabs.name =  state.secondId
                    tabs.url =  item.children[0].front_url
                    state.tabKeys.push(tabs)
                    state.activeTabKey = item.children[0].front_url
                    localStorage.setItem("activeTabKey",state.activeTabKey)
                }
                localStorage.setItem("secondMenu",JSON.stringify(state.secondMenu))
                const rr:any = new Map()
                state.tabKeys = state.tabKeys.filter((a) => !rr.has(a.name) && rr.set(a.name, 1))

                localStorage.setItem("tabKeys",JSON.stringify(state.tabKeys))
                localStorage.setItem("navId",state.navId)
                localStorage.setItem("secondId",state.secondId)
            }

            const changeRouter = (item) => {
                StorageUtil.clearParams()
                if(item.children && item.children.length>0){

                }else{
                    state.secondId = item.meta.title
                    if(item.front_url.substr(0, 1).indexOf('/') == -1){
                        router.push("/" + item.front_url)
                    }else{
                        router.push( item.front_url)
                    }
                    localStorage.setItem("secondId",state.secondId)
                    const tabs:any = {name:"",url:""}
                    tabs.name =  state.secondId
                    tabs.url =  item.front_url
                    state.tabKeys.push(tabs)
                    state.activeTabKey = item.front_url
                    localStorage.setItem("activeTabKey",state.activeTabKey)
                    const rr:any = new Map()
                    state.tabKeys = state.tabKeys.filter((a) => !rr.has(a.name) && rr.set(a.name, 1))
                    localStorage.setItem("tabKeys",JSON.stringify(state.tabKeys))
                }
            }

            const changeRouter2 = (item) => {
                StorageUtil.clearParams()
                if(item.children && item.children.length>0){
                }else{
                    state.secondId = item.meta.title
                    if(item.front_url == 'order/after_sale_lists'){
                        router.push("/" + item.front_url)
                    }else{
                        if(item.front_url.substr(0, 1).indexOf('/') == -1){
                            router.push("/" + item.front_url)
                        }else{
                            router.push( item.front_url)
                        }
                    }

                    const tabs:any = {name:"",url:""}
                    tabs.name =  state.secondId
                    tabs.url =  item.front_url
                    state.tabKeys.push(tabs)
                    state.activeTabKey = item.front_url
                    localStorage.setItem("activeTabKey",state.activeTabKey)
                    const rr:any = new Map()
                    state.tabKeys = state.tabKeys.filter((a) => !rr.has(a.name) && rr.set(a.name, 1))
                    localStorage.setItem("tabKeys",JSON.stringify(state.tabKeys))
                    localStorage.setItem("secondId",state.secondId)


                }
            }

            const chooseIcon = () => {
                if(appId.includes('1000618830')){
                    state.iconList = ['icon-shouye1', 'icon-jichupeizhi', 'icon-shimingrenzheng', 'icon-zixun', 'icon-yonghuguanli', 'icon-guanggaoguanli', 'icon-gangwei', 'icon-gerenjianli', 'icon-kefu', 'icon-ziyuanxhdpi' , 'icon-yemian','icon-sousuoshangjia', 'icon-jichupeizhi', 'icon-shangpin', 'icon-fuwu_o', 'icon-kefu','icon-caidan1',  'icon-ziyuanxhdpi' , 'icon-yemian', 'icon-shimingrenzheng', 'icon-zixun', 'icon-yonghuguanli', 'icon-guanggaoguanli',]
                }
                else if(appId.includes('1000618801')){
                    state.iconList = ['icon-shouye1','icon-yonghuguanli','icon-shimingrenzheng','icon-zixun','icon-guanggaoguanli','icon-jichupeizhi','icon-sousuoshangjia','icon-shangpin','icon-fuwu_o',  'icon-kefu','icon-caidan1' ]
                }else{
                    state.iconList = ['icon-iconfontyiqiyibiao', 'icon-yonghu', 'icon-pinglun1', 'icon-shenhe', 'icon-caiwu1', 'icon-wuliu', 'icon-cheliang', 'icon-xiaoxi', 'icon-xiugaioryijian', 'icon-guanggao' , 'icon-wuliu','icon-jichushuju', 'icon-canshu', 'icon-caidan', 'icon-xiugaioryijian', 'icon-fuwu_o', 'icon-kefu','icon-caidan1',  'icon-ziyuanxhdpi' , 'icon-yemian']
                }


            }

            onMounted(() => {
                chooseIcon()
                getCurrentMenus();
            })

            onBeforeUnmount(()=>{

                const data:any = state.tabKeys
                localStorage.setItem("tabKeys",JSON.stringify(data))
            })

            return {
                ...toRefs(state),
                goPage,
                chooseIcon,
                changeNav,
                changeRouter,
                changeRouter2,
                menus,
                appTitle,
                getMenu,
                removeTab,
                onTabChange,
                goBack,
                closeAllTabs
            };
        },
    });
