
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import dayjs from 'dayjs';

import {
  IBannerPositionItem,
  IBannerUpdateItem,
  linkTypes,
  terminalTypes,
} from "@/models/banner_model"
import ApiBanner from "@/request/apis/api_banner"
import ApiSetting from "@/request/apis/api_setting";
import {IDictionaryItem} from "@/models/setting_model";
import {menuTarget} from "@/models/menu_model";
import ParamsSet from '@/components/ParamsSet.vue'

interface IState {
  position: IBannerPositionItem[] // 广告位
  dictionary: IDictionaryItem[] // 跳转配置
  loading: boolean,
  pictureExtra: string//图片建议
}

// 新增编辑文章
export default defineComponent({
  name: "BannerSet",
  components: {UploadComponent, ParamsSet},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any
    const paramsRef = ref()
    const formRef = ref()
    const formState: UnwrapRef<IBannerUpdateItem> = reactive({
      pid: "", //父id 广告位id
      title: "", //广告标题
      terminal: 1, //终端：1-移动端；2-PC端
      image: "", //图片地址
      link_type: 3, //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型// 暂时传3
      category_id: 0, //商品分类（pc端广告）// 暂时不传
      start_time: 0, //投放时间
      end_time: 0, //结束时间
      url: "", //链接
      sort: 0, //排序
      time: [],
      status: 1,
      image_background: '',
      image_active: '',
      target: 0,
      params: null
    })

    // 校验
    const rules = {
      title: [{required: true, message: "请输入文章标题"}],
      url: [{required: true, message: "请输入跳转链接"}],
      pid: [{required: true, message: "请选择广告位"}],
      image: [{required: true, message: "请上传广告图片"}],
      // image_background: [{required: true, message: "请上传背景图片"}],
      // image_active: [{required: true, message: "请上传点击状态图片"}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const params = paramsRef.value.getParams()
            let data = {...toRaw(formState), params}
            data.terminal = state.position.find(
                (e) => e.id === Number(data.pid)
            )!.terminal
            if (data.time) {
              data.start_time = parseInt(`${Number(data.time![0]) / 1000}`)
              data.end_time = parseInt(`${Number(data.time![1]) / 1000}`)
            }else{
              delete data.start_time
              delete data.end_time
            }
            if (editId) {
              data.id = editId
              onEdit(data)
            } else {
              onAdd(data)
            }
            console.log(JSON.stringify(data, null, 2))
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: string) => (formState.image = url)
    const onBackgroundImageChange = (url: string) => (formState.image_background = url)
    const onActiveImageChange = (url: string) => (formState.image_active = url)

    const state: IState = reactive({
      position: [],
      dictionary: [],
      loading: false,
      pictureExtra: ''
    })

    // 广告位change
    const onPositionChange = (e: any) => {
      formState.pid = e
      setExtraHelp(e)
    }

    // 获取广告位列表
    const getPositionList = async () => {
      const res = await ApiBanner.getBannerPositionList()
      if (res && res.error_code === 0) {
        state.position = res.data || []
      }
    }

    const setExtraHelp = (id: number) => {
      const item = state.position.find(item => item.id === id)
      if (item) {
        state.pictureExtra = `建议尺寸：${item.width}*${item.height}`
      }
    }

    // 获取跳转配置列表 getDictionaryAllList
    const getDictionaryAllList = async (name = '') => {
      const res = await ApiSetting.getDictionaryAllList(name)
      if (res && res.error_code === 0) {
        state.dictionary = res.data || []
      }
    }

    // 获取详情
    const getDetail = async () => {
      const {id} = route.query
      //IArticleDetail
      if (id) {
        editId = id
        const res = await ApiBanner.getBannerDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          const dateFormat = 'YYYY-MM-DD HH:mm:ss';
          formState.pid = data.pid //广告位id
          formState.title = data.title //广告标题
          formState.terminal = data.terminal //终端：1-移动端；2-PC端
          formState.image = data.image //图片地址
          formState.image_background = data.image_background //图片地址
          formState.image_active = data.image_active //图片地址
          formState.target = data.target //跳转类型
          // formState.link_type = data.link_type //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型// 暂时不传
          formState.link_type = 3 //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型// 暂时不传
          formState.category_id = data.category_id //商品分类（pc端广告）// 暂时不传
          formState.start_time = data.start_time //投放时间
          formState.end_time = data.end_time //结束时间
          formState.url = Number(data.link) //链接
          formState.sort = data.sort //排序
          formState.status = data.status || 1
          formState.time = data.start_time && data.end_time ? [dayjs(dayjs(data.start_time * 1000).format(dateFormat)), dayjs(dayjs(data.end_time * 1000).format(dateFormat))] : []
          formState.params = data?.params || null
          setExtraHelp(data.pid)
        }
      }
    }

    // 新增
    const onAdd = async (params: IBannerUpdateItem) => {
      state.loading = true
      const res = await ApiBanner.addBanner(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: IBannerUpdateItem) => {
      state.loading = true
      const res = await ApiBanner.editBanner(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(async () => {
      await getDictionaryAllList()
      await getPositionList()
      await getDetail()
    })

    // select搜索
    const filterOption = (input: string, option: any) => {
      return option.label.includes(input)
    };

    return {
      formRef,
      paramsRef,
      formState,
      rules,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      ...toRefs(state),
      onSubmit,
      resetForm,
      onImageChange,
      onBackgroundImageChange,
      onActiveImageChange,
      onPositionChange,
      goBack,
      filterOption,
      terminalTypes,
      linkTypes,
      menuTarget
    }
  },
})
