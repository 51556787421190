
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    name:''
                },
                total:0,
                title:'',
                visible:false,
                formState:{
                    id:'',
                    name:'',
                    image:'',
                    remark:'',
                    sort:''
                },
                type:''
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '标签名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '标签描述',
                    dataIndex: 'remark',
                    key: 'remark',
                    align:'center'
                },
                {
                    title: '创建时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align:'center'
                },
                {
                    title: '修改时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];
            const rules = {
                name: [{required: true, message: "请输入标签名称"}],
                remark: [{required: true, message: "请输入标签简介"}],
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.TagLists(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const addApply = () =>{
                state.title = "新增标签"
                state.type = 1
                state.formState={
                    id:'',
                    name:'',
                    remark:'',
                },
                    state.visible = true
            }
            const edit = (val) =>{
                state.title = "编辑标签"
                state.type = 2
                state.formState={
                    id:val.id,
                    name:val.name,
                    remark:val.remark,
                }
                state.visible = true
            }
            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    const params = {...state.formState}
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.TagAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.TagEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const onDel = async (item) => {
                const params = {id:item.id}
                const res = await apiMember.TagDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                uploadHeaders,
                uploadAction,
                onSearch,
                onReset,
                uploadFormData,
                pagination,
                columns,
                edit,
                onDel,
                formRef,
                handleChange,
                onSubmit,
                rules,
                addApply,
                handleTableChange
            }
        }
    })
