
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import StorageUtil from "@/utils/storage_util";
    import PageUtil from "@/utils/page_util";
    import {IDriverItem, IEditDriver} from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";
    import mammoth from "mammoth";
    interface IState {
        list: any;
        total: number;
        searches: any;
    }

    // 表格
    const columns = [

        {
            title: "订单编码",
            dataIndex: "order_sn",
            key: "order_sn",
            align: "center"
        },
        {
            title: "商品名称",
            dataIndex: "shop_name",
            key: "shop_name",
            align: "center"
        },
        {
            title: "店铺名称",
            dataIndex: "shopName",
            key: "shopName",
            align: "center"
        },
        {
            title: "用户姓名",
            dataIndex: "user_name",
            key: "user_name",
            align: "center"
        },
        {
            title: "商品数量",
            dataIndex: "goods_num",
            key: "goods_num",
            align: "center"
        },
        {
            title: "商品价格",
            dataIndex: "goods_price",
            key: "goods_price",
            align: "center"
        },
        {
            title: "折扣价",
            dataIndex: "discount_price",
            key: "discount_price",
            align: "center"
        },
        {
            title: "总价",
            dataIndex: "total_price",
            key: "total_price",
            align: "center"
        },
        {
            title: "实际支付价",
            dataIndex: "total_pay_price",
            key: "total_pay_price",
            align: "center"
        },
        {
            title: "订单状态",
            dataIndex: "order_status",
            key: "order_status",
            align: "center"
        },
        {
            title: "订单类型",
            dataIndex: "order_type",
            key: "order_type",
            align: "center"
        },
        {
            title: "支付方式",
            dataIndex: "pay_way",
            key: "pay_way",
            align: "center"
        },
        {
            title: "支付状态",
            dataIndex: "pay_status",
            key: "pay_status",
            align: "center"
        },
        {
            title: "退款状态",
            dataIndex: "refund_status",
            key: "refund_status",
            align: "center"
        },
    ];

    export default defineComponent({
        name: "DriverList",
        components:{mammoth},
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: "",
                },
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches};
                StorageUtil.setParams(params);
                const res = await ApiSetting.RecruiTorderList(params);
                if (res && res.error_code === 0) {
                    state.total = res.data?.total || 0;
                    state.list = res.data?.data || [];
                }
            };

            const onSearch = () => {
                getList()
            }

            const onReset = () => {
                state.searches = {
                    page: 1,
                    limit: PageUtil.pageSize,
                    keywords: "",
                }
                getList()
            }

            const changeFile = (event)=> {
                let file = event.target.files[0];
                let reader = new FileReader();
                reader.onload = function (loadEvent) {
                    let loadEvent2:any =loadEvent
                    let arrayBuffer:any = loadEvent2.target.result;
                    mammoth.extractRawText({ arrayBuffer: new Uint8Array(arrayBuffer)}).then((res)=>{
                        console.log(res.value,"aaa")
                    });
                };
                reader.readAsArrayBuffer(file);
            }



            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                changeFile,
                pagination,
                onSearch,
                onReset,
                handleTableChange
            };
        },
    });
