
// 角色配置
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs, watch} from 'vue';
import type {UnwrapRef} from 'vue';
import {message} from "ant-design-vue/es";
import ApiParty from "@/request/apis/api_party";
import {IOrganizationItem, IRoleUpdateItem, roleLevels} from "@/models/party_model";

interface IState {
  list: IOrganizationItem[]
  loading: boolean;
  editItem?: IRoleUpdateItem;
}

export default defineComponent({
  props: ['editItem', 'reset'],
  name: "RoleSet",
  setup(props) {
    const state: IState = reactive({
      loading: false,
      editItem: props.editItem,
      list: []
    })

    const formRef = ref();
    const formState: UnwrapRef<IRoleUpdateItem> = reactive({
      name: '',
      organization_id: [0,1,5],
      level: undefined
    });


    watch(() => props.editItem, (newValue, oldValue) => {
      state.editItem = props.editItem
      formState.name = newValue?.name || '';
      formState.level = newValue?.level || ''
      formState.organization_id = newValue?.organization_id || []
    })

    const rules = {
      name: [{required: true, message: '请输入名称'}],
      level: [{required: true, message: '请选择等级'}],
    };

    // 获取列表
    const getList = async () => {
      const res = await ApiParty.getOrganizationList()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params: any = {...toRaw(formState)}
            params.organization_id = params.organization_id[params.organization_id.length - 1]
            if (props.editItem) {
              params.id = props.editItem.id
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      formState.organization_id = undefined
      if (props.reset) {
        props.reset()
      }
    };

    // 添加
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiParty.addRole(params)
      state.loading = false
      if (res) {
        if (res.error_code === 0) {
          message.success('新增成功')
          resetForm()
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiParty.editRole(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('编辑成功')
          resetForm()
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 16},
      roleLevels,
      formState,
      rules,
      onSubmit,
      resetForm,
      onAdd,
      onEdit,
      getList
    }
  }
})
