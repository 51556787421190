
    import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
    import apiSetting from "@/request/apis/api_setting";
    import {message} from "ant-design-vue/es";
    interface IState {
        automatic:any
    }

    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                automatic:""
            })

            const getData = async () => {
                const res = await apiSetting.SettingIndex()
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.automatic = data
                }
            }
            const setIndex = async () => {
                const params = {automatic:state.automatic}
                const res = await apiSetting.SettingIndex_set(params)
                if (res && res.error_code == 0) {
                    message.success(res.msg)
                    getData()
                }else{
                    message.error(res.msg)
                }
            }
            onMounted(() => {
                getData()
            })

            return {
                ...toRefs(state),
                getData,
                setIndex
            }
        }
    });
