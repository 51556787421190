
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import ApiUser from "@/request/apis/api_user";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import Combobox from "ant-design-vue/es/vc-select/examples/combobox";
    import countTo from '../../../components/vue-count-to/vue-countTo.vue';
    import mounted = Combobox.mounted;

    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        course_num: any,
        article_num: any,
        xData: any,
        yData: any,
        yData2: any,
        add_user_num: any,
        order_num: any,
        order_price: any,
        visit_user_num: any,
        shopList: any,
        shopList2: any
    }


    export default defineComponent({
        name: 'Home',
        components: {UserOutlined, countTo},
        setup() {
            let state = reactive({
                updata_time: "",
                course_num: [],
                appId: appId,
                article_num: [],
                xData: [] as any [],
                yData: [] as any [],
                yData2: [] as any [],
                add_user_num: '',
                order_num: '',
                order_price: '',
                visit_user_num: '',
                shopList: [],
                shopList2: []
            })


            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const getDate = () => {
                let dateTime
                let yy = new Date().getFullYear()
                let mm = new Date().getMonth() + 1
                let dd = new Date().getDate()
                let hh = new Date().getHours()
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
                dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
                state.updata_time = dateTime
            }

            const columns = [
                {
                    title: '排名',
                    dataIndex: 'shop_id',
                    key: 'shop_id',
                },
                {
                    title: '商家',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '销售金额',
                    dataIndex: 'order_amount',
                    key: 'order_amount',
                },

            ];

            const columns2 = [
                {
                    title: '排名',
                    dataIndex: 'shop_id',
                    key: 'shop_id',
                },
                {
                    title: '商家',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '进店人数',
                    dataIndex: 'visited_num',
                    key: 'visited_num',
                },

            ];
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                const myChart2 = proxy.$echarts.init(document.getElementById('mycharts2'));
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '5%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "数量",
                            data: state.yData,
                            type: 'line',
                            smooth:"true",
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00BFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00BFFF' //改变折线颜色
                                    }
                                }
                            },
                        }
                    ]
                });

                myChart2.setOption({
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '5%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "数量",
                            data: state.yData2,
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00BFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00BFFF' //改变折线颜色
                                    }
                                }
                            },
                        }
                    ]
                });
            };

            const getdata = async () => {
                const res = await ApiUser.indexState();
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.add_user_num = data.data.add_user_num
                    state.order_num = data.data.order_num
                    state.order_price = data.data.order_price
                    state.visit_user_num = data.data.visit_user_num
                }
            }

            const getEcharts = async () => {
                const res = await ApiUser.chartData();
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.xData = data.dates
                    state.yData = data.echarts_order_amount
                    state.yData2 = data.echarts_user_visit
                    initT()
                }
            }

            const getShop = async () => {
                const params = {type: 1}
                const res = await ApiUser.indexShop(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.shopList = data.lists
                }
            }

            const getShop2 = async () => {
                const params = {type: 2}
                const res = await ApiUser.indexShop(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.shopList2 = data.lists
                }
            }


            onMounted(() => {
                getDate()
                getdata()
                getEcharts()
                getShop()
                getShop2()
            })

            return {
                ...toRefs(state),
                appTitle,
                myRef,
                getEcharts,
                columns,
                columns2,
                initT,
                getShop

            }
        }
    });
