// const baseUrlPro = 'https://api.yngxccwl.com'//共仓物流正式
// const baseUrlDev = 'https://dev.ynjgyy.cn'//测试
// const baseUrlPro = 'https://www.ynjgyy.cn'//正式
// const baseUrlDev = 'https://dev.ynjgyy.cn'//测试
export const isDev = false // 是否是开发模式
export const baseUrl = isDev ? 'https://dev.ynjgyy.cn' : 'https://api.yngxccwl.com'
export const shopUrl = "https://testmall.ynjgyy.cn"
export const signUrl = "https://eam.ynjgyy.cn/api/"
// 共仓物流
export const appId: string = 'GCWL1000618880'
export const appTitle = '共仓物流'
export const homeRoute = '/home';
export const menuText = {
    'partyTitle': '物流',
    'partyMenu1': '物流',
    'partyMenu2': '物流',
    'partyMenu3': '物流',
    'roleName': '物流',
    'postNotice': '防疫报备协议',
}
export const serviceConfig: any[] = [

]

//视频产业园
// export const appId: string = 'RSSY1000618910'
// export const appTitle = '共享仓超数字视频产业园'
// export const homeRoute = '/home';
// export const menuText = {
//     'partyTitle': '视频产业园',
//     'partyMenu1': '视频产业园',
//     'partyMenu2': '视频产业园',
//     'partyMenu3': '视频产业园',
//     'roleName': '视频产业园',
// }

// export const appId: string = 'GCWL1000618880'
// export const appTitle = '共仓物流'
// export const homeRoute = '/home';
// export const menuText = {
//     'partyTitle': '物流',
//     'partyMenu1': '物流',
//     'partyMenu2': '物流',
//     'partyMenu3': '物流',
//     'roleName': '物流',
// }
// export const appId: string = 'GCWL1000618880'
// export const appTitle = '共仓物流'
// export const baseUrl = apiUrl
// export const baseUrl = apiUrl
// export const homeRoute = '/home';
// export const menuText = {
//     'partyTitle': '物流',
//     'partyMenu1': '物流',
//     'partyMenu2': '物流',
//     'partyMenu3': '物流',
//     'roleName': '物流',
// }
// export const serviceConfig = [
//     {name: '用户协议', id: 'courier_service'},
//     {name: '司机福利', id: 'courier_welfare'},
//     {name: '隐私政策', id: 'courier_privacy'},
//     {name: '入驻协议', id: 'courier_settled'},
// ]

// 共享英才云
// export const appId: string = 'GXYC1000618830'//GXYC
// export const appTitle = '共享英才云'
// 