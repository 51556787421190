
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        loading:boolean,
        detailData:any,
        statusId:any;
        reason:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                statusId:'',
                loading:false,
                reason:'',
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                total:0,
                visible:false,
                detailData:''
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '公司名',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '合同编号',
                    dataIndex: 'e_num',
                    key: 'e_num',
                    align:'center'
                },
                {
                    title: '签约类型',
                    dataIndex: 'contractType',
                    key: 'contractType',
                    align:'center'
                },
                {
                    title: '佣金比例',
                    dataIndex: 'commission',
                    key: 'commission',
                    align:'center'
                },
                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    align:'center'
                },
                 {
                    title: '更新时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                {
                    title: '审核备注',
                    dataIndex: 'reason',
                    key: 'reason',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 200,
                    align:'center'
                },
            ];
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.contractList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.list
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const onStatus = async(val) => {
                state.statusId = val.id
                state.visible = true
                state.reason=""
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const pass = async(val)=>{
                const params = {id:state.statusId,pass:val,reason:state.reason}
                state.loading = true
                const res = await ApiMember.contractAudit(params)
                if(res.error_code == 0){
                   state.visible = false
                   state.loading = false
                   getList()
                }else{
                    state.loading = false
                    message.error(res.msg)
                }
            }

            const getLink = async(val) => {
                const params = {id:val.id}
                const res = await ApiMember.contractLink(params)
                if(res.error_code == 0){
                    const data:any = res.data
                  window.open(data.link)
                }else{
                    message.error(res.msg)
                }
            }

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                onStatus,
                getLink,
                pass,
                pagination,
                columns,
                handleTableChange
            }
        }
    })
