
import {defineComponent, onMounted, shallowRef, onBeforeUnmount, ref, reactive, toRefs, watch} from "vue";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {IEditorConfig, createEditor, createToolbar} from '@wangeditor/editor'
import UploadUtil from "@/utils/upload_util";

// const {BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip} = E;
export default defineComponent({
  name: "EditorComponent",
  components: {Editor, Toolbar},
  props: {
    height: {
      type: Number,
      default: 500
    },
    content: {
      type: String,
      default: ''
    },
    isExtra: {
      // 是否添加附件
      type: Boolean,
      default: false
    },
    extra: {
      // 附件
      type: Array,
      default: []
    }
  },
  setup(props) {
    const uploadFormData = {cid: 3}
    const uploadAction = UploadUtil.uploadFileConfig.url
    const uploadHeaders = UploadUtil.uploadHeaders()

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref(props.content || '')

    const editorState: any = reactive({
      editor: null,
      fileList: [], //附件
    })

    const toolbarConfig = {}

    const editorConfig: any = {
      placeholder: '请输入内容',
      MENU_CONF: {
        uploadImage: {
          // 单个文件的最大体积限制，默认为 2M
          maxFileSize: 5 * 1024 * 1024, // 5M
          // 最多可上传几个文件，默认为 100
          maxNumberOfFiles: 5,
          // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
          allowedFileTypes: ['image/*'],
          // 自定义上传
          async customUpload(file: File, insertFn: any) {
            // file 即选中的文件
            // 自己实现上传，并得到图片 url alt href
            const res = await UploadUtil.uploadImage(file);
            // 最后插入图片
            if (res && res.error_code === 0 && res.data) {
              const data: any = res.data
              insertFn(data.uri, data.name, '');
            }
            // insertFn(url, alt, href)
          }
        },
        uploadVideo: {
          // 最多可上传几个文件，默认为 5
          maxNumberOfFiles: 1,
          // 自定义上传
          async customUpload(file: File, insertFn: any) {
            // file 即选中的文件
            // 自己实现上传，并得到视频 url
            const res: any = await UploadUtil.uploadVideo(file);
            // 最后插入视频
            if (res && res.error_code === 0 && res.data) {
              insertFn(res.data.uri)
            }
          }
        }
      },
    }


    watch(() => props.content, (newVal) => {
      if (newVal) {
        valueHtml.value = newVal
      }
    })

    watch(() => props.extra, (newVal) => {
      if (newVal) {
        editorState.fileList = newVal
      }
    })

    // 获取富文本内容
    const getHtmlText = () => {
      let data = editorRef.value.getHtml()
      console.log(data)
      return data || ''
    }

    // 清空富文本
    const clearHtml = () => {
      editorState.editor.clear()
    }

    const setHtmlText = (value) => {
      valueHtml.value = value
    }

    // 附件内容改变
    const handleChange = ({file, fileList}: any) => {
      editorState.fileList = fileList
    }

    // 获取附件内容
    const getExtraFile = () => {
      const uid = Number(new Date())
      const uri = editorState.fileList.map((e: any, i: number) => {
        if (e.response && e.response.error_code === 0) {
          return {
            url: e.response.data[1].uri,
            name: e.response.data[1].name,
            uid: uid + i
          }
        }
        return e
      })
      return uri || []
    }

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    onMounted(() => {

    })

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    return {
      ...toRefs(editorState),
      getHtmlText,
      uploadFormData,
      uploadAction,
      setHtmlText,
      uploadHeaders,
      handleChange,
      getExtraFile,
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      clearHtml
    }
  }
})
