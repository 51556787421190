
    import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
    import type {UnwrapRef} from "vue"
    import ApiArticle from "@/request/apis/api_article"
    import UploadComponent from "@/components/UploadComponent.vue"
    import {message} from "ant-design-vue/es"
    import {useRoute, useRouter} from "vue-router"
    import {courseTypes, ICourseUpdate} from "@/models/course_model";
    import ApiCourse from "@/request/apis/api_course";
    import PageUtil from "@/utils/page_util";
    import ApiUser from "@/request/apis/api_user";
    import ApiMember from "@/request/apis/api_member"
    interface IState {
        formState:any,
        teacherData:any,
        free:any
    }


    // 新增编辑文章
    export default defineComponent({
        name: "CourseSet",
        components: {UploadComponent},
        setup() {
            const route = useRoute()
            const router = useRouter()
            let editId: any;
            const formRef = ref()
            const editorComponentRef = ref()
            const state: IState = reactive({
                formState:{
                    "id":'',
                    "type":'',
                    "title":'',
                    "lecturer":'',
                    "thumb":'',
                    "intro":'',
                    "status":'',
                    "sort":'',
                    "recommend":'',
                    "subtitle":'',
                    "price":'',
                    "shop_id":'',
                    "tutor_id":undefined
                },
                free:'',
                teacherData:[]
            })
            const rules = {
                title: [{required: true, message: "请输入文章标题"},],
                intro: [{max: 1000, message: "文章简介200字以内"},],
                lecturer: [{required: false, message: "请输入讲师"},],
                thumb: [{required: false, message: "请上传缩略图",},],
                type: [{required: true, message: "请选择课程分类"},],
                media_image: [{required: false, message: "请上传封面",},],
            }

            const onSubmit = () => {
                        let params = {...toRaw(state.formState)}
                        params.sort = Number(params.sort)
                        if(state.free == "0"){
                            params.price = ""
                        }
                        if(params.tutor_id == null){
                                params.tutor_id = ""
                        }
                        if (editId) {
                            onEdit(params)
                        } else {
                            onAdd(params)
                        }

            }
            const resetForm = () => {
                formRef.value.resetFields()
            }

            const onImageChange = (url: string) => state.formState.thumb = url


            // 获取课程详情
            const getDetail = async () => {
                const {id} = route.query
                if (id) {
                    editId = id
                    const res = await ApiCourse.getCourseDetail(id.toString())
                    if (res && res.error_code === 0 && res.data) {
                        const data = res.data
                        state.formState.id = data.id
                        state.formState.type = data.type
                        state.formState.title = data.title
                        state.formState.lecturer = data.lecturer
                        state.formState.thumb = data.thumb
                        state.formState.intro = data.intro
                        state.formState.sort = data.sort || 999
                        state.formState.status = data.status
                        state.formState.price = data.price
                        state.formState.subtitle = data.subtitle
                        state.formState.shop_id = data.shop_id
                        state.formState.tutor_id = data.tutor_id == 0 ? null : data.tutor_id
                        state.formState.recommend = data.recommend
                        state.free = data.price == '0.00' ? 0 : 1
                    }
                console.log(state.formState)
                }
            }

            // 新增
            const onAdd = async (params: any) => {
                const res = await ApiCourse.createCourse(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        resetForm()
                        router.back()
                        return
                    }
                    message.error(res.msg)
                }
            }

            // 编辑
            const onEdit = async (params: any) => {
                const res = await ApiCourse.editCourse(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        resetForm()
                        router.back()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const getTeacher = async () => {
                const params={page:1,limit:100}
                const res = await ApiMember.tutorList(params)
                if (res) {
                    if (res.error_code === 0) {
                        const data:any = res.data
                        state.teacherData = data.data
                    }
                }
            }

            const goBack = () => {
                router.back()
            }

            const change = () =>{
                if(state.formState.tutor_id == undefined){
                    state.formState.tutor_id = null
                }
                console.log(state.formState.tutor_id)
            }
            onMounted(() => {
                getDetail()
                getTeacher()
            })

            return {
                ...toRefs(state),
                formRef,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                other: "",
                rules,
                change,
                getTeacher,
                onSubmit,
                resetForm,
                onImageChange,
                goBack,
                courseTypes,

                editorComponentRef,
            }
        },
    })
