
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import dayjs from "dayjs";
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    interface IState {
        list: any,
        searches: any,
        total: any,
        visible: any,
        title: any,
        formState: any,
        type: any,
        tagList: any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keyword: '',
                    keyword_type: 'nickname'
                },
                total: 0,
                title: '',
                visible: false,
                formState: {
                    "id": '',
                    "nickname": "",
                    "avatar": "",
                    "mobile": "",
                    "birthday": "",
                    "remark": "",
                    "select": []
                },
                type: '',
                tagList: []
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center'
                },
                {
                    title: '用户昵称',
                    dataIndex: 'nickname',
                    key: 'nickname',
                    align: 'center'
                },
                {
                    title: 'sn',
                    dataIndex: 'sn',
                    key: 'sn',
                    align: 'center'
                },
                {
                    title: '手机号码',
                    dataIndex: 'mobile',
                    key: 'mobile',
                    align: 'center'
                },

                {
                    title: '头像',
                    key: 'avatar',
                    dataIndex: 'avatar',
                    align: 'center'
                },
                {
                    title: '用户状态',
                    key: 'disable',
                    dataIndex: 'disable',
                    align: 'center'
                },
                {
                    title: '生日',
                    key: 'birthday',
                    dataIndex: 'birthday',
                    align: 'center'
                },
                {
                    title: '用户标签',
                    key: 'tag_str',
                    dataIndex: 'tag_str',
                    align: 'center'
                },
                {
                    title: '注册时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align: 'center'
                },
                {
                    title: '修改时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];
            const rules = {
                nickname: [{required: true, message: "请输入会员昵称"}],
                avatar: [{required: true, message: "请上传会员头像"}],
                remark: [{required: false, message: "请输入备注"}],
                mobile: [{required: true, message: "请输入手机号"}],
                select: [{required: true, message: "请选择会员标签"}],
                birthday: [{required: true, message: "请选择生日"}]
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiMember.UserLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const edit = (val) => {
                state.title = "编辑会员信息"
                state.formState = {
                    id: val.id,
                    nickname: val.nickname,
                    avatar: val.avatar,
                    mobile: val.mobile,
                    birthday: val.birthday == null ? '' : val.birthday,
                    remark: val.remark,
                    select: val.tag_ids == '' ? [] : val.tag_ids.split(',').map(i => parseInt(i, 0))
                }
                state.visible = true
            }

            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.avatar = data.uri
                    }
                }
            }

            const onSubmit = async () => {
                formRef.value.validate().then(async () => {
                    state.formState.select = state.formState.select.join(',')
                    const params = {...state.formState}
                    const res = await ApiMember.UserEdit(params)
                    if (res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        getList()
                    } else {
                        message.error(res.msg)
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const onDel = async (item, item2) => {
                const params = {id: item.id, status: item2}
                const res = await apiMember.UserDisable(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.keyword = ''
                getList()
            }

            const getTag = async () => {
                const params = {page: 1, limit: 999}
                const res = await ApiMember.TagLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.tagList = data.lists
                } else {
                    message.error(res.msg)
                }
            }

            onMounted(() => {
                getList()
                getTag()
            })

            return {
                ...toRefs(state),
                getList,
                getTag,
                edit,
                onDel,
                dayjs,
                onSearch,
                onReset,
                handleTableChange,
                handleChange,
                onSubmit,
                uploadFormData,
                pagination,
                columns,
                uploadHeaders,
                uploadAction,
                formRef,
                rules,

            }
        }
    })
