import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc7b4086"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, { title: "优惠券管理" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          dataSource: _ctx.list,
          columns: $setup.columns,
          bordered: "",
          pagination: $setup.pagination,
          onChange: $setup.handleTableChange,
          style: {"margin-top":"10px"}
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.key === 'image')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: record.image,
                  style: {"width":"50px"}
                }, null, 8, _hoisted_1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "pagination", "onChange"])
      ]),
      _: 1
    })
  ]))
}