
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import ApiSetting from "@/request/apis/api_setting";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import ApiBanner from "@/request/apis/api_banner";

    interface IState {
        searches:any,
        total:any,
        list:any,
        platform_cate:any,
        title:any,
        goodsReAuditId:any,
        goodsReAuditName:any,
        visible:any,
        reason:any,
        type:any,
        seckill_id:any,
        end_date:any,
        start_date:any,
        typeData:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches:{
                    shop_name:'',
                    goods_name:'',
                    type:0,
                    platform_cate_id:'',
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize
                },
                total:0,
                typeData:[],
                platform_cate:[],
                list:[],
                seckill_id:"",
                start_date:"",
                end_date:"",
                title:"",
                goodsReAuditId:'',
                goodsReAuditName:'',
                visible:false,
                reason:'',
                type:""
            })
            const columns = [
                // {
                //     title: '商家信息',
                //     dataIndex: 'shop',
                //     key: 'shop',
                //     width:500
                // },
                {
                    title: '商品信息',
                    dataIndex: 'goods',
                    key: 'goods',
                    width:550
                },
                {
                    title: '销售数据',
                    dataIndex: 'sale',
                    key: 'sale',
                    width: 300
                },
                {
                    title: '秒杀时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                },
                {
                    title: '审核说明',
                    key: 'tags',
                    dataIndex: 'tags',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 300
                },
            ];

            const getList = async() => {
                if(state.platform_cate.length >0){
                    state.searches.platform_cate_id=state.platform_cate.join(',')
                }
                const params = {...state.searches}
                const res = await ApiSetting.SeckillGoodsGoodsLists(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.total = data.count
                    state.list  = data.lists
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const OnSearch = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = PageUtil.pageNo
                getList()
            }

            const OnReset = () => {
                state.searches.shop_name=''
                state.searches.goods_name=''
                state.searches.platform_cate_id = ''
                state.platform_cate = []
                state.searches.page = PageUtil.pageNo
                state.searches.limit = PageUtil.pageSize
                getList()
            }




            const goodsReAudit = (val,val2) => {
                state.reason = ''
                state.goodsReAuditId=val.goods_id,
                    state.goodsReAuditName =val.name
                state.title = '商品审核：' + state.goodsReAuditName
                state.seckill_id = val.seckill_id
                state.end_date = val.end_date
                state.start_date = val.start_date
                state.type = val2
                state.visible = true
            }

            const handleOk = async(val) => {
                const params = {
                    "review_status":val,
                    "audit_status":val,
                    "review_desc":state.reason,
                    "goods_id":state.goodsReAuditId,
                    "seckill_id":state.seckill_id,
                    "audit_remark":state.reason,
                    "reason":state.reason,
                    "start_date":state.start_date,
                    "end_date":state.end_date,
                }
                if(state.type == 1){
                    const res = await ApiSetting.SeckillGoodsAudit(params)
                    if(res.error_code == 0){
                        state.visible = false
                        getType()
                        state.visible = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
                if(state.type == 2){
                    const res = await ApiSetting.SeckillGoodsReAudit(params)
                    if(res.error_code == 0){
                        state.visible = false
                       getType()
                        state.visible = false
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }


            const getType = async() => {
                const res = await ApiSetting.SeckillGoodsSta()
                    if(res.error_code == 0){
                        state.typeData= res.data
                        state.searches.type = state.typeData[0].name
                        getList()
                    }else{
                        message.error(res.msg)
                    }
            }

            const onchange = (val) => {
                state.searches.type = val
                getList()
            }
            onMounted(() => {
                getType()
            })

            return {
                ...toRefs(state),
                getList,
                onchange,
                OnReset,
                getType,
                handleOk,
                goodsReAudit,
                columns,
                OnSearch,
                pagination,
                handleTableChange
            }
        }
    });
