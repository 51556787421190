
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import api_article from "@/request/apis/api_article";
    import ApiMember from "@/request/apis/api_member";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState:any,
        treeData:any
    }



    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData:[],
                list: [],
                total: 0,
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                type: '',
                formState:''
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '部门名称',
                    dataIndex: 'full_name',
                    key: 'full_name',
                },
                {
                    title: '所属公司',
                    dataIndex: 'company',
                    key: 'company',
                },
                {
                    title: '首字母大写',
                    dataIndex: 'mnemonic_code',
                    key: 'mnemonic_code',
                },
                {
                    title: '序号',
                    dataIndex: 'number',
                    key: 'number',
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    align:'center'
                },
            ]

            const rules = {
                full_name: [{required: true, message: "请输入部门全称"},],
                mnemonic_code: [{required: true, message: "请输入首字母大写"},],
                number: [{required: true, message: "请输入序号"},],
                remark: [{required: true, message: "请输入备注"},],
                company_id: [{required: true, message: "请选择所属公司"},],
            }


            const add = () => {
                state.title = '新增部门'
                state.visible = true
                state.type = 1
                state.formState={
                    "full_name":'',
                    "mnemonic_code":'',
                    "number":'',
                    "remark":'',
                    "company_id":undefined
                }
            }



            const edit = (val) => {
                state.type = 2
                state.formState={
                    "id":val.id,
                    "full_name":val.full_name,
                    "mnemonic_code":val.mnemonic_code,
                    "number":val.number,
                    "remark":val.remark,
                    "company_id":val.company_id
                }
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSetting.departmentList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.data
                    state.total = data.total

                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiSetting.departmentAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiSetting.departmentEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id:val.id}
                const res = await ApiSetting.departmentDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }


            const getCompany = async () => {
                const params = {page:1,limit:9999}
                const res = await ApiSetting.companyList(params)
                if (res.error_code === 0) {
                    const data:any = res.data
                    state.treeData = data.data
                }else{
                    message.error(res.msg)
                }
            }

            onMounted(() => {
                getList()
                getCompany()
            })

            return {
                ...toRefs(state),
                columns,
                open,
                formRef,
                onSubmit,
                getCompany,
                rules,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
