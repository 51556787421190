import {baseRequestClient} from "@/request/request_client";
import {IArticleCategory, IArticleDetail, IArticleItem, IArticleUpdate} from "@/models/article_model";
import {IList } from "@/models/base_model";

const requestClient = baseRequestClient

// 文章
export default class ApiArticle {
    // GET 获取资讯分类列表
    static getArticleCategory = async () => {
        return await requestClient.get<IArticleCategory[]>('/admin/party.articleCategory/lists')
    }

    // POST 添加资讯分类
    static addArticleCategory = async (data: any) => {
        return await requestClient.post('/admin/party.articleCategory/add', data)
    }

    // POST 资讯分类修改
    static editArticleCategory = async (data: { name: string; id: number; }) => {
        return await requestClient.post('/admin/party.articleCategory/edit', data)
    }

    // POST 资讯分类删除
    static delArticleCategory = async (id: number) => {
        return await requestClient.post('/admin/party.articleCategory/del', {id})
    }

    // POST 资讯分类显示隐藏 "id", "status": 0 //0不显示1显示
    static showArticleCategory = async (id: number, status: number) => {
        return await requestClient.post('/admin/party.articleCategory/switchStatus', {id, status})
    }

    // GET 获取资讯列表
    static getArticleList = async (params: any) => {
        for(let key in params){
            if(!params[key]) delete params[key]
        }
        delete params.c_id
        return await requestClient.get<IList<IArticleItem>>('/admin/party.article/lists', params)
    }

    // GET 获取资讯详情
    static getArticleDetail = async (id: string) => {
        return await requestClient.get<IArticleDetail>('/admin/party.article/detail', {id})
    }

    // POST 资讯添加
    static addArticle = async (data: IArticleUpdate) => {
        return await requestClient.post('/admin/party.article/add', data)
    }

    // POST 资讯修改
    static editArticle = async (data: any) => {
        return await requestClient.post('/admin/party.article/edit', data)
    }

    // POST 资讯删除
    static delArticle = async (id: number) => {
        return await requestClient.post('/admin/party.article/del', {id})
    }

    // POST 资讯审核
    static auditArticle = async (id: number, status: number) => {
        return await requestClient.post('/admin/party.article/switchStatus', {id, status})
    }

    // POST 资讯推荐 status:0不推荐,1推荐
    static recommendArticle = async (id: number, status: number) => {
        return await requestClient.post('/admin/party.article/recommend', {id, status})
    }

    // POST 资讯排序 number
    static sortArticle = async (id: number, number: number) => {
        return await requestClient.post('/admin/party.article/upSort', {id, number})
    }

    static regionTree = async () => {
        return await requestClient.post('/admin/common.Goods/regionTree')
    }

}