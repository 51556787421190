
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import dayjs, {Dayjs} from 'dayjs';
    import StorageUtil from "@/utils/storage_util";
    import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
    import ImageView from '@/components/ImageView.vue'
    import PageUtil from "@/utils/page_util";
    import apiMember from "@/request/apis/api_member";
    import moment from "moment"
    import {baseUrl} from "../../../../v3-admin-all/src/utils/config"
    import UploadUtil from "@/utils/upload_util";

    interface IState {
        list: any,
        total: any;
        loading: boolean;
        category: IArticleCategory[]
        subCategory: IArticleCategory[]
        searches: any
        editItem?: IArticleItem,
        visible: boolean,
        checkNick: boolean,
        form: any,
        Title: any,
        type: any,
        exceldata: any,
        result: any,
        levelList: any,
        industryList: any
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '企业名称',
            dataIndex: 'company',
            key: 'company',
            width: 300
        },
        {
            title: '企业地址',
            dataIndex: 'company_addr',
            key: 'company_addr',
        },
        {
            title: '企业网址',
            dataIndex: 'website',
            key: 'website',
        },
        {
            title: '企业logo',
            dataIndex: 'logo',
            key: 'logo',
        },

        {
            title: '企业资质',
            dataIndex: 'qualifications',
            key: 'qualifications',
        },
        {
            title: '联系人姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '联系人电话',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: '推荐人',
            dataIndex: 'recommender',
            key: 'recommender',
        },
        {
            title: '上次联系时间',
            dataIndex: 'contact_time',
            key: 'contact_time',
        },
        {
            title: '所属行业',
            dataIndex: 'Industrydata',
            key: 'Industrydata',
        },
        {
            title: '客户评级',
            dataIndex: 'leveldata',
            key: 'leveldata',
        },
        {
            title: '职位',
            dataIndex: 'post',
            key: 'post',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '缴费状态',
            dataIndex: 'fee_status',
            key: 'fee_status',
            width: 200
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '简介',
            dataIndex: 'intro',
            key: 'intro',
        },
        {
            title: '入会时间',
            dataIndex: 'join_time',
            key: 'join_time',
            width: 200
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 350,
            fixed: 'right'
        },
    ];
    const formRef = ref();

    const labelCol = {span: 4}
    const wrapperCol = {span: 8};
    const rules = {
        name: [{required: true, message: "请输入姓名"}],
        mobile: [{required: true, message: "请输入手机号码"}],
        sex: [{required: true, message: "请选择性别"}],
        company: [{required: true, message: "请输入企业名称"}],
        post: [{required: true, message: "请输入职位"}],
        company_addr: [{required: true, message: "请输入企业地址"}],
        join_time: [{required: true, message: "请选择入会时间"}],
        recommender: [{required: true, message: "请输入推荐人"}],
        logo: [{required: true, message: "请上传logo"}],
        website: [{required: true, message: "请上传企业网址"}],
        intro: [{required: true, message: "请上传简介"}],
        qualifications: [{required: true, message: "请输入持有资质"}],
        contact_time: [{required: true, message: "请选择上次联系时间"}],
        industry: [{required: true, message: "请选择企业行业"}],
        level: [{required: true, message: "请选择级别"}],
    }
    export default ({
        name: 'ArticleList',
        components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                category: [],
                result: [],
                subCategory: [],
                editItem: undefined,
                visible: false,
                exceldata: [],
                checkNick: false,
                Title: "新增会员",
                levelList: [],
                industryList: [],
                type: '',
                form: {
                    name: '',
                    mobile: "",
                    sex: undefined,
                    company: '',
                    company_addr: '',
                    logo: '',
                    website: '',
                    intro: '',
                    qualifications: '',
                    contact_time: '',
                    industry: '',
                    level: '',
                    post: '',
                    remark: '',
                    join_time: '',
                    recommender: ''
                },
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: '',
                    mobile: "",
                    sex: '',
                    company: '',
                    fee_status: '',
                    remark: '',
                    post: '',
                    qualifications: '',
                    time: [],
                }
            })
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches}
                StorageUtil.setParams(params)
                state.loading = true
                const res = await apiMember.MemberList(params)
                state.loading = false
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.total = data.count
                    state.list = data.lists
                }
            }
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const setSortEditItem = (val) => {
                state.Title = '编辑会员信息'
                state.type = 2
                state.form.name = val.name
                state.form.mobile = val.mobile
                state.form.sex = (val.sex).toString()
                state.form.post = val.post
                state.form.company = val.company
                state.form.join_time = ""
                state.form.remark = val.remark
                state.form.id = val.id
                state.form.company_addr = val.company_addr
                state.form.recommender = val.recommender
                state.form.join_time = dayjs(val.join_time * 1000).format('YYYY/MM/DD')
                state.form.join_time = dayjs(state.form.join_time, 'YYYY/MM/DD')
                state.form.logo = val.logo
                state.form.website = val.website
                state.form.intro = val.intro
                state.form.qualifications = val.qualifications
                state.form.contact_time = dayjs(val.contact_time * 1000).format('YYYY/MM/DD')
                state.form.contact_time = dayjs(state.form.contact_time, 'YYYY/MM/DD')
                state.form.industry = val.industry
                state.form.level = val.level
                state.visible = true
                formRef.value.clearValidate()
            }
            // 删除用户
            const onDel = async (item) => {
                const res = await apiMember.deleteMember(item.id)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            //设置状态
            const onStatus = async (val1, val2) => {
                const params: any = {id: val1.id, status: val2}
                const res = await apiMember.invalidMember(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onFeed = async (val1, val2) => {
                const params: any = {id: val1.id, status: val2}
                const res = await apiMember.feeStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            // 搜索
            const onSearch = (key: string, value: any, e: any) => {
                state.searches[key] = value
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const resetSearch = () => {
                state.searches.keywords = ''
                state.searches.industry = ''
                state.searches.level = ''
                state.searches.fee_status = ''
                state.searches.time = []
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const initSearch = () => {
                const res = StorageUtil.getParams()
                if (res) {
                    state.searches = {...state.searches, ...res}
                }
            }
            const onSubmit = async () => {
                // state.form.join_time = "123123123"
                formRef.value.validate()
                    .then(() => {
                        const params = {...state.form}
                        if (state.type == 1) {
                            addMember(params)
                        } else {
                            editSub(params)
                        }
                    })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const goSet = async () => {
                state.Title = "新增会员"
                state.type = 1
                state.visible = true
                state.form = {
                    name: '',
                    mobile: "",
                    sex: undefined,
                    company: '',
                    company_addr: '',
                    logo: '',
                    website: '',
                    intro: '',
                    qualifications: '',
                    contact_time: '',
                    industry: undefined,
                    level: undefined,
                    post: '',
                    remark: '',
                    join_time: '',
                    recommender: ''
                }
            }

            const addMember = async (val) => {
                val.join_time = (Date.parse(val.join_time)).toString().slice(0, 10)
                val.contact_time = (Date.parse(val.contact_time)).toString().slice(0, 10)
                const res = await apiMember.addMember(val)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    getList()
                    state.visible = false
                } else {
                    message.error(res.msg)
                }
            }

            const editSub = async (val) => {
                val.join_time = Number((Date.parse(val.join_time)).toString().slice(0, 10))
                val.contact_time = Number((Date.parse(val.contact_time)).toString().slice(0, 10))
                const res = await apiMember.editMember(val)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    getList()
                    state.visible = false
                } else {
                    message.error(res.msg)
                }
            }

            const exportData = (info: any) => {
                const params = info.file.originFileObj
                const reader = new FileReader();
                reader.readAsBinaryString(params);//发起异步请求
                reader.onload = e => {
                    let data: any;
                    let workbook: any;
                    if (e.target) {
                        data = e.target.result;
                        let XLSX = require('xlsx')
                        workbook = XLSX.read(data, {type: 'binary'});
                        let SheetName = workbook.SheetNames[0];
                        let sheetInfos = workbook.Sheets[SheetName];
                        let outdata = XLSX.utils.sheet_to_json(sheetInfos);
                        outdata.map((val: any, index: number) => {
                            let obj: any = {
                                name: val.联系人姓名,
                                mobile: val.联系人电话,
                                sex: val.联系人性别,
                                recommender: val.推荐人,
                                company: val.企业名称,
                                company_addr: val.企业地址,
                                post: val.联系人职位,
                                status: val.状态,
                                fee_status: val.缴费状态,
                                remark: val.备注,
                                join_time: val.入会时间,
                                website: val.企业网址,
                                intro: val.企业简介,
                                qualifications: val.持有资质,
                                contact_time: val.上次联系时间,
                                industry: val.所属行业,
                                level: val.客户评级
                            };

                            state.result.push(obj);
                        });

                    }
                    addData(state.result)
                };
            }

            const exportExcel = async () => {
                let params = {page: 1, limit: state.total}
                const res = await apiMember.MemberList(params)
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.exceldata = data.lists
                }
                let tableData = [['企业名称','企业地址','所属行业','客户评级','联系人姓名', '联系人电话', '联系人性别', '推荐人', '企业网址', '企业logo', '持有资质', '上次联系时间',   '联系人职位', '状态', '缴费状态', '备注', '入会时间']]
                state.exceldata.forEach((item, index) => {
                    let rowData: any = []
                    rowData = [
                        item.company,
                        item.company_addr,
                        item.Industrydata == null ? '无' : item.Industrydata['name'],
                        item.leveldata == null ? '无' : item.leveldata['name'],
                        item.name,
                        item.mobile,
                        item.sex == 1 ? '男' : item.sex == 0 ? '未知' : '女',
                        item.recommender,
                        item.website,
                        item.logo,
                        item.qualifications,
                        dayjs(item.contact_time).format('YYYY-MM-DD'),
                        item.post,
                        item.status == 1 ? '正常' : '已退会', item.fee_status == 1 ? '已缴费' : '未缴费', item.remark, dayjs(item.join_time).format('YYYY-MM-DD')
                    ]
                    tableData.push(rowData)
                })
                let XLSX = require('xlsx')
                let ws = XLSX.utils.aoa_to_sheet(tableData)
                let wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws, '会员列表') // 工作簿名称
                XLSX.writeFile(wb, '会员列表.xlsx') // 保存的文件名
            }


            const downloadTemp = () => {
                window.open(baseUrl + '/static/hydr.xls')
            }

            const addData = async (val) => {
                const res = await apiMember.memberImport(val)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    state.result = []
                    getList()
                } else {
                    message.error(res.msg)
                }
            }

            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.form.logo = data.uri
                    }
                }
            }

            const getLevel = async () => {
                const data = {}
                const res = await apiMember.levelLists(data)
                if (res && res.error_code === 0) {
                    const data = res.data
                    state.levelList = data
                }
                const data2 = {page: 1, limit: 100}
                const res2 = await apiMember.industryLists(data2)
                if (res2 && res2.error_code === 0) {
                    const erdata: any = res2.data
                    state.industryList = erdata.lists
                }
            }

            const searchData = () => {
                getList()
            }


            onMounted(() => {
                initSearch()
                getList()
                getLevel()
            })

            return {
                ...toRefs(state),
                columns,
                uploadFormData,
                downloadTemp,
                getLevel,
                searchData,
                onDel,
                uploadHeaders,
                addData,
                handleChange,
                exportData,
                pagination,
                uploadAction,
                moment,
                exportExcel,
                formRef,
                wrapperCol,
                onFeed,
                handleTableChange,
                onSearch,
                resetSearch,
                editSub,
                articleTypes,
                onStatus,
                setSortEditItem,
                rules,
                goSet,
                onSubmit
            }
        }
    })
