
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogisticsOrder from "@/request/apis/api_logistics_order";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {LogisticsOrderItem, LogisticsOrderDetail} from "@/models/logistics_model";
import {getLogisticsOrderStatus, logisticsOrderStatus} from "@/utils/status";

interface IState {
  list: LogisticsOrderItem[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '订单号',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: '货主信息',
    dataIndex: 'shipper_user',
    key: 'shipper_user',
  },
  {
    title: '司机信息',
    dataIndex: 'receiver_user',
    key: 'receiver_user',
  },
  {
    title: '车辆信息',
    dataIndex: 'car',
    key: 'car',
    align: 'right',
  },
  {
    title: '发货信息',
    dataIndex: 'shipper',
    key: 'shipper',
  },
  {
    title: '收货信息',
    dataIndex: 'receiver',
    key: 'receiver',
  },
  {
    title: '货物信息',
    dataIndex: 'goods',
    key: 'goods',
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   key: 'remark',
  // },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 100
  },
];

export default defineComponent({
  name: 'ArticleList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      category: [],
      subCategory: [],
      editItem: undefined,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        type: '',// 订单状态
        // 创建时间
        dateTime: [],
        start_time: '',
        end_time: '',
        // 搜索框
        search_key: '',
        keyword: ''
      }
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      delete params.dateTime
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogisticsOrder.getLogisticsOrderLists(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.lists || []
      }
    }

    // 详情
    const goDetail = (item: LogisticsOrderDetail) => {
      router.push({path: '/logistics_order/order_details', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any) => {
      console.log(key)
      console.log(value)
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const onDateChange = (date: any, dateStrings: any) => {
      state.searches.start_time = dateStrings[0];
      state.searches.end_time = dateStrings[1];
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const onInputSearch = (key: string, value: any) => {
      for (let key in state.searches) {
        if(key === 'dateTime'){
          state.searches[key] = []
        }else{
          state.searches[key] = ''
        }
      }
      state.searches.search_key = key
      state.searches.keyword = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        if(key === 'dateTime'){
          state.searches[key] = []
        }else{
          state.searches[key] = ''
        }
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      // state.subCategory = []
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    const onPay = async (item: LogisticsOrderItem) => {
      const res = await ApiLogisticsOrder.onPayOrderTest(item.id)
      getList();
    }

    return {
      ...toRefs(state),
      columns,
      goDetail,
      pagination,
      handleTableChange,
      onSearch,
      onInputSearch,
      resetSearch,
      onDateChange,
      getLogisticsOrderStatus,
      logisticsOrderStatus,
      onPay,
    }
  }
})
