import {baseRequestClient} from "@/request/request_client";
import {IList, IListResult} from "@/models/base_model";
import {IDictionaryItem, IDictionaryUpdate} from "@/models/setting_model";
import {IMenuItem, IMenuUpdate} from "@/models/menu_model";
import {IAppVersionItem} from './../../models/version_model';

const requestClient = baseRequestClient

// 页面配置
export default class ApiSetting {

    /// 跳转配置

    // GET 跳转配置全部列表
    static getDictionaryAllList = async (name = '') => {
        return await requestClient.get<IDictionaryItem[]>('/admin/dictionary.menu/all', name ? {name} : null)
    }

    // GET 跳转配置列表
    static getDictionaryList = async (params: any) => {
        return await requestClient.get<IList<IDictionaryItem>>('/admin/dictionary.menu/lists', params)
    }

    // GET 跳转配置详情
    static getDictionaryDetail = async (id: string) => {
        return await requestClient.get<IDictionaryItem>('/admin/dictionary.menu/info', {id})
    }

    // POST 跳转配置添加
    static addDictionary = async (data: IDictionaryUpdate) => {
        return await requestClient.post('/admin/dictionary.menu/create', data)
    }

    // POST 跳转配置编辑
    static editDictionary = async (data: IDictionaryUpdate) => {
        return await requestClient.post('/admin/dictionary.menu/update', data)
    }

    // POST 跳转配置删除
    static delDictionary = async (id: number) => {
        return await requestClient.post('/admin/dictionary.menu/delete', {id})
    }

    // POST 跳转配置启用禁用status:1启用,2禁用
    static setDictionaryStatus = async (id: number, status: number) => {
        return await requestClient.post('/admin/dictionary.menu/changeStatus', {id, status})
    }

    /// 菜单
    // GET 获取菜单列表
    static getMenuList = async (params: any) => {
        for (let key in params) {
            if (!params[key] && key != 'is_pc') delete params[key]
        }
        return await requestClient.get<IListResult<IMenuItem>>('/admin/decoration.MenuDecorate/lists', params)
    }
    // GET 获取所有菜单列表
    static getMenuListAll = async () => {
        return await requestClient.get<IMenuItem[]>('/admin/decoration.MenuDecorate/listAll')
    }
    // GET 获取菜单详情
    static getMenuDetail = async (id: any) => {
        return await requestClient.get<any>('/admin/decoration.MenuDecorate/detail', {id})
    }

    // POST /admin/decoration.MenuDecorate/add 新增导航
    static addMenu = async (data: IMenuUpdate) => {
        return await requestClient.post('/admin/decoration.MenuDecorate/add', data)
    }
    // POST /admin/decoration.MenuDecorate/edit 编辑菜单
    static editMenu = async (data: IMenuUpdate) => {
        return await requestClient.post('/admin/decoration.MenuDecorate/edit', data)
    }
    static setInfo = async (data: any) => {
        return await requestClient.post('/admin/goods.goods/setInfo', data)
    }
    // POST /admin/decoration.MenuDecorate/del 删除菜单
    static delMenu = async (id: any) => {
        return await requestClient.post('/admin/decoration.MenuDecorate/del', {id})
    }
    // POST /admin/decoration.MenuDecorate/swtichStatus  切换菜单状态
    static setMenuStatus = async (id: any, status: number) => {
        return await requestClient.post('/admin/decoration.MenuDecorate/swtichStatus', {id, status})
    }

    static setMenuSort = async (id: any, number: number) => {
        return await requestClient.post('/admin/decoration.MenuDecorate/upSort', {id, number})
    }

    static CustomerServiceIndex = async () => {
        return await requestClient.get<any>('/admin/setting.CustomerService/index')
    }
    static CustomerService = async (params: any) => {
        return await requestClient.post('/admin/setting.CustomerService/set', params)
    }
    static TransactionIndex = async () => {
        return await requestClient.get<any>('/admin/setting.Transaction/index')
    }
    static TransactionSet = async (params: any) => {
        return await requestClient.post('/admin/setting.Transaction/set', params)
    }
    static LogLists = async (params: any) => {
        return await requestClient.get<any>('/admin/system.Log/lists', params)
    }
    static BasicCopyright = async () => {
        return await requestClient.get<any>('/admin/setting.Basic/copyright')
    }
    static setCopyright = async (params: any) => {
        return await requestClient.post('/admin/setting.Basic/setCopyright', params)
    }
    static BasicShare = async () => {
        return await requestClient.get<any>('/admin/setting.Basic/share')
    }
    static BasicSetShare = async (params: any) => {
        return await requestClient.post('/admin/setting.Basic/setShare', params)
    }
    static BasicWithdraw = async () => {
        return await requestClient.get<any>('/admin/setting.Basic/withdraw')
    }
    static BasicSetWithdraw = async (params: any) => {
        return await requestClient.post('/admin/setting.Basic/setWithdraw', params)
    }
    static SmsLogLists = async (params: any) => {
        return await requestClient.get<any>('/admin/setting.Sms/logLists', params)
    }
    static NoticeSetting = async (params: any) => {
        return await requestClient.get<any>('/admin/setting.NoticeSetting/record', params)
    }
    static ShopWithdrawalIndex = async () => {
        return await requestClient.get<any>('/admin/setting.ShopWithdrawal/index')
    }
    static ShopWithdrawalSet = async (params: any) => {
        return await requestClient.post('/admin/setting.ShopWithdrawal/set', params)
    }
    static BasicApp = async () => {
        return await requestClient.get<any>('/admin/setting.Basic/app')
    }
    static BasicSetApp = async (params: any) => {
        return await requestClient.post('/admin/setting.Basic/setApp', params)
    }
    static HotSearchInex = async () => {
        return await requestClient.get<any>('/admin/setting.HotSearch/index')
    }
    static HotSearchSet = async (params: any) => {
        return await requestClient.post('/admin/setting.HotSearch/set', params)
    }
    static postList = async (params: any) => {
        return await requestClient.get<any>('/admin/share_talents.Recruit/postList', params)
    }
    static resumeList = async (params: any) => {
        return await requestClient.get<any>('/admin/share_talents.Recruit/resumeList', params)
    }
    static RecruitPostDel = async (params: any) => {
        return await requestClient.post('/admin/share_talents.Recruit/postDel', params)
    }
    static HelpCategoryAdd = async (params: any) => {
        return await requestClient.post('/admin/content.HelpCategory/add', params)
    }
    static HelpCategoryLists = async (params: any) => {
        return await requestClient.get<any>('/admin/content.HelpCategory/lists', params)
    }
    static HelpCategoryEdit = async (params: any) => {
        return await requestClient.post('/admin/content.HelpCategory/edit', params)
    }
    static HelpCategoryHide = async (params: any) => {
        return await requestClient.post('/admin/content.HelpCategory/hide', params)
    }
    static HelpCategoryDel = async (params: any) => {
        return await requestClient.post('/admin/content.HelpCategory/del', params)
    }
    static HelpLists = async (params: any) => {
        return await requestClient.get<any>('/admin/content.Help/lists', params)
    }
    static HelpAdd = async (params: any) => {
        return await requestClient.post('/admin/content.Help/add', params)
    }
    static HelpEdit = async (params: any) => {
        return await requestClient.post('/admin/content.Help/edit', params)
    }
    static HelpHide = async (params: any) => {
        return await requestClient.post('/admin/content.Help/hide', params)
    }
    static HelpDel = async (params: any) => {
        return await requestClient.post('/admin/content.Help/del', params)
    }
    static RecruitPostTopList = async (params: any) => {
        return await requestClient.get<any>('/admin/share_talents.Recruit/postTopList', params)
    }
    static BargainSet = async (params: any) => {
        return await requestClient.post('/admin/bargain.Bargain/set', params)
    }
    static RecruitFlushList = async (params: any) => {
        return await requestClient.get<any>('/admin/share_talents.Recruit/flushList', params)
    }
    static appData = async (params: any) => {
        const header = {"app-id": "GXCC1000618800"}
        return await requestClient.get<any>('/admin/goods.goods/appData', params, header)
    }
    // /admin/decoration.MenuDecorate/bottomNavigation  底部导航
    // /admin/decoration.MenuDecorate/editNavigation  编辑底部导航
    static SettingIndex = async () => {
        return await requestClient.get<any>('/admin/team.Setting/index')
    }
    static SettingIndex_set = async (params: any) => {
        return await requestClient.post<any>('/admin/team.Setting/set', params)
    }
    static ActivityLists = async (params: any) => {
        return await requestClient.get<any>('/admin/team.Activity/lists', params)
    }
    static ActivityDetail = async (params: any) => {
        return await requestClient.get<any>('/admin/team.Activity/details', params)
    }
    static ActivityRecord = async (params: any) => {
        return await requestClient.get<any>('/admin/team.Activity/record', params)
    }
    static FoundLists = async (params: any) => {
        return await requestClient.get<any>('/admin/team.Found/lists', params)
    }
    static FoundDetail = async (params: any) => {
        return await requestClient.get<any>('/admin/team.Found/detail', params)
    }
    static SeckillGoodsLists = async (params: any) => {
        return await requestClient.get<any>('/admin/seckill.SeckillGoods/lists', params)
    }
    static SeckillGoodsGoodsLists = async (params: any) => {
        return await requestClient.get<any>('/admin/seckill.SeckillGoods/goodsLists?', params)
    }
    static SeckillGoodsAudit = async (params: any) => {
        return await requestClient.post<any>('/admin/seckill.SeckillGoods/audit', params)
    }
    static SeckillGoodsReAudit = async (params: any) => {
        return await requestClient.post<any>('/admin/seckill.SeckillGoods/reAudit', params)
    }
    static goodsAudit = async (params: any) => {
        return await requestClient.post<any>('/admin/goods.goods/audit', params)
    }
    static goodsReAudit = async (params: any) => {
        return await requestClient.post<any>('/admin/goods.goods/reAudit', params)
    }
    static RecruiTorderList = async (params: any) => {
        return await requestClient.get<any>('/admin/share_talents.Recruit/orderList', params)
    }
    static FootprintIndex = async (params: any) => {
        return await requestClient.get<any>('/admin/Footprint/index', params)
    }
    static AreaLists = async (params: any) => {
        return await requestClient.get<any>('/admin/activity_area.Area/lists', params)
    }
    static AreaAdd = async (params: any) => {
        return await requestClient.post<any>('/admin/activity_area.Area/add', params)
    }
    static AreaEdit = async (params: any) => {
        return await requestClient.post<any>('/admin/activity_area.Area/edit', params)
    }
    static AreaDel = async (params: any) => {
        return await requestClient.post<any>('/admin/activity_area.Area/del', params)
    }
    static GoodsLists = async (params: any) => {
        return await requestClient.get<any>('/admin/activity_area.Goods/lists', params)
    }
    static Crontablists = async (params: any) => {
        return await requestClient.get<any>('/admin/system.Crontab/lists', params)
    }
    static CrontabAdd = async (params: any) => {
        return await requestClient.post<any>('/admin/system.Crontab/add', params)
    }
    static CrontabEdit = async (params: any) => {
        return await requestClient.post<any>('/admin/system.Crontab/edit', params)
    }
    static CrontabDel = async (params: any) => {
        return await requestClient.post<any>('/addmin/system.Crontab/del', params)
    }
    // 获取版本列表 ?page&limit
    static getAppVersionList = async (params: any) => {
        return await requestClient.get<IAppVersionItem[]>('/admin/package.AppPackage/index', params)
    }
    // 获取版本详情
    static getAppVersionDetail = async (id: any) => {
        return await requestClient.get<IAppVersionItem[]>('/admin/package.AppPackage/show', {id})
    }
    // 新增版本
    static updateAppVersion = async (data: any) => {
        return await requestClient.post('/admin/package.AppPackage/store', data)
    }
    // 删除版本
    static delAppVersion = async (id: any) => {
        return await requestClient.post('/admin/package.AppPackage/destroy', {id})
    }
    static SeckillGoodsSta = async () => {
        return await requestClient.get('/admin/seckill.SeckillGoods/statistics')
    }
    static resumePreview = async (params: any) => {
        return await requestClient.post('/admin/share_talents.resume/preview',params)
    }
    static resumeExport = async (params: any) => {
        return await requestClient.post('/admin/share_talents.resume/export',params)
    }
    static companyAdd = async (params: any) => {
        return await requestClient.post('/admin/Agent/companyAdd',params)
    }
    static companyList = async (params: any) => {
        return await requestClient.get('/admin/Agent/companyList',params)
    }
    static companyDel = async (params: any) => {
        return await requestClient.post('/admin/Agent/companyDel',params)
    }
    static companyEdit = async (params: any) => {
        return await requestClient.post('/admin/Agent/companyEdit',params)
    }
    static departmentAdd = async (params: any) => {
        return await requestClient.post('/admin/Agent/departmentAdd',params)
    }
    static departmentEdit = async (params: any) => {
        return await requestClient.post('/admin/Agent/departmentEdit',params)
    }
    static departmentList = async (params: any) => {
        return await requestClient.get('/admin/Agent/departmentList',params)
    }
    static departmentDel = async (params: any) => {
        return await requestClient.post('/admin/Agent/departmentDel',params)
    }
    static employeeAdd = async (params: any) => {
        return await requestClient.post('/admin/Agent/employeeAdd',params)
    }
    static employeeEdit = async (params: any) => {
        return await requestClient.post('/admin/Agent/employeeEdit',params)
    }
    static employeeList = async (params: any) => {
        return await requestClient.get('/admin/Agent/employeeList',params)
    }
    static employeeDel = async (params: any) => {
        return await requestClient.post('/admin/Agent/employeeDel',params)
    }
    static departmentAll = async (params: any) => {
        return await requestClient.get('/admin/Agent/departmentAll',params)
    }
    static distributionAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.Goods/distributionAdd',params)
    }

}
