
// 设置参数
import {defineComponent, reactive, toRefs, watch} from "vue";
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons-vue';

interface IState {
  paramsArrays: {
    key: string
    value: any
    id: number
  }[]
}

export default defineComponent({
  name: "ParamsSet",
  components: {PlusOutlined, MinusCircleOutlined},
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const state: IState = reactive({
      paramsArrays: []
    })

    watch(() => props.params, (newVal: any) => {
      if (newVal) {
        console.log(newVal)
        let params: any[] = []
        let now = Number(new Date())
        for (let key in newVal) {
          now += 1
          params.push({key, value: newVal[key], id: now})
        }
        state.paramsArrays = params
      }
    })

    // 添加参数
    const addParams = () => {
      console.log('add')
      state.paramsArrays.push({key: '', value: '', id: Number(new Date())})
    }

    // 删除参数
    const removeParams = (id: number) => {
      state.paramsArrays = state.paramsArrays.filter(e => e.id !== id)
    }

    // 获取参数
    const getParams = () => {
      if (state.paramsArrays.length) {
        let obj: any = {}
        state.paramsArrays.map(e => obj[e.key] = e.value)
        return obj
      }
      return null
    }

    return {
      ...toRefs(state),
      addParams,
      removeParams,
      getParams,
    }
  }
})
