
    import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
    import apiSetting from "@/request/apis/api_setting";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    interface IState {
        list:any,
        searches:any,
        total:any,
    }

    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                list:[],
                searches:{
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize,
                    coupon_name:'',
                    goods_name:''
                },
                total:0,
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '拼团编号',
                    dataIndex: 'team_sn',
                    key: 'team_sn',
                    align:'center'
                },
                {
                    title: '发起人',
                    dataIndex: 'nickname',
                    key: 'nickname',
                    align:'center'
                },
                {
                    title: '参加人数',
                    dataIndex: 'peopleJoin',
                    key: 'peopleJoin',
                    align:'center'
                },
                {
                    title: '商品名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '商品图片',
                    dataIndex: 'image',
                    key: 'image',
                    align:'center'
                },
                {
                    title: '商品价格',
                    dataIndex: 'price',
                    key: 'price',
                    align:'center'
                },
                {
                    title: '拼团价',
                    dataIndex: 'team_price',
                    key: 'team_price',
                    align:'center'
                },
                {
                    title: '备注',
                    dataIndex: 'spec_value_str',
                    key: 'spec_value_str',
                    align:'center'
                },
                {
                    title: '开团时间',
                    dataIndex: 'kaituan_time',
                    key: 'kaituan_time',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status_text',
                    key: 'status_text',
                    align:'center'
                },
            ];

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiSetting.FoundLists(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                }
            }


            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.page_size,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                columns,
                pagination,
                handleTableChange
            }
        }
    });
