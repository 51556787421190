// 跳转配置新增编辑参数
export interface IDictionaryUpdate {
    id?: number
    pid: number // 上级id
    name: string // 名称
    link: string //链接
    params: any //参数
    link_type: number // 链接类型
    terminal: number
}

// 跳转配置列表item
export interface IDictionaryItem {
    app_id: string
    create_time: string
    id: number
    link: string
    link_type: number
    name: string
    params: null
    pid: number
    sort: number
    status: number
    terminal: number
    update_time: string
}

export const dictionaryTerminal = [
    {name: "PC端", id: 2},
    {name: "移动端", id: 1},
]