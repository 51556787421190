
    import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import * as dayjs from 'dayjs'
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";

    interface IState {
        list: any,
        visible: any,
        title: any,
        name: any,
        total: any,
        searches: any,
        editId: any,
        type: any,
        pid:any,
        sonData:any,
        visible2:any,
        visible3:any,
        position:any,
        title2:any,
        metnodsType:any,
        form:any,
        list2:[]
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                metnodsType:'',
                name: '',
                list: [],
                list2:[],
                total: 0,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                },
                editId: '',
                type: '',
                pid:'',
                sonData:[],
                visible3:false,
                position:'',
                visible2:false,
                title2:'',
                form:{}
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '快递名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '图标',
                    dataIndex: 'icon',
                    key: 'icon',
                },
                {
                    title: '网址',
                    dataIndex: 'website',
                    key: 'website',
                },
                {
                    title: '编码',
                    dataIndex: 'code',
                    key: 'code',
                },
                {
                    title: '快递100编码',
                    dataIndex: 'code100',
                    key: 'code100',
                },
                {
                    title: '快递鸟编码',
                    dataIndex: 'codebird',
                    key: 'codebird',
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width:150
                },
            ]

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiTalent.ExpressList(params)
                if (res && res.error_code == 5001) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                }
            }



            const handleOk2 = async() => {
                const params = state.form
                if(state.metnodsType == 1){
                    const res = await apiTalent.ExpressAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible3= false
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }else{
                    const res = await apiTalent.ExpressEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible3= false
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }

            }
            const onDel = async (val,val2) => {
                const params = {delData:{id:val.id,name:val.name,sort:val.sort}}
                const res = await apiTalent.ExpressDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        state.visible2 = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                getList()
            }

            const onReset = () => {
                state.searches = {
                    name:"",
                    level:3
                }
                getList()
            }


            const addPosition = () => {
                state.visible3 = true
                state.title = "新增技能"
                state.metnodsType = 1
                state.position =""
                state.form={
                    name:'',
                    poster:'',
                    website:'',
                    code:'',
                    code100:'',
                    codebird:'',
                    sort:''
                }
            }



            const edit = (val) => {
                state.title = "编辑"
                state.metnodsType = 2
                state.visible3 = true
                state.form={
                    id:val.id,
                    name:val.name,
                    poster:val.icon,
                    website:val.website,
                    code:val.website,
                    code100:val.code100,
                    codebird:val.codebird,
                    sort:val.sort
                }
            }

            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.form.poster = data.uri
                    }
                }
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                edit,
                formRef,
                uploadHeaders,
                uploadAction,
                uploadFormData,
                handleChange,
                onSearch,
                dayjs,
                onReset,
                pagination,
                handleOk2,
                onDel,
                addPosition,
                handleTableChange
            }
        }
    });
