
import {onMounted, reactive, toRefs, computed, ref} from "vue";
import EditorComponent from '@/components/editor/Editor.vue'
import ApiMember from "@/request/apis/api_member";
import {message} from "ant-design-vue/es";
import PageUtil from "@/utils/page_util";
import UploadUtil from "@/utils/upload_util";
import {baseUrl} from "@/utils/config";
import apiMember from "@/request/apis/api_member";
import { UploadOutlined } from '@ant-design/icons-vue';
interface IState {
  list: any,
  searches: any,
  total: any,
  visible: any,
  title: any,
  formState: any,
  type: any,
  levelData: any,
  artData: any,
  introVisible: boolean;
  about: string
}

export default ({
  name: 'TeacherList',
  components: {EditorComponent, UploadOutlined},
  setup() {

    const editorComponentRef = ref()
    const formRef = ref();
    const uploadHeaders = UploadUtil.uploadHeaders()
    let uploadAction = baseUrl + '/admin/upload/image'
    let uploadFormData = {cid: 10}


    const state: IState = reactive({
      list: [],
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        name: ''
      },
      total: 0,
      title: '',
      visible: false,
      artData: [],
      levelData: [],
      formState: {
        id: '',
        name: '',
        intro: '',
        image: '',
        sort: '',
        position: ''
      },
      type: '',
      introVisible: false,
      about: ''
    })

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center'
      },
      {
        title: '导师姓名',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 200
      },
      {
        title: '导师头像',
        dataIndex: 'image',
        key: 'image',
        align: 'center'
      },
      {
        title: '头衔',
        dataIndex: 'position',
        key: 'position',
        align: 'center',
      },
      {
        title: '导师简介',
        dataIndex: 'intro',
        key: 'intro',
        align: 'center',
      },
      {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        align: 'center'
      },
      {
        title: '操作',
        key: 'action',
        width: 150
      },
    ];
    const rules = {
      name: [{required: true, message: "请输入标签名称"}],
      image: [{required: true, message: "请上传导师头像"}],
      intro: [{required: false, message: "请输入导师简介"}]
    }
    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否启用pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    const getList = async () => {
      const params = {...state.searches}
      const res = await ApiMember.tutorList(params)
      if (res.error_code == 0) {
        const data: any = res.data
        state.list = data.data
        state.total = data.total
      } else {
        message.error(res.msg)
      }
    }

    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    const addApply = () => {
      state.title = "新增导师"
      state.type = 1
      state.formState = {
        name: '',
        intro: '',
        image: '',
        sort: '',
        position: ''
      }
      state.visible = true
    }

    const edit = (val) => {
      state.title = "编辑"
      state.type = 2
      state.formState = {
        id: val.id,
        name: val.name,
        image: val.image,
        sort: val.sort,
        intro: val.intro,
        position: val.position
      }
      state.visible = true
    }

    const handleChange = ({file}: any) => {
      if (file.status === 'done' && file.response) {
        const res = file.response
        if (res && res.error_code === 0) {
          const data = res.data
          state.formState.image = data.uri
        }
      }
    }


    const onSubmit = async () => {
      formRef.value.validate().then(async () => {
        console.log(editorComponentRef.value)
        const params = {...state.formState, intro: editorComponentRef.value.getHtmlText()}
        if (state.type == 1) {
          const res = await ApiMember.tutorAdd(params)
          if (res.error_code == 0) {
            state.visible = false
            await getList()
          } else {
            message.error(res.msg)
          }
        } else {
          const res = await ApiMember.tutorEdit(params)
          if (res.error_code == 0) {
            message.success(res.msg)
            state.visible = false
            await getList()
          } else {
            message.error(res.msg)
          }
        }
      })
          .catch((error: any) => {
            console.log("error", error)
          })
    }

    const onDel = async (item) => {
      const params = {id: item.id}
      const res = await apiMember.tutorDel(params)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    const showAbout = (intro: string, flg: boolean) => {
      state.about = intro;
      state.introVisible = flg
    }

    const onSearch = () => {
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }
    const onReset = () => {
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      state.searches.name = ''
      getList()
    }
    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      getList,
      editorComponentRef,
      uploadHeaders,
      uploadAction,
      onSearch,
      onReset,
      uploadFormData,
      pagination,
      columns,
      edit,
      onDel,
      formRef,
      handleChange,
      onSubmit,
      rules,
      addApply,
      handleTableChange,
      showAbout
    }
  }
})
