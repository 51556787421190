<template>
<div>
    <div class="main">
        <div class="mainTitle">店铺设置</div>
        <div style="float:right;margin-right: 20px">
            <a-button type="primary">保存</a-button>
        </div>
    </div>
    <a-card style="padding-top:10px">
        <a-form
                name="basic"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 10 }"
                autocomplete="off"
                @finish="onFinish"
                @finishFailed="onFinishFailed"

        >
            <div class="title">营业设置</div>
            <a-form-item label="经营状态" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">营业</a-radio>
                    <a-radio value="2">休息</a-radio>
                </a-radio-group>
                <div class="tips">设置休息后，买家将无法在店内消费，请谨慎操作。</div>
            </a-form-item>
            <a-form-item label="营业时间" style="margin-top:40px">
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">全天</a-radio>
                    <a-radio value="2">自定义</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="企业服务热线" >
                <a-input placeholder="企业服务热线"/>
            </a-form-item>

            <div class="title">营业设置</div>
            <a-form-item label="店铺名称" >
                <a-input placeholder="店铺名称"/>
            </a-form-item>
            <a-form-item label="店铺介绍" >
                <a-textarea :rows="6" placeholder="店铺介绍"/>
            </a-form-item>
            <a-form-item label="主营类目" >
                <a-select v-model:value="value3" placeholder="请选择">
                    <a-select-option value="lucy">Lucy</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="店铺logo" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
                <div class="tips2">建议：上传尺寸为400*400的PNG图片，该设置将在保存后30分钟内生效。</div>
            </a-form-item>
            <a-form-item label="店铺门头（APP）" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
                <div class="tips2">建议：上传尺寸为400*400的PNG图片，该设置将在保存后30分钟内生效。</div>
            </a-form-item>
            <a-form-item label="店铺门头(网站)" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
                <div class="tips2">建议：上传尺寸为400*400的PNG图片，该设置将在保存后30分钟内生效。</div>
            </a-form-item>
            <a-form-item label="营业执照" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
            </a-form-item>
            <a-form-item label="许可证" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
            </a-form-item>
            <a-form-item label="证照与资质上传" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
            </a-form-item>
            <a-form-item label="轮播图（APP）" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
                <div class="tips2">建议：上传尺寸为400*400的PNG图片，该设置将在保存后30分钟内生效。</div>
            </a-form-item>
            <a-form-item label="轮播图（网站）" >
                <a-upload
                        v-model:file-list="fileList"
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <loading-outlined v-if="loading"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div class="ant-upload-text">上传</div>
                    </div>
                </a-upload>
                <div class="tips2">建议：上传尺寸为 宽度为满屏(一般为1600) 高度为300 的PNG图片。</div>
            </a-form-item>
            <div class="title">评论设置</div>
            <a-form-item label="评论设置" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                    <a-radio value="2">关闭</a-radio>
                </a-radio-group>
                <div class="tips2">根据《电商法》要求，经营者应当建立健全信用评价制度，未为消费者提供评价的途径涉嫌违规罚款。为保障商家和消费者权益，买家评价功能默认为开启。</div>
            </a-form-item>
            <div class="title" style="margin-top: 70px">商品设置</div>
            <a-form-item label="商品销量展示" style="margin-top: 40px">
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                    <a-radio value="2">关闭</a-radio>
                </a-radio-group>
                <div class="tips2">选择展示，商品详情页中将展示当前商品的销量。</div>

                <div style="margin-top:30px">
                    <a-checkbox v-model:checked="checked"></a-checkbox>
                    <span style="margin-left:10px;margin-right: 10px">销量达到</span><a-input placeholder="" style="width:100px;"/><span style="margin-left:20px">件时展示。</span>
                    <span></span>
                </div>
            </a-form-item>
            <a-form-item label="售罄商品展示" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">展示</a-radio>
                    <a-radio value="2">不展示</a-radio>
                </a-radio-group>
                <div class="tips2">展示后，售罄商品会在店铺中展示，并显示“已售罄”标记。</div>
            </a-form-item>

            <div class="title" style="margin-top:50px">交易设置</div>
            <a-form-item label="库存扣减方式" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">拍下减库存</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:10px">发布商品时，库存扣减方式将默认选择拍下减库存，你也可以手动修改。</div>
            </a-form-item>
            <a-form-item label="拍下扣库存预占时间" style="margin-top: 40px">
                <a-input placeholder="拍下扣库存预占时间" style="width:100px;"/><span style="margin-left:20px">分钟内未付款，库存自动释放，方便其他消费者下单。</span>
                <div class="tips2" style="margin-top:10px">拍下未付款订单 24 小时内未付款，自动取消订单。</div>
            </a-form-item>
            <a-form-item label="待付款订单取消时间" style="margin-top:40px">
                <a-input placeholder="待付款订单取消时间" style="width:100px;"/><span style="margin-left:20px">小时内未付款，自动取消订单。</span>
                <div class="tips2" style="margin-top:10px">分钟内未付款，库存自动释放，方便其他消费者下单。</div>
            </a-form-item>
            <a-form-item label="发货后自动确认收货时间" style="margin-top:40px">
                <a-input placeholder="发货后自动确认收货时间" style="width:100px;"/><span style="margin-left: 20px">天，自动确认收货。</span>
                <div class="tips2" style="margin-top:10px"><span >发货后 7 天，自动确认收货。 （修改后将对新产生的订单立即生效，已产生的订单自动确认收货时间将在以发货时的设置为准。）</span></div>
            </a-form-item>
            <a-form-item label="单品/单笔订单 多运单发货" style="margin-top:40px">
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:5px">单品按数量拆分多包裹发货，支持填写多个运单号。【新增运单】。</div>
            </a-form-item>


            <div class="title" style="margin-top:50px">退货设置</div>
            <a-form-item label="自动同意退货申请" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                    <a-radio value="2">关闭</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="自动确认收货时间" >
                <span style="margin-right:10px">买家发货后</span>
                <a-input placeholder="自动确认收货时间" style="width:100px;"/>
                <span style="margin-left:20px">天，自动确认收货。</span>
            </a-form-item>


            <div class="title"  style="margin-top:50px">支付设置</div>
            <a-form-item label="微信支付" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:10px">资金结算至店铺余额，结算时扣除0.6%交易手续费（微信收取）。（仅支持境内使用，支付宝内无法使用微信支付）</div>
            </a-form-item>

            <a-form-item label="支付宝支付" style="margin-top:40px">
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:10px">资金结算至店铺余额，结算时扣除0.6%交易手续费（支付宝收取）。（仅支持境内使用，微信内无法使用支付宝支付）</div>
            </a-form-item>

            <a-form-item label="银行卡支付" style="margin-top:40px" >
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:10px">支付使用储蓄卡和信用卡。资金结算至店铺余额，结算时扣除0.6%交易手续费（支付机构收取）。</div>
            </a-form-item>

            <a-form-item label="货到付款" style="margin-top:40px">
                <a-radio-group v-model:value="value" name="radioGroup">
                    <a-radio value="1">开启</a-radio>
                    <a-radio value="2">关闭</a-radio>
                </a-radio-group>
                <div class="tips2" style="margin-top:10px">启用货到付款后，请自行安排合作快递/物流完成收款和结算，建供易营不提供代收货款服务。</div>
            </a-form-item>
        </a-form>
    </a-card>


</div>
</template>


<style scoped>
    .title{
        width: 100%;
        background: rgba(24, 144, 255, 0.1);
        height: 35px;
        line-height: 35px;
        font-weight: bold;
        padding-left: 30px;
        margin-bottom: 30px;
    }
.tips{
    font-size: 12px;
    margin-top: 10px;
    position: absolute;
    margin-bottom: 10px;
    color: #ccc;
    letter-spacing: .1em;
}
    .tips2{
        font-size: 12px;
        position: absolute;
        color: #ccc;
        letter-spacing: .1em;
    }
    .main{
        width: 100%;
        height: 50px;
        background: #fff;
        line-height: 50px;
        position:sticky;
        top:100px;
        z-index: 100;
    }
    .mainTitle{
        float: left;
        padding-left: 20px;
        font-size: 18px;
        font-weight: bold;
    }
</style>