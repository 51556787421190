
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";

    interface IState {
        list: any,
        searches: any,
        total: any,
        formState: any,
        type: any,
        activeKey: any,
        typeData: any,
        visible: any,
        detailData: any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    shop_name:'',
                    goods_name:''
                },
                typeData: [],
                visible: false,
                total: 0,
                title: '',
                activeKey: '',
                formState: {
                    id: '',
                    name: '',
                    image: '',
                    remark: '',
                    sort: ''
                },
                type: '',
                detailData: ''
            })

            const columns = [
                {
                    title: '售后信息',
                    dataIndex: 'aftersale',
                    key: 'aftersale',
                    width: 500
                },
                {
                    title: '商家信息',
                    dataIndex: 'shop',
                    key: 'shop',
                    width: 500
                },
                {
                    title: '商品信息',
                    dataIndex: 'goods',
                    key: 'goods',
                    width: 550
                },
                {
                    title: '会员信息',
                    dataIndex: 'user',
                    key: 'user',
                    width: 550
                },
                {
                    title: '售后状态',
                    key: 'refund_status',
                    dataIndex: 'refund_status',
                    align: 'left',
                    width: 500
                },

                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const activeKeyChange = (val) => {
                state.searches.type = val
                state.searches.page = 1
                getList()
            }
            const getList = async () => {
                const params = state.searches
                const res = await ApiMember.afterSaleLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const detail = async (val) => {
                state.visible = true
                state.detailData = val
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onDel = async (item) => {
                const params = {id: item.id}
                const res = await apiMember.CategoryDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onStatus = async (item, item2) => {
                const params = {id: item.id}
                const res = await apiMember.CommentChangeStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const gettype = async () => {
                const params = {}
                const res = await apiMember.statistics(params)
                if (res) {
                    if (res.error_code === 0) {
                        state.typeData = res.data
                    }
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.shop_name = ''
                state.searches.goods_name = ''
                getList()
            }
            onMounted(() => {
                getList()
                gettype()
            })

            return {
                ...toRefs(state),
                getList,
                uploadHeaders,
                uploadAction,
                onSearch,
                onReset,
                onStatus,
                uploadFormData,
                detail,
                pagination,
                columns,
                onDel,
                formRef,
                gettype,
                handleChange,
                activeKeyChange,
                handleTableChange
            }
        }
    })
