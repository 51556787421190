
// 设置分类
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs, watch} from 'vue';
import type {UnwrapRef} from 'vue';
import ApiArticle from "@/request/apis/api_article";
import {message} from "ant-design-vue/es";
import UploadComponent from "@/components/UploadComponent.vue";
import {IArticleCategory} from "@/models/article_model";

interface FormState {
  name: string;// 分类名称
  remark: string;//备注
  image: string;
  pid: any;
  sort: any,
  link:any,
  sn:any
}

interface IState {
  loading: boolean;
  editItem?: IArticleCategory;
  list: IArticleCategory[]
}

export default defineComponent({
  props: ['editItem', 'reset'],
  name: "ArticleCategorySet",
  components: {UploadComponent},
  setup(props) {
    const state: IState = reactive({
      loading: false,
      editItem: props.editItem,
      list: []
    })

    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      name: '',
      remark: '',
      image: '',
      pid: 0,
      link:'',
      sort: '',
      sn:""
    });


    watch(() => props.editItem, (newValue, oldValue) => {
      state.editItem = props.editItem
      formState.name = newValue?.name || '';
      formState.remark = newValue?.remark || '';
      formState.image = newValue?.image || '';
      formState.pid = newValue?.pid || 0
      formState.sort = newValue ? newValue.sort : ''
      formState.link = newValue?.link || ''
      formState.sn = newValue?.sn || ''
    })

    const rules = {
      name: [{required: true, message: '请输入分类名称', trigger: ['change', 'blur']},],
      remark: [{required: true, message: '请输入分类描述', trigger: ['change', 'blur']}],
      // image: [{required: true, message: '请上传分类图片', trigger: ['change', 'blur']}],
    };

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            if (props.editItem) {
              onEdit()
            } else {
              onAdd()
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      getList()
      if(props.reset){
        props.reset()
      }
    };

    // 获取分类列表
    const getList = async () => {
      const res = await ApiArticle.getArticleCategory()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    // 添加资讯分类
    const onAdd = async () => {
      state.loading = true
      const res = await ApiArticle.addArticleCategory(toRaw(formState))
      state.loading = false
      if (res) {
        if (res.error_code === 0) {
          message.success('添加分类成功')
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑分类
    const onEdit = async () => {
      let data = {...props.editItem, ...toRaw(formState)}
      if(!data.pid){
        data.pid = 0
      }
      state.loading = true
      const res = await ApiArticle.editArticleCategory(data)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('编辑分类成功')
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    const onImageChange = (url: string) => {
      formState.image = url
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
      onAdd,
      onEdit,
      onImageChange
    }
  }
})
