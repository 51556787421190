import {baseRequestClient} from "@/request/request_client";
import {ILoginData, ILoginResult, IUserInfo, IVerificationConfig} from "@/models/user_models";
import StorageUtil from "@/utils/storage_util";
import router from "@/router";
import {homeRoute} from "@/utils/config";

const requestClient = baseRequestClient

// 管理员
export default class ApiUser {

    // POST 登录
    static login = async (data: ILoginData) => {
        StorageUtil.clear()
        const res = await requestClient.post<ILoginResult>('/admin/login/login', data)
        if (res.error_code == 0) {
            // 成功
            let expiredTime = res.data?.expire_time || 0
            if (expiredTime) {
                expiredTime += 28800;// 过期时间延迟8个小时
            }
            StorageUtil.setToken(res.data?.access_token || '', expiredTime)
            router.replace(homeRoute).then()
            // router.replace('/').then()
        }
        return res
    }

    // GET 获取登录验证码
    static getVerificationCode = async () => {
        return await requestClient.get<IVerificationConfig>('/admin/login/login')
    }

    // POST 获取当前管理员信息
    static getUserInfo = async () => {
        return await requestClient.post<IUserInfo>('/admin/admin/current')
    }

    // POST 修改管理员密码
    static editUserPassword = async (data: any) => {
        return await requestClient.post<ILoginResult>('/admin/admin/password', data)
    }

    // GET 获取聊天对象信息 //司机信息courier_id //用户信息user_id//管理员信息id
    static getChatUser = async (params: any) => {
        return await requestClient.get<{
            name: string;
            id: any;
            avatar: string
        }>('/admin/user.user/getUser', params)
    }

    // GET 获取聊天记录 ?from=1&to=5&ftype=1&ttype=1&page_size=10&page_no=1
    // from:用户id; to:跟谁聊天的用户id
    // ftype: 用户类型0管理员1用户, ttype:聊天对象的用户类型0管理员1用户
    static getMsgList = async (params: any) => {
        params.ftype = 0;
        params.ttype = 1;
        params.page_size = 15;
        return await requestClient.get<any>('/admin/user.user/privateRecord', params);
    }

    // POST 发送验证码
    static SmsSend = async (data: any) => {
        return await requestClient.post('/admin/login/sendSms', data)
    }
    //验证码登录

    static smsLogin = async (data: ILoginData) => {
        StorageUtil.clear()
        const res = await requestClient.post<ILoginResult>('/admin/login/smsLogin', data)
        if (res.error_code == 0) {
            // 成功
            let expiredTime = res.data?.expire_time || 0
            if (expiredTime) {
                expiredTime += 28800;// 过期时间延迟8个小时
            }
            StorageUtil.setToken(res.data?.access_token || '', expiredTime)
            router.replace(homeRoute).then()
            // router.replace('/').then()
        }
        return res
    }

    static indexState = async () => {
        return await requestClient.get('/admin/index/stat')
    }


    // GET 首页数据
    static chartData = async () => {
        return await requestClient.get('/admin/index/chartData')
    }

    static indexShop = async (params: any) => {
        return await requestClient.get('/admin/index/shop',params)
    }

    static GoodList = async (params: any) => {
        return await requestClient.get('/admin/goods.goods/lists',params)
    }


    static categoryList = async (params: any) => {
        return await requestClient.get('/admin/goods.category/lists',params)
    }
    static goodsReAudit = async (params: any) => {
        return await requestClient.post('/admin/goods.Goods/reAudit',params)
    }
    static getListByLevelOne = async (params: any) => {
        return await requestClient.get('/admin/goods.category/getListByLevelOne',params)
    }
    static CategoryDel = async (params: any) => {
        return await requestClient.post('/admin/goods.Category/del',params)
    }
    static CategoryEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.Category/edit',params)
    }

    static CategoryAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.Category/add',params)
    }
    static CategoryLists = async (params: any) => {
        return await requestClient.get('/admin/goods.Category/lists',params)
    }
    static CategoryLists2 = async (params: any) => {
        const header={"app-id":'GXCC1000618800'}
        return await requestClient.get('/admin/goods.Category/lists',params,header)
    }

    static unitLists = async (params: any) => {
        return await requestClient.get('/admin/goods.unit/lists',params)
    }
    static unitAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.unit/add',params)
    }
    static unitDel = async (params: any) => {
        return await requestClient.post('/admin/goods.unit/del',params)
    }
    static unitEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.unit/edit',params)
    }
    static brandLists = async (params: any) => {
        return await requestClient.get('/admin/goods.brand/lists',params)
    }
    static brandDel = async (params: any) => {
        return await requestClient.post('/admin/goods.brand/del',params)
    }
    static brandAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.brand/add',params)
    }
    static brandEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.brand/edit',params)
    }

    static ColumnLists = async (params: any) => {
        return await requestClient.get('/admin/goods.Column/lists',params)
    }

    static ColumnAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.Column/add',params)
    }
    static ColumnDel = async (params: any) => {
        return await requestClient.post('/admin/goods.Column/del',params)
    }
    static ColumnEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.Column/edit',params)
    }
    static OrderLists = async (params: any) => {
        return await requestClient.get('/admin/order.Order/lists',params)
    }
    static StoreLists = async (params: any) => {
        return await requestClient.get('/admin/shop.Store/lists',params)
    }

    static ApplyLists = async (params: any) => {
        return await requestClient.get('/admin/shop.Apply/lists',params)
    }
    static ApplyAudit = async (params: any) => {
        return await requestClient.post('/admin/shop.Apply/audit',params)
    }
    static StoreAdd = async (params: any) => {
        return await requestClient.post('/admin/shop.Store/add',params)
    }
    static StoreEdit = async (params: any) => {
        return await requestClient.post('/admin/shop.Store/edit',params)
    }
    static StoreSet = async (params: any) => {
        return await requestClient.post('/admin/shop.Store/set',params)
    }
    static StoreAccount = async (params: any) => {
        return await requestClient.post('/admin/shop.Store/account',params)
    }
    static getGoodList = async (params: any) => {
        const header={"app-id":'GXCC1000618800'}
        return await requestClient.get('/admin/goods.goods/lists',params,header)
    }
    static StoreLists2 = async (params: any) => {
        const header={"app-id":'GXCC1000618800'}
        return await requestClient.get('/admin/shop.Store/lists',params,header)
    }
    static shareTalentsDay = async () => {
        return await requestClient.get('/admin/party.Home/shareTalentsDay')
    }

    static shareTalentsMonth = async () => {
        return await requestClient.get('/admin/party.Home/shareTalentsMonth')
    }
    static shareTalentsClass = async () => {
        return await requestClient.get('/admin/party.Home/shareTalentsClass')
    }
    static destroyIndex = async (params: any) => {
        return await requestClient.get('/admin/shop.destroy/index',params)
    }
    static destroyAudit = async (params: any) => {
        return await requestClient.post('/admin/shop.destroy/audit',params)
    }

}

