
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import {useRouter, useRoute} from 'vue-router'
    import ApiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user"
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import dayjs from "dayjs";
    import type {CascaderProps} from 'ant-design-vue';
    import router from "@/router";

    interface IState {
        list: any,
        searches: any,
        searches2: any,
        total: any,
        formState: any,
        type: any,
        goodList: any,
        recordData: any,
        okText: any,
        detailData: any,
        count2: any,
        total2: any,
        total3: any,
        goods_name: any,
        page3: any,
        showtype: any,
        multipleSelection: any,
        typeName: any,
        typeList: any,
        goods_categorys: any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list: [],
                goods_name: '',
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ''
                },
                okText: "下一步",
                searches2: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ''
                },
                total: 0,
                goodList: [],
                total3: 0,
                page3: 1,
                showtype: 1,
                recordData: [],
                multipleSelection: [],
                detailData: '',
                count2: [],
                formState: {
                    name: '',
                    money: '',
                    shop_id: '',
                    type: undefined,
                    send_time_start: '',
                    send_time_end: '',
                    send_total_type: '',
                    send_total: '',
                    condition_type: '',
                    condition_money: '',
                    use_time_type: '',
                    use_time_start: '',
                    use_time_end: '',
                    use_time: '',
                    get_type: '',
                    get_num_type: '',
                    get_num: '',
                    use_goods_type: "",
                    status: '',
                    goods_ids: [],
                    day_get_num: "",
                    tomorrow_use_time: '',
                    goods_categorys: "",
                },
                type: '',
                total2: 0,
                typeName: '',
                typeList: [],
                goods_categorys: ref<string[]>([])
            })


            const rules = {
                name: [{required: true, message: "优惠券名称"}],
                money: [{required: true, message: "优惠券面额"}],
                type: [{required: true, message: "类型"}],
                send_time_start: [{required: true, message: "优惠券发放开始时间"}],
                send_time_end: [{required: true, message: "优惠券发放结束时间"}],
                send_total_type: [{required: true, message: "发送总量类型"}],
                send_total: [{required: true, message: "发送限制张数"}],
                condition_type: [{required: true, message: "使用条件类型"}],
                condition_money: [{required: true, message: "使用条件"}],
                use_time_type: [{required: true, message: "用券时间类型"}],
                use_time_start: [{required: true, message: "使用开始时间"}],
                use_time_end: [{required: true, message: "使用截止时间"}],
                use_time: [{required: true, message: "领取后多少天内可用"}],
                get_type: [{required: true, message: "领取类型"}],
                get_num_type: [{required: true, message: "领取次数类型"}],
                get_num: [{required: true, message: "领取限制次数"}],
                use_goods_type: [{required: true, message: "适用商品类型"}],
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const formRef = ref();

            const getGoods = async () => {
                const params = {page_no: state.page3, page_size: 10, type: '1', goods_name: state.goods_name}
                const res = await ApiUser.getGoodList(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    // state.goodList=data.lists
                    data.lists.forEach((item)=>{
                        state.goodList.push(item)
                    })
                    state.total3 = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const getType = async () => {
                const params = {page: state.page3, limit: 10, name: state.typeName}
                const res = await ApiUser.CategoryLists2(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.typeList = data

                } else {
                    message.error(res.msg)
                }
            }
            const cancle = () => {
                router.push("/coupon")
            }

            const handleChange = (value) => {
                state.goods_name = value
                state.page3 = 1
                getGoods()
            }

            const popupScroll = (e) => {
                const { clientHeight, scrollHeight, scrollTop } = e.target;
                if (clientHeight + scrollTop == scrollHeight) {
                    //计算一下当前所查分页是否还有数据，如果小于总数则查，大于则不查，防抖节流，减少服务器损耗
                    if(state.page3 < state.total3){
                        state.page3 += 1
                        getGoods()
                    }
                }

            }
            const onSubmit = async () => {
                formRef.value.validate().then(async () => {
                    if (state.formState.send_total_type == 1) {
                        state.formState.send_total = ""
                    }
                    if (state.formState.condition_type == 1) {
                        state.formState.condition_money = ""
                    }
                    if (state.formState.use_time_type == 2 || state.formState.use_time_type == 3) {
                        state.formState.use_time_start = ""
                        state.formState.use_time_end = ""
                    }
                    if (state.formState.use_time_type == 1) {
                        state.formState.use_time = ""
                    }
                    if (state.formState.get_num_typ == 1) {
                        state.formState.get_num = ""
                    }
                    state.formState.goods_categorys = state.goods_categorys.join(",")
                    const params = {...state.formState}
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.ShopCouponAdd(params)
                        if (res.error_code == 0) {
                            message.success(res.msg)
                            router.push("/coupon")
                        } else {
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.ShopCouponEdit(params)
                        if (res.error_code == 0) {
                            message.success(res.msg)
                            router.push("/coupon")
                        } else {
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const route = useRoute()

            const filterOption = (input: string, option: any) => {
                return option.key.indexOf(input) >= 0;
            };
            onMounted(() => {

                const query: any = router.currentRoute.value.query
                state.type = query.type
                if (state.type == 2) {
                    const detailData = JSON.parse(query.detail)
                    state.formState.id = detailData.id
                    const aa = dayjs(detailData.send_time_start * 1000).format('YYYY/MM/DD')
                    state.formState.send_time_start = dayjs(aa, 'YYYY/MM/DD')
                    const bb = dayjs(detailData.send_time_end * 1000).format('YYYY/MM/DD')
                    state.formState.send_time_end = dayjs(bb, 'YYYY/MM/DD')
                    const cc = dayjs(detailData.use_time_start * 1000).format('YYYY/MM/DD')
                    state.formState.use_time_start = dayjs(cc, 'YYYY/MM/DD')
                    const dd = dayjs(detailData.use_time_end * 1000).format('YYYY/MM/DD')
                    state.formState.use_time_end = dayjs(dd, 'YYYY/MM/DD')
                    state.formState.condition_money = detailData.condition_money
                    state.formState.condition_type = detailData.condition_type
                    state.formState.day_get_num = detailData.day_get_num
                    state.formState.get_num = detailData.get_num
                    state.formState.get_num_type = detailData.get_num_type
                    state.formState.get_type = detailData.get_type
                    state.formState.goods_categorys = detailData.goods_categorys
                    state.formState.money = detailData.money
                    state.formState.name = detailData.name
                    state.formState.send_total = detailData.send_total
                    state.formState.send_total_type = detailData.send_total_type
                    state.formState.shop_id = detailData.shop_id
                    state.formState.status = detailData.status
                    state.formState.tomorrow_use_time = detailData.tomorrow_use_time
                    state.formState.type = detailData.type
                    state.formState.use_goods_type = detailData.use_goods_type
                    state.formState.use_time = detailData.use_time
                    state.formState.use_time_type = detailData.use_time_type
                    detailData.goods.forEach((item, index) => {
                        state.formState.goods_ids.push(item.goods_id)
                    })
                    if (detailData.goods_categorys == '') {
                        state.goods_categorys = []
                    } else {
                        detailData.categorys.forEach((item) => {
                            state.goods_categorys.push(item.id)
                        })
                    }
                    state.formState.goods_ids = Array.from(state.formState.goods_ids)
                }
                getGoods()
                getType()

            })

            return {
                ...toRefs(state),
                getGoods,
                popupScroll,
                pagination,
                handleChange,
                route,
                formRef,
                getType,
                filterOption,
                onSubmit,
                dayjs,
                cancle,
                rules
            }
        }
    })
