
    import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
    import apiSetting from "@/request/apis/api_setting";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";

    interface IState {
        list: any,
        visible: any,
        title: any,
        name: any,
        total: any,
        searches: any,
        editId: any,
        type: any,
        form: any,
        is_show:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                list: [],
                total: 0,
                searches: {
                    keywords: '',
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                },
                editId: '',
                type: '',
                is_show:undefined,
                form: {
                    first: undefined,
                    second: undefined,
                    name: ''
                }
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '状态',
                    dataIndex: 'is_show',
                    key: 'is_show',
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '最后修改时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 200
                },
            ]


            const add = () => {
                state.title = '新增分类'
                state.name = ''
                state.visible = true
                state.type = 1
            }

            const edit = (val) => {
                state.type = 2
                state.editId = val.id
                state.name = val.name
                state.is_show = val.is_show
                state.title = '编辑分类名称'
                state.visible = true
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiSetting.HelpCategoryLists(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                }
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    const params = {name: state.name, is_show:state.is_show}
                    const res = await apiSetting.HelpCategoryAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }

                if (state.type == 2) {
                    const params = {name: state.name, is_show:state.is_show,id:state.editId}
                    const res = await apiSetting.HelpCategoryEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }
            }


            const onDel = async (val, val2) => {
                const params = {id: val.id}
                const res = await apiSetting.HelpCategoryHide(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onDelete = async (val, val2) => {
                const params = {id: val.id}
                const res = await apiSetting.HelpCategoryDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                getList()
            }

            const onReset = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                state.searches.keywords=""
                getList()
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                handleOk,
                onDelete,
                onSearch,
                onReset,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
