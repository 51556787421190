
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import EditorComponent from '@/components/editor/Editor.vue'
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {courseTypes, ISectionUpdate} from "@/models/course_model";
import ApiCourse from "@/request/apis/api_course";

interface IState {
  loading: boolean;
  courseName: string;
  typeName: string;
}

// 新增编辑文章
export default defineComponent({
  name: "SectionSet",
  components: {UploadComponent, EditorComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<ISectionUpdate> = reactive({
      title: "",
      content: '',	// 课程内容
      media_type: 1, // 1视频，2音频，3书籍
      media_url: '',
      sort: 1,
      duration: ''
    });
    const rules = {
      title: [{required: true, message: "请输入文章标题"},],
    }



    // 获取课程详情
    const getDetail = async () => {
      const {course_id, section_id} = route.query
      //IArticleDetail
      if (course_id && section_id) {
        const res = await ApiCourse.getSectionDetail(course_id, section_id)
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.title = data.title
          formState.content = data.content
          formState.course_id = data.course_id
          formState.duration = data.duration || ''
          formState.section_id = data.id
          formState.media_type = data.media_type || 0
          formState.media_url = data.media_url || ''
          formState.sort = data.sort || 1
        }
      }
    }



    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onMediaChange = (url: string) => formState.media_url = url

    const state: IState = reactive({
      loading: false,
      courseName: '',
      typeName: '',
    })

    const init = () => {
      const {name = '', type = '', course_id}: any = route.query
      state.courseName = name
      state.typeName = courseTypes.find(e => e.id == type)?.name || '';
      const media_type = Number(type)
      formState.course_id = Number(course_id)
      formState.media_type = media_type
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiCourse.createSection(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('新增成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiCourse.editSection(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('修改成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }


    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            params.sort = Number(params.sort)
            params.content = editorComponentRef.value.getHtmlText()
            if (formState.section_id) {
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      init()
      getDetail()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      courseTypes,
      ...toRefs(state),
      editorComponentRef,
      onMediaChange,
    }
  },
})
