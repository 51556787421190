
    import { defineComponent, reactive } from 'vue';
    import {message} from "ant-design-vue/es";
    import apiMember from "@/request/apis/api_member";
    interface IState {
        password: string;
        old_password:string;
        conpassword: string;
    }
    export default defineComponent({
        setup() {
            const formState = reactive({
                password: '',
                conpassword: '',
                old_password:'',
            });
            const onFinish = async(values: any) => {
                console.log('Success:', values);
                if(values.password !== values.conpassword){
                    message.error("两次输入的密码不一致")
                }else{
                    const params ={old_password:formState.old_password,password:formState.password}
                    const res = await apiMember.adminPassword(params);
                    if (res && res.error_code === 0) {
                       message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            };

            const onFinishFailed = (errorInfo: any) => {
                console.log('Failed:', errorInfo);
            };
            return {
                formState,
                onFinish,
                onFinishFailed,
            };
        },
    });
