
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import ApiArticle from "@/request/apis/api_article"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {courseTypes, ICourseUpdate} from "@/models/course_model";
import ApiCourse from "@/request/apis/api_course";

interface IState {
  loading: boolean;
}

// 新增编辑文章
export default defineComponent({
  name: "CourseSet",
  components: {UploadComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<ICourseUpdate> = reactive({
      title: "",
      intro: '',	// 课程简介
      type: 1, // 1视频，2音频，3书籍
      lecturer: '',	// 讲师
      thumb: '',	// 缩略图
      sort: 999,
    })
    const rules = {
      title: [{required: true, message: "请输入文章标题"},],
      intro: [{max: 1000, message: "文章简介200字以内"},],
      lecturer: [{required: false, message: "请输入讲师"},],
      thumb: [{required: false, message: "请上传缩略图",},],
      type: [{required: true, message: "请选择课程分类"},],
      media_image: [{required: false, message: "请上传封面",},],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            params.sort = Number(params.sort)
            if (editId) {
              params.course_id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: string) => formState.thumb = url

    const state: IState = reactive({
      loading: false,
    })

    // 获取课程详情
    const getDetail = async () => {
      const {id} = route.query
      //IArticleDetail
      if (id) {
        editId = id
        const res = await ApiCourse.getCourseDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.title = data.title
          formState.intro = data.intro
          formState.type = data.type
          formState.lecturer = data.lecturer
          formState.thumb = data.thumb
          formState.sort = data.sort || 999
        }
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiCourse.createCourse(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiCourse.editCourse(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getDetail()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      onImageChange,
      goBack,
      courseTypes,
      ...toRefs(state),
      editorComponentRef,
    }
  },
})
