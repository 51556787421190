
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import dayjs, {Dayjs} from 'dayjs';
    import apiMember from "@/request/apis/api_member";
    import ApiSetting from "@/request/apis/api_setting";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any,
        appData:any,
        couponList:any,
        page:any,
        coutotal:any,
        goodtotal:any,
        page2:any,
        goolList:any,
        showtype:any,
        oKtext:any,
        name:any,
        shop_name:any,
        multipleSelection:any,
        shop_name_title:any,
        sysName:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                shop_name_title:'',
                searches:{
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize,
                    name:'',
                    shop_name:''
                },
                name:'',
                sysName:'',
                multipleSelection:[],
                shop_name:"",
                total:0,
                showtype:1,
                oKtext:'下一步',
                title:'',
                visible:false,
                formState:{
                   name:"",
                    type:"",
                    shop_id:"",
                    belonging_app_id:undefined,
                    validity_time_start:"",
                    validity_time_end:"",
                    status:undefined,
                    sort:""
                },
                type:'',
                appData:[],
                couponList:[],
                goolList:[],
                page:1,
                coutotal:0,
                goodtotal:0,
                page2:1
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '项目名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '店铺名',
                    dataIndex: 'shop_name',
                    key: 'shop_name',
                    align:'center'
                },
                {
                    title: '所属平台',
                    dataIndex: 'app_name',
                    key: 'app_name',
                    align:'center'
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                {
                    title: '开始时间',
                    key: 'validity_time_start',
                    dataIndex: 'validity_time_start',
                    align:'center'
                },
                {
                    title: '结束时间',
                    key: 'validity_time_end',
                    dataIndex: 'validity_time_end',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250,
                    align:'center'
                },
            ];

            const columns2 = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    width:300
                },
                {
                    title: '店铺名称',
                    dataIndex: 'name',
                    key: 'name',
                    width:300
                },
                {
                    title: '简介',
                    dataIndex: 'intro',
                    key: 'intro',
                    width:550
                },
            ];
            const rules = {
                name: [{required: true, message: "请输入商品名称"}],
                type: [{required: true, message: "请选择类型"}],
                shop_id: [{required: true, message: "请选择商家"}],
                belonging_app_id: [{required: true, message: "请选择所属平台"}],
                validity_time_start: [{required: true, message: "请选择开始时间"}],
                validity_time_end: [{required: true, message: "请选择结束时间"}],
                status: [{required: true, message: "请选择状态"}],
                sort: [{required: false, message: "请输入排序"}],
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.page_size,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.veryGoodsList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };

            const addApply = () =>{
                state.title = "新增项目"
                state.type = 1
                state.showtype = 1
                state.sysName = ""
                state.oKtext="下一步"
                state.name = ''
                state.shop_name = ''
                state.shop_name_title = ''
                state.multipleSelection = []
                state.formState={
                    name:"",
                    type:undefined,
                    shop_id:undefined,
                    belonging_app_id:undefined,
                    validity_time_start:"",
                    validity_time_end:"",
                    status:undefined,
                    sort:""
                },
                    state.goolList = []
                state.goodtotal = 0
                getApp()
                // getGoods()
                state.visible = true
            }
            const edit = (val) =>{
                state.multipleSelection = []
                state.title = "编辑项目"
                state.showtype = 1
                state.oKtext ="下一步"
                state.type = 2
                state.name = ''
                state.shop_name = ''
                state.sysName = val.app_name
                state.formState={
                    id:val.id,
                    name:val.name,
                    type:val.type,
                    shop_id:val.shop_id,
                    belonging_app_id:val.belonging_app_id,
                    validity_time_start:val.validity_time_start,
                    validity_time_end:val.validity_time_end,
                    status:val.status,
                    sort:val.sort
                },
                state.shop_name_title = val.shop_name
                state.multipleSelection.push(val.shop_id)
                state.visible = true
                getApp()
                getGoods()

            }
            const formRef = ref();
            const onSubmit = async () => {
                if(state.showtype == 1){
                    if(state.formState.belonging_app_id == '' || state.formState.belonging_app_id == undefined){
                        message.error("请选择所属平台")
                        return false
                    }else{
                        state.showtype = 2
                        state.oKtext="确定"
                    }

                }else{
                    formRef.value.validate() .then(async() => {
                        const params = {...state.formState}
                        if (state.type == 1) {
                            const params = {...state.formState}
                            const res = await ApiMember.veryGoodsAdd(params)
                            if(res.error_code == 0){
                                message.success("添加成功")
                                getList()
                                state.visible = false
                            }else{
                                message.error(res.msg)
                            }
                        } else {
                            const params = {...state.formState}
                            const res = await ApiMember.veryGoodsEdit(params)
                            if(res.error_code == 0){
                                message.success("成功")
                                state.visible = false
                                getList()
                            }else{
                                message.error(res.msg)
                            }
                        }
                    })
                        .catch((error: any) => {
                            console.log("error", error)
                        })
                }

            }

            const onDel = async (item,item2) => {
                const params = {id:item.id,status:item2}
                const res = await apiMember.goodsGoodsStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success("成功")
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onStatus = async (item,item2) => {
                const params = {id:item.id,status:item2}
                const res = await apiMember.veryGoodsStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success("成功")
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                state.searches.name = ''
                state.searches.shop_name = ''
                getList()
            }

            const getApp  = async() =>{
                const params= {}
                const res = await ApiSetting.appData(params)
                if(res && res.error_code == 0){
                    const data:any = res.data
                    state.appData = data
                }
            }
            const getShopCouponLists  = async() =>{
                const params= {page:state.page,limit:10}
                const res = await ApiMember.ShopCouponLists2(params)
                if(res && res.error_code == 0){
                    const data:any = res.data
                    state.coutotal = data.count
                    state.couponList = state.couponList.concat(data.list)
                }
            }

            const getAppid = (value,value2) =>{
                state.formState.belonging_app_id = value2.key
            }

            const handlePopupScroll = (e) => {
                const { target } = e
                const rmHeight = target.scrollHeight - target.scrollTop
                const clHeight = target.clientHeight
                if (rmHeight === 0 && clHeight === 0) {
                } else {
                    if (rmHeight < clHeight + 1) {
                        if(state.coutotal > state.couponList.length){
                            state.page = state.page+1
                            getShopCouponLists()
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }
            }

            const handlePopupScroll2 = (e) => {
                const { target } = e
                const rmHeight = target.scrollHeight - target.scrollTop
                const clHeight = target.clientHeight
                if (rmHeight === 0 && clHeight === 0) {
                } else {
                    if (rmHeight < clHeight + 1) {
                        if(state.goodtotal > state.appData.length){
                            state.page2 = state.page+1
                            getApp()
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }
            }

            const getGoods  = async() =>{
                const params= {page:state.page2,limit:10,name:state.name,"shop_name":state.shop_name,"app_id":state.formState.belonging_app_id}
                const res = await ApiUser.StoreLists2(params)
                if(res && res.error_code == 0){
                    const data:any = res.data
                    state.goodtotal = data.count
                    state.goolList = data.lists
                }
            }

            const onSearch2 = async() => {
                state.page2 = 1
                state.searches.page_size = 10
                await getGoods()
            }
            const onReset2 =( ) => {
                state.page2 = 1
                state.searches.page_size = 10
                state.name = ""
                state.shop_name = ""
                getGoods()
            }
            const handleTableChange2 = (pages: any) => {
                state.searches.page_size = 10
                state.page2 = pages.current
                getGoods()
            };
            const pagination2 = computed(() => ({
                total: state.goodtotal,
                current: state.page2,
                pageSize: 10,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const onSelect = (val1,val2) =>{
                state.formState.shop_id = val2[0].id
                state.multipleSelection = val1;
                state.shop_name_title = val2[0].name
            }

            const sysChange = (val,val2) => {
                state.sysName = val2.children[0].children
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                sysChange,
                getGoods,
                pagination2,
                getShopCouponLists,
                getApp,
                handlePopupScroll,
                handlePopupScroll2,
                onSearch,
                getAppid,
                onReset,
                pagination,
                columns,
                edit,
                onDel,
                formRef,
                onSelect,
                onSubmit,
                rules,
                onStatus,
                addApply,
                onReset2,
                onSearch2,
                dayjs,
                handleTableChange,
                columns2,handleTableChange2
            }
        }
    })
