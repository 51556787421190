
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons-vue';
import {linkTypes, terminalTypes} from "@/models/banner_model"
import {dictionaryTerminal, IDictionaryUpdate} from "@/models/setting_model";
import ApiSetting from "@/request/apis/api_setting";

interface IState {
  loading: boolean,
  paramsArrays: {
    key: string
    value: any
    id: number
  }[]
}

// 新增编辑跳转配置
export default defineComponent({
  name: "DictionarySet",
  components: {UploadComponent, PlusOutlined, MinusCircleOutlined},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any
    const formRef = ref()
    const formState: UnwrapRef<IDictionaryUpdate> = reactive({
      pid: 0, //上级id
      link_type: 3, //链接类型：0-为空;1-商城页面;2-商品页面;3-自定义类型; // 暂时传3
      status: 1,
      name: '',
      link: '', //链接
      params: null, //参数
      terminal: 2, //1移动端，2pc端
    })

    // 校验
    const rules = {
      name: [{required: true, message: "请输入标题"}],
      link: [{required: true, message: "请输入跳转链接"}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState), params: null}
            if (editId) {
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const state: IState = reactive({
      loading: false,
      paramsArrays: []
    })

    // 获取详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiSetting.getDictionaryDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.name = data.name
          formState.terminal = data.terminal
          formState.link = data.link
          formState.link_type = data.link_type
          formState.id = data.id
        }
      }
    }

    // 新增
    const onAdd = async (params: IDictionaryUpdate) => {
      state.loading = true
      const res = await ApiSetting.addDictionary(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '新增成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: IDictionaryUpdate) => {
      state.loading = true
      const res = await ApiSetting.editDictionary(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '编辑成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(async () => {
      await getDetail()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      terminalTypes,
      linkTypes,
      ...toRefs(state),
      dictionaryTerminal
    }
  },
})
