import {baseRequestClient} from "@/request/request_client";
import {LogisticsOrderItem, LogisticsOrderDetail} from "@/models/logistics_model";
import {IList, IListResult} from "@/models/base_model";

const requestClient = baseRequestClient

//  物流订单板块接口
export default class apiMember {
    /**
     * GET 订单列表
     * @param params
     */
    static addMember = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/add', params)
    }


    /**
     * GET 客户列表
     * @param params
     */

    static MemberList = async (params: any) => {
        params = params ? {...params} : {};
        if (params.time && params.time.length > 0) {
            params.start_time = Number((Date.parse(params.time[0])).toString().slice(0, 10))
            params.end_time = Number((Date.parse(params.time[1])).toString().slice(0, 10))
            delete params.time;
        }
        return await requestClient.get('/admin/user.Customer/lists', params)
    }
    /**
     * post 编辑客户
     * @param params
     */
    static editMember = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/edit', params)
    }
    /**
     * post 删除客户
     * @param params
     */
    static deleteMember = async (id: string) => {
        return await requestClient.post('/admin/user.Customer/del', {id})
    }
    /**
     * post 设置用户状态
     * @param params
     */
    static invalidMember = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/invalid', params)
    }
    /**
     * post 设置用户缴费状态
     * @param params
     */
    static feeStatus = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/feeStatus', params)
    }

    /**
     * post 导入用户
     * @param params
     */
    static memberImport = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/import', params)
    }
    //行业列表
    static industryLists = async (params: any) => {
        return await requestClient.get('/admin/user.Customer/industryLists', params)
    }
    //新建行业
    static industryAdd = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/industryAdd', params)
    }
    //删除行业
    static industryDel = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/industryDel', params)
    }
    //编辑行业
    static industryEdit = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/industryEdit', params)
    }

    //级别列表
    static levelLists = async (params: any) => {
        return await requestClient.get('/admin/user.Customer/levelLists', params)
    }
    //新建级别
    static levelAdd = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/levelAdd', params)
    }
    //删除级别
    static levelDel = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/levelDel', params)
    }
    //编辑级别
    static levelEdit = async (params: any) => {
        return await requestClient.post('/admin/user.Customer/levelEdit', params)
    }

    static TreatyIndex = async () => {
        return await requestClient.get('/admin/shop.Treaty/index')
    }
    static TreatyIndex2 = async (params: any) => {
        return await requestClient.post('/admin/shop.Treaty/index', params)
    }

    static CategoryLists = async (params: any) => {
        return await requestClient.get('/admin/shop.Category/lists',params)
    }

    static CategoryAdd = async (params: any) => {
        return await requestClient.post('/admin/shop.Category/add', params)
    }
    static CategoryDel = async (params: any) => {
        return await requestClient.post('/admin/shop.Category/del', params)
    }
    static CategoryEdit = async (params: any) => {
        return await requestClient.post('/admin/shop.Category/edit', params)
    }

    static UserLists = async (params: any) => {
        return await requestClient.get('/admin/user.User/lists',params)
    }
    static TagLists = async (params: any) => {
        return await requestClient.get('/admin/user.Tag/lists',params)
    }
    static TagAdd = async (params: any) => {
        return await requestClient.post('/admin/user.Tag/add', params)
    }
    static TagEdit = async (params: any) => {
        return await requestClient.post('/admin/user.Tag/edit', params)
    }
    static TagDel = async (params: any) => {
        return await requestClient.post('/admin/user.Tag/del', params)
    }
    static UserEdit = async (params: any) => {
        return await requestClient.post('/admin/user.User/edit', params)
    }
    static UserDisable = async (params: any) => {
        return await requestClient.post('/admin/user.User/disable', params)
    }
    static LevelAdd = async (params: any) => {
        return await requestClient.post('/admin/user.Level/add', params)
    }
    static LevelLists = async (params: any) => {
        return await requestClient.get('/admin/user.Level/lists',params)
    }
    static LevelEdit = async (params: any) => {
        return await requestClient.post('/admin/user.Level/edit',params)
    }
    static LevelDel = async (params: any) => {
        return await requestClient.post('/admin/user.Level/del',params)
    }
    static ShopCouponLists = async (params: any) => {
        return await requestClient.get('/admin/coupon.ShopCoupon/lists',params)
    }
    static BargainActivity = async (params: any) => {
        return await requestClient.get('/admin/bargain.Bargain/activity',params)
    }
    static BargainAudit = async (params: any) => {
        return await requestClient.post('/admin/bargain.Bargain/audit',params)
    }
    static BargainViolation = async (params: any) => {
        return await requestClient.post('/admin/bargain.Bargain/violation',params)
    }
    static BargainSwitchStatus = async (params: any) => {
        return await requestClient.post('/admin/bargain.Bargain/switchStatus',params)
    }
    static BargainLaunch = async (params: any) => {
        return await requestClient.get('/admin/bargain.Bargain/launch',params)
    }
    static BargainDetail = async (params: any) => {
        return await requestClient.get('/admin/bargain.Bargain/detail',params)
    }
    static CommentLists = async (params: any) => {
        return await requestClient.get('/admin/goods.Comment/lists',params)
    }
    static afterSaledetail = async (params: any) => {
        return await requestClient.get('/admin/after_sale.AfterSale/detail',params)
    }
    static afterSaleLists = async (params: any) => {
        return await requestClient.get2('/admin/after_sale.AfterSale/lists',params)
    }
    static CommentDel = async (params: any) => {
        return await requestClient.post('/admin/goods.Comment/del',params)
    }
    static CommentChangeStatus = async (params: any) => {
        return await requestClient.post('/admin/goods.Comment/changeStatus',params)
    }
    static StatisticsVisit = async (params: any) => {
        return await requestClient.post('/admin/Statistics/visit',params)
    }
    static StatisticsTrading = async (params: any) => {
        return await requestClient.post('/admin/Statistics/trading',params)
    }
    static StatisticsMember = async (params: any) => {
        return await requestClient.post('/admin/Statistics/member',params)
    }
    static StatisticsShop = async (params: any) => {
        return await requestClient.get('/admin/Statistics/shop',params)
    }
    static StatisticsGoods = async (params: any) => {
        return await requestClient.get('/admin/Statistics/goods',params)
    }
    static adminPassword = async (params: any) => {
        return await requestClient.post('/admin/admin/password',params)
    }
    static ShopCouponAdd = async (params: any) => {
        return await requestClient.post('/admin/coupon.ShopCoupon/add',params)
    }
    static ShopCouponList = async (params: any) => {
        return await requestClient.get('/admin/coupon.ShopCoupon/lists',params)
    }
    static ShopCouponEdit = async (params: any) => {
        return await requestClient.post('/admin/coupon.ShopCoupon/edit',params)
    }
    static ShopCouponRecord = async (params: any) => {
        return await requestClient.get('/admin/coupon.ShopCoupon/record',params)
    }
    static ShopCouponDetail = async (params: any) => {
        return await requestClient.get('/admin/coupon.ShopCoupon/detail',params)
    }
    static goodsGoodsAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/goodsAdd',params)
    }
    static goodsGoodsList = async (params: any) => {
        return await requestClient.get('/admin/goods.Goods/goodsList',params)
    }
    static goodsGoodsEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/goodsEdit',params)
    }
    static goodsGoodsDel = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/goodsDel',params)
    }
    static goodsGoodsStatus = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/goodsStatus',params)
    }
    static ShopCouponLists2 = async (params: any) => {
        return await requestClient.get('/admin/coupon.ShopCoupon/lists',params)
    }
    static veryGoodsAdd = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/veryGoodsAdd',params)
    }
    static veryGoodsList = async (params: any) => {
        return await requestClient.get('/admin/goods.goods/veryGoodsList',params)
    }
    static veryGoodsEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/veryGoodsEdit',params)
    }
    static veryGoodsStatus = async (params: any) => {
        return await requestClient.post('/admin/goods.goods/veryGoodsStatus',params)
    }
    static getLevel = async (params: any) => {
        return await requestClient.get('/admin/user.Level/getLevel',params)
    }
    static articleCategoryLists = async (params: any) => {
        return await requestClient.get('/admin/party.articleCategory/lists',params)
    }
    static getLevelList = async (params: any) => {
        return await requestClient.get('/admin/user.Level/getLevelList',params)
    }
    static vipWelfareAdd = async (params: any) => {
        return await requestClient.post('/admin/user.User/vipWelfareAdd',params)
    }
    static vipWelfareEdit = async (params: any) => {
        return await requestClient.post('/admin/user.User/vipWelfareEdit',params)
    }
    static vipWelfareDel = async (params: any) => {
        return await requestClient.post('/admin/user.User/vipWelfareDel',params)
    }
    static vipWelfareList = async (params: any) => {
        return await requestClient.get('/admin/user.User/vipWelfareList',params)
    }
    static statistics = async (params: any) => {
        return await requestClient.get('/admin/after_sale.AfterSale/statistics',params)
    }
    static OrderStatistics = async (params: any) => {
        return await requestClient.get('/admin/order.Order/statistics',params)
    }

    static tutorAdd = async (params: any) => {
        return await requestClient.post('/admin/party.Course/tutorAdd',params)
    }
    static tutorEdit = async (params: any) => {
        return await requestClient.post('/admin/party.Course/tutorEdit',params)
    }

    static tutorDel = async (params: any) => {
        return await requestClient.post('/admin/party.Course/tutorDel',params)
    }
    static tutorList = async (params: any) => {
        return await requestClient.get('/admin/party.Course/tutorList',params)
    }
    static activityAreGoodsnum = async () => {
        return await requestClient.get('/admin/activity_area.Goods/num')
    }
    static areaGoodsLists = async (params: any) => {
        return await requestClient.get('/admin/activity_area.Goods/lists',params)
    }
    static AreaLists = async (params: any) => {
        return await requestClient.get('/admin/activity_area.Area/lists',params)
    }
    static AreaGoodsAudit = async (params: any) => {
        return await requestClient.post('/admin/activity_area.Goods/audit',params)
    }
    static GoodsDetail = async (params: any) => {
        return await requestClient.get('/admin/activity_area.Goods/detail',params)
    }
    static SeckillTimeAddTime = async (params: any) => {
        return await requestClient.post('/admin/seckill.SeckillTime/addTime',params)
    }
    static SeckillTimeTimeLists= async (params: any) => {
        return await requestClient.get('/admin/seckill.SeckillTime/timeLists',params)
    }
    static SeckillTimeDel = async (params: any) => {
        return await requestClient.post('/admin/seckill.SeckillTime/delTime',params)
    }
    static SeckillTimeEditTime = async (params: any) => {
        return await requestClient.post('/admin/seckill.SeckillTime/editTime',params)
    }
    static contractList= async (params: any) => {
        return await requestClient.get('/admin/contract.contract/list',params)
    }
    static contractInfo= async (params: any) => {
        return await requestClient.get('/admin/contract.contract/info',params)
    }
    static contractAudit = async (params: any) => {
        return await requestClient.post('/admin/contract.contract/audit',params)
    }
    static contractLink = async (params: any) => {
        return await requestClient.get('/admin/contract.contract/link',params)
    }
    static MnpList = async (params: any) => {
        return await requestClient.get('/admin/wechat.Mnp/list',params)
    }
    static MnpAdd = async (params: any) => {
        return await requestClient.post('/admin/wechat.Mnp/add',params)
    }
    static MnpEdit = async (params: any) => {
        return await requestClient.post('/admin/wechat.Mnp/edit',params)
    }
    static MnpDel = async (params: any) => {
        return await requestClient.post('/admin/wechat.Mnp/del',params)
    }
    static OrderDetail = async (params: any) => {
        return await requestClient.get('/admin/order.Order/detail',params)
    }
    static distributionList = async (params: any) => {
        return await requestClient.get('/admin/goods.Goods/distributionList', params)
    }
    static distributionEdit = async (params: any) => {
        return await requestClient.post('/admin/goods.Goods/distributionEdit', params)
    }
    static distributionDel = async (params: any) => {
        return await requestClient.post('/admin/goods.Goods/distributionDel', params)
    }
    static tipsEdit = async (params: any) => {
        return await requestClient.post('/admin/tips/edit', params)
    }
    static tipsList = async (params: any) => {
        return await requestClient.get('/admin/tips/list',params)
    }
    static tipsStatus = async (params: any) => {
        return await requestClient.post('/admin/tips/status', params)
    }
}
