
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import {IArticleCategory, IArticleUpdate, articleTypes} from "@/models/article_model"
import ApiArticle from "@/request/apis/api_article"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import EditorComponent from '@/components/editor/Editor.vue'

interface IState {
  category: IArticleCategory[],
  loading: boolean;
  proList: any,
  value: any
}

// 新增编辑文章
export default defineComponent({
  name: "ArticleSet",
  components: {UploadComponent, EditorComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<IArticleUpdate> = reactive({
      title: "",
      //https://video-creater-file.atvideo.cc/video-editor-shop/asset/6f68558cd5d311eb8e7d00163e248bcb/1629892965465f46c71e4-5acd-49ad-bcbb-b5ae4409abd8.mp4
      content: "",
      cid: [],
      value: '',
      source: "",
      edit_user: "",
      image: "",
      image_app: "",
      type: 0, // 0文章，1视频，2音频
      intro: "",
      author: "",
      media_image: "",
      media_url: "",
      tag: '',
      extract_image: '',
      is_intro: 1,
      url: '', // 外链
      enclosure: [], // 附件
      if_project: 0,
      price: '',
      market_price: '',
      isFree: 0,
      location_province: '',
      location_province_code: '',
      location_city: '',
      location_city_code: ''

    })
    const rules = {
      title: [{required: true, message: "请输入文章标题"},],
      intro: [{max: 1000, message: "文章简介200字以内"},],
      source: [{required: false, message: "请输入文章来源"},],
      edit_user: [{required: false, message: "请输入网站编辑"},],
      price: [{required: true, message: "请输入售价"},],
      cid: [{required: true, message: "请选择文章分类", type: "array",},],
      media_image: [{required: false, message: "请上传封面",},],
      media_url: [{required: false, message: "请上传文件",},],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            params.cid = params.cid[params.cid.length - 1]
            params.content = editorComponentRef.value.getHtmlText()
            params.extract_image = getImagesFromHtml(params.content)
            const enclosure = editorComponentRef.value.getExtraFile()
            params.enclosure = enclosure.length ? JSON.stringify(enclosure) : ''
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              params.sort = 0
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: string) => formState.image = url

    const onImageAppChange = (url: string) => formState.image_app = url

    const onMediaChange = (url: string) => formState.media_url = url

    const onImageCoverChange = (url: string) => formState.media_image = url

    // 富文本
    const onEditorChange = (e: string) => {
      formState.content = e
    }

    const state: IState = reactive({
      category: [],
      loading: false,
      proList: [],
      value: []
    })

    const onTypeChange = (e: any) => {
      const value = e.target.value
      if (value === 1) {
        rules.media_url[0].message = '请输入视频网络地址或上传视频'
        rules.media_url[0].required = true
        rules.media_image[0].message = '请上传视频封面'
        rules.media_image[0].required = true
        return
      }
      if (value === 2) {
        rules.media_url[0].message = '请输入音频网络地址或上传音频'
        rules.media_url[0].required = true
        rules.media_image[0].message = '请上传音频封面'
        rules.media_image[0].required = true
        return
      }
      rules.media_url[0].required = false
      rules.media_image[0].required = false
    }

    // 获取分类列表
    const getCategoryList = async () => {
      const res = await ApiArticle.getArticleCategory()
      if (res && res.error_code === 0) {
        state.category = res.data || []
        uniqueByIsshow(state.category)
      }
    }

    const  uniqueByIsshow = (data) => {
      data.forEach((item, index) => {
        if (item.is_show == 0) {
          data.splice(index, 1);
        }
        if (item.children && item.children.length) {
          uniqueByIsshow(item.children,);
        }
      })
    }

    const getPro = async () => {
      const res = await ApiArticle.regionTree()
      if (res && res.error_code === 0) {
        state.proList = res.data
        state.proList = JSON.parse(JSON.stringify(state.proList).replace(/"id"/g, "\"value\""))
        state.proList.forEach((item) => {
          if (item.children && item.children.length) {
            item.children.forEach((item2) => {
              delete item2.children
            })
          }
        })
      }
    }

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      //IArticleDetail
      if (id) {
        editId = id
        const res = await ApiArticle.getArticleDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.title = data.title
          formState.content = data.content
          formState.cid = data.pid ? [data.pid, data.cid] : [data.cid]
          formState.source = data.source
          formState.edit_user = data.edit_user
          formState.image = data.image
          formState.image_app = data.image_app || data.image
          formState.type = data.type
          formState.intro = data.intro
          formState.author = data.author
          formState.price = data.price
          formState.market_price = data.market_price

          formState.isFree = data.price == '0.00' ? 0 : 1
          formState.media_url = data.media_url
          formState.media_image = data.media_image
          formState.location_province = data.location_province
          formState.location_city = data.location_city
          formState.if_project = data.if_project
          formState.tag = data.tag
          formState.is_intro = data.is_intro
          formState.url = data.url || ''
          if (data.enclosure) {
            formState.enclosure = JSON.parse(data.enclosure)
          } else {
            formState.enclosure = []
          }
          state.value[0] = Number(data.location_province_code)
          state.value[1] = Number(data.location_city_code)
        }
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiArticle.addArticle(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiArticle.editArticle(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 从html中获取图片集和
    const getImagesFromHtml = (htmlText: string) => {
      if (!htmlText) return ''
      const urls = Array.from(new DOMParser().parseFromString(htmlText, 'text/html')
          .querySelectorAll('img'))
          .map(img => img.getAttribute('src'));
      if (urls.length) {
        return urls.join(';')
      }
      return ''
    }

    const goBack = () => {
      router.back()
    }

    const onChange = (val, val2) => {
      formState.location_province = val2[0].label
      formState.location_city = val2[1].label
      formState.location_province_code = val2[0].value
      formState.location_city_code = val2[1].value
    }

    onMounted(() => {
      getDetail()
      getPro()
      getCategoryList()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      onChange,
      formState,
      rules,
      onSubmit,
      getPro,
      resetForm,
      onImageChange,
      onImageAppChange,
      onMediaChange,
      onImageCoverChange,
      onEditorChange,
      onTypeChange,
      goBack,
      articleTypes,
      uniqueByIsshow,
      ...toRefs(state),
      editorComponentRef,
    }
  },
})
