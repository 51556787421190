<template>
    <div>
        <div>
            <div class="shop">
                <div class="header">
                    <i class="iconfont icon-shangpin-xianxing" style="margin-right:10px"></i>商品</div>
                <div class="bottom">
                    <div class="bottomItem">
                        <div class="title">已售数量</div>
                        <div class="num">3,571</div>
                    </div>
                    <div class="bottomItem">
                        <div class="title">待售数量</div>
                        <div class="num">3,571</div>
                    </div>
                    <div class="bottomItem">
                        <div class="title">好评度</div>
                        <div class="num">99%</div>
                    </div>
                </div>
            </div>

            <div class="order">
                <div class="header">
                    <i class="iconfont icon-dingdan1" style="margin-right:10px"></i>订单
                </div>
                <div class="bottom">
                    <div class="bottomItem">
                        <div class="title">已完成订单</div>
                        <div class="num">5,357</div>
                    </div>
                    <div class="bottomItem">
                        <div class="title">计划完成订单</div>
                        <div class="num">8,928</div>
                    </div>
                </div>
                <div>
                    <div id="mycharts" ref="myRef" class="chart-box" />
                </div>
            </div>


            <div class="target">
                <div class="header">
                    <i class="iconfont icon-gerenmubiao" style="margin-right:10px"></i>小目标
                </div>
                <div>
                    <div id="mycharts2" ref="myRef2" class="chart-box" />
                </div>
            </div>


            <div class="target2">
                <div class="header">
                    <i class="iconfont icon-icon-xiaoshoue" style="margin-right:10px"></i>销售额
                </div>
                <div>
                    <div id="mycharts3" ref="myRef3" class="chart-box" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import ApiUser from "@/request/apis/api_user";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import Combobox from "ant-design-vue/es/vc-select/examples/combobox";
    import countTo from '../components/vue-count-to/vue-countTo.vue';
    export default {
        name: "workbenchworkbench",
        setup(){
            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                const myChart2 = proxy.$echarts.init(document.getElementById('mycharts2'));
                const myChart3 = proxy.$echarts.init(document.getElementById('mycharts3'));
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['1季度','2季度','3季度','4季度'],
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '20',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "已完成订单",
                            data: [1001,2010,3102,985],
                            type: 'line',
                            smooth:'true'

                        },
                        {
                            name: "未完成订单",
                            data: [2001,1010,4102,1985],
                            type: 'line',
                            smooth:'true'

                        }
                    ]
                });


                myChart2.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'5%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        splitLine:{
                            show:false
                        },
                        show:false,
                    },
                    yAxis: {
                        type: 'category',
                        data: ['西山区', '呈贡', '盘龙区', '五华区', '官渡区', '嵩明'],
                        splitLine:{ show:false },
                        axisTick:{ show:false },
                        axisLine: {show:false},
                    },
                    series: [
                        {
                            name: '目标',
                            type: 'bar',
                            data: [650, 390, 374, 341, 302, 320],
                            barWidth:'30',
                            itemStyle: {
                                normal: {
                                    color: new proxy.$echarts.graphic.LinearGradient(
                                        0, 0, 1, 0,
                                        [
                                            { offset: 0, color: '#3ED573' },                   //柱图渐变色
                                            { offset: 1, color: '#399EFC' },                   //柱图渐变色
                                        ]
                                    ),
                                    label: {
                                        show: true, //开启显示
                                        position: 'right',
                                        formatter: '{c}亿元',
                                        textStyle: { //数值样式
                                            color: '#399FFB',
                                            fontSize: 14
                                        }
                                    }
                                },
                            },
                        },
                    ]
                });


                myChart3.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '0',
                        top: '20%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "销售额",
                            data: [1001,2010,3102,985,1001,2010,3102,985,1001,2010,3102,985],
                            type: 'line',
                            smooth:'true'

                        },
                    ]
                });
            };



            onMounted(() => {
                initT()
            })

            return{
                initT,
            }
        }
    }
</script>

<style lang="less" scoped>
    .shop{
        width: 25%;
        height: 200px;
        background: linear-gradient(90deg,#60b2fb,#6485f6);
        .header{
            height: 55px;
            line-height: 55px;
            border-bottom: 1px solid #fff;
            color: #fff;
            font-size: 16px;
            padding-left: 20px;
        }
        .bottom{
            width: 100%;
            float: left;
            display: flex;
            .bottomItem{
                width: 33%;
                text-align: center;
                .title{
                    margin-top: 30px;
                    color: #fff;
                }
                .num{
                    margin-top:10px;
                    font-size: 30px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }

    .order{
        width: 25%;
        height: 490px;
        background: #fff;
        margin-top:20px;
        .header{
            height: 55px;
            line-height: 55px;
            border-bottom: 1px solid #ccc;
            /*color: #fff;*/
            font-size: 16px;
            padding-left: 20px;
        }
        .bottom{
            width: 100%;
            float: left;
            display: flex;
            .bottomItem{
                width:50%;
                text-align: center;
                .title{
                    margin-top: 30px;
                }
                .num{
                    margin-top:10px;
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }
        .chart-box{
            width:90%;
            height:300px;
            margin-top:120px;
            margin-left:5%
        }
    }


    .target{
        width: 73%;
        height: 490px;
        background: #fff;
        margin-left: 27%;
        margin-top:-710px;
        .header{
            height: 55px;
            line-height: 55px;
            border-bottom: 1px solid #ccc;
            /*color: #fff;*/
            font-size: 16px;
            padding-left: 20px;
        }
        .bottom{
            width: 100%;
            float: left;
            display: flex;
            .bottomItem{
                width:50%;
                text-align: center;
                .title{
                    margin-top: 30px;
                }
                .num{
                    margin-top:10px;
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }
        .chart-box{
            height:420px;
        }
    }
    .target2{
        width: 73%;
        height: 490px;
        background: #fff;
        margin-left: 27%;
        margin-top:20px;
        .header{
            height: 55px;
            line-height: 55px;
            border-bottom: 1px solid #ccc;
            /*color: #fff;*/
            font-size: 16px;
            padding-left: 20px;
        }
        .bottom{
            width: 100%;
            float: left;
            display: flex;
            .bottomItem{
                width:50%;
                text-align: center;
                .title{
                    margin-top: 30px;
                }
                .num{
                    margin-top:10px;
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }
        .chart-box{
            height:380px;
        }
    }
</style>