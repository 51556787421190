
import {computed, defineComponent, onMounted, reactive, ref, toRaw, toRefs} from 'vue';
import type {UnwrapRef} from 'vue';
import ApiUser from "@/request/apis/api_user";
import {message} from "ant-design-vue/es";
import router from "@/router";
import StorageUtil from "@/utils/storage_util";
import {appTitle} from "@/utils/config";
import {IEditDriver} from "@/models/logistics_member";
import CryptoJS from "crypto-js"

import { format } from 'echarts';
interface ILoginDataForm {
  account: string;
  password: string;
  captcha: string;

}

interface IState {
  code:any,
  mobile:any,
  count:any,
  message:any,
  timer:any,
  loginType:any,
  isClick:any
}



export default defineComponent({
  name: 'Login',
  setup() {
    const state = reactive({
      verificationCode: '',
      verificationKey: '',
      loading: false,
    })
    const codeState: IState = reactive({
      code:'',
      mobile:"",
      count:10,
      loginType:1,
      message:"发送验证码",
      timer:null,
      isClick:0
    })

    const formRef = ref();
    const formState: UnwrapRef<ILoginDataForm> = reactive({
      account: '',
      password: '',
      captcha: '',
    });
    const rules = {
      account: [
        {required: true, message: '请输入登录账号', trigger: ['change', 'blur']},
      ],
      password: [
        {required: true, message: '请输入登录密码', trigger: ['change', 'blur']},
      ],
      captcha: [
        {required: true, message: '请输入验证码', trigger: ['change', 'blur']},
      ],
    };
    // 是否可以点击登录按钮
    const disabled = computed(() => !formState.account || !formState.password || !formState.captcha)

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            login();
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };



    // 获取验证码
    const getVerificationCode = async () => {
      let res = await ApiUser.getVerificationCode();
      if (res && res.error_code == 0) {
        state.verificationCode = res.data?.captcha.image || ''
        state.verificationKey = res.data?.captcha.key || ''
        formState.captcha = ''
      }
    }



    const getCode = () =>{
      if(codeState.mobile == ''){
        message.error("请输入手机号")
      }else{
        let phoneCodeVerification = /^[1][3,4,5,7,8][0-9]{9}$/;
        if(!phoneCodeVerification.test(codeState.mobile)){
          message.error("请输入正确的手机号")
        }else{
          sendCode()
          codeState.isClick = 1
          codeState.timer = setInterval(()=>{
            codeState.count--
            codeState.message ='已发送（'+ codeState.count+'s）'
            if(codeState.count===0){
              codeState.message = '发送验证码'
              codeState.isClick = 0
              codeState.count = 10
              clearInterval(codeState.timer)
            }
          },1000)
        }
      }
    }

    const sendCode = async () =>{
      const params = {mobile:codeState.mobile,key:'HTYZMDL'}
      let res = await ApiUser.SmsSend(params);
      if(res.error_code == 0){
        message.success(res.msg)
      }else{
        message.error(res.msg)
      }
    }

    const login = async () => {
      state.loading = true
        formState.password = CryptoJS.SHA512(formState.password).toString()
      let res = await ApiUser.login({...formState, login_key: state.verificationKey});
      state.loading = false
      if (res && res.error_code !== 0) {
        // 失败
        message.destroy()
        message.info(res.msg)
        if (res.msg !== '验证码错误') {
          await getVerificationCode()
        } else {
          formState.captcha = ''
        }
      }
    }

    const codeSubmit = async() =>{
      state.loading = true
      const params:any = {mobile:codeState.mobile,code:codeState.code}
      let res = await ApiUser.smsLogin(params);
      state.loading = false
      if (res && res.error_code !== 0) {
        // 失败
        message.destroy()
        message.error(res.msg)
      }
    }

    onMounted(() => {
      getVerificationCode();
      StorageUtil.clear()
      localStorage.clear()
    })

    return {
      ...toRefs(state),
      disabled,
      getCode,
      sendCode,
      formRef,
      other: '',
      formState,
      codeState,
      rules,
      onSubmit,
      codeSubmit,
      resetForm,
      getVerificationCode,
      appTitle
    };
  }
});
