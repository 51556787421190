export interface IOrganizationItem {
    id: number
    pid: number
    name: string
    path: string
    create_time: string
    update_time: string
    is_deleted: boolean
}

// 组织新增编辑
export interface IOrganizationUpdateItem {
    name: string
    pid: any[]
    id?: string | number
}

// 角色新增编辑
export interface IRoleUpdateItem {
    organization_id?: string | number | any[] // 组织id
    name: string
    level?: number | string
    id?: string | number
}

// 角色列表item
export interface IRoleItem {
    app_id: string
    create_time: string
    id: number
    is_deleted: number
    name: string
    organization_id: number
    update_time: string
    level: number
    organization: {
        pp_id: string
        create_time: string
        id: number
        is_deleted: boolean
        name: string
        path: string
        pid: number
        update_time: string
    }
}

// 人员
export interface IMemberItem extends IMemberDetail{ }

// 人员提交
export interface IMemberUpdate {
    id?: any
    name: string //姓名
    phone: string //电话
    role_id: number[] //角色id
    gender: number //性别 0未知 1男 2女
    avatar: string //头像
    introduction: string
}

// 人员详情
export interface IMemberDetail {
    app_id: string
    avatar: string
    create_time: string
    gender: number
    id: number
    introduction: string
    is_deleted: number
    name: string
    phone: string
    roles: {
        app_id: string
        create_time: string
        id: number
        is_deleted: number
        level: number
        name: string
        organization_id: number
        pivot: {
            app_id: string
            cpc_member_id: number
            create_time: string
            id: number
            role_id: number
            update_time: string
        }
        update_time: string
    }[]
    update_time: string
}

// 角色等级
export const roleLevels = Array.from({length: 20}, (v, k) => k + 1)
