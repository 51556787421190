
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import {PlusOutlined,CloseCircleOutlined} from '@ant-design/icons-vue'
    import apiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any,
        storeList:any,
        devList:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent,PlusOutlined,CloseCircleOutlined},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    name:''
                },
                total:0,
                storeList:[],
                title:'',
                visible:false,
                formState:{},
                devList:[],
                type:''
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '微信app_id',
                    dataIndex: 'wx_app_id',
                    key: 'wx_app_id',
                    align:'center'
                },
                {
                    title: '微信app_secret',
                    dataIndex: 'app_secret',
                    key: 'app_secret',
                    align:'center'
                },
                {
                    title: '开发者微信号',
                    key: 'dev_wx_id',
                    dataIndex: 'dev_wx_id',
                    align:'center'
                },
                {
                    title: '微信原始ID',
                    key: 'original_id',
                    dataIndex: 'original_id',
                    align:'center'
                },
                {
                    title: '电子邮箱',
                    key: 'email',
                    dataIndex: 'email',
                    align:'center'
                },
                {
                    title: '密码',
                    key: 'password',
                    dataIndex: 'password',
                    align:'center'
                },
                {
                    title: '店铺',
                    key: 'shop',
                    dataIndex: 'shop',
                    align:'center'
                },  
                {
                    title: '店铺id',
                    key: 'shop_id',
                    dataIndex: 'shop_id',
                    align:'center'
                },   
                {
                    title: '业务状态',
                    key: 'status',
                    dataIndex: 'status',
                    align:'center'
                },  
                {
                    title: '技术状态',
                    key: 'tec_status',
                    dataIndex: 'tec_status',
                    align:'center'
                }, 
                {
                    title: '联系人姓名',
                    key: 'contact_name',
                    dataIndex: 'contact_name',
                    align:'center'
                },
                {
                    title: '联系人电话',
                    key: 'contact_phone',
                    dataIndex: 'contact_phone',
                    align:'center'
                },  
                {
                    title: '是否推荐',
                    key: 'recommend',
                    dataIndex: 'recommend',
                    align:'center'
                },  
                {
                    title: '类型',
                    key: 'type',
                    dataIndex: 'type',
                    align:'center'
                },                                            
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    fixed:'right'
                },
            ];
            const rules = {
                wx_app_id: [{required: true, message: "请输入微信app_id"}],
                app_secret: [{required: true, message: "请输入微信app_secret"}],
                dev_wx_id: [{required: true, message: "请输入开发者微信号"}],
                original_id: [{required: true, message: "请输入微信原始ID"}],
                email: [{required: true, message: "请输入电子邮箱"}],
                password: [{required: true, message: "请输入密码"}],
                shop_id: [{required: true, message: "请选择店铺"}],
                status: [{required: true, message: "请选择业务状态"}],
                tec_status: [{required: true, message: "请选择技术状态"}],
                contact_name: [{required: true, message: "请输入联系人姓名"}],
                contact_phone: [{required: true, message: "请输入联系人电话"}],
                recommend: [{required: true, message: "请选择是否推荐"}],
                type: [{required: true, message: "请选择类型"}],
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.MnpList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const addApply = () =>{
                state.title = "新增账号"
                state.type = 1
                state.formState={
                    wx_app_id:'',
                    app_secret:'',
                    dev_wx_id:[''],
                    original_id:'',
                    email:'',
                    password:'',
                    shop_id:undefined,
                    status:undefined,
                    tec_status:undefined,
                    contact_name:'',
                    contact_phone:'',
                    recommend:'',
                    type:'',
                },
                    state.visible = true
            }
            const edit = (val) =>{
                state.title = "编辑账号"
                state.type = 2
                state.formState={
                    id:val.id,
                    wx_app_id:val.wx_app_id,
                    app_secret:val.app_secret,
                    dev_wx_id:val.dev_wx_id,
                    original_id:val.original_id,
                    email:val.email,
                    password:val.password,
                    shop_id:val.shop_id,
                    status:val.status,
                    tec_status:val.tec_status,
                    contact_name:val.contact_name,
                    contact_phone:val.contact_phone,
                    recommend:val.recommend,
                    type:val.type
                },
                state.visible = true
            }
            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onSubmit = async () => {
                console.log(state.formState)
                formRef.value.validate() .then(async() => {
                    const params = {...state.formState}
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.MnpAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            state.searches.page = 1
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.MnpEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const onDel = async (item) => {
                const params = {id:item.id}
                const res = await ApiMember.MnpDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }

            const getshop = async () => {
                const params = {page:1,limit:999999}
                const res = await ApiUser.StoreLists(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        const data:any = res.data
                        state.storeList = data.lists
                        
                    }
                }
            }
            const filterOption = (input: string, option: any) => {
                return option.key.indexOf(input) >= 0;
            };

            const addAccount = () => {
                state.formState.dev_wx_id.push('')
            }
            const onCancle = () => {
                console.log(123)
                getList()
            }
            const removeAccount = (index) => {
                if(state.formState.dev_wx_id.length== 1){
                    message.warning("至少保留一个")
                }else{
                    state.formState.dev_wx_id.splice(index,1)
                }
                
            }
            onMounted(() => {
                getList()
                getshop()
            })

            return {
                ...toRefs(state),
                getList,
                filterOption,
                getshop,
                addAccount,
                uploadHeaders,
                removeAccount,
                uploadAction,
                onSearch,
                onReset,
                uploadFormData,
                pagination,
                columns,
                onCancle,
                edit,
                onDel,
                formRef,
                handleChange,
                onSubmit,
                rules,
                addApply,
                handleTableChange
            }
        }
    })
