import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75cb7d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "center_text" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "center_text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "detail_content"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "detail_content",
  style: {"padding-top":"30px"}
}
const _hoisted_11 = { class: "t_intro" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.detail)
      ? (_openBlock(), _createBlock(_component_a_card, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, {
              level: 4,
              class: "title_text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.detail?.title), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.detail?.author)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "作者：" + _toDisplayString(_ctx.detail?.author), 1))
                : _createCommentVNode("", true),
              (_ctx.detail?.author && _ctx.detail?.edit_user)
                ? (_openBlock(), _createBlock(_component_a_divider, {
                    key: 1,
                    type: "vertical"
                  }))
                : _createCommentVNode("", true),
              (_ctx.detail?.edit_user)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "编辑：" + _toDisplayString(_ctx.detail?.edit_user), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.detail?.create_time), 1),
              (_ctx.detail?.source)
                ? (_openBlock(), _createBlock(_component_a_divider, {
                    key: 0,
                    type: "vertical"
                  }))
                : _createCommentVNode("", true),
              (_ctx.detail?.source)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "来源：" + _toDisplayString(_ctx.detail?.source), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.detail?.type > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.detail?.type === 1)
                    ? (_openBlock(), _createElementBlock("video", {
                        key: 0,
                        src: _ctx.detail?.media_url,
                        controls: "",
                        autoplay: "",
                        style: {"width":"100%"}
                      }, null, 8, _hoisted_8))
                    : _createCommentVNode("", true),
                  (_ctx.detail?.type === 2)
                    ? (_openBlock(), _createElementBlock("audio", {
                        key: 1,
                        src: _ctx.detail?.media_url,
                        controls: "",
                        autoplay: "",
                        style: {"width":"100%"}
                      }, null, 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.detail?.intro)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.detail?.intro), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "detail_content",
              style: {"padding-top":"30px"},
              innerHTML: _ctx.detail?.content
            }, null, 8, _hoisted_12)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}