
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";

    interface IState {
        list: any,
        searches: any,
        total: any,
        visible: any,
        title: any,
        formState: any,
        type: any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ''
                },
                total: 0,
                title: '',
                visible: false,
                formState: {
                    id: '',
                    review_status: undefined,
                    description: '',
                },
                type: 0
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center'
                },
                {
                    title: '商品图片',
                    dataIndex: 'goods',
                    key: 'goods',
                    align: 'center'
                },
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    key: 'goods_name',
                    align: 'center'
                },
                {
                    title: '最高价',
                    dataIndex: 'max_price',
                    key: 'max_price',
                    align: 'center'
                },
                {
                    title: '最低价',
                    dataIndex: 'min_price',
                    key: 'min_price',
                    align: 'center'
                },
                {
                    title: '商家信息',
                    dataIndex: 'shop_name',
                    key: 'shop_name',
                    align: 'center'
                },
                {
                    title: '店铺logo',
                    dataIndex: 'logo',
                    key: 'logo',
                    align: 'center'
                },
                {
                    title: '最多优惠价格',
                    dataIndex: 'bargain_max_price',
                    key: 'bargain_max_price',
                    align: 'center'
                },
                {
                    title: '最少优惠价格',
                    dataIndex: 'bargain_min_price',
                    key: 'bargain_min_price',
                    align: 'center'
                },
                {
                    title: '活动开始时间',
                    dataIndex: 'activity_start_time',
                    key: 'activity_start_time',
                    align: 'center'
                },
                {
                    title: '活动结束时间',
                    dataIndex: 'activity_end_time',
                    key: 'activity_end_time',
                    align: 'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center'
                },
                {
                    title: '审核状态',
                    dataIndex: 'audit_status',
                    key: 'audit_status',
                    align: 'center'
                },
                {
                    title: '审核备注',
                    dataIndex: 'audit_remark',
                    key: 'audit_remark',
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200
                },
            ];
            const rules = {
                review_status: [{required: true, message: "请选择审核意见"}],
                description: [{required: true, message: "请输入审核备注"}],
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiMember.BargainActivity(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onSubmit = async () => {
                formRef.value.validate().then(async () => {
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.BargainAudit(params)
                        if (res.error_code == 0) {
                            state.visible = false
                            message.success(res.msg)
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.BargainViolation(params)
                        if (res.error_code == 0) {
                            state.visible = false
                            message.success(res.msg)
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    }


                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const examine = (item, item2) => {
                state.visible = true
                state.title = "审核商品"
                state.type = item2
                state.formState = {
                    id: item.id,
                    review_status: item.audit_status,
                    description: item.audit_remark,
                }
            }

            const changeStatus = async(val1,val2) => {
                const params = {id:val1.id,status:val2,field:'status'}
                const res = await ApiMember.BargainSwitchStatus(params)
                if (res.error_code == 0) {
                    state.visible = false
                    message.success(res.msg)
                    getList()
                } else {
                    message.error(res.msg)
                }
            }


            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                examine,
                changeStatus,
                uploadHeaders,
                uploadAction,
                uploadFormData,
                pagination,
                columns,
                formRef,
                handleChange,
                onSubmit,
                rules,
                handleTableChange
            }
        }
    })
