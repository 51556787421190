
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import {UserOutlined, BellOutlined} from '@ant-design/icons-vue';
import ApiUser from "@/request/apis/api_user";
import {IUserInfo} from "@/models/user_models";
import {useRouter} from "vue-router";
import StorageUtil from "@/utils/storage_util";
import {ArrowLeftOutlined} from '@ant-design/icons-vue';
import {appTitle, homeRoute,appId} from '@/utils/config'
import Chat from './Chat.vue'
import store from '../store/index'

interface IHeaderData {
  userInfo: IUserInfo | undefined;
}

export default defineComponent({
  name: 'Headers',
  components: {UserOutlined, ArrowLeftOutlined, BellOutlined, Chat},
  setup() {
    const router = useRouter()
    const state: IHeaderData = reactive({
      userInfo: undefined,
    })

    const goHome = () => {
      router.replace(homeRoute).then()
    };

    // 获取用户信息
    const getUserInfo = async () => {
      const res = await ApiUser.getUserInfo()
      if (res && res.error_code === 0) {
        state.userInfo = res.data
        store.commit('setUserInfo', res.data)
      }
    }

    const getImageUrl = (url:string) => {
      if (url.indexOf("http") >= 0) return url;
      return "http://jjyyimage.ynjjyy.cn/" + url;
    }

    const exitLogin = () => {
      StorageUtil.clear()
      localStorage.clear()
      router.replace('/login')
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getUserInfo()
    })



    return {
      ...toRefs(state),
      exitLogin,
      goBack,
      appTitle,
      getImageUrl,
      goHome,
    }
  }
})
