
// import Layout from "@/layouts/index"
import {defineComponent, onMounted} from "vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {appTitle} from '@/utils/config';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default defineComponent({
  // components:{Layout}
  setup() {
    window.document.title = appTitle+'后台管理系统'
    onMounted(() => {

    })
    return {
      locale: zhCN,
      dayjs,
    }
  }
})

