
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import ApiLogistics from "@/request/apis/api_logistics";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import StorageUtil from "@/utils/storage_util";
    import {
        EditOutlined,
        CheckOutlined,
        CloseOutlined,
    } from "@ant-design/icons-vue";
    import ImageView from "@/components/ImageView.vue";
    import DriverSet from "@/components/DriverSet.vue";
    import PageUtil from "@/utils/page_util";
    import {IDriverItem, IEditDriver} from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";
    import apiSetting from "@/request/apis/api_setting";

    interface IState {
        list: any;
        total: number;
        loading: boolean;
        searches: any;
        visible: boolean;
        editItem?: IEditDriver;
        commentDetail: any,
        formState: any,
        type:any
    }

    // 表格
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: 'center'
        },
        {
            title: "类型",
            dataIndex: "type_desc",
            key: "type_desc",
            align: 'center'
        },
        {
            title: "任务名称",
            dataIndex: "name",
            key: "name",
            align: 'center'
        },
        {
            title: "命令内容",
            dataIndex: "command",
            key: "command",
            align: 'center'
        },
        {
            title: "是否系统任务",
            dataIndex: "system",
            key: "system",
            align: 'center'
        },
        {
            title: "参数",
            dataIndex: "parameter",
            key: "parameter",
            align: 'center'
        },
        {
            title: "最大执行时长",
            dataIndex: "max_time",
            key: "max_time",
            align: 'center'
        },
        {
            title: "运行规则",
            dataIndex: "expression",
            key: "expression",
            align: 'center'
        },
        {
            title: "最后执行时间",
            dataIndex: "last_time_str",
            key: "last_time_str",
            align: 'center'
        },
        {
            title: "状态",
            dataIndex: "status",
            key: "status",
            align: 'center'
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 150
        },
    ];

    export default defineComponent({
        name: "DriverList",
        components: {
            EditOutlined,
            CheckOutlined,
            CloseOutlined,
            ImageView,
            DriverSet,
        },
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                commentDetail: '',
                visible: false,
                type:'',
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    type: "", // 类型 1为用户，2为商家，不传全部
                    shop_admin_id: null,
                    name: "",
                },
                formState: {
                    "name": "",
                    "type": "",
                    "remark": "",
                    "command": "",
                    "parameter": "",
                    "status": 1,
                    "expression": "",
                    "id": ''
                }
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches};
                StorageUtil.setParams(params);
                state.loading = true;
                const res = await ApiSetting.Crontablists(params);
                state.loading = false;
                if (res && res.error_code === 0) {
                    state.total = res.data?.count || 0;
                    state.list = res.data?.lists || [];
                }
            };

            const addPlan = () => {
                state.type = 1
                state.formState = {
                    "name": "",
                    "type": undefined,
                    "remark": "",
                    "command": "",
                    "parameter": "",
                    "status": undefined,
                    "expression": "",
                }
                state.visible = true
            }

            const edit = (val) =>{
                state.type = 2
                state.formState = {
                    "id":val.id,
                    "name": val.name,
                    "type": val.type,
                    "remark": val.remark,
                    "command": val.command,
                    "parameter": val.parameter,
                    "status": val.status,
                    "expression": val.expression,
                }
                state.visible = true
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    const params = {...state.formState}
                    const res = await apiSetting.CrontabAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }

                if (state.type == 2) {
                    const params = {...state.formState}
                    const res = await apiSetting.CrontabEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }
            }


            const onDel = async (val, val2) => {
                const params = {id: val.id}
                const res = await apiSetting.CrontabDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }



            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                addPlan,
                onDel,
                handleOk,
                edit,
                pagination,
                handleTableChange
            };
        },
    });
