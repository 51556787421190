import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4369dbbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    title: "底部导航",
    style: {"margin-bottom":"16px"},
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        dataSource: _ctx.list,
        columns: _ctx.columns,
        loading: _ctx.loading,
        bordered: "",
        pagination: _ctx.pagination,
        onChange: _ctx.handleTableChange
      }, {
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.key === 'selected_icon')
            ? (_openBlock(), _createBlock(_component_a_image, {
                key: 0,
                src: text,
                style: {"width":"50px"}
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          (column.key === 'un_selected_icon')
            ? (_openBlock(), _createBlock(_component_a_image, {
                key: 1,
                src: text,
                style: {"width":"50px"}
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          (column.key === 'create_time')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.dayjs(record.create_time * 1000).format('YYYY-MM-DD HH:mm:ss')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["dataSource", "columns", "loading", "pagination", "onChange"])
    ]),
    _: 1
  }))
}