
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import StorageUtil from "@/utils/storage_util";
    import PageUtil from "@/utils/page_util";
    import {IDriverItem, IEditDriver} from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        list: any;
        total: number;
        loading: boolean;
        searches: any;
    }

    // 表格
    const columns = [
        {
            title: "日志ID",
            dataIndex: "resume_id",
            key: "resume_id",
            align: "center"
        },
        {
            title: "姓名",
            dataIndex: "name",
            key: "name",
            align: "center"
        },
        {
            title: "头像",
            dataIndex: "avatar",
            key: "avatar",
            align: "center"
        },
        {
            title: "简历姓名",
            dataIndex: "resume_name",
            key: "resume_name",
            align: "center"
        },

        {
            title: "邮箱",
            dataIndex: "resume_email",
            key: "resume_email",
            align: "center"
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            align: "center"
        },
        {
            title: "地址",
            dataIndex: "resume_address",
            key: "resume_address",
            align: "center"
        },
        {
            title: "优势",
            dataIndex: "resume_advantage",
            key: "resume_advantage",
            align: "center"
        },
        {
            title: "变动次数",
            dataIndex: "change_times",
            key: "change_times",
            align: "center"
        },
        {
            title: "刷新时间",
            dataIndex: "create_time",
            key: "create_time",
            align: "center",
        },
    ];

    export default defineComponent({
        name: "DriverList",
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: "",
                },
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches};
                StorageUtil.setParams(params);
                state.loading = true;
                const res = await ApiSetting.RecruitFlushList(params);
                state.loading = false;
                if (res && res.error_code === 0) {
                    state.total = res.data?.total || 0;
                    state.list = res.data?.data || [];
                }
            };

            const onSearch = () => {
                state.searches.page=1,
                 state.searches.limit=PageUtil.pageSize,
                getList()
            }

            const onReset = () => {
                state.searches = {
                    page: 1,
                    limit: PageUtil.pageSize,
                    keywords: "",
                }
                getList()
            }
            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                pagination,
                onSearch,
                onReset,
                handleTableChange
            };
        },
    });
