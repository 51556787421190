
    import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import dayjs, {Dayjs} from 'dayjs';
    import PageUtil from "@/utils/page_util";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        formState:any,
        type:any
    }

    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                list:[],
                visible:false,
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    coupon_name:'',
                    goods_name:''
                },
                total:0,
                type:'',
                formState:{}
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '开始时间',
                    dataIndex: 'start_time',
                    key: 'start_time',
                    align:'center'
                },
                {
                    title: '结束时间',
                    dataIndex: 'end_time',
                    key: 'end_time',
                    align:'center'
                },
                {
                    title: '时间段',
                    dataIndex: 'time',
                    key: 'time',
                    align:'center'
                },
                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    align:'center'
                },
                {
                    title: '更新时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    align:'center'
                },
            ];

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiMember.SeckillTimeTimeLists(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.list
                    state.total = data.count
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const addTime = () => {
                state.visible = true
                state.type = 1
                state.formState={
                    start_time:'',
                    end_time:""
                }
            }

            const handleOk = async() => {
                console.log(state.formState)
                if(state.type == 1){
                        const params = {...state.formState}
                        const res = await ApiMember.SeckillTimeAddTime(params)
                        if (res && res.error_code == 0) {
                            message.success(res.msg)
                            getList()
                            state.visible = false
                        }else{
                             message.error(res.msg)
                        }
                }
                if(state.type == 2){
                        const params = {...state.formState}
                        const res = await ApiMember.SeckillTimeEditTime(params)
                        if (res && res.error_code == 0) {
                            message.success(res.msg)
                            getList()
                            state.visible = false
                        }else{
                             message.error(res.msg)
                        }
                }                
            }

            const onDel = async (item) => {
                const params = {id:item.id}
                const res = await ApiMember.SeckillTimeDel(params)
                if (res.error_code === 0) {
                    message.success(res.msg)
                    await getList()
                    return
                }else{
                    message.error(res.msg)
                }
            }
            
            const edit = (val) => {
                state.type = 2
                state.formState = {
                    id:val.id,
                    start_time:val.start_time,
                    end_time:val.end_time
                }
                state.visible = true
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                dayjs,
                edit,
                columns,
                addTime,
                pagination,
                handleOk,
                onDel,
                handleTableChange
            }
        }
    });
