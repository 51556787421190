
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref, computed} from 'vue';
    import {appTitle} from "@/utils/config";
    import {UserOutlined} from '@ant-design/icons-vue';
    import apiSetting from "@/request/apis/api_setting";
    import 'echarts-wordcloud';
    interface IHeaderData {
        cloud:any,
        word:any,
        visible:any
    }


    export default defineComponent({
        name: 'Home',
        components: {UserOutlined},
        setup() {
            let state = reactive({
                cloud: [] as any [],
                word: [] as any [],
                visible:false
            })

            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                myChart.setOption({
                    title: {
                        text: '',
                        x: "center"
                    },
                    backgroundColor: "#fff",
                    // tooltip: {
                    //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
                    // },
                    series: [
                        {
                            type: "wordCloud",
                            gridSize: 10,
                            sizeRange: [14, 40],
                            rotationRange: [0, 0],
                            textStyle: {
                                color: function () {
                                    return (
                                        "rgb(" +
                                        Math.round(Math.random() * 255) +
                                        ", " +
                                        Math.round(Math.random() * 255) +
                                        ", " +
                                        Math.round(Math.random() * 255) +
                                        ")"
                                    );
                                }
                            },
                            left: "center",
                            top: "center",
                            right: null,
                            bottom: null,
                            width: "100%",
                            height: "100%",
                            //数据
                            data: state.cloud
                        }
                    ]
                });
            };
            const getdata = async () => {
                state.cloud =[]
                state.word=[]
                const res = await apiSetting.HotSearchInex();
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    data.forEach((item,index)=>{
                        const obj = {value:"",name:""}
                        obj.value = index
                        obj.name = item
                        state.cloud.push(obj)
                        state.word.push(obj)
                    })

                    initT()
                }
            }

            const setHot = () => {
                state.visible = true
            }

            const addHot = () => {
                state.word.push({
                    value: '',
                    name: '',
                });
            }

            const handleOk = async() => {
                const arr:any = []
                state.word.forEach((item)=>{
                    arr.push(item.name)
                })
                const params ={hot_keyword:arr}
                const res = await apiSetting.HotSearchSet(params);
                if (res && res.error_code === 0) {
                    getdata()
                    state.visible = false
                }
            }

            const onDel = (val) => {
                state.word.splice(val, 1);
            }
            onMounted(() => {
                getdata()
            })

            return {
                ...toRefs(state),
                appTitle,
                onDel,
                handleOk,
                myRef,
                addHot,
                setHot

            }
        }
    });
