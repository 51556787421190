import {baseRequestClient} from "@/request/request_client";
import {IList} from "@/models/base_model";
import {
    IBannerDetail,
    IBannerItem,
    IBannerPositionDetail,
    IBannerPositionItem,
    IBannerPositionUpdate,
    IBannerUpdateItem
} from "@/models/banner_model";

const requestClient = baseRequestClient

// 广告
export default class ApiBanner {
    // GET 获取广告位列表
    static getBannerPositionList = async () => {
        return await requestClient.get<IBannerPositionItem[]>('/admin/decoration.AdPosition/lists')
    }

    // GET 获取广告位详情
    static getBannerPositionDetail = async (id: string) => {
        return await requestClient.get<IBannerPositionDetail>('/admin/decoration.AdPosition/detail', {id})
    }

    // POST 广告位添加
    static addBannerPosition = async (data: IBannerPositionUpdate) => {
        return await requestClient.post('/admin/decoration.AdPosition/add', data)
    }

    // POST 广告位编辑
    static editBannerPosition = async (data: IBannerPositionUpdate) => {
        return await requestClient.post('/admin/decoration.AdPosition/edit', data)
    }

    // POST 广告位显示隐藏 //status 状态：0-停用；1-显示
    static showBannerPosition = async (id: number, status: number) => {
        return await requestClient.post('/admin/decoration.AdPosition/swtichStatus', {id, status})
    }

    // POST 广告位删除
    static delBannerPosition = async (id: number) => {
        return await requestClient.post('/admin/decoration.AdPosition/del', {id})
    }

    // GET 获取广告列表 terminal=1&limit=10&page=1
    static getBannerList = async (params: any) => {
        for(let key in params){
            if(!params[key]) delete params[key]
        }
        return await requestClient.get<IList<IBannerItem>>('/admin/decoration.Ad/lists', params)
    }

    // GET 获取广告详情
    static getBannerDetail = async (id: string) => {
        return await requestClient.get<IBannerDetail>('/admin/decoration.Ad/detail', {id})
    }

    // POST 广告添加
    static addBanner = async (data: IBannerUpdateItem) => {
        delete data.time
        return await requestClient.post('/admin/decoration.Ad/add', data)
    }

    // POST 广告修改
    static editBanner = async (data: IBannerUpdateItem) => {
        delete data.time
        return await requestClient.post('/admin/decoration.Ad/edit', data)
    }

    // POST 广告删除
    static delBanner = async (id: number) => {
        return await requestClient.post('/admin/decoration.Ad/del', {id})
    }

    // POST 广告隐藏显示
    static showBanner = async (id: number, status: number) => {
        return await requestClient.post('/admin/decoration.Ad/swtichStatus', {id, status})
    }

    // POST 广告排序
    static setBannerSort = async (id: number, number: number) => {
        return await requestClient.post('/admin/decoration.Ad/upSort', {id, number})
    }

    static bottomNavigation = async (params:any) => {
        return await requestClient.get('/admin/decoration.MenuDecorate/bottomNavigation', params)
    }

}