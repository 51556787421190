
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";
import EditorComponent from '@/components/editor/Editor.vue'

interface IState {
  detail: string
  loading: boolean,
}

export default defineComponent({
  name: "Protocol",
  components: {EditorComponent},
  props:['protocolType'],
  setup(props) {
    const editorComponentRef = ref()

    const state: IState = reactive({
      detail: '',
      loading: false,
    })

    // 获取文章详情
    const getDetail = async () => {
      state.loading = true
      const res = await ApiLogistics.getProtocol(props.protocolType)
      state.loading = false
      if (res && res.error_code === 0 && res.data) {
        state.detail = res.data.content || ''
      }
    }

    // 修改
    const onEdit = async () => {
      const value = editorComponentRef.value.getHtmlText() || ''
      if (!value) {
        message.destroy()
        message.warning('请输入内容')
        return
      }
      state.loading = true
      const res = await ApiLogistics.setProtocol(props.protocolType, value)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('修改成功')
        // await getDetail()
      } else {
        message.error(res?.msg || '修改失败');
      }
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      onEdit,
      editorComponentRef,
    }
  }
})
