// 上传
import {baseRequestClient} from "@/request/request_client";
import StorageUtil from "@/utils/storage_util";
import {appId, baseUrl} from "@/utils/config";

const requestClient = baseRequestClient

export default class UploadUtil {

    // 上传资源配置
    static uploadHeaders = () => {
        return {
            "access-token": StorageUtil.getToken(),
            "app-id": appId
        }
    }

    // 图片url
    static uploadImageConfig = {
        params: {cid: 10},
        url: baseUrl+'/admin/upload/image'
    }

    // 附件url
    static uploadFileConfig = {
        url: baseUrl+'/admin/upload/file'
    }

    // 上传图片
    static uploadImage = async (file: File) => {
        let fileForm = new FormData();
        fileForm.append('file', file);
        fileForm.append('cid', '10');

        return requestClient.post('/admin/upload/image', fileForm)
    }

    // 上传视频
    static uploadVideo = async (file: File) => {
        let fileForm = new FormData();
        fileForm.append('file', file);
        fileForm.append('cid', '11');
        return requestClient.post('/admin/upload/video', fileForm)
    }

    // 上传音频
    static uploadAudio = async (form: any) => {
        return requestClient.post('/admin/upload/voice', form)
    }

    // 上传附件
    static uploadFile = async (form: any) => {
        return requestClient.post('/admin/upload/file', form, UploadUtil.uploadHeaders())
    }
}
