
import {defineComponent, onMounted, reactive, toRefs, computed, shallowRef } from "vue";
import ApiLogisticsOrder from "@/request/apis/api_logistics_order";
import ApiLogistics from "@/request/apis/api_logistics"
import {ILogisticsTypeItem, LogisticsOrderDetail} from "@/models/logistics_model";
import {useRoute, useRouter} from "vue-router";
import dayjs from "dayjs";
import {message} from "ant-design-vue";
import {getLogisticsOrderStatus} from "@/utils/status";
import {ManOutlined, WomanOutlined, StarFilled} from '@ant-design/icons-vue';
import AMapLoader from '@amap/amap-jsapi-loader';
import {dateFormat} from "@/utils/common";

interface IState {
  detail?: LogisticsOrderDetail,
  expressTypes: ILogisticsTypeItem[],
  carTypes: any[],
  loading: boolean,
  routePath: any[],
  mapTimer: any,
  routePathDetail:any,
  willPathDetail:any
}

export default defineComponent({
  name: "ArticleDetail",
  components:{ManOutlined, WomanOutlined, StarFilled},
  setup() {
    let editId: any;
    const route = useRoute();
    const router = useRouter();
    let map:any = shallowRef(null);
    let AMaps:any = null;
    let carAlreadyDriving:any = null;
    let carAlreadyPolyline:any = null;
    let carWillDriving:any = null;
    let carMarker:any = null;

    const carImages = computed<string[]>(() => {
      if (!state.detail || !state.detail.car) {
        return [];
      }
      let images = JSON.parse(state.detail.car.vehicle_images);
      let array:any[] = [];
      images.back ? array.push(images.back) : null;

      return array;
    });

    const state: IState = reactive({
      detail: undefined,
      expressTypes: [],
      carTypes: [],
      loading: false,
      routePath: [],
      mapTimer: null,
      willPathDetail: null,
      routePathDetail: {
        "steps": [],
        "restriction": 0,
        "distance": 410,
        "time": 149,
        "policy": "速度最快",
        "tolls": 0,
        "tolls_distance": 0
      }
    })

    const goBack = () => {
      router.back()
    }

    // 转化时间为 1m30s倒计时格式 dateTime单位秒 mode最小单位  0秒 1分
    const timeCountFormat = (dateTime:any) => {
      if (!dateTime || isNaN(dateTime)) return "";
      dateTime = Number(dateTime);
      if (dateTime <= 60) {
        return dateTime + "秒";
      }
      if (dateTime <= 60 * 60) {

        return Math.floor(dateTime / 60) + "分钟" + (dateTime % 60) + "秒";
      }
      if (dateTime <= 60 * 60 * 60) {
        return Math.floor(dateTime / (60 * 60)) + "小时" + (
                Math.floor((dateTime % (60 * 60)) / 60)
        ) + "分钟";
      }
      return Math.floor(dateTime / (60 * 60 * 24)) + "天"
              + Math.floor((dateTime % (60 * 60 * 24)) / (60 * 60)) + "小时";
    };

    // 转换距离 如1024m => 1.02Km 10241024m => 12041.02Km 102m => 120m
    const distanceFormatKm = (distance:any) => {
      if (!distance || isNaN(distance)) return "";
      distance = Number(distance);
      if (distance < 1000) {
        return distance + "米";
      }
      return Math.floor(distance * 100 / 1000) / 100.0 + "公里"
    };

    // 获取订单详情
    const getDetail = async () => {
      const {id} = route.query

      const express_res = await ApiLogistics.getLogisticsCategoryList();
      console.log(express_res)
      if (express_res && express_res.error_code === 0 && express_res.data) {
        state.expressTypes = express_res.data.list || [];
      }
      // console.log(expressTypes)

      const car_res = await ApiLogisticsOrder.getCarTypes({});
      if (car_res && car_res.error_code === 0 && car_res.data) {
        state.carTypes = car_res.data.list;
      }

      if (id) {
        editId = id
        const res = await ApiLogisticsOrder.getLogisticsOrderDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
        }
      else {
          message.destroy();
          message.error(res?.msg);
        }
      }

      getPathRoute();
    }

    onMounted(() => {
      getDetail();
      initAmap();
    })

    const initAmap = async () => {
      (window as any)._AMapSecurityConfig = {
        securityJsCode:'2ed6d161b9f17067b3796336711ef037',
      }
      AMapLoader.load({
        "key": "55dd0460690ae7bb1ec62e2a536f7845",              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [
          "AMap.TruckDriving",
          "AMap.MoveAnimation",
          "AMap.Driving",
          "AMap.LabelMarker",
          "AMap.LabelsLayer",
          "AMap.Polyline"
        ],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {             // 是否加载 AMapUI，缺省不加载
          "version": '1.1',   // AMapUI 版本
          "plugins": ['overlay/SimpleMarker', "misc/PathSimplifier"],       // 需要加载的 AMapUI ui插件
        },
        "Loca": {                // 是否加载 Loca， 缺省不加载
          "version": '2.0'  // Loca 版本
        },
      }).then((AMap) => {
        AMaps = AMap;
        AMap._AMapSecurityConfig = {
          securityJsCode:'2ed6d161b9f17067b3796336711ef037',
        }
        map = new AMap.Map('amap', {
          resizeEnable: true,
          dragEnable: true,
          doubleClickZoom: true,
          zoomEnable: true,
          center: null,
          zooms: [4, 18], //设置地图级别范围
          zoom: 10
        });
        (window as any).map = map;
        getPathRoute();
        Promise.resolve(AMap);
      }).catch((e) => {
        console.error(e);  //加载错误提示
        Promise.reject(e)
      });
    };

    // 获取有效地址
    const getEnablePath = (list:any[], maxNumber:number) => {
      let allActivePoint:any[] = []; // 所有有效点
      list.map((item, index) => {
        let point = item.points.location.split(",");
        if (index > 0) {
          let lastPoint = allActivePoint[allActivePoint.length - 1].points.location.split(",");;
          if (Math.abs(lastPoint[0] - point[0]) > 0.01 && Math.abs(lastPoint[0] - point[0]) > 0.01) {
            allActivePoint.push(item)
          }
        }
        else {
          allActivePoint.push(item)
        }
        return item;
      });
      return getListItemByCellNumber(allActivePoint, maxNumber);
    };
    // 从数组中平均取出最多n个点 16 20
    const getListItemByCellNumber = (list:any[], maxNumber:number) => {
      if (!list || list.length < maxNumber) return list;
      let itemList: any[] = [];
      let index = list.length; // 20 16 19 15 18 14 17 13 16 12 15 11 14 10 13 9 12 8 11 7 10 6 9 5 8 4 6 3 4 2 2 1
      while (index > 0) {
        if (maxNumber - itemList.length <= 0) break;
        let divideIndex = Math.floor(index / (maxNumber - itemList.length));
        itemList.push(list[index - 1]);
        index -= divideIndex;
      }
      return itemList.reverse();
    };

    // 获取轨迹
    const getPathRoute = async ()=>{
      if (!state.detail || !state.detail.receiver_user) return;
      const express_res = await ApiLogisticsOrder.getOrderPathRoute({page: 1, limit: 10000, shop_id: state.detail.receiver_user.shop_admin_id, order_id: state.detail.id});
      if (express_res && express_res.error_code === 0 && express_res.data) {
        state.routePath = getEnablePath(express_res.data.list, 16);
        // 创建定时更新
        if (state.mapTimer) {
          clearInterval(state.mapTimer);
        }
        drawMapRoutes();
      }
      else {
        state.routePath = [];
      }
    }

    // 转化路径为点数组
    const parseRouteToPath = (routes:any) => {
      if (!routes) return;
      let path:any[] = [];
      routes.map((route:any) => {
        if (!route) return;
        route.steps.map((step:any, index:number) => {
          if (!step) return;
          step.path.map((poi:any) => {
            if (!poi) return;
            path.push(poi);
          })
        })
      })

      return path;
    };

    // 绘制轨迹图
    const drawMapRoutes = async () => {
      if (!map || !AMaps) return;
      if (!state.routePath || !state.routePath.length || !state.detail) {

        return;
      }
      let startPoint = [state.detail.shipper.lng, state.detail.shipper.lat];
      let endPoint = [state.detail.receiver.lng, state.detail.receiver.lat];
      let carCurrentPath = state.routePath[state.routePath.length - 1];
      let carPoint = carCurrentPath.points.location.split(",");
      let pathPoints:any[] = state.routePath.map(item => {return item.points.location.split(",");});
      if (carAlreadyDriving) {
        carMarker.moveTo(carPoint, {
          duration:1000,
        }, ()=>{

        });
        carAlreadyDriving.autoFitView = false;
        carAlreadyDriving.search(startPoint, carPoint, {
          waypoints: pathPoints || []
        }, function(status:string, result:any) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === 'complete') {
            console.log('绘制驾车路线完成1', result.routes[0]);
            state.routePathDetail = result.routes[0];
            carAlreadyPolyline.setPath(parseRouteToPath(result.routes))

          } else {
            console.log('获取驾车数据失败1' + JSON.stringify(result))
          }
        });
        carWillDriving.search(carPoint, endPoint, function(status:string, result:any) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === 'complete') {
            console.log('绘制驾车路线完成', result.routes[0]);
            state.willPathDetail = result.routes[0];

          } else {
            console.log('获取驾车数据失败' + JSON.stringify(result))
          }
        });
        return
      }
      // 卡车位置
      carMarker = new AMaps.Marker({
        map: map,
        position:  carPoint,
        icon: "https://webapi.amap.com/images/car.png",
        offset: new AMaps.Pixel(-26, -13),
        autoRotation: true,
        angle:-90,
      });
      map.add(carMarker);
      // 起点
      let markerContent = '' +
              '<div class="custom-content-marker">' +
              '   <img src="https://webapi.amap.com/theme/v1.3/markers/n/start.png">' +
              '</div>';
      let startMarker = new AMaps.Marker({
        position: startPoint,
        // 将 html 传给 content
        content: markerContent,
        // 以 icon 的 [center bottom] 为原点
        offset: new AMaps.Pixel(-13, -30)
      });
      map.add(startMarker);
      // 终点
      let emarkerContent = '' +
              '<div class="custom-content-marker">' +
              '   <img src="https://webapi.amap.com/theme/v1.3/markers/n/end.png">' +
              '</div>';
      let endMarker = new AMaps.Marker({
        position: endPoint,
        // 将 html 传给 content
        content: emarkerContent,
        // 以 icon 的 [center bottom] 为原点
        offset: new AMaps.Pixel(-13, -30)
      });
      map.add(endMarker);

      //构造已经行驶过的路线
      carAlreadyDriving = new AMaps.Driving({
        policy: AMaps.DrivingPolicy.LEAST_TIME,
        autoFitView: true,
        hideMarkers: true,
        showTraffic: false,
        isOutline: false,
      });
      if (!carAlreadyPolyline) {
        carAlreadyPolyline = new AMaps.Polyline({
          path: [startPoint],
          showDir: true,
          strokeColor: "#FF5C57",
          strokeWeight: 6,
          strokeOpacity: 0.9,
          zIndex: 200,
          bubble: true,
        })
        map.add(carAlreadyPolyline);
      }
      carAlreadyDriving.search(startPoint, carPoint, {
        waypoints: pathPoints || []
      }, function(status:string, result:any) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === 'complete') {
          console.log('绘制驾车路线完成1', result.routes[0]);
          state.routePathDetail = result.routes[0];
          carAlreadyPolyline.setPath(parseRouteToPath(result.routes))

        } else {
          console.log('获取驾车数据失败1' + JSON.stringify(result))
        }
      });

      // 未行驶的路线
      carWillDriving = new AMaps.Driving({
        map: map,
        policy: AMaps.DrivingPolicy.LEAST_TIME,
        autoFitView: false,
        hideMarkers: true,
        showTraffic: false,
        isOutline: false,
      });
      carWillDriving.search(carPoint, endPoint, function(status:string, result:any) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === 'complete') {
          console.log('绘制驾车路线完成', result.routes[0]);
          state.willPathDetail = result.routes[0];

        } else {
          console.log('获取驾车数据失败' + JSON.stringify(result))
        }
      });
    }

    return {
      ...toRefs(state),
      carImages,
      dayjs,
      goBack,
      map,
      AMaps,
      initAmap,
      drawMapRoutes,
      timeCountFormat,
      distanceFormatKm,
      getLogisticsOrderStatus,
      dateFormat
    }
  },
})
