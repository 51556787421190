export interface IMenu {
    path: string;
    name: string;
    key: string;
    icon?: any;
    children?: IMenu[];
}

export interface NetMenu {
    path: string;
    name: string;
    component: string,
    icon?: any;
    children?: NetMenu[];
    front_url: string,
    meta: {
        "hidden": boolean,
        "title": string,
        "icon": string,
        "noClosable": boolean
    }
}

export interface IAdminRole {
    "name": string,
    "desc": string,
    "auth_ids": any,
    id?: number,
    admin_id?: number,
    role_id?: number,
    create_time?: string,
    status: number,
    "role"?: IAdminRoleDetail,
    auths?:IAdminRoleAuthDetail[]
}

export interface IAdminRoleDetail {
    "id"?: number,
    "name"?: string
}

export interface IAdminRoleAuthDetail {
    auth: any,
    create_time: string,
    id: number,
    menu_auth_id: number,
    role_id: number,
}

export interface AuthMenu {
    id?: number,
    "title": string, // 菜单名称
    "type": number, // 1-菜单；2-权限，3-菜单且权限（前端直接下拉写死）
    "system"?: number,
    "pid"?: number,
    "name"?: string, // 角色名称
    "sort": number, // 排序
    "icon"?: string,
    "uri": string, // 权限（接口路径）
    "front_url": string, // 新版前端路由
    component?:string,
    "disable": any, // 状态：0-启用；1-禁用；
    "type_str"?: string,
    "open"?: boolean,
    sub?: AuthMenu[]
}

export interface AuthMenu2 {
    id?: number,
    "title": string, // 菜单名称
    "type": number, // 1-菜单；2-权限，3-菜单且权限（前端直接下拉写死）
    "system"?: number,
    "pid"?: number,
    "name"?: string, // 角色名称
    "sort": number, // 排序
    "icon"?: string,
    "uri": string, // 权限（接口路径）
    "front_url": string, // 新版前端路由
    "disable": number, // 状态：0-启用；1-禁用；
    "type_str"?: string,
    "open"?: boolean,
    sub?: AuthMenu2[]
    component:any
}

export interface IMenuItem {
    app_id: string
    create_time: string
    decorate_type: number
    del: number
    id: number
    image: string
    is_pc: number
    is_show: number
    link_address: string
    link_type: number
    name: string
    pid: number
    sort: number
    subtitle: string
    update_time: string
    target: number
    params: any
}

export interface IMenuUpdate {
    id?: any;
    url?: any;
    pid: number; //上级id
    type: number;// 菜单类型 1-首页导航；2-个人中心；3-底部导航
    link_type: number; //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型// 暂时传3
    link_address: number | string;
    status?: number;
    name: string;// 菜单名称
    subtitle: string; // 副标题
    is_pc: number; //1-pc端，0-
    image: string;//图标
    target: number,// 跳转方式 0-内部跳转；1-新窗口
    sort: number
    params: any
}

// 菜单类型
export const menuType = [{name: '首页导航', id: 1}, {name: '个人中心', id: 2}, {name: '底部导航', id: 3}]

export const getMenuType = (id: any) => menuType.find(e => e.id === id)

// 跳转类型
export const menuTarget = [{name: '内部跳转', id: 0}, {name: '新窗口', id: 1}]

export const permissionTypes = [{id: 1, name:'菜单'},{id: 2, name:'权限'},{id: 3, name:'权限菜单'}]