import { IUserInfo } from '@/models/user_models'
import { createStore } from 'vuex'

interface IState {
  userInfo: IUserInfo | null
}

export default createStore<IState>({
  state: {
    userInfo: null
  },
  mutations: {
    setUserInfo(state, data){
      state.userInfo = data
    }
  },
  actions: {
  },
  modules: {
  }
})
