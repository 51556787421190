
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any,
        levelData:any,
        artData:any,
        goods_name:'',
        page3:any,
        goodList:any,
        total3:any,
        coupleName:any,
        coupleList:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    name:''
                },
                total:0,
                levelData:[],
                artData:[],
                title:'',
                goods_name:'',
                page3:1,
                goodList:[],
                total3:0,
                visible:false,
                formState:{
                    id:'',
                    name:'',
                    user_level_id:undefined,
                    type:undefined,
                    goods_id:undefined,
                    count:'',
                    coupon_id:undefined,
                    article_id:undefined,
                },
                type:'',
                coupleName:'',
                coupleList:[]
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '福利名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '用户等级',
                    dataIndex: 'level',
                    key: 'level',
                    align:'center'
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                    align:'center'
                },
                {
                    title: '福利',
                    key: 'vipWelfara',
                    dataIndex: 'vipWelfara',
                    align:'center'
                },
                {
                    title: '创建时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align:'center'
                },
                {
                    title: '修改时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];

            const rules = {
                name: [{required: true, message: "请输入福利名称"}],
                user_level_id: [{required: true, message: "请选择用户等级"}],
                type: [{required: true, message: "请选择福利类型"}],
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.vipWelfareList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.data
                    state.total = data.total
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const addApply = () =>{
                state.title = "新增会员福利"
                state.type = 1
                state.formState={
                    id:'',
                    name:'',
                    user_level_id:undefined,
                    type:undefined,
                    goods_id:undefined,
                    count:'',
                    coupon_id:undefined,
                    article_id:undefined,
                },
                    state.visible = true
            }
            const edit = (val) =>{
                state.title = "编辑会员福利"
                state.type = 2
                state.formState={
                    id:val.id,
                    name:val.name,
                    user_level_id:val.user_level_id,
                    type:val.type,
                    goods_id:val.goods_id,
                    count:val.count,
                    coupon_id:val.coupon_id,
                    article_id:val.article_id,
                }
                state.visible = true
            }
            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.vipWelfareAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.vipWelfareEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const onDel = async (item) => {
                const params = {id:item.id}
                const res = await apiMember.vipWelfareDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const getLevel = async () => {
                const params = {}
                const res = await apiMember.getLevelList(params)
                if (res) {
                    if (res.error_code === 0) {
                        state.levelData = res.data
                    }
                }
            }

            const getArt = async () => {
                const params = {}
                const res = await apiMember.getLevel(params)
                if (res) {
                    if (res.error_code === 0) {
                        state.artData = res.data
                    }
                }
            }

            const getGoods = async () => {
                const params = {page_no: state.page3, page_size: 10, type: '1', goods_name: state.goods_name}
                const res = await ApiUser.getGoodList(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    // state.goodList=data.lists
                    data.lists.forEach((item)=>{
                        state.goodList.push(item)
                    })
                    state.total3 = data.count
                } else {
                    message.error(res.msg)
                }
            }
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }

            const handleChange2 = (value) => {
                state.goods_name = value
                state.page3 = 1
                getGoods()
            }

            const popupScroll = (e) => {
                const { clientHeight, scrollHeight, scrollTop } = e.target;
                if (clientHeight + scrollTop == scrollHeight) {
                    //计算一下当前所查分页是否还有数据，如果小于总数则查，大于则不查，防抖节流，减少服务器损耗
                    if(state.page3 < state.total3){
                        state.page3 += 1
                        getGoods()
                    }
                }

            }

            const getCouple = async () => {
                const params = {page: 1, limit:999,name:state.coupleName}
                const res = await ApiMember.ShopCouponLists2(params)
                if (res.error_code == 0) {
                    const data:any =res.data
                   state.coupleList = data.list
                } else {
                    message.error(res.msg)
                }
            }

            const changeType = () => {
                state.formState.count = ''
                state.formState.goods_id=undefined
                state.formState.coupon_id=undefined
                state.formState.article_id=undefined
            }
            onMounted(() => {
                getGoods()
                getList()
                getLevel()
                getArt()
                getCouple()
            })

            return {
                ...toRefs(state),
                getList,
                changeType,
                getCouple,
                uploadHeaders,
                uploadAction,
                onSearch,
                getArt,
                popupScroll,
                getLevel,
                onReset,
                handleChange2,
                uploadFormData,
                pagination,
                columns,
                edit,
                onDel,
                formRef,
                handleChange,
                onSubmit,
                rules,
                addApply,
                handleTableChange
            }
        }
    })
