
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    interface IState {
        list:any,
        searches:any,
        total:any,
        title:any,
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    name:''
                },
                total:0,
                title:'',
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '优惠券名称',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '店铺名称',
                    dataIndex: 'shop_name',
                    key: 'shop_name',
                    align:'center'
                },
                {
                    title: '适用范围',
                    dataIndex: 'use_goods_type_desc',
                    key: 'use_goods_type_desc',
                    align:'center'
                },
                {
                    title: '可用时间',
                    dataIndex: 'use_time_desc',
                    key: 'use_time_desc',
                    align:'center'
                },
                {
                    title: '获取方式',
                    dataIndex: 'get_type_desc',
                    key: 'get_type_desc',
                    align:'center'
                },
                {
                    title: '满减方式',
                    dataIndex: 'condition_type_desc',
                    key: 'condition_type_desc',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'statusDesc',
                    key: 'statusDesc',
                    align:'center'
                },
                {
                    title: '发放时间',
                    dataIndex: 'send_time',
                    key: 'send_time',
                    align:'center'
                },
                {
                    title: '创建时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align:'center'
                },
            ];
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.ShopCouponLists(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.list
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                uploadHeaders,
                uploadAction,
                uploadFormData,
                pagination,
                columns,
                formRef,
                handleTableChange
            }
        }
    })
