
    import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
    import ApiLogistics from "@/request/apis/api_logistics";
    import router from "@/router";
    import { message } from "ant-design-vue/es";
    import StorageUtil from "@/utils/storage_util";
    import type {FormInstance} from 'ant-design-vue';
    import {
        EditOutlined,
        CheckOutlined,
        CloseOutlined,
        DownOutlined,
        MonitorOutlined
    } from "@ant-design/icons-vue";
    import ImageView from "@/components/ImageView.vue";
    import DriverSet from "@/components/DriverSet.vue";
    import PageUtil from "@/utils/page_util";
    import { IDriverItem, IEditDriver } from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";
    import apiTalent from "@/request/apis/api_talent";
    interface IState {
        list:any;
        total: number;
        loading: boolean;
        searches: any;
        visible: boolean;
        editItem?: IEditDriver;
        commentDetail:any
    }

    // 表格
    const columns = [
        {
            title: "商品图",
            dataIndex: "shop_name",
            key: "shop_name",
            align:"center"
        },
        {
            title: "商品名",
            dataIndex: "name",
            key: "name",
            align:"center"
        },
        {
            title: "价格（元）",
            dataIndex: "number",
            key: "number",
            align:"center"
        },
        {
            title: "库存",
            dataIndex: "degree_text",
            key: "degree_text",
            align:"center"
        },
        {
            title: "销量",
            dataIndex: "working_year_text",
            key: "working_year_text",
            align:"center"
        },
        {
            title: "创建时间",
            dataIndex: "describe",
            key: "describe",
            align:"center"
        },
        {
            title: "全新/二手",
            dataIndex: "wages",
            key: "wages",
            align:"center"
        },
        {
            title: "交易类型",
            dataIndex: "province",
            key: "province",
            align:"center"
        },
        {
            title: "是否支持团采",
            dataIndex: "address",
            key: "address",
            align:"center"
        },
        {
            title: "是否推荐",
            dataIndex: "del",
            key: "del",
            align:"center"
        },
        {
            title: "上架状态",
            dataIndex: "create_time",
            key: "create_time",
            align:"center"
        },
        {
            title: "审核状态",
            dataIndex: "create_time",
            key: "create_time",
            align:"center"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            align:"center"
        },
    ];

    export default defineComponent({
        name: "DriverList",
        components: {
            EditOutlined,
            CheckOutlined,
            CloseOutlined,
            ImageView,
            DriverSet,
            DownOutlined,MonitorOutlined
        },
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                commentDetail:'',
                visible:false,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: "",
                    shop_name:"",
                    education:undefined,
                    working_years:undefined,
                    wages_start:"",
                    wages_end:""
                },
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = { ...state.searches };
                StorageUtil.setParams(params);
                state.loading = true;
                const res = await ApiSetting.postList(params);
                state.loading = false;
                if (res && res.error_code === 0) {
                    state.total = res.data?.total || 0;
                    state.list = res.data?.data || [];
                }
            };

            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await ApiSetting.RecruitPostDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const onSearch = () => {
                getList()
            }

            const onReset = () => {
                state.searches={
                    page: 1,
                    limit: PageUtil.pageSize,
                    name: "",
                    shop_name:"",
                    education:undefined,
                    working_years:undefined,
                    wages_start:"",
                    wages_end:""
                }
                getList()
            }
            const expand = ref(false);
            const formRef = ref<FormInstance>();
            const formState = reactive({});
            const plainOptions = ['全新', '二手', '出租'];

            const rowSelection = () => {

            }
            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                expand,
                rowSelection,
                formRef,
                pagination,
                formState,
                plainOptions,
                onSearch,
                onReset,
                onDel,
                handleTableChange
            };
        },
    });
