
import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, computed} from 'vue';
import {appTitle} from "@/utils/config";
import {
  UserOutlined,
  ArrowUpOutlined,
  RiseOutlined,
  CalendarOutlined,
  DollarOutlined,
  TransactionOutlined,
  FallOutlined,
  FrownOutlined
} from '@ant-design/icons-vue';
import {IUserInfo} from "@/models/user_models";
import ApiUser from "@/request/apis/api_user";
import * as echarts from 'echarts';
import {IDriverItem} from "@/models/logistics_member";
import StorageUtil from "@/utils/storage_util";
import ApiLogistics from "@/request/apis/api_logistics";
import ImageView from "@/components/ImageView.vue";
import * as dayjs from 'dayjs'
import {IHomeLogisticsDataItem, IHomeStatisticListItem} from "@/models/logistics_model";

interface IHeaderData {
  userInfo: IUserInfo | undefined,
  driverOrderNumberList: IDriverItem[], // 接单榜
  driverRatingList: IDriverItem[], // 评分榜
  driverStaticsList: IDriverItem[],
  loading: boolean,
  activeKey: number,
  staticsTypeTabs: any[],
  currentDateString: string,
  todayData: IHomeLogisticsDataItem,
  yesterdayData: IHomeLogisticsDataItem,
  sumData: IHomeLogisticsDataItem,
  pieData: any
}

// 表格
const driverColumns = [
  {
    title: '排名',
    dataIndex: 'id',
    key: 'id',
    width: 80
  },
  {
    title: '司机',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '接单数',
    dataIndex: 'consignment_total',
    key: 'consignment_total',
    align: 'right'
  }
];
const driverCommentColumns = [
  {
    title: '排名',
    dataIndex: 'id',
    key: 'id',
    width: 80
  },
  {
    title: '司机',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '评分',
    dataIndex: 'stars_total',
    key: 'stars_total',
    align: 'right'
  }
];

export default defineComponent({
  name: 'Home',
  components: {
    UserOutlined,
    ArrowUpOutlined,
    RiseOutlined,
    CalendarOutlined,
    TransactionOutlined,
    FallOutlined,
    FrownOutlined,
    ImageView
  },
  setup() {
    let orderChart: any, orderExpressTypeCharts: any, moneyExpressTypeCharts: any, userActiveCharts: any = null;
    const {proxy: {$nextTick, $forceUpdate}}: any = getCurrentInstance();
    const state: IHeaderData = reactive({
      userInfo: undefined,
      driverStaticsList: [],
      loading: false,
      activeKey: 0,
      staticsTypeTabs: [{id: 1, name: "物流类型"}, {id: 2, name: "车辆类型"}],
      driverOrderNumberList: [],
      driverRatingList: [],
      currentDateString: "",
      todayData: {
        courier_active_num: 0,
        new_courier_num: 0,
        new_user_num: 0,
        new_vehicle_num: 0,
        order_amount: 0,
        order_num: 0,
        order_num_complaint: 0,
        order_num_good: 0,
        user_active_num: 0
      },
      yesterdayData: {
        courier_active_num: 0,
        new_courier_num: 0,
        new_user_num: 0,
        new_vehicle_num: 0,
        order_amount: 0,
        order_num: 0,
        order_num_complaint: 0,
        order_num_good: 0,
        user_active_num: 0
      },
      sumData: {
        courier_active_num: 0,
        new_courier_num: 0,
        new_user_num: 0,
        new_vehicle_num: 0,
        order_amount: 0,
        order_num: 0,
        order_num_complaint: 0,
        order_num_good: 0,
        user_active_num: 0
      },
      pieData: null
    });
    const dayjs1: any = dayjs;
    // 订单信息曲线图
    const orderOption = {
      title: {
        text: '订单'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#283b56'
          }
        }
      },
      toolbox: {
        show: true,
        feature: {
          dataView: {readOnly: false},
          restore: {},
          saveAsImage: {}
        }
      },
      legend: {},
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: '订单',

          min: 0,
          boundaryGap: [0.2, 0.2]
        },
        {
          type: 'value',
          scale: true,
          name: '营业额',
          min: 0,
          boundaryGap: [0.2, 0.2]
        }
      ],
      series: [
        {
          name: '成交订单',
          data: [],
          type: 'line',
          smooth: true
        },

        {
          name: '订单好评',
          data: [],
          type: 'line',
          smooth: true
        },
        {
          name: '订单投诉',
          data: [],
          type: 'line',
          smooth: true
        },
        {
          name: '营业额',
          data: [],
          type: 'bar',
          yAxisIndex: 1,
          smooth: true,
        },
      ]
    };
    // 订单物流类型饼
    const orderExpressTypeOption = {
      title: {
        text: '订单',
        left: "center",
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: '订单物流',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    };
    const moneyExpressTypeOption = {
      title: {
        text: '营业额',
        left: "center",
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: '订单物流',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 1048, name: '快件急件'},
            {value: 735, name: '零担运输'},
            {value: 580, name: '整车运输'},
            {value: 484, name: '特种物流'},
            {value: 300, name: '物流商家'},
            {value: 540, name: '大件运输'}
          ]
        }
      ]
    };
    // 活跃度曲线
    const userActiveOption = {
      title: {
        text: "活跃度",
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#283b56'
          }
        }
      },
      toolbox: {
        show: true,
        feature: {
          dataView: {readOnly: false},
          restore: {},
          saveAsImage: {}
        }
      },
      legend: {},
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: '人',
          min: 0,
          boundaryGap: [0.2, 0.2]
        },
        // {
        //   type: 'value',
        //   scale: true,
        //   name: '人',
        //   min: 0,
        //   boundaryGap: [0.2, 0.2]
        // }
      ],
      series: [
        {
          name: '新增用户',
          data: [],
          type: 'line',
          smooth: true
        },

        {
          name: '新增司机',
          data: [],
          type: 'line',
          smooth: true
        },
        {
          name: '最高在线用户数',
          data: [],
          type: 'line',
          yAxisIndex: 0,
          smooth: true
        },
        {
          name: '最高在线用司机数',
          data: [],
          type: 'line',
          yAxisIndex: 0,
          smooth: true,
        },
      ]
    };

    const initCart = () => {
      if (document) {
        let orderChartDoom: HTMLElement = document.getElementById('orderChart') as HTMLElement;
        let orderExpressTypeChart: HTMLElement = document.getElementById('orderExpressTypeChart') as HTMLElement;
        let moneyExpressTypeChart: HTMLElement = document.getElementById('moneyExpressTypeChart') as HTMLElement;
        let userActiveChart: HTMLElement = document.getElementById('userActiveChart') as HTMLElement;
        orderChart = echarts.init(orderChartDoom);
        orderExpressTypeCharts = echarts.init(orderExpressTypeChart);
        moneyExpressTypeCharts = echarts.init(moneyExpressTypeChart);
        userActiveCharts = echarts.init(userActiveChart);
        $nextTick(() => {
          orderChart.setOption(orderOption);
          orderExpressTypeCharts.setOption(orderExpressTypeOption);
          moneyExpressTypeCharts.setOption(moneyExpressTypeOption);
          userActiveCharts.setOption(userActiveOption);
        });
      }

    };

    const getStar = (record: IDriverItem) => {
      return record.consignment_total ? parseInt((Number(record.stars_total) / record.consignment_total).toString()) : 0;
    }

    // 获取变化率颜色等
    const getStaticsCss = (today: number = 0, yesterday: number = 0) => {
      today = today || 0
      yesterday = yesterday || 0
      return {
        color: today > yesterday ? "red" : (today === yesterday ? "gray" : "green"),
        text: today === yesterday ? "无变化" : "",
        divide: Math.abs(today - yesterday),
        isAdd: today > yesterday,
        mode: today > yesterday ? 1 : (today === yesterday) ? 0 : -1
      }
    }

    // 获取用户信息
    const getUserInfo = async () => {
      const res = await ApiUser.getUserInfo()
      if (res && res.error_code === 0) {
        state.userInfo = res.data
      }
    };

    //获取列表
    const getDriverList = async () => {
      state.loading = true
      const res = await ApiLogistics.getDriverOrderNumList({})
      if (res && res.error_code === 0) {
        state.driverOrderNumberList = res.data || [];
      }

      const resRate = await ApiLogistics.getDriverRatingList({})
      state.loading = false
      if (resRate && resRate.error_code === 0) {
        state.driverRatingList = resRate.data || [];
      }
    }

    // 获取运营数据
    const getHomeData = async () => {
      state.loading = true
      const res = await ApiLogistics.getHomeData({})
      if (res && res.error_code === 0) {
        state.todayData = res.data?.today || state.todayData;
        state.yesterdayData = res.data?.yesterday || state.yesterdayData;
        state.sumData = res.data?.sum || state.sumData;
      }

      const resList = await ApiLogistics.getHomeList({})
      if (resList && resList.error_code === 0) {
        let list: IHomeStatisticListItem[] = resList.data || [];
        // 订单变化
        orderOption.xAxis.data = list.map(item => {
          return item.date
        }) as never[];
        orderOption.series[0].data = list.map(item => {
          return item.order_num
        }) as never[];
        orderOption.series[1].data = list.map(item => {
          return item.order_num_good
        }) as never[];
        orderOption.series[2].data = list.map(item => {
          return item.order_num_complaint
        }) as never[];
        orderOption.series[3].data = list.map(item => {
          return Number(item.order_amount)
        }) as never[];
        // 活跃变化
        userActiveOption.xAxis.data = list.map(item => {
          return item.date
        }) as never[];
        userActiveOption.series[0].data = list.map(item => {
          return item.new_user_num
        }) as never[];
        userActiveOption.series[1].data = list.map(item => {
          return item.new_courier_num
        }) as never[];
        userActiveOption.series[2].data = list.map(item => {
          return item.user_active_num
        }) as never[];
        userActiveOption.series[3].data = list.map(item => {
          return Number(item.courier_active_num)
        }) as never[];
        $nextTick(() => {
          orderChart.setOption(orderOption);
          userActiveCharts.setOption(userActiveOption);
        });
      }
      await initPieChart();

      state.loading = false
    }

    const initPieChart = async () => {
      if (!state.pieData) {
        const resStatis = await ApiLogistics.getHomeStatistics({});
        if (resStatis && resStatis.error_code === 0) {
          state.pieData = resStatis.data;
        }
      }
      if (!state.pieData) return
      switch (state.activeKey) {
        case 0:
        case 1:
          let datas: any = state.pieData.express_category;
          orderExpressTypeOption.series[0].data = datas.order.map((item: any) => {
            return {value: item.category_count, name: item.categoryType.name}
          })
          moneyExpressTypeOption.series[0].data = datas.money.map((item: any) => {
            return {value: Number(item.category_money), name: item.categoryType.name}
          })
          break
        case 2:
          let data2: any = state.pieData.vehicle_type;
          orderExpressTypeOption.series[0].data = data2.order.map((item: any) => {
            return {value: item.vehicle_type_count, name: item.carType.name}
          })
          moneyExpressTypeOption.series[0].data = data2.money.map((item: any) => {
            return {value: Number(item.vehicle_type_money), name: item.carType.name}
          })
          break
      }
      console.log(moneyExpressTypeOption, state.activeKey)
      $nextTick(() => {
        orderExpressTypeCharts.setOption(orderExpressTypeOption);
        moneyExpressTypeCharts.setOption(moneyExpressTypeOption);
      });
    }

    onMounted(() => {
      getUserInfo();
      initCart();
      getHomeData();
      getDriverList();
      state.currentDateString = dayjs1(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
    })

    return {
      ...toRefs(state),
      appTitle,
      driverColumns,
      driverCommentColumns,
      getDriverList,
      orderOption,
      getStar,
      dayjs,
      getStaticsCss,
      initPieChart
    }
  }
});
