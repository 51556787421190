import dayjs from "dayjs";


/**
 * 时间格式化
 * @param time 毫秒
 * @param showTime 是否显示时分秒
 */
export const dateFormat = (time: number, showTime: boolean = true) => {
    if(!time){
        return ''
    }
    const format = showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
    return dayjs(time).format(format)
}

/**
 * 金额格式化
 * @param num 金额 单位【元】
 * @param showUnit 是否显示人民币符号
 */
export const amountFormat = (num: string | number, showUnit = false) => {
    let result:any[] = [], temp_arr:any[] = [], counter = 0;
    num = num || 0;

    //先用小数点分割，格式化整数部分
    temp_arr = num.toString().split('.');
    //整数部分
    num = temp_arr[0];
    //把整数部分分割成单个字符数组
    let numArr: any = num.toString().split('');

    for (let i = numArr.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(numArr[i]);
        if (!(counter % 3) && i != 0) {
            result.unshift(',');
        }
    }
    // result.unshift("¥");

    //temp_arr数组长度为2表示有小数
    if (temp_arr.length == 2) {
        result.push('.' + temp_arr[1]);
    }
    let res = result.join('')
    if (showUnit) {
        return '¥' + res
    }
    return res
}
