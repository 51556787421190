
// 设置组织
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs, watch} from 'vue';
import type {UnwrapRef} from 'vue';
import {message} from "ant-design-vue/es";
import ApiParty from "@/request/apis/api_party";
import {IOrganizationItem, IOrganizationUpdateItem} from "@/models/party_model";

interface IState {
  list: IOrganizationItem[]
  loading: boolean;
  editItem?: IOrganizationItem;
}

export default defineComponent({
  props: ['editItem', 'reset'],
  name: "OrganizationSet",
  setup(props) {
    const state: IState = reactive({
      loading: false,
      editItem: props.editItem,
      list: []
    })

    const formRef = ref();
    const formState: UnwrapRef<IOrganizationUpdateItem> = reactive({
      name: '',
      pid: []
    });


    watch(() => props.editItem, (newValue, oldValue) => {
      state.editItem = props.editItem
      formState.name = newValue?.name || '';
      formState.pid = newValue?.pid || [];
    })

    const rules = {
      name: [{required: true, message: '请输入组织名称'}],
    };

    // 获取列表
    const getList = async () => {
      const res = await ApiParty.getOrganizationList()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params: any = {...toRaw(formState)}
            if (params.pid.length) {
              params.pid = params.pid[params.pid.length - 1]
            } else {
              params.pid = 0
            }
            if (props.editItem) {
              params.id = props.editItem.id
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    };
    const resetForm = () => {
      getList()
      formRef.value.resetFields();
      if (props.reset) {
        props.reset()
      }
    };

    // 添加
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiParty.addOrganization(params)
      state.loading = false
      if (res) {
        if (res.error_code === 0) {
          message.success(res.msg || '新增成功')
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiParty.editOrganization(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '编辑成功')
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 16},
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
      onAdd,
      onEdit,
      getList
    }
  }
})
