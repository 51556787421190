
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import ApiLogistics from "@/request/apis/api_logistics";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import StorageUtil from "@/utils/storage_util";
    import {
        EditOutlined,
        CheckOutlined,
        CloseOutlined,
    } from "@ant-design/icons-vue";
    import ImageView from "@/components/ImageView.vue";
    import DriverSet from "@/components/DriverSet.vue";
    import PageUtil from "@/utils/page_util";
    import {IDriverItem, IEditDriver} from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        list: any;
        total: number;
        loading: boolean;
        searches: any;
        editItem?: IEditDriver;
        commentDetail: any,
        detailData: any,
        visible:boolean;
    }

    // 表格
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center"
        },
        {
            title: "职位名",
            dataIndex: "post_name",
            key: "post_name",
            align: "center"
        },
        {
            title: "操作人头像",
            dataIndex: "user_avatar",
            key: "user_avatar",
            align: "center"
        },
        {
            title: "操作人",
            dataIndex: "user_name",
            key: "user_name",
            align: "center"
        },
        {
            title: "操作人手机号",
            dataIndex: "user_mobile",
            key: "user_mobile",
            align: "center"
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            align: "center"
        },
        {
            title: "次数",
            dataIndex: "pre_top_times",
            key: "pre_top_times",
            align: "center"
        },
        {
            title: "发布时间",
            dataIndex: "create_time",
            key: "create_time",
            align: "center"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            align: "center"
        },
    ];

    export default defineComponent({
        name: "DriverList",
        components: {
            EditOutlined,
            CheckOutlined,
            CloseOutlined,
            ImageView,
            DriverSet,
        },
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                visible:false,
                commentDetail: '',
                detailData: '',
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: "",
                },
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches};
                StorageUtil.setParams(params);
                state.loading = true;
                const res = await ApiSetting.RecruitPostTopList(params);
                state.loading = false;
                if (res && res.error_code === 0) {
                    state.total = res.data?.total || 0;
                    state.list = res.data?.data || [];
                }
            };

            const onSearch = () => {
                state.searches.page=1,
                state.searches.limit=PageUtil.pageSize,
                getList()
            }

            const onReset = () => {
                state.searches = {
                    page: 1,
                    limit: PageUtil.pageSize,
                    keywords: "",
                }
                getList()
            }

            const getDetail = (val) => {
                if(val.log){
                    state.detailData = val.log.post
                }
                state.visible = true
            }
            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                pagination,
                onSearch,
                getDetail,
                onReset,
                handleTableChange
            };
        },
    });
