import {baseRequestClient,baseRequestClient2} from "@/request/request_client";
import { LogisticsOrderItem,LogisticsOrderDetail } from "@/models/logistics_model";
import {IList,IListResult} from "@/models/base_model";

const requestClient = baseRequestClient
const shopRequestClient = baseRequestClient2
//  物流订单板块接口
export default class ApiParty {
    /**
     * GET 订单列表
     * @param params
     */
    static getLogisticsOrderLists = async (params: any) => {
        return await requestClient.get<IListResult<LogisticsOrderItem>>('/admin/logistics.LogisticsOrder/lists',params)
    }

    /**
     * GET 获取订单详情
     * @param id
     */
    static getLogisticsOrderDetail = async (id: string) => {
        return await requestClient.get<LogisticsOrderDetail>('/admin/logistics.LogisticsOrder/detail', {id})
    }

    /**
     * 假支付，测试用
     * @param id
     */
    static onPayOrderTest = async (id: any) => {
        return await requestClient.post('/api/Consignment/pay', {id})
    }

    // 获取物流类型
    static getExpressTypes = async () => {
        return await requestClient.get<any>('/api/consignment/cateLis')
    }

    // 获取车辆类型
    static getCarTypes = async (params: any) =>{
        return await requestClient.get<any>('/admin/logistics.CarType/list', params)
    }

    // 获取订单物流轨迹
    static getOrderPathRoute = async (params: any) => {
        return await requestClient.get<any>('/admin/logistics.Trail/pointList', params)
    }
    static wantbuylist = async (params: any) => {
        return await requestClient.get('/admin/WantBuy/list',params)
    }
    static wantbuyStatus = async (params: any) => {
        return await requestClient.post('/admin/WantBuy/status', params)
    }
    static  WaBntBuyShow= async (params: any) => {
        return await requestClient.get('/admin/WantBuy/show',params)
    }
}
