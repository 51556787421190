
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import ApiLogisticsOrder from "@/request/apis/api_logistics_order";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import StorageUtil from "@/utils/storage_util";
    import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
    import ImageView from '@/components/ImageView.vue'
    import PageUtil from "@/utils/page_util";
    import {IAdminRole} from "@/models/menu_model";
    import ApiAdmin from "@/request/apis/api_admin";

    interface IState {
        list: IAdminRole[],
        total: number;
        loading: boolean;
        searches: any
    }

    // 表格
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '角色名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '描述',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: '权限描述',
            dataIndex: 'auth_str',
            key: 'auth_str',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 160,
            fixed: "right"
        }
    ];

    export default defineComponent({
        name: 'RoleList',
        components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                category: [],
                subCategory: [],
                editItem: undefined,
                searches: {
                    keywords: '',
                    pid: '',
                    cid: '',
                    c_id: '',
                    is_recommend: '',
                    type: '',
                    is_show: '',
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                }
            })


            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            //获取列表
            const getList = async () => {
                let params = {...state.searches}
                StorageUtil.setParams(params)
                delete params.total
                delete params.pid
                state.loading = true
                // const res = await ApiAdmin.getRoleList2(params)
                // state.loading = false
                // if (res && res.error_code === 0) {
                //     state.total = res.data?.count || 0
                //     state.list = res.data?.lists || []
                // }
            }

            // 添加或修改角色
            const addOrUpdateAdmin = async (item?: IAdminRole) => {
                let params = {}
                if (item) {
                    item.auth_ids = item.auths?.map(item1 => {return item1.menu_auth_id}) as number[];
                    item.auths = [];
                    params = {id: item.id, data: JSON.stringify(item)}
                }
                router.push({path: '/setting/role-set', query: params}).then()
            };

            // 搜索
            const onSearch = (key: string, value: any, e: any) => {
                if (key === 'keywords') {
                    for (let key in state.searches) {
                        state.searches[key] = ''
                    }
                } else {
                    state.searches.keywords = ''
                    if (key === 'pid') {
                        state.searches.cid = value
                        state.searches.c_id = ''
                        if (value) {
                            // state.subCategory = state.category.find(item => item.id === value)?.children || []
                        } else {
                            // state.subCategory = []
                        }
                    }
                    if (key === 'c_id') {
                        if (value) {
                            state.searches.cid = value
                        } else {
                            state.searches.cid = state.searches.pid
                        }
                    }
                }
                state.searches[key] = value
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const resetSearch = () => {
                for (let key in state.searches) {
                    state.searches[key] = ''
                }
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                // state.subCategory = []
                getList()
            }


            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                addOrUpdateAdmin,
                pagination,
                handleTableChange,
                onSearch,
                resetSearch,
            }
        }
    })
