
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import api_article from "@/request/apis/api_article";
    import ApiMember from "@/request/apis/api_member";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState:any,
        treeData:any
    }



    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData:[],
                list: [],
                total: 0,
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                type: '',
                formState:''
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    fixed:'left'
                },
                {
                    title: '公司名',
                    dataIndex: 'name',
                    key: 'name',
                    fixed:'left'
                },
                {
                    title: '省',
                    dataIndex: 'province',
                    key: 'province',
                },
                 {
                    title: '市',
                    dataIndex: 'city',
                    key: 'city',
                },
                 {
                    title: '区',
                    dataIndex: 'area',
                    key: 'area',
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: '联系人',
                    dataIndex: 'contact',
                    key: 'contact',
                },
                {
                    title: '营业执照号',
                    dataIndex: 'certificate_no',
                    key: 'certificate_no',
                },
                {
                    title: '电子邮箱',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: '传真',
                    dataIndex: 'fax',
                    key: 'fax',
                },
                {
                    title: '公司全称',
                    dataIndex: 'full_name',
                    key: 'full_name',
                },
                {
                    title: '手机号码',
                    dataIndex: 'mobile',
                    key: 'mobile',
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                },
                {
                    title: '银行卡号',
                    dataIndex: 'bank',
                    key: 'bank',
                },
                {
                    title: 'QQ号',
                    dataIndex: 'qq_number',
                    key: 'qq_number',
                },
                {
                    title: '是否集团',
                    dataIndex: 'is_bloc',
                    key: 'is_bloc',
                },
                {
                    title: '经营范围',
                    dataIndex: 'scope',
                    key: 'scope',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    fixed:'right',
                    align:'center'
                },
            ]

            const rules = {
                name: [{required: true, message: "请输入公司名称"},],
                full_name: [{required: true, message: "请输入公司全称"},],
                urban: [{required: true, message: "请选择省市区"},],
                address: [{required: true, message: "请输入详细地址"},],
                contact: [{required: true, message: "请输入联系人"},],
                certificate_no: [{required: true, message: "请输入营业执照号"},],
                mobile: [{required: true, message: "请输入手机号码"},],
            }


            const add = () => {
                state.title = '新增公司'
                state.visible = true
                state.type = 1
                state.formState={
                    "province":'',
                    "city":'',
                    "urban":[],
                    "area":'',
                    "address":"",
                    "contact":"",
                    "certificate_no":'',
                    "email":'',
                    "fax":'',
                    "full_name":'',
                    "mobile":'',
                    "name":'',
                    "remark":'',
                    "bank":'',
                    "qq_number":'',
                    "is_bloc":'',
                    "scope":''
                }
            }



            const edit = (val) => {
                state.type = 2
                state.formState={
                    "id":val.id,
                    "province":val.province.id,
                    "city":val.city.id,
                    "urban":[Number(val.province.id),Number(val.city.id),Number(val.area.id)],
                    "area":val.area.id,
                    "address":val.address,
                    "contact":val.contact,
                    "certificate_no":val.certificate_no,
                    "email":val.email,
                    "fax":val.fax,
                    "full_name":val.full_name,
                    "mobile":val.mobile,
                    "name":val.name,
                    "remark":val.remark,
                    "bank":val.bank,
                    "qq_number":val.qq_number,
                    "is_bloc":val.is_bloc,
                    "scope":val.scope
                }
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSetting.companyList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.data
                    state.total = data.total

                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    state.formState.province = state.formState.urban[0]
                    state.formState.city = state.formState.urban[1]
                    state.formState.area = state.formState.urban[2]
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiSetting.companyAdd(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiSetting.companyEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id:val.id}
                const res = await ApiSetting.companyDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }


            const getTree = async () => {
                const res = await api_article.regionTree()
                    if (res.error_code === 0) {
                        state.treeData = res.data
                    }else{
                        message.error(res.msg)
                    }
            }

            onMounted(() => {
                getList()
                getTree()
            })

            return {
                ...toRefs(state),
                columns,
                open,
                formRef,
                onSubmit,
                getTree,
                rules,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
