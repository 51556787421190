<template>
    <a-card style="position: relative">
        <div class="maintitle" style="margin-bottom: 30px">店铺信息</div>
        <img  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" style="position: absolute;margin-top:10px;width:9%;height: auto"/>
        <a-descriptions  bordered style="width:90%;margin-left:10%">
            <a-descriptions-item label="店铺名称">金华五金商贸</a-descriptions-item>
            <a-descriptions-item label="店铺id">46</a-descriptions-item>
            <a-descriptions-item label="主体信息">
                <span>金华五金商贸</span>
                <span class="tips" style="margin-left:20px">修改</span>
                <span class="tips" style="margin-left:10px">查看</span>
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">2021-09-11 11:46:30</a-descriptions-item>
            <a-descriptions-item label="店铺经营证明">
               <div class="tips">查看</div>
            </a-descriptions-item>
            <a-descriptions-item label="店铺类型">附近的店</a-descriptions-item>
            <a-descriptions-item label="经营类目"></a-descriptions-item>
            <a-descriptions-item label="负责人姓名">王金网店</a-descriptions-item>
            <a-descriptions-item label="负责人帐号">18388045974</a-descriptions-item>
            <a-descriptions-item label="联系电话">18388045974</a-descriptions-item>
            <a-descriptions-item label="店铺简介">售卖商品</a-descriptions-item>
        </a-descriptions>
    </a-card>

    <a-card style="margin-top:20px">
        <div class="maintitle">店铺信誉</div>
       <div class="main">
           <div class="item">
               <div class="title">得分评价</div>
               <div class="value">12分</div>
               <div class="status">良好</div>
           </div>
           <div class="item">
               <div class="title">店铺状态</div>
               <div class="value" style="color:#67c23a">正常</div>
           </div>
           <div class="item">
               <div class="title">用户评价</div>
               <div class="value">108条</div>
               <div class="status">查看</div>
           </div>
       </div>
    </a-card>
</template>

<script>
    export default {
        name: "shopManage"
    }
</script>

<style scoped>
    .maintitle{
        font-size: 18px;
        font-weight: bold;
        color: #0f2027;
    }
    .main{
        width: 100%;
        display: flex;
    }
    .item{
        width: 33.333%;
        text-align: center;
    }
    .value{
        margin-top:10px;
        font-size: 20px;
        font-weight: bold;
    }
    .status{
        margin-top:10px
    }
    .tips{
        color: #1890FF;
        cursor: pointer;
    }
</style>