// 本地存储
export default class StorageUtil {
    // 设置token及过期时间
    static setToken = (token: string, expiredTime: number) => {
        if (token && expiredTime) {
            localStorage.setItem('ssss', `${token},${expiredTime}`)
        }
    }

    static getToken = () => {
        const res = localStorage.getItem('ssss')
        if (res) {
            const data = res.split(',');
            return data[0]
        }
        return null
    }

    // 根据token判断是否登录
    static isLogin = () => {
        const res = localStorage.getItem('ssss')
        if (res) {
            const data = res.split(',');
            const expiredTime = parseInt(data[1])
            const isLogin = expiredTime > Number(new Date()) / 1000;
            if (!isLogin) {
                // 未登录则清除所有数据
                StorageUtil.clear()
            }
            return isLogin
        }
        return false
    }

    // 路由参数-本地存储-用于页面返回持久化搜索参数
    static setParams = (params: any) => {
        localStorage.setItem('params', JSON.stringify(params))
    }

    static getParams = () => {
        const res = localStorage.getItem('params')
        if (res) {
            return JSON.parse(res)
        }
        return null
    }

    static clearParams = () => localStorage.removeItem('params')

    static clear = () => localStorage.clear()
}

