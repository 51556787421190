
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import ApiUser from "@/request/apis/api_user";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import countTo from '../components/vue-count-to/vue-countTo.vue';

    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        course_num: any,
        article_num: any,
        xData: any,
        yData: any,
        yData2: any,
        shopList: any,
        activeKey: any,
        shopList2: any,
        shop_30data: any,
        user_30data: any,
        yesterday_order_num: any,
        today_order_num: any,
        yesterday_order_sum: any,
        today_order_sum: any,
        yesterday_resume_num: any,
        today_resume_num: any,
        yesterday_post_num: any,
        today_post_num: any,
        yesterday_user_num: any,
        today_user_num: any,
        yesterday_company_num: any,
        today_company_num: any,
        yesterday_newuser_num: any,
        today_newuser_num: any,
        yesterday_newcompany_num: any,
        today_newcompany_num: any,
        yesterday_newvipuser_num: any,
        today_newvipuser_num: any,
        order_30data: any,
        order_30data2: any,
        post_30data:any,
        resume_30data: any,
        order_num: any,
        order_sum: any,
        resume_num: any,
        post_num: any,
        user_num: any,
        company_num: any,
        newshop_30data: any,
        post_classdata: any,
        resume_classdata: any,
        serviceorder_classdata: any,
        goodsorder_classdata: any,
        pieData: any,

    }


    export default defineComponent({
        name: 'Home',
        components: {UserOutlined, countTo},
        setup() {
            let state = reactive({
                updata_time: "",
                course_num: [],
                appId: appId,
                article_num: [],
                xData: [] as any [],
                yData: [] as any [],
                yData2: [] as any [],
                yesterday_order_num: '',
                today_order_num: '',
                yesterday_order_sum: '',
                today_order_sum: '',
                yesterday_resume_num: '',
                today_resume_num: '',
                yesterday_post_num: '',
                today_post_num: '',
                yesterday_user_num: '',
                today_user_num: '',
                yesterday_company_num: '',
                today_company_num: '',
                yesterday_newuser_num: '',
                today_newuser_num: '',
                yesterday_newcompany_num: '',
                today_newcompany_num: '',
                yesterday_newvipuser_num: '',
                today_newvipuser_num: '',
                shopList: [],
                shopList2: [],
                order_30data: [] as any [],
                order_30data2: [] as any [],
                post_30data: [] as any [],
                resume_30data: [] as any [],
                newshop_30data: [] as any [],
                user_30data: [] as any [],
                shop_30data: [] as any [],
                post_classdata: [] as any [],
                resume_classdata: [] as any [],
                serviceorder_classdata: [] as any [],
                goodsorder_classdata: [] as any [],
                pieData: [] as any [],
                order_num: '',
                order_sum: '',
                resume_num: '',
                post_num: '',
                user_num: '',
                company_num: '',
                activeKey: 1,
            })


            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const getDate = () => {
                let dateTime
                let yy = new Date().getFullYear()
                let mm = new Date().getMonth() + 1
                let dd = new Date().getDate()
                let hh = new Date().getHours()
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
                dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
                state.updata_time = dateTime
            }

            const columns = [
                {
                    title: '排名',
                    dataIndex: 'shop_id',
                    key: 'shop_id',
                    align:'center'
                },
                {
                    title: '商家',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '金额',
                    dataIndex: 'sum_money',
                    key: 'sum_money',
                    align:'center'
                },

            ];

            const columns2 = [
                {
                    title: '排名',
                    dataIndex: 'shop_id',
                    key: 'shop_id',
                    align:'center'
                },
                {
                    title: '公司名',
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
                {
                    title: '岗位数',
                    dataIndex: 'num',
                    key: 'num',
                    align:'center'
                },

            ];


            const initPie = () => {
                const myChart3 = proxy.$echarts.init(document.getElementById('mycharts3'));
                myChart3.setOption({
                    title: {
                        text: '订单',
                        left: 'center',
                        top: '20'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        top: '30'
                    },
                    series: [
                        {
                            name: '数量',
                            type: 'pie',
                            radius: '50%',
                            data: state.pieData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                });
                window.addEventListener("resize", (() => {
                    myChart3.resize();
                }));

            };


            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                const myChart2 = proxy.$echarts.init(document.getElementById('mycharts2'));
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: '个数',
                        },
                        {
                            type: 'value',
                            name: '服务订单（元）',
                            min: 0,
                            splitLine: {
                                show: false,
                            },
                        }],
                    legend: {
                        orient: 'horizontal',
                        x: 'center',
                        data: ['服务订单（数量）', '新增商家', '简历', '招聘岗位', '服务订单（金额）']
                    },
                    grid: {
                        left: '2%',
                        right: '3%',
                        bottom: '0',
                        top: '10%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "服务订单（数量）",
                            data: state.order_30data2,
                            type: 'bar',
                            smooth: true,
                        },
                        {
                            name: "新增商家",
                            data: state.newshop_30data,
                            type: 'bar',
                            smooth: true,
                        },
                        {
                            name: "招聘岗位",
                            data: state.post_30data,
                            type: 'bar',
                            smooth: true,
                        },
                        {
                            name: "简历",
                            data: state.resume_30data,
                            type: 'bar',
                            smooth: true,
                        },
                        {
                            name: "服务订单（金额）",
                            data: state.order_30data,
                            type: 'line',
                            smooth: true,
                            yAxisIndex: 1,
                        },
                    ]
                });

                myChart2.setOption({
                    xAxis: {
                        type: 'category',
                        data: state.xData,

                    },
                    yAxis: [
                        {
                            splitNumber: 8,
                            type: 'value',
                            name: '数量',
                            scale:true,
                        },

                    ],
                    legend: {
                        orient: 'horizontal',
                        x: 'center',
                        data: ['用户在线', '商家在线']
                    },
                    grid: {
                        left: '2%',
                        right: '2%',
                        bottom: '0',
                        top: '20%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "用户在线",
                            data: state.user_30data,
                            type: 'line',
                            smooth: true,
                            areaStyle:{}
                        },
                        {
                            name: "商家在线",
                            data: state.shop_30data,
                            type: 'line',
                            smooth: true,
                            areaStyle:{}
                        },
                    ]
                });
                window.addEventListener("resize", (() => {
                    myChart.resize();
                    myChart2.resize();
                }));
            };
            const getdata = async () => {

                const res = await ApiUser.shareTalentsDay();
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.yesterday_order_num = data.yesterday_order_num
                    state.today_order_num = data.today_order_num
                    state.yesterday_order_sum = data.yesterday_order_sum
                    state.today_order_sum = data.today_order_sum
                    state.yesterday_resume_num = data.yesterday_resume_num
                    state.yesterday_post_num = data.yesterday_post_num
                    state.today_post_num = data.today_post_num
                    state.yesterday_user_num = data.yesterday_user_num
                    state.today_user_num = data.today_user_num
                    state.yesterday_company_num = data.yesterday_company_num
                    state.today_company_num = data.today_company_num
                    state.yesterday_newuser_num = data.yesterday_newuser_num
                    state.today_newuser_num = data.today_newuser_num
                    state.yesterday_newcompany_num = data.yesterday_newcompany_num
                    state.today_newcompany_num = data.today_newcompany_num
                    state.yesterday_newvipuser_num = data.yesterday_newvipuser_num
                    state.today_newvipuser_num = data.today_newvipuser_num
                    state.order_num = data.order_num
                    state.order_sum = data.order_sum
                    state.resume_num = data.resume_num
                    state.post_num = data.post_num
                    state.user_num = data.user_num
                    state.company_num = data.company_num
                    state.shopList2 = data.post_ranking
                    state.shopList = data.order_ranking
                    initT()
                }

                const res2 = await ApiUser.shareTalentsMonth();
                if (res2 && res2.error_code === 0) {
                    const data: any = res2.data
                    const order_30data = data.order_30data
                    const newshop_30data = data.newshop_30data
                    const post_30data = data.post_30data
                    const shop_30data = data.shop_30data
                    const resume_30data = data.resume_30data
                    const user_30data = data.user_30data
                    order_30data.forEach((item: any, index) => {
                        state.xData.push(item.date.slice(5, 10))
                        state.order_30data.push(item.sum_money)
                        state.order_30data2.push(item.order_num)
                    })
                    newshop_30data.forEach((item: any, index) => {
                        state.newshop_30data.push(item.shop_num)
                    })
                    post_30data.forEach((item: any, index) => {
                        state.post_30data.push(item.post_num)
                    })
                    user_30data.forEach((item: any, index) => {
                        state.user_30data.push(item.user_num)
                    })
                    shop_30data.forEach((item: any, index) => {
                        state.shop_30data.push(item.shop_num)
                    })
                    post_30data.forEach((item: any, index) => {
                        state.post_30data.push(item.post_num)
                    })
                    resume_30data.forEach((item: any, index) => {
                        state.resume_30data.push(item.resume_num)
                    })
                    initT()
                }

                const res3 = await ApiUser.shareTalentsClass();
                if (res3 && res3.error_code === 0) {
                    const data: any = res3.data
                    state.post_classdata = data.post_classdata
                    state.resume_classdata = data.resume_classdata
                    state.serviceorder_classdata = data.serviceorder_classdata
                    state.goodsorder_classdata = data.goodsorder_classdata
                    if (state.activeKey == 1) {
                        state.goodsorder_classdata.forEach((item, index) => {
                            const obj = {name: "", value: ""}
                            obj.value = item.total_amount
                            obj.name = item.order_type
                            state.pieData.push(obj)
                        })
                    }
                    initPie()
                }
            }

            const changePie = () => {
                state.pieData = []
                if (state.activeKey == 1) {
                    state.goodsorder_classdata.forEach((item, index) => {
                        const obj = {name: "", value: ""}
                        obj.value = item.total_amount
                        obj.name = item.order_type
                        state.pieData.push(obj)
                    })
                }
                if (state.activeKey == 2) {
                    state.serviceorder_classdata.forEach((item, index) => {
                        const obj = {name: "", value: ""}
                        obj.value = item.total_amount
                        obj.name = item.order_type
                        state.pieData.push(obj)
                    })
                }
                if (state.activeKey == 3) {
                    state.resume_classdata.forEach((item, index) => {
                        const obj = {name: "", value: ""}
                        obj.value = item.resume_num
                        obj.name = item.topname
                        state.pieData.push(obj)
                    })
                }
                if (state.activeKey == 4) {
                    state.post_classdata.forEach((item, index) => {
                        const obj = {name: "", value: ""}
                        obj.value = item.post_num
                        obj.name = item.topname
                        state.pieData.push(obj)
                    })
                }
                initPie()
            }

            onMounted(() => {
                getDate()
                getdata()
                initPie()
            })

            return {
                ...toRefs(state),
                appTitle,
                changePie,
                myRef,
                initPie,
                columns,
                columns2,
                initT,

            }
        }
    });
