
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import PageUtil from "@/utils/page_util";
import {message} from "ant-design-vue/es";
import {linkTypes, linkTypesArr, terminalTypes} from "@/models/banner_model";
import RoleSet from '@/components/RoleSet.vue'
import * as dayjs from 'dayjs'
import {IOrganizationItem, IRoleItem, IRoleUpdateItem} from "@/models/party_model";
import ApiParty from "@/request/apis/api_party";
import {menuText} from "@/utils/config";

interface IState {
  list: IRoleItem[],
  total: number;
  loading: boolean;
  editItem?: IRoleUpdateItem
  organizationList: IOrganizationItem[];
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '等级',
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: '所属组织',
    dataIndex: 'organization',
    key: 'organization',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
  },
];

export default defineComponent({
  name: 'RoleList',
  components: {RoleSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      organizationList: [],
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        organization_id: '', //组织id
      }
    })

    /// 搜索参数
    const searches: any = reactive({
      organization_id: '', //组织id
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否启用pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      state.loading = true
      const res = await ApiParty.getRoleList({...state.searches})
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.data || []
      }
    }

    // 删除
    const onDel = async (id: number) => {
      const res = await ApiParty.delRole(id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('删除成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 获取组织列表
    const getOrganizationList = async () => {
      const res = await ApiParty.getOrganizationList()
      if (res && res.error_code === 0) {
        state.organizationList = res.data || []
      }
    }


    // 设置编辑数据
    const onEdit = (item: IRoleItem) => {
      let organization = item.organization.path.match(/\d+(.\d+)?/g) || []
      let organizationArr: any = []
      if(organization.length > 0){
        organizationArr = organization.map(e => Number(e)).concat(Number(item.organization.id)).slice(1)
      }
      state.editItem = {
        id: item.id,
        level: item.level,
        name: item.name,
        organization_id: organizationArr
      }
    }

    const reset = () => {
      state.editItem = undefined
      getList()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'organization_id') {
        const val = value[value.length - 1]
        searches[key] = val
      } else {
        searches[key] = value
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in searches) {
        searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    onMounted(() => {
      getOrganizationList()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      onEdit,
      onDel,
      handleTableChange,
      dayjs,
      onSearch,
      resetSearch,
      reset,
      pagination,
      linkTypesArr,
      searches,
      linkTypes,
      terminalTypes,
      menuText,
    }
  }
})
