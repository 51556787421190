// 课程

// 课程item
export interface ICourseItem {
    app_id: string
    collects: number // 收藏数
    create_time: string
    id: number
    intro: string // 课程简介
    lecturer: string // 讲师
    sort: number
    status: number
    thumb: string
    title: string
    type: number // 课程类型(1:视频，2音频，3书籍)
    update_time: string
    recommend:any
    price:any
    subtitle:any
    shop_id:any
    tutor_id:any
    views: number // 阅读数
}

export interface ICourseUpdate {
    course_id?: any // 课程ID
    id?: any	// 课程ID
    type: number	// 课程类型(1:视频，2音频，3书籍)
    title: string	// 课程名称
    lecturer: string	// 讲师
    thumb: string	// 缩略图
    intro: string	// 课程简介
    status?: number	// 当前状态
    sort?: number	// 排序
    views?: number	// 阅读数
    collects?: string	// 收藏数
    app_id?: string	// 应用标识
    create_time?: string	// 创建时间
    update_time?: string	// 更新时间
}

// 课程章节
export interface ISectionUpdate {
    section_id?: any	// 课程ID
    course_id?: any	// 课程ID
    title: string	// 课程名称
    content: string	// 课程内容
    media_type: number // 媒体类型
    media_url: string	//	媒体文件地址
    status?: number	// 当前状态(单位秒)
    views?: number	// 浏览次数
    duration: any	// 媒体时长
    sort?: number	// 排序
    app_id?: string	// 应用标识
    create_time?: string	// 创建时间
    update_time?: string	// 更新时间
}

export interface ISectionItem {
    app_id: string
    content: string
    course_id: number
    create_time: string
    duration: any
    id: number
    media_type: number
    media_url: string
    sort: number
    status: number
    title: string
    update_time: string
    views: number
}

export interface ISectionedit {
    "id":any
    "type":any
    "title":any
    "lecturer":any
    "thumb":any
    "intro":any
    "status":any
    "sort":any
    "subtitle":any
    "price":any
    "shop_id":any
    section_id:any
    course_id:'',
    content:''
}

export const courseTypes = [{id: 1, name:'视频'},{id: 2, name:'音频'},{id: 3, name:'书籍'}]