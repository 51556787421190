import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ab7d06e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "mycharts",
  ref: "myRef",
  class: "chart-box",
  style: {"width":"100%","height":"650px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, {
      title: _ctx.访问分析 
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, null, 512),
        _createVNode(_component_a_pagination, {
          current: _ctx.searches.page,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searches.page) = $event)),
          total: _ctx.total,
          "show-less-items": "",
          onChange: _ctx.change,
          style: {"float":"right"}
        }, null, 8, ["current", "total", "onChange"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}