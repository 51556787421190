import {baseRequestClient} from "@/request/request_client";
import {IAdminInfo, ILoginData, ILoginResult, IUserInfo, IVerificationConfig} from "@/models/user_models";
import StorageUtil from "@/utils/storage_util";
import router from "@/router";
import {IArticleCategory} from "@/models/article_model";
import {AuthMenu, IAdminRole, NetMenu} from "@/models/menu_model";
import {IListResult} from "@/models/base_model";

const requestClient = baseRequestClient

// 管理员菜单，管理，权限
export default class ApiAdmin {

    // 获取用户菜单 弃用
    static getMenus = async () => {
        return await requestClient.get<NetMenu[]>('/admin/admin/currentMenu')
    };

    // 获取所有菜单列表
    static getAllMenus = async (params: any) => {
        return await requestClient.get<IListResult<AuthMenu>>('/admin/auth/lists', params)
    };

    static getAllMenus2 = async (params: any) => {
        return await requestClient.get<IListResult<AuthMenu>>('/admin/shop.Auth/lists', params)
    };
    // 增加菜单
    static addMenu = async (data: AuthMenu) => {
        return await requestClient.post('/admin/auth/add', data)
    };

    // 增加菜单
    static AuthStatus = async (data: AuthMenu) => {
        return await requestClient.post('/admin/shop.Auth/status', data)
    };

    // 修改菜单
    static updateMenu = async (data: AuthMenu) => {
        return await requestClient.post('/admin/auth/edit', data)
    };

    static addMenu2 = async (data: AuthMenu) => {
        return await requestClient.post('/admin/shop.Auth/add', data)
    };

    // 修改菜单
    static updateMenu2 = async (data: AuthMenu) => {
        return await requestClient.post('/admin/shop.Auth/edit', data)
    };

    // 删除菜单
    static deleteMenu = async (ids: number[]) => {
        return await requestClient.post('/admin/auth/del', {ids})
    };
    static deleteMenu2 = async (ids: number[]) => {
        return await requestClient.post('/admin/shop.Auth/del', {ids})
    };

    // 获取管理员列表
    static getAdminList = async (params: any) => {
        return await requestClient.get<IListResult<IAdminInfo>>('/admin/admin/lists', params)
    };

    // 编辑管理员
    static updateAdminInfo = async (data: IAdminInfo) => {
        return await requestClient.post('/admin/admin/edit', data)
    };

    // 新增管理员
    static addAdmin = async (data: IAdminInfo) => {
        return await requestClient.post('/admin/admin/add', data)
    };

    // 获取角色列表
    static getRoleList = async (params: any) => {
        return await requestClient.get<IListResult<IAdminRole>>('/admin/role/lists', params)
    }
    static getRoleList2 = async (params: any) => {
        return await requestClient.get<IListResult<IAdminRole>>('/shop/Role/lists', params)
    }

    // 添加角色
    static addRole = async (data: IAdminRole) => {
        return await requestClient.post('/admin/role/add', data)
    };

    // 修改角色
    static updateRole = async (data: IAdminRole) => {
        return await requestClient.post('/admin/role/edit', data)
    };

    static initializationMenu = async (params: any) => {
        return await requestClient.get('/admin/admin/initializationMenu', params)
    }

}

