
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref, computed} from 'vue';
    import {appTitle} from "@/utils/config";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import apiMember from "@/request/apis/api_member";
    import PageUtil from "@/utils/page_util";
    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        xData: any,
        yData: any,
        echarts_add:any,
        echarts_count:any,
        searches:any,
        echarts_sale:any,
        total:any,
        timeline:any
    }
    export default defineComponent({
        name: 'Home',
        components: {UserOutlined},
        setup() {
            let state = reactive({
                appId: appId,
                xData: [] as any [],
                echarts_add: [] as any [],
                echarts_count: [] as any [],
                echarts_sale: [] as any [],
                total: [] as any [],
                timeline: [] as any [],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
            })

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                myChart.setOption({
                    title: {
                        text: '商家分析',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        splitLine: {
                            show: true
                        },
                        axisTick: {
                            "show": false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '70',
                        top: '15%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "关注量",
                            data: state.echarts_add,
                            type: 'line',
                            smooth:true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00FFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00FFFF' //改变折线颜色
                                    }
                                }
                            },
                        },
                        {
                            name: "浏览量",
                            data: state.echarts_count,
                            type: 'line',
                            smooth:true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00BFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00BFFF' //改变折线颜色
                                    }
                                }
                            },

                        },
                        {
                            name: "销售额",
                            data: state.echarts_sale,
                            type: 'line',
                            smooth:true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00BFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00BFFF' //改变折线颜色
                                    }
                                }
                            },

                        }
                    ]
                });


            };
            const getdata = async () => {
                state.echarts_add = []
                state.echarts_add=[]
                state.echarts_count=[]
                state.echarts_sale=[]
                state.xData=[]
                const params = {...state.searches}
                const res = await apiMember.StatisticsShop(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    const echarts = data.lists
                    state.total = data.count
                    echarts.forEach((item,index)=>{
                        state.xData.push(item.name)
                        state.echarts_add.push(item.follow_num)
                        state.echarts_count.push(item.visited_num)
                        state.echarts_sale.push(item.sales_price)
                    })
                    initT()
                }
            }

            const change = (page) => {
                state.searches.page = page
                getdata()
            }
            onMounted(() => {
                getdata()
            })

            return {
                ...toRefs(state),
                appTitle,
                change,
                pagination,
                myRef,

            }
        }
    });
