
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiArticle from "@/request/apis/api_article";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import ApiSsy from "@/request/apis/api_ssy";
import PageUtil from "@/utils/page_util";

interface IState {
  list: IArticleItem[],
  total: number;
  loading: boolean;
  category: IArticleCategory[]
  subCategory: IArticleCategory[]
  searches: any
  editItem?: IArticleItem
  xuanxiangList:any,
   templateList:any,
          articleVisable:any,
       template_id:any,
       article_id:any,
       option_ids:any,
}
// 表格
let columns: any = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '封面图',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '文章标题',
    dataIndex: 'title',
    key: 'title',
    width: 300,
  },
  // {
  //   title: '文章描述',
  //   dataIndex: 'intro',
  //   key: 'intro',
  // },
  {
    title: '所属分类',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '点赞',
    dataIndex: 'likes',
    key: 'likes',
    align: 'right',
  },
  {
    title: '审核',
    dataIndex: 'is_show',
    key: 'is_show',
    align: 'right',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'right',
    width: 150
  },
  {
    title: '是否是项目',
    dataIndex: 'if_project',
    key: 'if_project',
    align: 'right',
    width: 150
  },
  {
    title: '地区',
    dataIndex: 'location_province',
    key: 'location_province',
    align: 'right',
  },
  {
    title: '售价',
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    width: 90
  },
  {
    title: '市场价',
    dataIndex: 'market_price',
    key: 'market_price',
    align: 'right',
    width: 90
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right',

  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    width: 300
  },
];

export default defineComponent({
  name: 'ArticleList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
              xuanxiangList:[],
        templateList:[],
        article_id:'',
      category: [],
      subCategory: [],
      editItem: undefined,
       articleVisable:false,
       template_id:undefined,
       option_ids:[],
        searches: {
        keywords: '',
        pid: '',
        cid: undefined,
        c_id: '',
        is_recommend: '',
        type: '',
        is_show: '',
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
       
      }
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      delete params.total
      delete params.pid
      state.loading = true
      const res = await ApiArticle.getArticleList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.total || 0
        state.list = res.data?.data || []
      }
    }

    // 删除文章
    const onDel = async (item: IArticleItem) => {
      const res = await ApiArticle.delArticle(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 审核文章
    const onAudit = async (item: IArticleItem, status: number) => {
      const res = await ApiArticle.auditArticle(item.id, status)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    const onRecommend = async (item: IArticleItem) => {
      const res = await ApiArticle.recommendArticle(item.id, Number(!Boolean(item.is_recommend)))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 排序
    const onSort = async (e: any) => {
      if (!state.editItem) return
      const res = await ApiArticle.sortArticle(state.editItem.id, e)
      if (res) {
        state.editItem = undefined
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 设置排序item
    const setSortEditItem = (item: IArticleItem) => {
      if (state.editItem && item.id === state.editItem.id) {
        state.editItem = undefined
      } else {
        state.editItem = item
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticleItem) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/article/set', query: params}).then()
    }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 获取分类
    const getCategory = async () => {
      const res = await ApiArticle.getArticleCategory()
      if (res && res.error_code === 0) {
        state.category = res.data || []
        if (state.searches.pid) {
          state.subCategory = state.category.find(e => e.id === state.searches.pid)?.children || []
        }
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      console.log(key, value, e)
      if (key === 'keywords') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      } else {
        state.searches.keywords = ''
        if (key === 'pid') {
          state.searches.cid = value
          state.searches.c_id = ''
          if (value) {
            state.subCategory = state.category.find(item => item.id === value)?.children || []
          } else {
            state.subCategory = []
          }
        }
        if (key === 'c_id') {
          if (value) {
            state.searches.cid = value
          } else {
            state.searches.cid = state.searches.pid
          }
        }
      }
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      state.subCategory = []
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res, c_id: res.c_id || undefined}
      }
    }

    const setForm = (record) => {
      state.article_id = record.id
      state.template_id = undefined,
      state.option_ids = []
      state.articleVisable = true
      getTemplate()
      getoptionList()
    }

                const getTemplate = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.templateIndex(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.templateList = data.list
                }
            }


            const getoptionList = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.optionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.xuanxiangList = data.list
                }
            }
            const handleOkForm = async() => {
               const params = {article_id:state.article_id,template_id:state.template_id,option_ids:state.option_ids}
                const res = await ApiSsy.articleForm(params)
                if (res && res.error_code == 0) {
                    state.articleVisable = false
                    getList()
                    message.success(res.msg)
                }
            }

    onMounted(() => {
      initSearch()
      getCategory()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      handleOkForm,
      setForm,
      goSet,
      onDel,
      onRecommend,
      onAudit,
      goDetail,
      pagination,
      getTemplate,
      getoptionList,
      handleTableChange,
      onSearch,
      resetSearch,
      articleTypes,
      onSort,
      setSortEditItem,
    }
  }
})
