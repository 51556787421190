import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import StorageUtil from "@/utils/storage_util"
import Layout from "../layouts/index.vue"
import Login from "../views/Login.vue"
import Home from "../views/Home.vue"
import associationHome from "../views/associationHome.vue"
import basicCommpany from "../basic/commpany.vue"
import basicSkill from "../basic/skill.vue"
import salaryRange from "../basic/salaryRange.vue"
import industry from "../basic/industry.vue"
import position from "../basic/position.vue"
import positionAdd from "../basic/positionAdd.vue"
import certificate from "../basic/certificate.vue"
import typeWorkList from "../basic/typeWorkList.vue"
import schoolManage from "../basic/schoolManage.vue"
import recommend from "../basic/recommend.vue"
import keyword from "../basic/keyword.vue"
import PostTop from "../views/system/Post_top.vue"
import ResumeRefresh from "../views/system/Resume_refresh.vue"
import BottomNavigation from "../views/banner/Bottom_navigation.vue"
import DistributionSurvey from "../views/distribution/survey.vue"
import DistributionOrder from "../views/distribution/order.vue"
import DistributionGoods from "../views/distribution/goods.vue"
// 商协党建
import About from "../views/About.vue"
import ArticleList from "../views/article/article-list.vue"
import ArticleCategory from "../views/article/article-category.vue"
import ArticleSet from "../views/article/article-set.vue"
import addactivity from "../views/article/addactivity.vue"
import ArticleDetail from "../views/article/article-detail.vue"
import BannerPosition from "../views/banner/banner-position.vue"
import BannerList from "../views/banner/banner-list.vue"
import BannerSet from "../views/banner/banner-set.vue"
import DictionaryList from "../views/setting/dictionary-list.vue"
import DictionarySet from "../views/setting/dictionary-set.vue"
import MenuList from "../views/setting/menu-list.vue"
import MenuSet from "../views/setting/menu-set.vue"
import OrganizationList from "../views/party/organization-list.vue"
import RoleList from "../views/party/role-list.vue"
import MemberList from "../views/party/member-list.vue"
import MemberSet from "../views/party/member-set.vue"
import shopRole from "../views/setting/shopRole.vue"
import CourseList from "../views/course/course_list.vue"
import CourseTeacher from "../views/course/teacher.vue"
import CourseSet from "../views/course/course-set.vue"
import CourseSection from "../views/course/course_section.vue"
import newSet from "../views/course/newSet.vue"
import SectionSet from "../views/course/section-set.vue"
import SectionDetail from "../views/course/section-detail.vue"
import vipManage from "../views/vip/vipManage.vue"
import industryManage from "../views/vip/industryManage.vue"
import levelManage from "../views/vip/levelManage.vue"
// 物流
import logisticsOrderList from "../views/logistics_order/order_list.vue"
import logisticsOrderDetails from "../views/logistics_order/order_details.vue"

import {homeRoute} from "@/utils/config";


//商家后台

import homehome from "../views/business/index/homehome.vue"
import personnelIndex from "../views/personnel.vue"
import personnelWorkbench from "../views/personnel_workbench.vue"
import workbench from "../views/business/index/workbench.vue"
import goodLists from "../views/business/good/lists.vue"
import goodCategories from "../views/business/good/categories.vue"
import goodUnits from "../views/business/good/units.vue"
import afterSaleLists from "../views/business/order/after_sale_lists.vue"
import goodBrands from "../views/business/good/brands.vue"
import goodColumn from "../views/business/good/column.vue"
import goodComment from "../views/business/good/comment.vue"
import after_sale_lists from "../views/business/good/comment.vue"
// import after_sale_lists from "../views/business/good/comment.vue"
import orderList from "../views/business/order/order_list.vue"
import lists from "../views/business/merchant/lists.vue"
import agreement from "../views/business/merchant/agreement.vue"
import shopMenu from "../views/business/merchant/shopMenu.vue"
import permissionSet from "../views/business/merchant/permissionSet.vue"
import apply_categories from "../views/business/merchant/apply_categories.vue"
import apply from "../views/business/merchant/apply.vue"
import userLists from "../views/business/user/user_lists.vue"
import userTags from "../views/business/user/user_tags.vue"
import userLevel from "../views/business/user/user_level.vue"
import Applet from "../views/business/user/Applet.vue"
import vipWelfare from "../views/business/user/vipWelfare.vue"
import merchantCoupons from "../views/business/Marketing/merchant_coupons.vue"
import bargainGoods from "../views/business/Marketing/bargain_goods.vue"
import footprint from "../views/business/Marketing/footprint.vue"
import areaGoods from "../views/business/Marketing/areaGoods.vue"
import ActivityArea from "../views/business/Marketing/ActivityArea.vue"
import AssortedSetting from "../views/business/Marketing/Assorted_setting.vue"
import AssortedRecord from "../views/business/Marketing/Assorted_record.vue"
import AssortedShop from "../views/business/Marketing/Assorted_shop.vue"
import SeckillGoods from "../views/business/Marketing/SeckillGoods.vue"
import SeckillTime from "../views/business/Marketing/SeckillTime.vue"
import coupon from "../views/business/Marketing/coupon.vue"
import addCoupon from "../views/business/Marketing/addCoupon.vue"
import GoodCircle from "../views/business/Marketing/Good_circle.vue"
import bargainingRecord from "../views/business/Marketing/bargaining_record.vue"
import bargainingSettings from "../views/business/Marketing/bargaining_settings.vue"
import accessAnalysis from "../views/business/analysis/access_analysis.vue"
import commodityAnalysis from "../views/business/analysis/commodity_analysis.vue"
import transactionAnalysis from "../views/business/analysis/transaction_analysis.vue"
import memberAnalysis from "../views/business/analysis/member_analysis.vue"
import merchantAnalysis from "../views/business/analysis/merchant_analysis.vue"
import changePwd from "../views/business/my/password.vue"
import systemLog from "../views/business/my/system_log.vue"
import planningTasks from "../views/business/my/planning_tasks.vue"
import serviceOrder from "../views/system/service_order.vue"
import platformCustomer from "../views/business/setting/platform_customer_se.vue"
import transaction from "../views/business/setting/transaction_settings.vue"
import copyrightFiling from "../views/business/setting/copyright_filing.vue"
import sharingSettings from "../views/business/setting/sharing_settings.vue"
import withdrawal from "../views/business/setting/member_withdrawal.vue"
import smsManagement from "../views/business/setting/sms_management.vue"
import shopWithdrawal from "../views/business/setting/set_shop_withdrawal.vue"
import notificationSetting from "../views/business/setting/notification_setting.vue"
import policyAgreement from "../views/business/setting/policy_agreement.vue"
import appSettings from "../views/business/setting/app_settings.vue"
import popularSearch from "../views/business/setting/popular_search.vue"
import classification from "../views/business/help/help_classification.vue"
import helpManage from "../views/business/help/help_manage.vue"
import postManage from "../views/post/post_manage.vue"
import resumeManage from "../views/resume/resume_manage.vue"
import uploadResume from "../views/resume/uploadResume.vue"
import ProjectRecommendation from "../views/gxcc/basic/Project_recommendation.vue"

import shopManage from "../views/store/shop/shopManage.vue"
import shopSetting from "../views/store/shop/shopSetting.vue"
import shopIndex from "../views/store/index/shopIndex.vue"
import indexBrench from "../views/store/index/brench.vue"
import indexPersonal from "../views/store/index/personal.vue"
import concatUs from "../views/store/shop/concatUs.vue"
import quailityService from "../views/store/shop/quailityService.vue"
import commodityManage from "../views/store/commodity/commodityManage.vue"
import contractList from "../views/contract/contractList.vue"
import comapanyManage from "../views/agent/comapanyManage.vue"
import staffManage from "../views/agent/staffManage.vue"
import partManage2 from "../views/agent/partManage.vue"
import expressManage from "../views/express/expressManage.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/associationHome",
        name: "associationHome",
        component: associationHome,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/about",
        name: "About",
        component: About,
        meta: {
          title: "",
        },
      },
      {
        path: "/article/list",
        name: "ArticleList",
        component: ArticleList,
        meta: {
          title: "文章列表",
        },
      },
      {
        path: "/article/category",
        name: "ArticleCategory",
        component: ArticleCategory,
        meta: {
          title: "文章分类",
        },
      },
      {
        path: "/article/set",
        name: "ArticleSet",
        component: ArticleSet,
        meta: {
          title: "文章编辑",
        },
      },
      {
        path: "/article/addactivity",
        name: "addactivity",
        component: addactivity,
        meta: {
          title: "添加活动",
        },
      },
      {
        path: "/article/detail",
        name: "ArticleDetail",
        component: ArticleDetail,
        meta: {
          title: "文章详情",
        },
      },
      {
        path: "/banner/position",
        name: "BannerPosition",
        component: BannerPosition,
        meta: {
          title: "广告位",
        },
      },
      {
        path: "/banner/list",
        name: "BannerList",
        component: BannerList,
        meta: {
          title: "广告列表",
        },
      },
      {
        path: "/banner/set",
        name: "BannerSet",
        component: BannerSet,
        meta: {
          title: "设置广告",
        },
      },
      {
        path: "/dictionary/list",
        name: "DictionaryList",
        component: DictionaryList,
        meta: {
          title: "跳转配置列表",
        },
      },
      {
        path: "/dictionary/set",
        name: "DictionarySet",
        component: DictionarySet,
        meta: {
          title: "设置跳转",
        },
      },
      {
        path: "/menu/list",
        name: "MenuList",
        component: MenuList,
        meta: {
          title: "菜单管理",
        },
      },
      {
        path: "/menu/set",
        name: "MenuSet",
        component: MenuSet,
        meta: {
          title: "菜单配置",
        },
      },
      {
        path: "/party/organization",
        name: "OrganizationList",
        component: OrganizationList,
        meta: {
          title: "组织管理",
        },
      },
      {
        path: "/party/role",
        name: "RoleList",
        component: RoleList,
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "/shopRole",
        name: "shopRole",
        component: shopRole,
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "/party/member",
        name: "MemberList",
        component: MemberList,
        meta: {
          title: "人员管理",
        },
      },
      {
        path: "/party/member/set",
        name: "MemberSet",
        component: MemberSet,
        meta: {
          title: "人员设置",
        },
      },
      {
        path: "/party/member/set",
        name: "MemberSet",
        component: MemberSet,
        meta: {
          title: "设置角色",
        },
      },
      {
        path: "/course/list",
        name: "CourseList",
        component: CourseList,
        meta: {
          title: "课程列表",
        },
      },
      {
        path: "/course/teacher",
        name: "CourseTeacher",
        component: CourseTeacher,
        meta: {
          title: "课程列表",
        },
      },
      {
        path: "/course/set",
        name: "CourseSet",
        component: CourseSet,
        meta: {
          title: "课程设置",
        },
      },
      {
        path: "/course/newSet",
        name: "newSet",
        component: newSet,
        meta: {
          title: "课程设置",
        },
      },
      {
        path: "/course/section",
        name: "CourseSection",
        component: CourseSection,
        meta: {
          title: "课程章节",
        },
      },
      {
        path: "/course/section/set",
        name: "SectionSet",
        component: SectionSet,
        meta: {
          title: "课程章节",
        },
      },
      {
        path: "/course/section/detail",
        name: "SectionDetail",
        component: SectionDetail,
        meta: {
          title: "章节详情",
        },
      },
      {
        path: "/vip/vipManage",
        name: "vipManage",
        component: vipManage,
        meta: {
          title: "会员管理",
        },
      },
      {
        path: "/vip/industryManage",
        name: "industryManage",
        component: industryManage,
        meta: {
          title: "行业管理",
        },
      },
      {
        path: "/vip/levelManage",
        name: "levelManage",
        component: levelManage,
        meta: {
          title: "级别管理",
        },
      },
      {
        path: "/logistics_order/order_list",
        name: "logisticsOrderList",
        component: logisticsOrderList,
        meta: {
          title: "物流订单列表",
        },
      },
      {
        path: "/logistics_order/order_details",
        name: "logisticsOrderDetails",
        component: logisticsOrderDetails,
        meta: {
          title: "物流订单详情",
        },
      },
      {
        path: "/audit/real_name_list",
        name: "RealNameList",
        component: () => import("../views/audit/real_name_list.vue"),
        meta: {
          title: "实名认证审核",
        },
      },
      {
        path: "/audit/real_name_detail",
        name: "RealNameDetail",
        component: ()=>import("../views/audit/real_name_detail.vue"),
        meta: {
          title: "实名认证详情",
        },
      },
      {
        path: "/audit/driving_licence",
        name: "DrivingLicence",
        component: () => import("../views/audit/driving_licence_list.vue"),
        meta: {
          title: "驾驶证审核",
        },
      },
      {
        path: "/audit/driving_licence_detail",
        name: "DrivingLicenceDetail",
        component: ()=>import("../views/audit/driving_licence_detail.vue"),
        meta: {
          title: "驾驶证认证详情",
        },
      },
      {
        path: "/audit/licence",
        name: "Licence",
        component: ()=>import("../views/audit/licence_list.vue"),
        meta: {
          title: "行驶证/物流运输证审核",
        },
      },
      {
        path: "/audit/licence_detail",
        name: "LicenceDetail",
        component: ()=>import("../views/audit/licence_detail.vue"),
        meta: {
          title: "行驶证/物流运输证认证详情",
        },
      },
      {
        path: "/member/driver",
        name: "LogisticsDriver",
        component: ()=>import("../views/logistics/member/driver.vue"),
        meta: {
          title: "司机管理",
        },
      },
      {
        path: "/member/owner",
        name: "LogisticsOwner",
        component: ()=>import("../views/logistics/member/owner.vue"),
        meta: {
          title: "司机管理",
        },
      },
      {
        path: "/cash/out_list",
        name: "CashOutList",
        component: ()=>import("../views/logistics/cash/cash_out_list.vue"),
        meta: {
          title: "司机提现管理",
        },
      },
      {
        path: "/cash/user_out_list",
        name: "UserCashOutList",
        component: ()=>import("../views/logistics/cash/user_cash_out_list.vue"),
        meta: {
          title: "用户提现管理",
        },
      },
      {
        path: "/balance/record",
        name: "BalanceRecordList",
        component: ()=>import("../views/logistics/cash/balance_record_list.vue"),
        meta: {
          title: "余额变动记录",
        },
      },
      {
        path: "/complaint/list",
        name: "ComplaintList",
        component: ()=>import("../views/logistics/complaint/complaint_list.vue"),
        meta: {
          title: "提现管理",
        },
      },
      {
        path: "/setting/admin-list",
        name: "adminList",
        component: ()=>import("../views/setting/admin-list.vue"),
        meta: {
          title: "管理员列表",
        },
      },
      {
        path: "/setting/admin-set",
        name: "adminSet",
        component: ()=> import("../views/setting/admin-set.vue")
        ,
        meta: {
          title: "新增管理员",
        },
      },
      {
        path: "/setting/role-list",
        name: "adminRoleList",
        component: ()=>import("../views/setting/role-list.vue"),
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "/setting/role-set",
        name: "adminRoleSet",
        component: ()=>import("../views/setting/role-set.vue"),
        meta: {
          title: "新增角色",
        },
      },
      {
        path: "/setting/admin-permission",
        name: "adminPermission",
        component: ()=> import("../views/setting/admin-permission.vue"),
        meta: {
          title: "权限管理",
        },
      },
      {
        path: "/setting/admin-permission-set",
        name: "adminPermissionSet",
        component: ()=> import("../views/setting/admin-permission-set.vue"),
        meta: {
          title: "权限管理",
        },
      },
      {
        path: "/complaint/detail",
        name: "ComplaintDetail",
        component: ()=> import("../views/logistics/complaint/complaint_detail.vue"),
        meta: {
          title: "投诉订单管理",
        },
      },
      {
        path: "/comment/list",
        name: "CommentList",
        component: ()=> import("../views/logistics/comment/comment_list.vue"),
        meta: {
          title: "评论管理",
        },
      },
      {
        path: "/comment/detail",
        name: "CommentDetail",
        component: ()=> import("../views/logistics/comment/comment_detail.vue"),
        meta: {
          title: "评论详情",
        },
      },
      {
        path: "/parameter/car_type",
        name: "CarType",
        component: ()=> import("../views/logistics/parameter/car_type.vue"),
        meta: {
          title: "车辆类型管理",
        },
      },
      {
        path: "/parameter/logistics_type",
        name: "LogisticsType",
        component: ()=> import("../views/logistics/parameter/logistics_type.vue"),
        meta: {
          title: "物流类型管理",
        },
      },
      {
        path: "/parameter/material",
        name: "MaterialType",
        component: ()=> import("../views/logistics/parameter/material_type.vue"),
        meta: {
          title: "物流类型管理",
        },
      },
      {
        path: "/feedback/list",
        name: "FeedbackList",
        component: ()=> import("../views/logistics/feedback/list.vue"),
        meta: {
          title: "意见反馈管理",
        },
      },
      {
        path: "/feedback/detail",
        name: "FeedbackDetail",
        component: ()=> import("../views/logistics/feedback/detail.vue"),
        meta: {
          title: "意见反馈管理",
        },
      },
      {
        path: "/articlePlat/list",
        name: "ArticlePlatList",
        component: ()=> import("../views/logistics/article/article-plat-list.vue"),
        meta: {
          title: "帮助中心平台",
        },
      },
      {
        path: "/articlePlat/detail",
        name: "ArticlePlatDetail",
        component: () => import("../views/logistics/article/article-plat-set.vue"),
        meta: {
          title: "帮助中心平台管理",
        }
      },
      {
        path: "/article/cate",
        name: "ArticleCateList",
        component: () => import("../views/logistics/article/article-cate-list.vue"),
        meta: {
          title: "帮助中心分类管理",
        }
      },
      {
        path: "/articleCate/set",
        name: "ArticleCateSet",
        component: () => import("../views/logistics/article/article-cate-set.vue"),
        meta: {
          title: "分类修改",
        }
      },
      {
        path: "/articles/list",
        name: "ArticleHelpList",
        component: () => import("../views/logistics/article/article-list.vue"),
        meta: {
          title: "帮助中心文章管理",
        }
      },
      {
        path: "/articles/set",
        name: "ArticleHelpSet",
        component: () => import("../views/logistics/article/article-set.vue"),
        meta: {
          title: "帮助中心文章",
        }
      },
      {
        path: "/car/list",
        name: "CarList",
        component: () => import("../views/logistics/car/car-list.vue"),
        meta: {
          title: "车辆列表",
        }
      },
      {
        path: "/privacy/detail",
        name: "Privacy",
        component: ()=> import("../views/logistics/protocol/detail.vue"),
        meta: {
          title: "协议配置",
        },
      },
      {
        path: "/privacy/user_agreement",
        name: "UserAgreement",
        component: ()=> import("../views/logistics/protocol/user_agreement.vue"),
        meta: {
          title: "用户协议",
        },
      },
      {
        path: "/privacy/welfare",
        name: "Welfare",
        component: ()=> import("../views/logistics/protocol/welfare.vue"),
        meta: {
          title: "司机福利",
        },
      },
      {
        path: "/plat/service",
        name: "PlatService",
        component: ()=> import("../views/logistics/service/service.vue"),
        meta: {
          title: "平台客服",
        },
      },
      {
        path: "/web/config",
        name: "WebConfig",
        component: ()=> import("../views/logistics/web/config.vue"),
        meta: {
          title: "网站配置",
        },
      },
      {
        path: "/user_message/list",
        name: "UserMessageList",
        component: ()=> import("../views/logistics/message/user_message_list.vue"),
        meta: {
          title: "消息管理",
        },
      },
      {
        path: "/driver_message/list",
        name: "DriverMessageList",
        component: ()=> import("../views/logistics/message/driver_message_list.vue"),
        meta: {
          title: "消息管理",
        }
      },
      {
        path: "/feedback/list",
        name: "feedList",
        component: ()=> import("../views/feedback/list.vue"),
        meta: {
          title: "车辆列表",
        },
      },
      {
        path: "/basic/commpany",
        name: "basicCommpany",
        component: ()=> import("../views/basic/commpany.vue"),
        meta: {
          title: "车辆列表",
        },
      },
      {
        path: "/basic/skill",
        name: "basicSkill",
        component: ()=> import("../views/basic/skill.vue"),
        meta: {
          title: "技能管理",
        },
      },
      {
        path: "/basic/salaryRange",
        name: "salaryRange",
        component: ()=> import("../views/basic/salaryRange.vue"),
        meta: {
          title: "薪资范围",
        },
      },
      {
        path: "/basic/industry",
        name: "industry",
        component: ()=> import("../views/basic/industry.vue"),
        meta: {
          title: "行业设置",
        },
      },
      {
        path: "/basic/position",
        name: "position",
        component: ()=> import("../views/basic/position.vue"),
        meta: {
          title: "职位管理",
        },
      },
      {
        path: "/basic/certificate",
        name: "certificate",
        component: ()=> import("../views/basic/certificate.vue"),
        meta: {
          title: "证书管理",
        },
      },
      {
        path: "/basic/typeWorkList",
        name: "typeWorkList",
        component: ()=> import("../views/basic/typeWorkList.vue"),
        meta: {
          title: "工种管理",
        },
      },
      {
        path: "/basic/positionAdd",
        name: "positionAdd",
        component: ()=> import("../views/basic/positionAdd.vue"),
        meta: {
          title: "职位管理",
        },
      },
      {
        path: "/basic/schoolManage",
        name: "schoolManage",
        component: ()=> import("../views/basic/schoolManage.vue"),
        meta: {
          title: "学校管理",
        },
      },
      {
        path: "/basic/recommend",
        name: "recommend",
        component: ()=> import("../views/basic/recommend.vue"),
        meta: {
          title: "学校管理",
        },
      },
      {
        path: "/basic/keyword",
        name: "ketword",
        component: ()=> import("../views/basic/keyword.vue"),
        meta: {
          title: "学校管理",
        },
      },
      {
        path: "/index/dashboard",
        name: "homehome",
        component: ()=> import("../views/business/index/homehome.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/index/workbench",
        name: "workbench",
        component: ()=> import("../views/business/index/workbench.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/goods/lists",
        name: "goodLists",
        component: ()=> import("../views/business/goods/lists.vue"),
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "/distributionManage",
        name: "distributionManage",
        component: ()=> import("../views/business/goods/distributionManage.vue"),
        meta: {
          title: "分销管理",
        },
      },
      {
        path: "/goods/categories",
        name: "goodCategories",
        component: ()=> import("../views/business/goods/categories.vue"),
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "/goods/units",
        name: "goodUnits",
        component: ()=> import("../views/business/goods/units.vue"),
        meta: {
          title: "商品单位",
        },
      },
      {
        path: "/goods/brands",
        name: "goodbrands",
        component: ()=> import("../views/business/goods/brands.vue"),
        meta: {
          title: "商品品牌",
        },
      },
      {
        path: "/goods/column",
        name: "goodColumn",
        component: ()=> import("../views/business/goods/column.vue"),
        meta: {
          title: "商品栏目",
        },
      },
      {
        path: "/goods/comment",
        name: "goodComment",
        component: ()=> import("../views/business/goods/comment.vue"),
        meta: {
          title: "商品评价",
        },
      },
      {
        path: "/order/after_sale_lists",
        name: "after_sale_lists",
        component: ()=> import("../views/business/order/comment.vue"),
        meta: {
          title: "商家售后",
        },
      },
      {
        path: "/afterSaleLists",
        name: "afterSaleLists",
        component: afterSaleLists,
        meta: {
          title: "商家售后",
        },
      },
      {
        path: "/order/lists",
        name: "orderList",
        component: ()=> import("../views/business/order/order_list.vue"),
        meta: {
          title: "订单列表",
        },
      },

      {
        path: "/merchant/lists",
        name: "lists",
        component:lists,
        meta: {
          title: "商家管理"
        },
      },
      {
        path: "/merchant/agreement",
        name: "agreement",
        component: agreement,
        meta: {
          title: "入驻协议",
        },
      },
      {
        path: "/shopMenu",
        name: "shopMenu",
        component: shopMenu,
        meta: {
          title: "商家菜单",
        },
      },
      {
        path: "/merchant/permissionSet",
        name: "permissionSet",
        component: permissionSet,
        meta: {
          title: "菜单设置",
        },
      },
      {
        path: "/merchant/apply_categories",
        name: "apply_categories",
        component: apply_categories,
        meta: {
          title: "主营类目",
        },
      },
      {
        path: "/merchant/apply",
        name: "apply",
        component:apply,
        meta: {
          title: "商家入驻",
        },
      },
      {
        path: "/member/list",
        name: "userLists",
        component:userLists,
        meta: {
          title: "会员管理",
        },
      },
      {
        path: "/user/tags",
        name: "userTags",
        component:userTags,
        meta: {
          title: "标签管理",
        },
      },
      {
        path: "/user/level",
        name: "userLevel",
        component:userLevel,
        meta: {
          title: "会员等级",
        },
      },
      {
        path: "/Applet",
        name: "Applet",
        component:Applet,
        meta: {
          title: "小程序账号",
        },
      },
      {
        path: "/vipWelfare",
        name: "vipWelfare",
        component:vipWelfare,
        meta: {
          title: "会员福利",
        },
      },
      {
        path: "/merchant_coupons",
        name: "merchantCoupons",
        component:merchantCoupons,
        meta: {
          title: "商家优惠券",
        },
      },
      {
        path: "/addCoupon",
        name: "addCoupon",
        component:addCoupon,
        meta: {
          title: "商家优惠券",
        },
      },
      {
        path: "/bargain_goods",
        name: "bargainGoods",
        component:bargainGoods,
        meta: {
          title: "砍价活动",
        },
      },
      {
        path: "/footprint",
        name: "footprint",
        component:footprint,
        meta: {
          title: "足迹气泡",
        },
      },
      {
        path: "/areaGoods",
        name: "areaGoods",
        component:areaGoods,
        meta: {
          title: "活动专区商品",
        },
      },
      {
        path: "/ActivityArea",
        name: "ActivityArea",
        component:ActivityArea,
        meta: {
          title: "活动专区",
        },
      },
      {
        path: "/Assorted_setting",
        name: "AssortedSetting",
        component:AssortedSetting,
        meta: {
          title: "拼团设置",
        },
      },
      {
        path: "/Assorted_record",
        name: "AssortedRecord",
        component:AssortedRecord,
        meta: {
          title: "拼团记录",
        },
      },
      {
        path: "/Assorted_shop",
        name: "AssortedShop",
        component:AssortedShop,
        meta: {
          title: "拼团商品",
        },
      },
      {
        path: "/SeckillTime",
        name: "SeckillTime",
        component:SeckillTime,
        meta: {
          title: "秒杀时段",
        },
      },
      {
        path: "/SeckillGoods",
        name: "SeckillGoods",
        component:SeckillGoods,
        meta: {
          title: "秒杀商品",
        },
      },
      {
        path: "/coupon",
        name: "coupon",
        component:coupon,
        meta: {
          title: "砍价活动",
        },
      },
      {
        path: "/bargaining_record",
        name: "bargainingRecord",
        component:bargainingRecord,
        meta: {
          title: "砍价活动",
        },
      },
      {
        path: "/Good_circle",
        name: "GoodCircle",
        component:GoodCircle,
        meta: {
          title: "好物圈推荐",
        },
      },
      {
        path: "/bargaining_settings",
        name: "bargainingSettings",
        component:bargainingSettings,
        meta: {
          title: "砍价设置",
        },
      },
      {
        path: "/access_analysis",
        name: "accessAnalysis",
        component:accessAnalysis,
        meta: {
          title: "访问分析",
        },
      },
      {
        path: "/commodity_analysis",
        name: "commodityAnalysis",
        component:commodityAnalysis,
        meta: {
          title: "商品分析",
        },
      },
      {
        path: "/transaction_analysis",
        name: "transactionAnalysis",
        component:transactionAnalysis,
        meta: {
          title: "交易分析",
        },
      },
      {
        path: "/member_analysis",
        name: "memberAnalysis",
        component:memberAnalysis,
        meta: {
          title: "会员分析",
        },
      },
      {
        path: "/merchant_analysis",
        name: "merchantAnalysis",
        component:merchantAnalysis,
        meta: {
          title: "商家分析",
        },
      },
      {
        path: "/my/password",
        name: "changePwd",
        component:changePwd,
        meta: {
          title: "修改密码",
        },
      },
      {
        path: "/platform_customer_se",
        name: "platformCustomer",
        component:platformCustomer,
        meta: {
          title: "平台客服",
        },
      },
      {
        path: "/transaction_settings",
        name: "transaction",
        component:transaction,
        meta: {
          title: "交易信息",
        },
      },
      {
        path: "/copyright_filing",
        name: "copyrightFiling",
        component:copyrightFiling,
        meta: {
          title: "版权备案",
        },
      },
      {
        path: "/sharing_settings",
        name: "sharingSettings",
        component:sharingSettings,
        meta: {
          title: "分享设置",
        },
      },
      {
        path: "/member_withdrawal",
        name: "withdrawal",
        component:withdrawal,
        meta: {
          title: "会员提现",
        },
      },
      {
        path: "/sms_management",
        name: "smsManagement",
        component:smsManagement,
        meta: {
          title: "短信管理",
        },
      },
      {
        path: "/set_shop_withdrawal",
        name: "shopWithdrawal",
        component:shopWithdrawal,
        meta: {
          title: "商家提现",
        },
      },
      {
        path: "/notification_setting",
        name: "notificationSetting",
        component:notificationSetting,
        meta: {
          title: "通知管理",
        },
      },
      {
        path: "/policy_agreement",
        name: "policyAgreement",
        component:policyAgreement,
        meta: {
          title: "政策协议",
        },
      },
      {
        path: "/app_settings",
        name: "appSettings",
        component:appSettings,
        meta: {
          title: "APP配置",
        },
      },
      {
        path: "/popular_search",
        name: "popularSearch",
        component:popularSearch,
        meta: {
          title: "热门搜索",
        },
      },
      {
        path: "/help_classification",
        name: "classification",
        component:classification,
        meta: {
          title: "热门搜索",
        },
      },
      {
        path: "/help_manage",
        name: "helpManage",
        component:helpManage,
        meta: {
          title: "帮助管理",
        },
      },
      {
        path: "/system_log",
        name: "systemLog",
        component:systemLog,
        meta: {
          title: "帮助分类",
        },
      },
      {
        path: "/planning_tasks",
        name: "planningTasks",
        component:planningTasks,
        meta: {
          title: "计划任务",
        },
      },
      {
        path: "/service_order",
        name: "serviceOrder",
        component:serviceOrder,
        meta: {
          title: "服务订单",
        },
      },
      {
        path: "/post/post_manage",
        name: "postManage",
        component:postManage,
        meta: {
          title: "岗位管理",
        },
      },
      {
        path: "/resume/resume_manage",
        name: "resumeManage",
        component:resumeManage,
        meta: {
          title: "简历管理",
        },
      },
      {
        path: "/uploadResume",
        name: "uploadResume",
        component:uploadResume,
        meta: {
          title: "上传简历",
        },
      },
      {
        path: "/personnelIndex",
        name: "personnelIndex",
        component:personnelIndex,
        meta: {
          title: "简历管理",
        },
      },
      {
        path: "/personnel_workbench",
        name: "personnelWorkbench",
        component:personnelWorkbench,
        meta: {
          title: "简历管理",
        },
      },
      {
        path: "/Post_top",
        name: "PostTop",
        component:PostTop,
        meta: {
          title: "岗位置顶日志",
        },
      },
      {
        path: "/Resume_refresh",
        name: "ResumeRefresh",
        component:ResumeRefresh,
        meta: {
          title: "简历刷新日志",
        },
      },
      {
        path: "/Bottom_navigation",
        name: "BottomNavigation",
        component:BottomNavigation,
        meta: {
          title: "底部导航",
        },
      },
      {
        path: "/Project_recommendation",
        name: "ProjectRecommendation",
        component:ProjectRecommendation,
        meta: {
          title: "项目推荐",
        },
      },

      {
        path: "/shop/shopManage",
        name: "shopManage",
        component:shopManage,
        meta: {
          title: "店铺信息",
        },
      },
      {
        path: "/shop/shopSetting",
        name: "shopSetting",
        component:shopSetting,
        meta: {
          title: "店铺设置",
        },
      },
      {
        path: "/index/shopIndex",
        name: "shopIndex",
        component:shopIndex,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/index/brench",
        name: "indexBrench",
        component:indexBrench,
        meta: {
          title: "工作台",
        },
      },
      {
        path: "/index/personal",
        name: "indexPersonal",
        component:indexPersonal,
        meta: {
          title: "个人设置",
        },
      },
      {
        path: "/shop/concatUs",
        name: "concatUs",
        component:concatUs,
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/shop/quailityService",
        name: "quailityService",
        component:quailityService,
        meta: {
          title: "质保服务",
        },
      },
      {
        path: "/commodity/commodityManage",
        name: "commodityManage",
        component:commodityManage,
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "/appVersion/list",
        name: "AppVersionList",
        component: ()=> import("../views/app_version/list.vue"),
        meta: {
          title: "app版本管理",
        },
      },
      {
        path: "/contractList",
        name: "contractList",
        component:contractList,
        meta: {
          title: "签约管理",
        },
      },
      {
        path: "/comapanyManage",
        name: "comapanyManage",
        component:comapanyManage,
        meta: {
          title: "公司管理",
        },
      },
      {
        path: "/partManage",
        name: "partManage2",
        component:partManage2,
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "/expressManage",
        name: "expressManage",
        component:expressManage,
        meta: {
          title: "快递管理",
        },
      },
      {
        path: "/staffManage",
        name: "staffManage",
        component:staffManage,
        meta: {
          title: "签约管理",
        },
      },
      {
        path: "/project/list",
        name: "ProjectList",
        component:() => import('../views/project/project-list.vue'),
        meta: {
          title: "项目管理",
        },
      },
      {
        path: "/project/business/list",
        name: "ProjectBusinessList",
        component:() => import('../views/project/business-list.vue'),
        meta: {
          title: "共享商圈",
        },
      },
      {
        path: "/project/logistics/list",
        name: "ProjectLogisticsList",
        component:() => import('../views/project/logistics-list.vue'),
        meta: {
          title: "共仓物流",
        },
      },
      {
        path: "/project/space/list",
        name: "ProjectSpaceList",
        component:() => import('../views/project/space-list.vue'),
        meta: {
          title: "共享空间",
        },
      },
      {
        path: "/project/supplier/list",
        name: "ProjectSupplierList",
        component:() => import('../views/project/supplier-list.vue'),
        meta: {
          title: "供应商",
        },
      },
      {
        path: "/notice",
        name: "notice",
        component:() => import('../views/basic/notice.vue'),
        meta: {
          title: "公告提示",
        },
      },
      {
        path: "/examination/paper",
        name: "ExaminationPaper",
        component:() => import('../views/examination/paper.vue'),
        meta: {
          title: "试卷列表",
        },
      },
      {
        path: "/examination/questions/:id",
        name: "ExaminationQuestions",
        component:() => import('../views/examination/questions.vue'),
        meta: {
          title: "试卷试题",
        },
      },
      {
        path: "/OptionsManage",
        name: "OptionsManage",
        component:() => import('../views/activity/OptionsManage.vue'),
        meta: {
          title: "选项管理",
        },
      },
      {
        path: "/templateManage",
        name: "templateManage",
        component:() => import('../views/activity/templateManage.vue'),
        meta: {
          title: "模板管理",
        },
      },
      {
        path: "/tagManage",
        name: "tagManage",
        component:() => import('../views/activity/tagManage.vue'),
        meta: {
          title: "标签管理",
        },
      },
      {
        path: "/activityManage",
        name: "activityManage",
        component:() => import('../views/activity/activityManage.vue'),
        meta: {
          title: "活动管理",
        },
      },
      {
        path: "/wantbuyList",
        name: "wantbuyList",
        component:() => import('../views/business/order/wantbuyList.vue'),
        meta: {
          title: "求购列表",
        },
      },
      {
        path: "/roomManage",
        name: "roomManage",
        component:() => import('../views/live/roomManage.vue'),
        meta: {
          title: "求购列表",
        },
      },
      {
        path: "/SignList",
        name: "SignList",
        component: ()=> import("../views/Sign/SignList.vue"),
        meta: {
          title: "签约列表",
        },
      },
      {
        path: "/signAdd",
        name: "signAdd",
        component: ()=> import("../views/Sign/signAdd.vue"),
        meta: {
          title: "新建银联签约账户",
        },
      },
      {
        path: "/shopCancellation",
        name: "shopCancellation",
        component: ()=> import("../views/business/merchant/shopCancellation.vue"),
        meta: {
          title: "商家注销",
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // to:Route:路由对象，即将要进入的对象
  // from:Route:当前导航正要离开的路由
  // next:Function:
  // if (to.meta) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // document.title = to.meta.title
  // }
  if (to.path === "/login") {
    StorageUtil.clear()
    next()
  } else if (to.path === "/") {
    next(homeRoute); // article/category
  } else {
    const isLogin = StorageUtil.isLogin()
    if (isLogin) {
      next()
    } else {
      next("/login")
    }
  }
})

export default router
