
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any,
        goolList:any,
        couponList:any,
        page:any,
        protocolType:any,
        coutotal:any,
        goodtotal:any,
        page2:any,
        showtype:any,
        shop_name:any,
        oKtext:any,
        multipleSelection:any,
        name:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize,
                    coupon_name:'',
                    goods_name:''
                },
                shop_name:'',
                multipleSelection:[],
                total:0,
                title:'',
                visible:false,
                protocolType:'',
                formState:{
                    goods_id:'',
                    images:'',
                    copywriting:'',
                    belonging_app_id:'',
                    coupon_id:'',
                    sort:''
                },
                type:'',
                goolList:[],
                couponList:[],
                page:1,
                coutotal:0,
                goodtotal:0,
                page2:1,
                showtype:1,
                oKtext:"下一步",
                name:"",
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    key: 'goods_name',
                    align:'center'
                },
                {
                    title: '商品图片',
                    dataIndex: 'goods_image',
                    key: 'goods_image',
                    align:'center'
                },
                {
                    title: '宣传图',
                    dataIndex: 'images',
                    key: 'images',
                    align:'center'
                },
                {
                    title: '优惠券',
                    dataIndex: 'coupon_name',
                    key: 'coupon_name',
                    align:'center'
                },
                {
                    title: '文案',
                    dataIndex: 'copywriting',
                    key: 'copywriting',
                    align:'center'
                },
                {
                    title: '所属平台',
                    dataIndex: 'belonging_app_id',
                    key: 'belonging_app_id',
                    align:'center'
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                {
                    title: '创建时间',
                    key: 'create_time',
                    dataIndex: 'create_time',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250,
                    align:'center'
                },
            ];
            const columns2 = [
                {
                    title: '商品信息',
                    dataIndex: 'goods',
                    key: 'goods',
                    width:550
                },
                {
                    title: '商家信息',
                    dataIndex: 'shop',
                    key: 'shop',
                    width:300
                },
                {
                    title: '销售数据',
                    dataIndex: 'sale',
                    key: 'sale',
                    width: 300
                },
                {
                    title: '审核说明',
                    key: 'tags',
                    dataIndex: 'tags',
                    width: 400
                },
            ];
            const editorComponentRef = ref()
            const rules = {
                goods_id: [{required: true, message: "请选择商品"}],
                images: [{required: true, message: "请上传宣传图"}],
                copywriting: [{required: true, message: "请输入文案"}],
                coupon_id: [{required: false, message: "请选择专属优惠券"}],
                sort: [{required: false, message: "请输入排序"}],
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.page_size,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const pagination2 = computed(() => ({
                total: state.goodtotal,
                current: state.page2,
                pageSize: 10,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.goodsGoodsList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };
            const handleTableChange2 = (pages: any) => {
                state.searches.page_size = 10
                state.page2 = pages.current
                getGoods()
            };

            const getGoods  = async() =>{
                const params= {page_no:state.page2,page_size:10,type:1,goods_name:state.name}
                const res = await ApiUser.getGoodList(params)
                if(res && res.error_code == 0){
                    const data:any = res.data
                    state.goodtotal = data.count
                    state.goolList = data.lists
                }
            }


            const addApply = () =>{
                state.title = "新增好物"
                state.type = 1
                state.showtype = 1
                state.page2 = 1
                state.shop_name =""
                state.oKtext ="下一步"
                state.multipleSelection =[]
                state.formState={
                    goods_id:undefined,
                    images:'',
                    copywriting:'',
                    belonging_app_id:'',
                    coupon_id:undefined,
                    sort:''
                },
                    getGoods()

                    state.visible = true
            }
            const edit = (val) =>{
                state.multipleSelection = []
                state.visible = true
                state.title = "编辑好物圈商品"
                state.page2 =1
                state.page2 =1
                state.showtype = 1
                state.type = 2
                state.oKtext="下一步"
                state.formState={
                    id:val.id,
                    goods_id:val.goods_id,
                    images:val.images,
                    copywriting:val.copywriting,
                    belonging_app_id:val.belonging_app_id,
                    coupon_id:Number(val.coupon_id) == 0 ? undefined : Number(val.coupon_id),
                    sort:val.sort
                }
                state.shop_name = val.goods_name
                state.multipleSelection.push(val.goods_id)
                state.protocolType = Math.random();
                getGoods()
                getShopCouponLists()
            }
            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.images = data.uri
                    }
                }
            }
            const onSubmit = async () => {
                if(state.showtype == 1){
                    state.showtype = 2
                    state.oKtext = "确定"
                }else{
                    state.formState.copywriting = editorComponentRef.value.getHtmlText()
                    formRef.value.validate() .then(async() => {
                        const params = {...state.formState}
                        if (state.type == 1) {
                            const params = {...state.formState}
                            const res = await ApiMember.goodsGoodsAdd(params)
                            if(res.error_code == 0){
                                state.visible = false
                                message.success("添加成功")
                                getList()
                            }else{
                                message.error(res.msg)
                            }
                        } else {
                            const params = {...state.formState}
                            const res = await ApiMember.goodsGoodsEdit(params)
                            if(res.error_code == 0){
                                message.success("成功")
                                state.visible = false
                                getList()
                            }else{
                                message.error(res.msg)
                            }
                        }
                    })
                        .catch((error: any) => {
                            console.log("error", error)
                        })
                }

            }

            const onDel = async (item) => {
                const params = {id:item.id}
                const res = await apiMember.goodsGoodsDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onStatus = async (item,item2) => {
                const params = {id:item.id,status:item2}
                const res = await apiMember.goodsGoodsStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success("成功")
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                state.searches.coupon_name = ''
                state.searches.goods_name = ''
                getList()
            }


            const getShopCouponLists  = async() =>{
                const params= {page:state.page,limit:10,"app_id":state.formState.belonging_app_id}
                const res = await ApiMember.ShopCouponLists2(params)
                if(res && res.error_code == 0){
                    const data:any = res.data
                    state.coutotal = data.count
                    state.couponList = state.couponList.concat(data.list)
                }
            }

            const getAppid = (value,value2) =>{
                state.formState.belonging_app_id = value2.key
            }

            const handlePopupScroll = (e) => {
                const { target } = e
                const rmHeight = target.scrollHeight - target.scrollTop
                const clHeight = target.clientHeight
                if (rmHeight === 0 && clHeight === 0) {
                } else {
                    if (rmHeight < clHeight + 1) {
                        if(state.coutotal > state.couponList.length){
                            state.page = state.page+1
                            getShopCouponLists()
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }
            }

            const handlePopupScroll2 = (e) => {
                const { target } = e
                const rmHeight = target.scrollHeight - target.scrollTop
                const clHeight = target.clientHeight
                if (rmHeight === 0 && clHeight === 0) {
                } else {
                    if (rmHeight < clHeight + 1) {
                        if(state.goodtotal > state.goolList.length){
                            state.page2 = state.page+1
                            getGoods()
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }
            }
            const rowRadioSelection ={
                "type":"radio"
            }
            const onSelect = (val1,val2) =>{
                state.formState.goods_id = val2[0].id
                state.formState.belonging_app_id = val2[0].goods_app_id
                state.multipleSelection = val1;
                getShopCouponLists()
                state.shop_name = val2[0].name
            }

            const onSearch2 = () =>{
                state.page2 = 1
                getGoods()
            }

            const onReset2 = () =>{
                state.page2 = 1
                state.name = ""
                getGoods()
            }
            const textChange = (data1,data2) => {
                state.formState.copywriting = data1
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                textChange,
                onReset2,
                getShopCouponLists,
                getGoods,
                onSearch2,
                handlePopupScroll,
                handlePopupScroll2,
                uploadHeaders,
                uploadAction,
                onSelect,
                onSearch,
                handleTableChange2,
                getAppid,
                onReset,
                editorComponentRef,
                uploadFormData,
                pagination,
                rowRadioSelection,
                columns,
                edit,
                onDel,
                formRef,
                handleChange,
                onSubmit,
                rules,
                onStatus,
                addApply,
                pagination2,
                handleTableChange,
                columns2,
            }
        }
    })
