import {baseRequestClient} from "@/request/request_client";
import {
    IBalanceItem,
    ICarItem,
    ICarTypeItem,
    ICashItem,
    ICommentItem,
    IComplaintItem,
    IFeedbackDetail, IFeedbackItem, IGoodsCateItem, IHomeLogisticsDataItem, IHomeStatisticListItem,
    ILogisticsTypeItem, IMsgRecordItem, IWebConfigItem, IWebConfigUpdateItem,
} from "@/models/logistics_model";
import {IList, IListResult, IListResult3} from "@/models/base_model";
import {IDriverItem} from "@/models/logistics_member";
import {IArticlePlatform, IArticlePlatformCate, IArticles} from "@/models/logistics_article_model";
import {IUserItem} from "@/models/user_models";

const requestClient = baseRequestClient

//  物流审核板块接口
export default class ApiLogistics {

    /**
     * GET 获取仪表盘的数据
     * @param params
     */
    static getHomeData = async (params: any) => {
        return await requestClient.get<{ today: IHomeLogisticsDataItem, yesterday: IHomeLogisticsDataItem, sum: IHomeLogisticsDataItem }>('/admin/logistics.Home/index', params)
    }

    static getHomeList = async (params: any) => {
        return await requestClient.get<IHomeStatisticListItem[]>('/admin/logistics.Home/dayList', params)
    }

    static getHomeStatistics = async (params: any) => {
        return await requestClient.get<any>('/admin/logistics.Home/statistics', params)
    }

    /**
     * GET 获取接单司机榜
     * @param params
     */
    static getDriverOrderNumList = async (params: any) => {
        return await requestClient.get<IDriverItem[]>('/admin/logistics.Dashboard/orderNum', params)
    }

    /**
     * GET 获取评分司机榜
     * @param params
     */
    static getDriverRatingList = async (params: any) => {
        return await requestClient.get<IDriverItem[]>('/admin/logistics.Dashboard/orderStar', params)
    }

    /**
     * GET 获取司机列表
     * @param params ?page_no&page_size&shop_admin_id&vehicle_type&idle&disable&vali_real_name&vali_driver&start_time&end_time
     */
    static getDriverList = async (params: any) => {
        return await requestClient.get<IListResult3<IDriverItem>>('/admin/logistics.courier/index', params)
    }

    /**
     * POST 启用/禁用司机
     * @param data { "id":1, "disable":true }
     */
    static setDriverDisable = async (data: any) => {
        return await requestClient.post('/admin/logistics.courier/disable', data)
    }

    /**
     * POST 编辑司机
     * @param data { "id":1, "disable":true }
     */
    static editDriver = async (data: any) => {
        return await requestClient.post('/admin/logistics.courier/update', data)
    }

    /**
     * GET 获取提现申请列表
     * @param params ?page_no&page_size
     * @param userType 'user' | 'driver'
     */
    static getCashOutList = async (params: any, userType: 'user' | 'driver' = 'driver') => {
        let uri = '/admin/logistics.UserWithdrawals/index'
        if (userType === 'driver') {
            uri = '/admin/logistics.withdrawals/index'
        }
        return await requestClient.get<IListResult3<ICashItem>>(uri, params)
    }

    /**
     * POST 提现审核
     * @param data { "id":1, "audit_remark":"", "is_pass:: true }
     * @param userType 'user' | 'driver'
     */
    static auditCashOut = async (data: any, userType: 'user' | 'driver' = 'driver') => {
        let uri = '/admin/logistics.UserWithdrawals/audit'
        if (userType === 'driver') {
            uri = '/admin/logistics.withdrawals/audit'
        }
        return await requestClient.post(uri, data)
    }

    /**
     * POST 提现打款确认
     * @param data { "id":1, "transfer_remark":"", "is_transfer:: true }
     * @param userType 'user' | 'driver'
     */
    static auditCashTransfer = async (data: any, userType: 'user' | 'driver' = 'driver') => {
        let uri = '/admin/logistics.UserWithdrawals/transfer'
        if (userType === 'driver') {
            uri = '/admin/logistics.withdrawals/transfer'
        }
        return await requestClient.post(uri, data)
    }

    /**
     * 余额变动记录
     * @param params ?page_no&page_size&shop_admin_id&type&start_time&end_time&remark&addition
     * type:  1订单收入 2提现申请 3管理员添加 4管理员扣减 5提现失败返还 6投诉理赔
     * @param userType 'user' | 'driver'
     */
    static getBalanceRecord = async (params: any, userType: 'user' | 'driver' = 'driver') => {
        let uri = '/admin/logistics.Balance/users'
        if (userType === 'driver') {
            uri = '/admin/logistics.Balance/index'
        }
        return await requestClient.get<IListResult3<IBalanceItem>>(uri, params)
    }

    /**
     * GET 有投诉的订单列表
     * @param params ?page_no&page_size
     */
    static getComplaintList = async (params: any) => {
        return await requestClient.get<IListResult3<IComplaintItem>>('/admin/logistics.arbitration/index', params)
    }

    /**
     * GET 投诉的订单详情
     * @param id
     */
    static getComplaintDetail = async (id: any) => {
        return await requestClient.get<IComplaintItem>('/admin/logistics.arbitration/info', {id})
    }

    /**
     * POST 仲裁
     * @param data {"consignment_complaint_id":1,"malicious":1,"responsible_party":1,"remark":"备注","compensation":""}
     */
    static onArbitration = async (data: any) => {
        return await requestClient.post('/admin/logistics.arbitration/arbitration', data)
    }

    /**
     * GET 获取用户评论列表
     * @param params ?page_no&page_size
     */
    static getUserCommentList = async (params: any) => {
        return await requestClient.get<IListResult3<ICommentItem>>('/admin/logistics.CustomerComment/index', params)
    }

    /**
     * GET 获取用户评论详情
     * @param id
     */
    static getCommentDetail = async (id: any) => {
        return await requestClient.get<ICommentItem>('/admin/logistics.CustomerComment/info', {id})
    }

    /**
     * POST 删除评论
     * @param id
     */
    static delComment = async (id: any) => {
        return await requestClient.post('/admin/logistics.CustomerComment/destroy', {id})
    }

    /**
     * POST 删除评论
     * @param id
     * @param status 状态 0不显示 1显示
     */
    static setCommentStatus = async (id: any, status: number) => {
        return await requestClient.post('/admin/logistics.CustomerComment/status', {id, status})
    }

    /**
     * GET 获取车辆类型列表
     * @param params ?status&name=面
     */
    static getCarTypeList = async (params: any) => {
        return await requestClient.get<IListResult3<ICarTypeItem>>('/admin/logistics.CarType/list', params)
    }

    /**
     * GET 获取车辆类型详情
     * @param id
     */
    static getCarTypeDetail = async (id: any) => {
        return await requestClient.get<ICommentItem>('/admin/logistics.CarType/info', {id})
    }

    /**
     * POST 添加车辆类型
     * @param data
     */
    static addCarType = async (data: any) => {
        return await requestClient.post('/admin/logistics.CarType/carAdd', data)
    }

    /**
     * POST 编辑车辆类型
     * @param data
     */
    static editCarType = async (data: any) => {
        return await requestClient.post('/admin/logistics.CarType/carUp', data)
    }

    /**
     * POST 停用启用车型
     * @param id
     * @param {boolean} status  false停用，true正常
     */
    static setCarTypeStatus = async (id: any, status: boolean) => {
        return await requestClient.post('/admin/logistics.CarType/statusUp', {id, status})
    }

    /**
     * GET 获取物流类型列表
     * @param params ?page_no&page_size
     */
    static getLogisticsCategoryList = async (params: any = {}) => {
        return await requestClient.get<IListResult3<ILogisticsTypeItem>>('/admin/logistics.category/index', {
            ...params,
            page_size: 100
        })
    }

    /**
     * GET 获取物流类型详情
     * @param id
     */
    static getLogisticsCategoryDetail = async (id: any) => {
        return await requestClient.get<ICommentItem>('/admin/logistics.category/info', {id})
    }

    /**
     * POST 新增、编辑物流类型
     * @param data
     */
    static setLogisticsCategory = async (data: any) => {
        return await requestClient.post('/admin/logistics.category/store', data)
    }

    /**
     * POST 禁用启用物流类型
     * @param id
     * @param {boolean} is_enabled  false停用，true正常
     */
    static setLogisticsCategoryStatus = async (id: any, is_enabled: boolean) => {
        return await requestClient.post('/admin/logistics.category/status', {id, is_enabled})
    }

    /**
     * GET 获取物品分类列表
     * @param params ?page_no&page_size
     */
    static getGoodsCateList = async (params: any = {}) => {
        return await requestClient.get<IListResult3<IGoodsCateItem>>('/admin/logistics.GoodsCate/index', {
            ...params,
            page_size: 100
        })
    }

    /**
     * GET 获取物品分类详情
     * @param id
     */
    static getGoodsCateDetail = async (id: any) => {
        return await requestClient.get<ICommentItem>('/admin/logistics.GoodsCate/info', {id})
    }

    /**
     * 新增、编辑物品分类
     * @param data
     */
    static setGoodsCate = async (data: any) => {
        return await requestClient.post('/admin/logistics.GoodsCate/store', data)
    }

    /**
     * POST 禁用启用物品分类
     * @param id
     * @param {boolean} is_enabled  false停用，true正常
     */
    static setGoodsCateStatus = async (id: any, is_enable: boolean) => {
        return await requestClient.post('/admin/logistics.GoodsCate/status', {id, is_enable})
    }

    /**
     * GET 获取意见反馈列表
     * @param params ?page=1&limit=10&keywords&state
     */
    static getFeedbackList = async (params: any) => {
        return await requestClient.get<IFeedbackItem[]>('/admin/logistics.Feedback/lists', params)
    }

    /**
     * GET 获取意见反馈详情
     * @param id
     */
    static getFeedbackDetail = async (id: any) => {
        return await requestClient.get<IFeedbackDetail>('/admin/logistics.Feedback/detail', {id})
    }

    /**
     * POST 回复意见反馈
     * @param data  {"pid":77, "reply":"的发射点的发生"}
     */
    static replyFeedback = async (data: any) => {
        return await requestClient.post('/admin/logistics.Feedback/reply', data)
    }

    /**
     * 获取文章平台列表
     * @param params {page_no:1, page_size:10}
     */
    static articlePlatList = async (params: any) => {
        return await requestClient.get<IListResult3<IArticlePlatform>>('/admin/logistics.DocPlatform/index', params)
    }

    /**
     * 获取平台下属文章类型
     * @param id
     */
    static articlePlatDetail = async (id: any) => {
        return await requestClient.get<IArticlePlatform>('/admin/logistics.DocPlatform/info', {id})
    }

    /**
     * 修改/新增文章平台
     * @param data
     */
    static updateOrAddArticlePlat = async (data: IArticlePlatform) => {
        return await requestClient.post('/admin/logistics.DocPlatform/store', data)
    }

    /**
     * 获取平台下属文章列表
     * @param params
     */
    static articleCateList = async (params: any) => {
        return await requestClient.get<IListResult3<IArticlePlatformCate>>('/admin/logistics.DocCategory/index', params)
    }

    static articleCateTree = async (params: any) => {
        return await requestClient.get<IArticlePlatformCate[]>('/admin/logistics.DocCategory/tree', params)
    }

    /**
     * 平台下属文章类型状态变更
     * @param data
     */
    static articleCateStatusChange = async (data: any) => {
        return await requestClient.post('/admin/logistics.DocCategory/status', data)
    }

    /**
     * 平台下属文章类型详情
     * @param id
     */
    static articleCateDetail = async (id: any) => {
        return await requestClient.get<IArticlePlatformCate>('/admin/logistics.DocCategory/show', {id})
    }

    /**
     * 修改/新增文章分类
     * @param data
     */
    static updateOrAddArticleCate = async (data: IArticlePlatformCate) => {
        return await requestClient.post('/admin/logistics.DocCategory/store', data)
    }

    /**
     * 获取文章列表
     * @param params
     */
    static articleList = async (params: any) => {
        return await requestClient.get<IListResult3<IArticles>>('/admin/logistics.Documentation/index', params)
    }

    /**
     * 删除文章
     * @param id
     */
    static articleDelete = async (id: any) => {
        return await requestClient.post('/admin/logistics.Documentation/destroy', {id})
    }

    /**
     * 文章详情
     * @param id
     */
    static articleDetail = async (id: any) => {
        return await requestClient.get<IArticles>('/admin/logistics.Documentation/show', {id})
    }

    /**
     * 文章状态变更
     * @param data
     */
    static articleStatusChange = async (data: any) => {
        return await requestClient.post('/admin/logistics.Documentation/status', data)
    }

    /**
     * 修改/新增文章分类
     * @param data
     */
    static updateOrAddArticle = async (data: IArticles) => {
        return await requestClient.post('/admin/logistics.Documentation/store', data)
    }

    /**
     * 车辆列表
     * @param params
     */
    static carList = async (params: any) => {
        return await requestClient.get<IListResult3<ICarItem>>('/admin/logistics.Vehicle/index', params)
    }

    /*
     * GET 获取隐私政策、用户协议、司机福利
     * @param type courier_service(用户协议)，courier_welfare(司机福利)，courier_privacy(隐私政策)，courier_settled(入驻协议)
     */
    static getProtocol = async (type: 'courier_service' | 'courier_welfare' | 'courier_privacy' | 'courier_settled') => {
        return await requestClient.get<{ content: string }>('/api/policy/courierService', {type})
    }

    /**
     * POST 设置隐私政策、用户协议、司机福利
     * @param type courier_service(用户协议)，courier_welfare(司机福利)，courier_privacy(隐私政策)，courier_settled(入驻协议)
     */
    static setProtocol = async (name: 'courier_service' | 'courier_welfare' | 'courier_privacy' | 'courier_settled', value: string) => {
        return await requestClient.post('/admin/logistics.Courier/courierAgreementSave', {type: 'policy', name, value})
    }

    /**
     * GET 获取网站信息列表
     * @param params ?page_no&page_size
     */
    static getWebsiteInformationList = async () => {
        return await requestClient.get<IListResult3<IWebConfigItem>>('/admin/logistics.WebsiteInformation/index', {page_size: 100})
    }

    /**
     * GET 获取网站信息详情
     * @param id
     */
    static getWebsiteInformationDetail = async (id: any) => {
        return await requestClient.get<IFeedbackDetail>('/admin/logistics.WebsiteInformation/info?id=8', {id})
    }

    /**
     * POST 新增编辑网站信息
     * @param data  {"id":13,"tag":"user_benefits","content":"这里是用户福利文章","is_json":false}
     */
    static setWebsiteInformation = async (data: IWebConfigUpdateItem) => {
        return await requestClient.post('/admin/logistics.WebsiteInformation/store', data)
    }

    /**
     * POST 删除网站信息【请注意确认】
     * @param id
     */
    static delWebsiteInformation = async (id: any) => {
        return await requestClient.post('/admin/logistics.WebsiteInformation/destroy', {id})
    }

    /**
     * GET 获取货主列表
     * @param params page=1&limit=10
     */
    static getOwnerList = async (params: any) => {
        return await requestClient.get<IListResult<IUserItem>>('/admin/user.user/lists', params)
    }

    /**
     * 编辑用户
     * @param data
     */
    static setOwner = async (data: any) => {
        return await requestClient.post('/admin/user.user/edit', data)
    }

    /**
     * POST 禁用启用物品分类
     * @param data
     */
    static setOwnerStatus = async (data: { id: any, status: number }) => {
        return await requestClient.post('/admin/user.user/disable', data)
    }

    /**
     * GET 获取用户消息记录
     * @param params ?page_no&page_size&user_id&message_type_id&send_time_start&send_time_end&keyword
     */
    static getUserMessageList = async (params: any) => {
        return await requestClient.get<IListResult3<IMsgRecordItem>>('/admin/logistics.message/userMessage', params)
    }

    /**
     * GET 获取司机消息记录
     * @param params ?page_no&page_size&shop_admin_id&message_type_id&send_time_start&send_time_end&keyword
     */
    static getDriverMessageList = async (params: any) => {
        return await requestClient.get<IListResult3<IMsgRecordItem>>('/admin/logistics.message/courierMessage', params)
    }

    /**
     * GET 获取客服列表
     * @param
     */
    static getServiceList = async () => {
        return await requestClient.get<any[]>('/admin/chat.Customer/lists')
    }

    /**
     * 新增客服
     * @param user_id
     */
    static addService = async (user_id: any) => {
        return await requestClient.post('/admin/chat.Customer/add', {user_id});
    }

    /**
     * 删除客服
     * @param id
     */
    static onDelService = async (id: any) => {
        return await requestClient.post('/admin/chat.Customer/del', {id});
    }

    /**
     * GET 获取评论接口
     * @param id
     */
    static getComment = async (params: any) => {
        return await requestClient.get<IListResult3<IDriverItem>>('/admin/logistics.CustomerComment/index', params)
    }

    /**
     * 删除评论接口
     * @param data { "id":1, "transfer_remark":"", "is_transfer:: true }
     */
    static destroyComment = async (data: any) => {
        return await requestClient.post('/admin/logistics.CustomerComment/destroy', data)
    }


    /**
     * GET 获取评论详情
     * @param params ?page_no&page_size&shop_admin_id&vehicle_type&idle&disable&vali_real_name&vali_driver&start_time&end_time
     */
    static getCommnetDetail = async (params: any) => {
        return await requestClient.get<IListResult3<IDriverItem>>('/admin/logistics.CustomerComment/info', params)
    }

    /**
     * POST 评论是否显示
     * @param data
     */
    static commentStatus = async (data: any) => {
        return await requestClient.post('/admin/logistics.CustomerComment/status', data)
    }

    /**
     * GET 获取车辆列表
     * @param id
     */
    static getCar = async (params: any) => {
        return await requestClient.get<IListResult3<IDriverItem>>('/admin/logistics.Vehicle/index', params)
    }

    /**
     * GET 获取反馈列表
     * @param id
     */
    static feedbackList = async (params: any) => {
        return await requestClient.get('/admin/logistics.Feedback/lists', params)
    }

    /**
     * GET 反馈详情
     * @param id
     */
    static feedbackDetail = async (params: any) => {
        return await requestClient.get('/admin/logistics.Feedback/detail', params)
    }

    /**
     * post 反馈回复
     * @param id
     */
    static FeedbackReply = async (params: any) => {
        return await requestClient.post('/admin/logistics.Feedback/reply', params)
    }

    /**
     * GET 反馈详情
     * @param id
     */
    static HomeIndex = async (params: any) => {
        return await requestClient.get('/admin/party.Home/index', params)
    }

}
