
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiArticle from "@/request/apis/api_article";
import {IArticleCategory} from "@/models/article_model";
import {message} from "ant-design-vue/es";
import ArticleCategorySet from '@/components/ArticleCategorySet.vue'

interface IState {
  list: IArticleCategory[];
  name: string;
  loading: boolean;
  editItem?: IArticleCategory;
  expands: number[]
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align:'center',

  },
  {
    title: '分类图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '分类名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '描述',
    dataIndex: 'remark',
    key: 'remark',
  },
  {
    title: 'sn',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: '状态',
    dataIndex: 'is_show',
    key: 'is_show',
  },
  {
    title: '操作',
    key: 'action',
  },
]

export default defineComponent({
  name: 'ArticleCategory',
  components: {ArticleCategorySet},
  setup() {
    const state: IState = reactive({
      list: [],
      name: '', // 新增分类名称
      loading: false,
      editItem: undefined,
      expands: []
    })

    // 获取分类列表
    const getList = async () => {
      const res = await ApiArticle.getArticleCategory()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    // 删除分类
    const onDel = async (item: IArticleCategory) => {
      const res = await ApiArticle.delArticleCategory(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('删除分类成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 显示隐藏分类
    const setShow = async (item: IArticleCategory) => {
      const res = await ApiArticle.showArticleCategory(item.id, Number(!item.is_show))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('操作成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    const setExpand = (id: number) => {
      if (state.expands.includes(id)) {
        state.expands = state.expands.filter(e => e != id)
      } else {
        state.expands = [...state.expands, id]
      }
    }

    const setExpandAll = () => {
      if (state.expands.length < state.list.length) {
        state.expands = state.list.map(e => e.id)
      } else {
        state.expands = []
      }
    }

    const setEdit = (item?: IArticleCategory) => {
      state.editItem = item
    }

    const reset = () => {
      state.editItem = undefined
      getList()
    }

    onMounted(() => {
      getList()
    })


    return {
      ...toRefs(state),
      columns,
      onDel,
      setEdit,
      reset,
      setShow,
      setExpand,
      setExpandAll,
    }
  }
})
