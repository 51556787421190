
    import { defineComponent, onMounted, reactive, toRefs, computed } from "vue";
    import ApiLogistics from "@/request/apis/api_logistics";
    import router from "@/router";
    import { message } from "ant-design-vue/es";
    import StorageUtil from "@/utils/storage_util";
    import {
        EditOutlined,
        CheckOutlined,
        CloseOutlined,
    } from "@ant-design/icons-vue";
    import ImageView from "@/components/ImageView.vue";
    import DriverSet from "@/components/DriverSet.vue";
    import PageUtil from "@/utils/page_util";
    import { IDriverItem, IEditDriver } from "@/models/logistics_member";
    import ApiSetting from "@/request/apis/api_setting";

    interface IState {
        list:any;
        total: number;
        loading: boolean;
        searches: any;
        visible: boolean;
        editItem?: IEditDriver;
        commentDetail:any
    }

    // 表格
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "手机号",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "内容",
            dataIndex: "content",
            key: "content",
        },
        {
            title: "发送时间",
            dataIndex: "send_time",
            key: "send_time",
        },
        {
            title: "事件名称",
            dataIndex: "scene_name",
            key: "scene_name",
        },
        {
            title: "状态",
            dataIndex: "send_status",
            key: "send_status",
        },
    ];

    export default defineComponent({
        name: "DriverList",
        components: {
            EditOutlined,
            CheckOutlined,
            CloseOutlined,
            ImageView,
            DriverSet,
        },
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                commentDetail:'',
                visible:false,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    send_type: "1",
                    id:'1'
                },
            });

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };

            //获取列表
            const getList = async () => {
                let params = { ...state.searches };
                StorageUtil.setParams(params);
                state.loading = true;
                const res = await ApiSetting.NoticeSetting(params);
                state.loading = false;
                if (res && res.error_code === 0) {
                    state.total = res.data?.count || 0;
                    state.list = res.data?.lists || [];
                }
            };

            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                columns,
                pagination,
                handleTableChange
            };
        },
    });
