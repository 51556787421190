import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a964e344"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageView = _resolveComponent("ImageView")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    dataSource: _ctx.list,
    columns: _ctx.columns,
    loading: _ctx.loading,
    bordered: "",
    pagination: _ctx.pagination,
    onChange: _ctx.handleTableChange
  }, {
    bodyCell: _withCtx(({ column, text, record }) => [
      (column.key === 'head_img')
        ? (_openBlock(), _createBlock(_component_ImageView, {
            key: 0,
            "image-url": text
          }, null, 8, ["image-url"]))
        : _createCommentVNode("", true),
      (column.key === 'front')
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: record.road_transportation_images.front,
            style: {"width":"50px"}
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      (column.key === 'sub')
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            src: record.road_transportation_images.sub,
            style: {"width":"50px"}
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["dataSource", "columns", "loading", "pagination", "onChange"]))
}