
    import {computed, defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import api_article from "@/request/apis/api_article";
    import ApiMember from "@/request/apis/api_member";
    import ApiSetting from "@/request/apis/api_setting";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import dayjs, {Dayjs} from 'dayjs';

    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState: any,
        treeData: any,
        partData: any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData: [],
                list: [],
                partData: [],
                total: 0,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                },
                type: '',
                formState: ''
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    fixed: 'left'
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'left'
                },
                {
                    title: '职位',
                    dataIndex: 'position',
                    key: 'position',
                    fixed: 'left'
                },
                {
                    title: '头像',
                    dataIndex: 'avatar',
                    key: 'avatar',
                },
                {
                    title: '公司',
                    dataIndex: 'company',
                    key: 'company',
                },
                {
                    title: '主部门',
                    dataIndex: 'part',
                    key: 'part',
                },
                {
                    title: '全部部门',
                    dataIndex: 'allPart',
                    key: 'allPart',
                },
                {
                    title: '用户名',
                    dataIndex: 'username',
                    key: 'username',
                },
                {
                    title: '详细地址',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: '生日',
                    dataIndex: 'birthday',
                    key: 'birthday',
                },
                {
                    title: '电子邮箱',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: '员工类型',
                    dataIndex: 'test',
                    key: 'test',
                },
                {
                    title: '入职日期',
                    dataIndex: 'entry_date',
                    key: 'entry_date',
                },
                {
                    title: '工号',
                    dataIndex: 'job_number',
                    key: 'job_number',
                },
                {
                    title: '私人号码',
                    dataIndex: 'personnel_number',
                    key: 'personnel_number',
                },
                {
                    title: '电话',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: '教育经历',
                    dataIndex: 'record_of_formal_schooling',
                    key: 'record_of_formal_schooling',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                },


                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    fixed: "left",
                    align: 'center'
                },
            ]

            const rules = {
                full_name: [{required: true, message: "请输入部门全称"},],
                name: [{required: true, message: "请输入姓名"},],
                username: [{required: true, message: "请输入用户名"},],
                address: [{required: true, message: "请输入地址"},],
                company_id: [{required: true, message: "请选择所属公司"},],
                department_id: [{required: true, message: "请选择主部门"},],
                all_department_id: [{required: true, message: "请选择全部部门"},],
                position: [{required: true, message: "请输入职位"},],
            }


            const add = () => {
                state.visible = true
                state.type = 1
                state.formState = {
                    "name": '',
                    "username": '',
                    "address": '',
                    "birthday": '',
                    "email": "",
                    "test": "",
                    "entry_date": '',
                    "job_number": '',
                    "personnel_number": '',
                    "phone": '',
                    "record_of_formal_schooling": '',
                    "sex": '',
                    "company_id": undefined,
                    "department_id": undefined,
                    "all_department_id": [],
                    "position": '',
                    "avatar": '',
                    remark: ''
                }
            }


            const edit = (val) => {
                state.type = 2
                state.formState = {
                    "id": val.id,
                    "name": val.name,
                    "username": val.username,
                    "address": val.address,
                    "birthday": val.birthday == null ? null : dayjs((val.birthday) * 1000).format('YYYY-MM-DD'),
                    "email": val.email,
                    "test": val.test,
                    "entry_date": val.entry_date == null ? null : dayjs((val.entry_date) * 1000).format('YYYY-MM-DD'),
                    "job_number": val.job_number,
                    "personnel_number": val.personnel_number,
                    "phone": val.phone,
                    "record_of_formal_schooling": val.record_of_formal_schooling,
                    "sex": val.sex,
                    "company_id": val.company_id,
                    "department_id": val.department_id,
                    "all_department_id": val.all_department_id == null ? [] : val.all_department_id.split(',').map(Number),
                    "position": val.position,
                    "avatar": val.avatar,
                    remark: val.remark
                }
                getpart2()
                console.log(state.formState)
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                console.log(state.searches.page)
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSetting.employeeList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.data
                    state.total = data.total

                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                formRef.value.validate().then(async () => {
                    if (state.type == 1) {
                        let params = {...state.formState}
                        params.birthday = dayjs(params.birthday).unix()
                        params.entry_date = dayjs(params.entry_date).unix()
                        params.all_department_id = params.all_department_id.join(',')
                        const res = await ApiSetting.employeeAdd(params)
                        if (res.error_code == 0) {
                            state.visible = false
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        params.birthday = dayjs(params.birthday).unix()
                        params.entry_date = dayjs(params.entry_date).unix()
                        const res = await ApiSetting.employeeEdit(params)
                        if (res.error_code == 0) {
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await ApiSetting.employeeDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    } else {
                        message.error(res.msg)
                    }
                }
            }


            const getCompany = async () => {
                const params = {page: 1, limit: 9999}
                const res = await ApiSetting.companyList(params)
                if (res.error_code === 0) {
                    const data: any = res.data
                    state.treeData = data.data
                } else {
                    message.error(res.msg)
                }
            }

            const getpart = async () => {
                state.formState.department_id = undefined
                const params = {id: state.formState.company_id}
                const res = await ApiSetting.departmentAll(params)
                if (res.error_code === 0) {
                    const data: any = res.data
                    state.partData = data
                } else {
                    message.error(res.msg)
                }
            }

            const getpart2 = async () => {
                const params = {id: state.formState.company_id}
                const res = await ApiSetting.departmentAll(params)
                if (res.error_code === 0) {
                    const data: any = res.data
                    state.partData = data
                } else {
                    message.error(res.msg)
                }
            }

            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange2 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.avatar = data.uri
                    }
                }
            }

            onMounted(() => {
                getList()
                getCompany()
            })

            return {
                ...toRefs(state),
                columns,
                getpart2,
                open,
                uploadHeaders,
                uploadAction,
                uploadFormData,
                handleChange2,
                formRef,
                getpart,
                onSubmit,
                dayjs,
                getCompany,
                rules,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
