import {baseRequestClient} from "@/request/request_client";
import {IMemberDetail, IMemberItem, IMemberUpdate, IOrganizationItem, IRoleItem} from "@/models/party_model";

const requestClient = baseRequestClient


export default class apiTalent {

    static wagesList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/wagesList', data)
    }
    static wagesAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/wagesAdd', data)
    }
    static wagesEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/wagesEdit', data)
    }
    static wagesDel = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/wagesDel', data)
    }


    static industryList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/industryList', data)
    }
    static industryAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/industryAdd', data)
    }
    static industryEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/industryEdit', data)
    }
    static industryStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/industryStatus', data)
    }

    static positionAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/positionAdd', data)
    }

    static positionEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/positionEdit', data)
    }
    static positionList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/positionList', data)
    }
    static positionStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/positionStatus', data)
    }
    static positionChildrenList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/positionChildrenList', data)
    }

    static certificateEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/certificateEdit', data)
    }
    static certificateList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/certificateList', data)
    }
    static certificateAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/certificateAdd', data)
    }
    static certificateStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/certificateStatus', data)
    }

    static typeWorkEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/typeWorkEdit', data)
    }
    static typeWorkList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/typeWorkList', data)
    }
    static typeWorkAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/typeWorkAdd', data)
    }
    static typeWorkStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/typeWorkStatus', data)
    }
    static BasicSkillAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/skillAdd', data)
    }
    static BasicSkillEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/skillEdit', data)
    }
    static BasicSkillList = async (data: any) => {
        return await requestClient.get('/admin/share_talents.Basic/skillList', data)
    }
    static BasicSkillStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/skillStatus', data)
    }
    static keywordAdd = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/keywordAdd', data)
    }
    static keywordEdit = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/keywordEdit', data)
    }
    static keywordList = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/keywordList', data)
    }
    static keywordStatus = async (data: any) => {
        return await requestClient.post('/admin/share_talents.Basic/keywordStatus', data)
    }
    static ExpressAdd = async (data: any) => {
        return await requestClient.post('/admin/Express/add', data)
    }
    static ExpressList = async (data: any) => {
        return await requestClient.get('/admin/Express/lists', data)
    }
    static ExpressDel = async (data: any) => {
        return await requestClient.post('/admin/Express/del', data)
    }
    static ExpressEdit = async (data: any) => {
        return await requestClient.post('/admin/Express/edit', data)
    }
    }