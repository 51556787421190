
    import {computed, defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import ApiBanner from "@/request/apis/api_banner";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import ApiMember from "@/request/apis/api_member";
    import dayjs from "dayjs";

    interface IState {
        searches: any,
        total: any,
        list: any,
        platform_cate: any,
        title: any,
        visible: any,
        reason: any,
        formState: any,
        type: any,
        treedata: any,
        visible2: any,
        visible3: any,
        setData: any,
        AccountData: any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: "",
                    is_recommend: undefined,
                    is_run: undefined,
                    is_freeze: undefined,
                    cid: undefined
                },
                total: 0,
                type: '',
                treedata: [],
                visible2: false,
                visible3: false,
                platform_cate: [],
                list: [],
                title: "",
                visible: false,
                reason: '',
                AccountData: '',
                formState: {
                    "type": 2,
                    "name": "",
                    "nickname": "",
                    "account": '',
                    "password": '',
                    "mobile": "",
                    "logo": "",
                    "background": "",
                    "license": "",
                    "keywords": "",
                    "intro": "",
                    "weight": '',
                    "trade_service_fee": '',
                    "is_run": undefined,
                    "is_freeze": undefined,
                    "is_product_audit": undefined,
                    "is_recommend": undefined,
                    "cid": undefined,
                    "expire_time": "",
                    "agent":""
                },
                setData: {
                    "id": '',
                    "weight": '',
                    "is_recommend": '',
                    "is_distribution": ""
                }
            })
            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    width: 150
                },
                {
                    title: '商家信息',
                    dataIndex: 'shop',
                    key: 'shop',
                    width: 300
                },
                {
                    title: '联系信息',
                    dataIndex: 'collect',
                    key: 'collect',
                },
                {
                    title: '商家账号',
                    dataIndex: 'account',
                    key: 'account',
                    width: 200
                },
                {
                    title: '商家状态',
                    key: 'status',
                    dataIndex: 'status',
                    width: 200
                },
                {
                    title: '合同信息',
                    key: 'order',
                    dataIndex: 'order',
                    width: 180
                },
                {
                    title: '支付渠道',
                    key: 'pay',
                    dataIndex: 'pay',
                    width: 160
                },
                {
                    title: '推荐状态',
                    key: 'recommand',
                    dataIndex: 'recommand',
                    width: 180
                },
                {
                    title: '变更时间',
                    key: 'time',
                    dataIndex: 'time',
                    width: 250
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250
                },
            ];
            const rules = {
                type: [{required: true, message: "请选择主营类目"}],
                name: [{required: true, message: "请输入商家名称"}],
                nickname: [{required: true, message: "请输入联系人姓名"}],
                mobile: [{required: true, message: "请输入手机号"}],
                logo: [{required: true, message: "请上传企业logo"}],
                background: [{required: true, message: "请上传背景图片"}],
                license: [{required: true, message: "请上传营业执照"}],
                keywords: [{required: true, message: "请输入商家账号"}],
                intro: [{required: true, message: "请输入店铺简介"}],
                account: [{required: true, message: "请输入商家账号"}],
                password: [{required: true, message: "请输入商家密码"}],
                cid: [{required: true, message: "请输入商家简介"}],
                weight: [{required: true, message: "请选择主营类目"}],
                trade_service_fee: [{required: true, message: "请填写交易服务费率"}],
                is_run: [{required: true, message: "请选择营业状态"}],
                is_freeze: [{required: true, message: "请选择商家状态"}],
                is_product_audit: [{required: true, message: "请选择是否审核"}],
                is_recommend: [{required: true, message: "请选择是否推荐"}],
                expire_time: [{required: true, message: "请选择到期时间"}],
            }
            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiUser.StoreLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.total = data.count
                    state.list = data.lists
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const OnSearch = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                getList()
            }

            const OnReset = () => {
                state.searches.name = ''
                state.searches.is_recommend = undefined
                state.searches.cid = undefined
                state.searches.is_run = undefined
                state.searches.is_freeze = undefined
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const getTree = async () => {
                const params = {page: 1, limit: 100}
                const res = await ApiMember.CategoryLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.treedata = data.lists
                } else {
                    message.error(res.msg)
                }
            }
            const callback = (key) => {
                state.searches.page_no = PageUtil.pageNo
                state.searches.page_size = PageUtil.pageSize
                state.searches.type = key
                getList()
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    formRef.value.validate().then(async () => {
                        const params = {...state.formState}
                        const res = await ApiUser.StoreAdd(params)
                        if (res.error_code == 0) {
                            state.visible = false
                            message.success(res.msg)
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    })
                        .catch((error: any) => {
                            // state.formState.expire_time = ''
                            console.log("error", error)
                        })
                }
                if (state.type == 2) {
                    formRef.value.validate().then(async () => {
                        const params = {...state.formState}
                        const res = await ApiUser.StoreEdit(params)
                        if (res.error_code == 0) {
                            state.visible = false
                            message.success(res.msg)
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    })
                        .catch((error: any) => {
                            // state.formState.expire_time = ''
                            console.log("error", error)
                        })
                }
            }

            const addShop = () => {
                state.title = "添加商家"
                state.type = 1
                state.visible = true
                state.formState = {
                    "type": 2,
                    "name": "",
                    "nickname": "",
                    "mobile": "",
                    "logo": "",
                    "background": "",
                    "license": "",
                    "keywords": "",
                    "intro": "",
                    "weight": '',
                    "trade_service_fee": '',
                    "is_run": undefined,
                    "is_freeze": undefined,
                    "is_product_audit": undefined,
                    "is_recommend": undefined,
                    "cid": undefined,
                    "expire_time": "",
                    "agent":""
                }
            }
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const formRef = ref();
            const handleChange1 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.logo = data.uri
                    }
                }
            }

            const handleChange2 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.background = data.uri
                    }
                }
            }

            const handleChange3 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.license = data.uri
                    }
                }
            }

            const editShop = (val) => {
                state.type = 2
                state.visible = true
                state.title = "编辑商家信息"
                state.formState = {
                    "id": val.id,
                    "type": 2,
                    "name": val.name,
                    "nickname": val.nickname,
                    "mobile": val.mobile,
                    "logo": val.logo,
                    "background": val.background,
                    "license": val.license,
                    "keywords": val.keywords,
                    "intro": val.intro,
                    "weight": val.weight,
                    "trade_service_fee": val.trade_service_ratio,
                    "is_run": val.is_run,
                    "is_freeze": val.is_freeze,
                    "is_product_audit": val.is_product_audit,
                    "is_recommend": val.is_recommend,
                    "cid": val.cid,
                    "agent":val.agent,
                    "expire_time": val.expire_time == '无期限' ? 0 : val.expire_time
                }
            }

            const setting = (val) => {
                state.setData = {
                    "id": val.id,
                    "weight": val.weight,
                    "is_recommend": val.is_recommend,
                    "is_distribution": val.is_distribution
                }
                state.visible2 = true
            }
            const setAccount = (val) => {
                state.AccountData = {
                    "id": val.id,
                    "account": val.admin.account,
                    "password": '',
                    "conPassword": '',
                    "nickname": val.admin.name
                }
                state.visible3 = true
            }

            const handleOk2 = async () => {
                const res = await ApiUser.StoreSet(state.setData)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    state.visible2 = false
                    getList()
                } else {
                    message.error(res.msg)
                }
            }

            const handleOk3 = async () => {
                if (state.AccountData.password == '' || state.AccountData.conPassword == '' || state.AccountData.account == '' || state.AccountData.nickname == '') {
                    message.error("请完整填写信息")
                } else if (state.AccountData.conPassword !== state.AccountData.password) {
                    message.error("两次密码不一致")
                } else {
                    const res = await ApiUser.StoreAccount(state.AccountData)
                    if (res.error_code == 0) {
                        message.success(res.msg)
                        state.visible3 = false
                        getList()
                    } else {
                        message.error(res.msg)
                    }
                }

            }


            onMounted(() => {
                getList()
                getTree()
            })

            return {
                ...toRefs(state),
                getList,
                editShop,
                setAccount,
                handleOk2,
                handleOk3,
                uploadHeaders,
                setting,
                uploadAction,
                uploadFormData,
                handleChange1,
                handleChange2,
                handleChange3,
                callback,
                formRef,
                addShop,
                OnReset,
                handleOk,
                getTree,
                columns,
                OnSearch,
                rules,
                pagination,
                handleTableChange
            }
        }
    });
