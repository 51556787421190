
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons-vue';
import {linkTypes, terminalTypes} from "@/models/banner_model"
import {dictionaryTerminal, IDictionaryItem, IDictionaryUpdate} from "@/models/setting_model";
import ApiSetting from "@/request/apis/api_setting";
import {IMenuItem, IMenuUpdate, menuTarget, menuType} from "@/models/menu_model";
import ParamsSet from "@/components/ParamsSet.vue";

interface IState {
  loading: boolean,
  paramsArrays: {
    key: string
    value: any
    id: number
  }[]
  dictionary: IDictionaryItem[] // 跳转配置
  menus: IMenuItem[],
}

// 新增编辑跳转配置
export default defineComponent({
  name: "MenuSet",
  components: {UploadComponent, PlusOutlined, MinusCircleOutlined, ParamsSet},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any
    const paramsRef = ref()
    const formRef = ref()
    const formState: UnwrapRef<IMenuUpdate> = reactive({
      pid: 0, //上级id
      type: 1,// 菜单类型 1-首页导航；2-个人中心；3-底部导航
      link_type: 3, //链接类型：0-为空；1-商城页面；2-商品页面；3-自定义类型// 暂时传3
      link_address: '',
      status: 1,
      name: '',// 菜单名称
      subtitle: '', // 副标题
      is_pc: 1, //1-pc端，0-
      image: '',
      target: 0,
      sort: 1,
      params: null
    })

    // 校验
    const rules = {
      name: [{required: true, message: "请输入菜单名称"}],
      // subtitle: [{required: true, message: "请输入菜单副标题"}],
      link_address: [{required: true, message: "请输入跳转链接"}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const params = paramsRef.value.getParams()
            let data = {...toRaw(formState), params}
            console.log(data)
            if (editId) {
              onEdit(data)
            } else {
              onAdd(data)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const state: IState = reactive({
      loading: false,
      paramsArrays: [],
      dictionary: [],
      menus: []
    })

    // 获取详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiSetting.getMenuDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.name = data.name
          formState.subtitle = data.subtitle
          formState.type = data.decorate_type
          formState.is_pc = data.is_pc
          formState.link_address = Number(data.link_address)
          formState.link_type = data.link_type
          formState.image = data.image
          formState.id = data.id
          formState.target = data.target
          formState.pid = data.pid
          formState.sort = data.sort
          formState.params = data.params || null
        }
      }
    }

    // 新增
    const onAdd = async (params: IMenuUpdate) => {
      state.loading = true
      const res = await ApiSetting.addMenu(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '新增成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: IMenuUpdate) => {
      params.url = params.link_address
      state.loading = true
      const res = await ApiSetting.editMenu(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '编辑成功')
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    //获取菜单列表
    const getMenuListAll = async () => {
      const res = await ApiSetting.getMenuListAll()
      if (res && res.error_code === 0) {
        let menus = res.data || []
        state.menus = menus.filter(e => !e.pid)
      }
    }

    // 获取跳转配置列表 getDictionaryAllList
    const getDictionaryAllList = async (name = '') => {
      const res = await ApiSetting.getDictionaryAllList(name)
      if (res && res.error_code === 0) {
        state.dictionary = res.data || []
      }
    }

    // select搜索
    const filterOption = (input: string, option: any) => {
      return option.label.includes(input)
    };

    const goBack = () => {
      router.back()
    }

    const onImageChange = (url: string) => (formState.image = url)

    onMounted(async () => {
      await getMenuListAll()
      await getDictionaryAllList()
      await getDetail()
    })

    return {
      formRef,
      paramsRef,
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      onImageChange,
      ...toRefs(state),
      filterOption,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      terminalTypes,
      linkTypes,
      dictionaryTerminal,
      menuType,
      menuTarget
    }
  },
})
