
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {message} from "ant-design-vue/es";
import OrganizationSet from '@/components/OrganizationSet.vue'
import {IBannerPositionItem} from "@/models/banner_model";
import ApiParty from "@/request/apis/api_party";
import {IOrganizationItem} from "@/models/party_model";
import type {TreeProps} from 'ant-design-vue';
import {menuText} from "@/utils/config";

interface IState {
  list: IOrganizationItem[];
  name: string;
  loading: boolean;
  editItem?: { id: number; name: string; pid: any };
  expands: number[],
  expandKeys: any[]
}

export default defineComponent({
  name: 'OrganizationList',
  components: {OrganizationSet},
  setup() {
    const setRef = ref()
    const state: IState = reactive({
      list: [],
      name: '', // 新增分类名称
      loading: false,
      editItem: undefined,
      expands: [],
      expandKeys: []
    })

    const fieldNames: TreeProps['fieldNames'] = {
      children: 'children',
      title: 'name',
      key: 'id'
    };

    // 获取列表
    const getList = async () => {
      const res = await ApiParty.getOrganizationList()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    // 删除
    const onDel = async (item: IBannerPositionItem) => {
      const res = await ApiParty.delOrganization(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('删除成功')
          await getList()
          setRef.value.getList()
          return
        }
        message.error(res.msg)
      }
    }

    const setExpand = (id: number) => {
      if (state.expands.includes(id)) {
        state.expands = state.expands.filter(e => e != id)
      } else {
        state.expands = [...state.expands, id]
      }
    }

    const setEdit = (item: IOrganizationItem) => {
      let pid = item.path.match(/\d+(.\d+)?/g) || []

      let pidArr: any = []
      if(pid.length > 1){
        pidArr = pid.map(e => Number(e)).slice(1)
      }

      state.editItem = {
        id: item.id,
        name: item.name, // 广告位名称
        pid: pidArr,
      }
    }

    const reset = () => {
      state.editItem = undefined
      getList()
    }

    onMounted(() => {
      getList()
    })


    return {
      ...toRefs(state),
      fieldNames,
      onDel,
      setEdit,
      reset,
      setExpand,
      setRef,
      menuText
    }
  }
})
