import axios, {AxiosRequestConfig} from "axios";
import {appId, baseUrl,shopUrl,signUrl} from "@/utils/config";
import StorageUtil from "@/utils/storage_util";
import router from "@/router";
import {notification} from 'ant-design-vue';

interface IRequestResult<T> {
    error_code: number;
    err_code: number;
    msg: string;
    tips: number;
    data?: T;
    code:number
}


class RequestClient {

    // 接口域名
    baseUrl = baseUrl

    constructor(url = baseUrl) {
        this.baseUrl = url
    }

    request = ({method = 'GET', params, data, url, headers = {}}: AxiosRequestConfig) => {
        let options: any = {
            "app-id": appId,
            ...headers,
        }
        const token = StorageUtil.getToken()
        if (token) {
            options = {
                ...options,
                "access-token": token
            }
        }
        return axios.request({
            baseURL: this.baseUrl,
            headers: options,
            url,
            method,
            params,
            data,
        }).then(res => {
            if (res.status === 200) {
                if (res.data.error_code === 2003) {
                    // 登录失效
                    notification.destroy()
                    notification.open({
                        message: '提示',
                        description: '登录过期，请重新登录',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    router.replace('/login').then()
                    return null
                }
                return res.data
            }
            if (res.status === 500) {
                if (res.data.error_code === 2003) {
                    // 登录失效
                    notification.destroy()
                    notification.open({
                        message: '提示',
                        description: '登录过期，请重新登录',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                    router.replace('/login').then()
                    return null
                }
                return res.data
            }
            return null
        }).catch(err => {
            console.log(err)
            return null
        })
    }

    // get方法
    get = async <T>(url: string, params?: any, headers = {}) => {
        if(params){
            // 去除空字符串参数
            for(let key in params){
                if(params[key] === ''){
                    delete params[key]
                }
            }
        }
        const res: IRequestResult<T> = await this.request({method: 'GET', url, params, headers});
        return res;
    }


    //没有过滤掉空值
    get2 = async <T>(url: string, params?: any, headers = {}) => {
        const res: IRequestResult<T> = await this.request({method: 'GET', url, params, headers});
        return res;
    }

    // post方法
    post = async <T>(url: string, data?: any, headers = {}) => {
        const res: IRequestResult<T> = await this.request({method: 'POST', url, data, headers});
        return res
    }
}

// 基础请求类
export const baseRequestClient = new RequestClient(baseUrl)
export const baseRequestClient2 = new RequestClient(shopUrl)
export const baseRequestClient3 = new RequestClient(signUrl)
