
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiCourse from "@/request/apis/api_course";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {courseTypes, ICourseItem} from "@/models/course_model";
import ApiMember from "@/request/apis/api_member";

interface IState {
  list: ICourseItem[],
  total: number;
  loading: boolean;
  teacherData:any,
  category: {id: number, name: string}[]
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '封面图',
    dataIndex: 'thumb',
    key: 'thumb',
  },
  {
    title: '课程名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '讲师',
    dataIndex: 'lecturer',
    key: 'lecturer',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '阅读',
    dataIndex: 'views',
    key: 'views',
    align: 'right',
  },
  {
    title: '收藏',
    dataIndex: 'collects',
    key: 'collects',
    align: 'right',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'right',
    width: 150
  },
  {
    title: '价格',
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    width: 150
  },
  {
    title: '推荐',
    dataIndex: 'recommend',
    key: 'recommend',
    align: 'right',
    width: 150
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
  },
];

export default defineComponent({
  name: 'CourseList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      teacherData:[],
      category: [{id: 1, name: '视频'},{id: 2, name: '音频'},{id: 3, name: '书籍'}], //课程类型(1:视频，2音频，3书籍)
      searches: {
        type: '',
        page: PageUtil.pageNo,
        limit: 15
      }
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = 15
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiCourse.getCourseList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.total || 0
        state.list = res.data?.data || []
      }
    }

    // 删除
    const onDel = async (item: IArticleItem) => {
      const res = await ApiCourse.delCourse(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('删除成功')
          await getList()
          return
        }
        message.error('删除失败')
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticleItem) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/course/newSet', query: params}).then()
    }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/course/section', query: {id: item.id, type: item.type, name: item.title}}).then()
    }

    // 类型
    const renderType = (type: number) => {
      return courseTypes.find(e => e.id === type)?.name;
    }



    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = 15
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    const getTeach = async () => {
      const params={page:1,limit:100}
      const res = await ApiMember.tutorList(params)
      if (res) {
        if (res.error_code === 0) {
          const data:any = res.data
          state.teacherData = data.data
        }
      }
    }

    onMounted(() => {
      initSearch()
      getList()
      getTeach()
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      onDel,
      goDetail,
      pagination,
      getTeach,
      handleTableChange,
      onSearch,
      resetSearch,
      articleTypes,
      renderType,
    }
  }
})
