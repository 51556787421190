
import {computed, defineComponent, nextTick, onMounted, reactive, toRefs} from "vue";
import {baseSocket} from "@/utils/socket";
import ApiUser from "@/request/apis/api_user";
import store from '../store/index'
import {BellOutlined, CloseOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import { appId } from "@/utils/config";

interface IUserItem {
  name: string;
  id: any;
  avatar: string
}

interface IState {
  list: any[];
  value: string;
  chatUsers: IUserItem[]; //聊天所有对象
  chatUserInfo?: IUserItem; //当前聊天对象
  dot: boolean;// 聊天红点
  visible: boolean; //显示隐藏聊天框
  page_no: number;
}

export default defineComponent({
  name: "Chat",
  components: {BellOutlined, CloseOutlined},
  setup() {
    const state = reactive<IState>({
      list: [],
      value: '',
      chatUsers: [],
      chatUserInfo: undefined,
      dot: false,
      visible: false,
      page_no: 1
    })

    const adminUserInfo = computed(() => {
      const user = store.state.userInfo
      return {
        id: user?.id || '',
        name: user?.name || '',
        avatar: user?.avatar || ''
      }
    });

    const setActive = (item: IUserItem) => {
      if (state.chatUserInfo && state.chatUserInfo.id == item.id) {
        return
      }
      state.chatUserInfo = item
      state.page_no = 1
      state.list = []
      getMsgList();
    }

    const initSocket = () => {
      baseSocket.connect(adminUserInfo?.value?.id)
      baseSocket.onMessage(getMsg)
    }

    // 获取聊天记录
    const getMsgList = async () => {
      const res = await ApiUser.getMsgList({
        page_no: state.page_no,
        from: adminUserInfo.value.id,
        to: state.chatUserInfo?.id
      })
      if (res && res.error_code === 0 && res.data && res.data !== '错误') {
        console.log(res.data)
        const history = res.data.data.map((e: any) => {
          return {
            ...e.content,
            chatType: `${e.content.sendId}` === `${adminUserInfo?.value?.id}admin` ? 'right' : 'left'
          }
        }).reverse()
        state.list = history;
        scrollBottom();
      }
    }

    // 滚动到底部
    const scrollBottom = () => {
      if (!state.visible) return
      nextTick(() => {
        const chatCon = document.getElementById('chat_con')
        chatCon!.scrollTop = chatCon!.scrollHeight
      })
    }

    const sendMsg = () => {
      if (!state.chatUserInfo) {
        message.destroy()
        message.warning('请选择客户')
        return
      }
      if(!state.value){
        message.destroy()
        message.warning('请输入内容')
        return
      }
      const data = {
        "type": "private",
        "is_admin": 1,
        "uid": adminUserInfo?.value?.id,
        "content": {
          "message": state.value,
          "msgType": "text",// text,image,video,audio
          // "message": 'http://jjyyimage.ynjjyy.cn/1000618880/uploads/images/20220301145706be2bf8696.jpg',//https://picsum.photos/200
          // "msgType": "image",// text,image,video,audio
          "userType": 'admin',
          "id": new Date().valueOf() + 'admin',
          "chatType": "right",
          "sendId": `${adminUserInfo?.value?.id}admin`
        },
        "to_admin": 0,
        "to": state.chatUserInfo?.id,
        "app_id": appId
      }
      baseSocket.send(data)

      state.value = ''
    }

    const onKeyListen = (e: any) => {
      e.preventDefault()
    }

    // 获取聊天对象信息
    const getUserInfo = async (params: { id: any, key: string }) => {
      const res = await ApiUser.getChatUser({[params.key]: params.id});
      if (res && res.error_code === 0 && res.data) {
        const i = state.chatUsers.findIndex(e => e.id == params.id)
        if (i < 0) {
          // 新的聊天对象
          state.chatUsers = state.chatUsers.concat([res.data])
        } else {
          state.chatUsers[i] = res.data
        }
        if (!state.chatUserInfo) {
          state.chatUserInfo = res.data
          state.page_no = 1
          await getMsgList();
        }
        localStorage.setItem('charts', JSON.stringify(state.chatUsers))
      }
    }

    // 获取消息
    const getMsg = (data: any) => {
      if (data) {
        state.dot = !state.visible;
        if (data.sendId.includes('user')) {
          const userId = data.sendId.replace('user', '')
          if (data.userType === 'driver') {
            // 司机
            getUserInfo({id: userId, key: 'courier_id'});
          }
          if (data.userType === 'user') {
            // 用户
            getUserInfo({id: userId, key: 'user_id'});
          }

          if (userId == state.chatUserInfo?.id || !state.chatUserInfo) {
            const msg: any = {

              ...data,
              chatType: `${data.sendId}` === `${adminUserInfo?.value?.id}admin` ? 'right' : 'left'
            }
            state.list = state.list.concat([msg])
          }
        }
        if (data.sendId.includes('admin')) {
          const msg: any = {
            ...data,
            chatType: `${data.sendId}` === `${adminUserInfo?.value?.id}admin` ? 'right' : 'left'
          }
          state.list = state.list.concat([msg])
        }
        scrollBottom();
      }
      // userInfo
    }

    const setVisible = () => {
      state.visible = !state.visible
      state.dot = false
      if (state.visible) {
        scrollBottom()
      }
    }

    // 删除聊天
    const onDelChat = (item: IUserItem) => {
      try {
        const charts = localStorage.getItem('charts')
        if (charts) {
          const chartList: IUserItem[] = JSON.parse(charts)
          const newChatList = chartList.filter(e => e.id != item.id) // 余下的聊天列表
          if(newChatList.length){// 还有聊天列表
            // 删除的是当前聊天
            if(state.chatUserInfo?.id === item.id){
              setActive(newChatList[0])
            }
            localStorage.setItem('charts', JSON.stringify(newChatList))
          }else{// 聊天列表未空
            state.chatUserInfo = undefined
            localStorage.removeItem('charts')
            state.list = []
            state.visible = false
          }
          state.chatUsers = newChatList
        }
      } catch (e) {

      }
    }

    onMounted(() => {
      initSocket();
      try {
        const charts = localStorage.getItem('charts')
        if (charts) {
          const chartList: IUserItem[] = JSON.parse(charts)
          state.chatUsers = chartList
          setActive(chartList[0]);
        }
      } catch (e) {

      }
    })

    return {
      ...toRefs(state),
      setActive,
      sendMsg,
      adminUserInfo,
      setVisible,
      onKeyListen,
      onDelChat,
    }
  }
})
