
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import apiMember from "@/request/apis/api_member";

    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        xData: any,
        yData: any,
        echarts_add:any,
        echarts_count:any,
        echarts_order_amount_add:any,
        echarts_order_num_add:any
    }


    export default defineComponent({
        name: 'Home',
        components: {UserOutlined},
        setup() {
            let state = reactive({
                appId: appId,
                xData: [] as any [],
                echarts_add: [] as any [],
                echarts_count: [] as any [],
                echarts_order_amount_add: [] as any [],
                echarts_order_num_add: [] as any [],
            })


            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                myChart.setOption({
                    title: {
                        text: '交易分析',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        splitLine: {
                            show: true
                        },
                        axisTick: {
                            "show": false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '70',
                        top: '15%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "数量",
                            data: state.echarts_count,
                            type: 'line',
                            smooth:true,
                        },
                        {
                            name: "订单数量",
                            data: state.echarts_order_num_add,
                            type: 'line',
                            smooth:true,
                        },
                        {
                            name: "订单金额",
                            data: state.echarts_order_amount_add,
                            type: 'line',
                            smooth:true,
                        },
                    ]
                });


            };
            const getdata = async () => {
                const params = {}
                const res = await apiMember.StatisticsTrading(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.xData = data.days
                    state.echarts_count = data.echarts_count
                    state.echarts_order_amount_add = data.echarts_order_amount_add
                    state.echarts_order_num_add = data.echarts_order_num_add
                    initT()
                }
            }
            onMounted(() => {
                getdata()
            })

            return {
                ...toRefs(state),
                appTitle,
                myRef,

            }
        }
    });
