import {baseRequestClient} from "@/request/request_client";
import {IArticleCategory, IArticleDetail, IArticleItem, IArticleUpdate} from "@/models/article_model";
import {IList} from "@/models/base_model";
import {ICourseItem, ISectionItem} from "@/models/course_model";

const requestClient = baseRequestClient

// 文章
export default class ApiCourse {
    // GET 获取课程列表
    static getCourseList = async (params: any) => {
        return await requestClient.get<IList<ICourseItem>>('admin/party.course/list', params)
    }

    // POST 新增课程
    static createCourse = async (data: any) => {
        return await requestClient.post('admin/party.course/create', data)
    }

    // POST 编辑课程
    static editCourse = async (data: any) => {
        return await requestClient.post('admin/party.course/edit', data)
    }

    // GET 获取资讯详情
    static getCourseDetail = async (course_id: any) => {
        return await requestClient.get<ICourseItem>('/admin/party.course/detail', {course_id})
    }

    // POST 删除课程
    static delCourse = async (course_id: any) => {
        return await requestClient.post('/admin/party.course/del', {course_id})
    }

    // GET 获取课程章节列表
    static getSectionList = async (params: any) => {
        return await requestClient.get<ISectionItem[]>('/admin/party.course/section', params)
    }

    // POST 新增课程章节
    static createSection = async (data: any) => {
        return await requestClient.post('/admin/party.course/section_add', data)
    }

    // POST 编辑课程章节
    static editSection = async (data: any) => {
        return await requestClient.post('/admin/party.course/section_edit', data)
    }

    // GET 获取课程章节详情
    static getSectionDetail = async (course_id: any, section_id: any) => {
        return await requestClient.get<ISectionItem>('/admin/party.course/section_detail', {course_id, section_id})
    }


    // POST 删除课程章节详情
    static delSection = async (course_id: any, section_id: any) => {
        return await requestClient.post('admin/party.course/section_del', {course_id, section_id})
    }

}