import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, { title: "拼团记录" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          dataSource: _ctx.list,
          columns: _ctx.columns,
          bordered: "",
          pagination: _ctx.pagination,
          onChange: _ctx.handleTableChange,
          style: {"margin-top":"10px"}
        }, {
          bodyCell: _withCtx(({ column, text, record }) => [
            (column.key === 'name')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(record.goods_snap.name), 1))
              : _createCommentVNode("", true),
            (column.key === 'image')
              ? (_openBlock(), _createBlock(_component_a_image, {
                  key: 1,
                  src: record.goods_snap.image,
                  style: {"height":"50px"}
                }, null, 8, ["src"]))
              : _createCommentVNode("", true),
            (column.key === 'price')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(record.goods_snap.price), 1))
              : _createCommentVNode("", true),
            (column.key === 'team_price')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(record.goods_snap.team_price), 1))
              : _createCommentVNode("", true),
            (column.key === 'spec_value_str')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(record.goods_snap.spec_value_str), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "pagination", "onChange"])
      ]),
      _: 1
    })
  ]))
}