
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user"
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    import dayjs from "dayjs";
    import router from "@/router";
    import moment from "moment"
    interface IState {
        list: any,
        searches: any,
        searches2: any,
        filterOption: any,
        total: any,
        visible: any,
        visible2: any,
        visible3: any,
        title: any,
        formState: any,
        type: any,
        goodList: any,
        recordData:any,
        detailData:any,
        count2:any,
        total2:any,
        total3:any,
        goods_name:any,
        page3:any,
        showtype:any,
        multipleSelection:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list: [],
                goods_name:'',
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ''
                },
                searches2: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ''
                },
                total: 0,
                title: '',
                goodList: [],
                visible: false,
                visible2: false,
                visible3: false,
                total3:0,
                page3:1,
                filterOption: [],
                showtype:1,
                recordData:[],
                multipleSelection:[],
                detailData:'',
                count2:[],
                formState: {
                    name: '',
                    money: '',
                    shop_id: '',
                    type: '',
                    send_time_start: '',
                    send_time_end: '',
                    send_total_type: '',
                    send_total: '',
                    condition_type: '',
                    condition_money: '',
                    use_time_type: '',
                    use_time_start: '',
                    use_time_end: '',
                    use_time: '',
                    get_type: '',
                    get_num_type: '',
                    get_num: '',
                    use_goods_type: 2,
                    status: '',
                    goods_ids: [],
                    day_get_num: "",
                    tomorrow_use_time: ''
                },
                type: '',
                total2:0,
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center'
                },
                {
                    title: '优惠券名称',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'left',
                    width: 200
                },
                {
                    title: '金额',
                    dataIndex: 'money',
                    key: 'money',
                    align: 'left',
                    width: 100
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'left',
                    width: 200
                },
                {
                    title: '总量类型',
                    key: 'send_total_type_desc',
                    dataIndex: 'send_total_type_desc',
                    align: 'left',
                    width: 200
                },
                {
                    title: '限制张数',
                    key: 'send_total',
                    dataIndex: 'send_total',
                    align: 'left',
                    width: 200
                },
                {
                    title: '使用条件',
                    key: 'condition_type_desc',
                    dataIndex: 'condition_type_desc',
                    align: 'left',
                    width: 200
                },
                {
                    title: '用券时间',
                    key: 'use_time',
                    dataIndex: 'use_time',
                    align: 'left',
                    width: 200
                },
                {
                    title: '使用时间',
                    key: 'use_time_desc',
                    dataIndex: 'use_time_desc',
                    align: 'left',
                    width: 400
                },
                {
                    title: '几天可用',
                    key: 'use_time',
                    dataIndex: 'use_time',
                    align: 'left',
                    width: 100
                },
                {
                    title: '领取类型',
                    key: 'get_type_desc',
                    dataIndex: 'get_type_desc',
                    align: 'left',
                    width: 100
                },
                {
                    title: '领取次数',
                    key: 'get_num_type',
                    dataIndex: 'get_num_type',
                    align: 'left',
                    width: 200
                },
                {
                    title: '限制次数',
                    key: 'get_num',
                    dataIndex: 'get_num',
                    align: 'left',
                    width: 200
                },
                {
                    title: '适用商品',
                    key: 'use_goods_type_desc',
                    dataIndex: 'use_goods_type_desc',
                    align: 'left',
                    width: 300
                },
                {
                    title: '状态',
                    key: 'status',
                    dataIndex: 'status',
                    align: 'left',
                    width: 100
                },
                {
                    title: '领券时间',
                    key: 'send_time',
                    dataIndex: 'send_time',
                    align: 'left',
                    width: 400
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 200,
                    fixed:"right"
                },
            ];

            const columns3 = [
                {
                    title: '商品信息',
                    dataIndex: 'goods',
                    key: 'goods',
                    width:550
                },
                {
                    title: '商家信息',
                    dataIndex: 'shop',
                    key: 'shop',
                    width:300
                },
                {
                    title: '销售数据',
                    dataIndex: 'sale',
                    key: 'sale',
                    width: 300
                },
                {
                    title: '审核说明',
                    key: 'tags',
                    dataIndex: 'tags',
                    width: 400
                },
            ];

            const columns2=[
                {
                    title: '优惠券id',
                    key: 'coupon_id',
                    dataIndex: 'coupon_id',
                    align: 'center'
                },
                {
                    title: '优惠券编码',
                    key: 'coupon_code',
                    dataIndex: 'coupon_code',
                    align: 'center'
                },
                {
                    title: 'sn',
                    key: 'sn',
                    dataIndex: 'sn',
                    align: 'center'
                },
                {
                    title: '领取人',
                    key: 'nickname',
                    dataIndex: 'nickname',
                    align: 'center'
                },
                {
                    title: '领取人手机号',
                    key: 'mobile',
                    dataIndex: 'mobile',
                    align: 'center'
                },
                {
                    title: '领取人头像',
                    key: 'avatar',
                    dataIndex: 'avatar',
                    align: 'center'
                },
                {
                    title: '是否使用',
                    key: 'status_desc',
                    dataIndex: 'status_desc',
                    align: 'center'
                },
            ]

            const rules  = {
                name: [{required: true, message: "优惠券名称"}],
                money: [{required: true, message: "优惠券面额"}],
                type: [{required: true, message: "类型"}],
                send_time_start: [{required: true, message: "优惠券发放开始时间"}],
                send_time_end: [{required: true, message: "优惠券发放结束时间"}],
                send_total_type: [{required: true, message: "发送总量类型"}],
                send_total: [{required: true, message: "发送限制张数"}],
                condition_type: [{required: true, message: "使用条件类型"}],
                condition_money: [{required: true, message: "使用条件"}],
                use_time_type: [{required: true, message: "用券时间类型"}],
                use_time_start: [{required: true, message: "使用开始时间"}],
                use_time_end: [{required: true, message: "使用截止时间"}],
                use_time: [{required: true, message: "领取后多少天内可用"}],
                get_type: [{required: true, message: "领取类型"}],
                get_num_type: [{required: true, message: "领取次数类型"}],
                get_num: [{required: true, message: "领取限制次数"}],
                use_goods_type: [{required: true, message: "适用商品类型"}],
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const pagination3 = computed(() => ({
                total: state.total3,
                current: state.page3,
                pageSize: 10,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const formRef = ref();
            const pagination2 = computed(() => ({
                total: state.total2,
                current: state.searches2.page,
                pageSize: state.searches2.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiMember.ShopCouponList(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.list
                    state.total = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const getRecord = async (val) => {
                state.searches2.id = val.id
                const params = {...state.searches2}
                const res = await ApiMember.ShopCouponRecord(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.recordData = data.lists
                    state.total2 = data.count
                    state.visible2 = true
                } else {
                    message.error(res.msg)
                }
            }

            const getDetail = async (val) => {
                const params = {id:val.id}
                const res = await ApiMember.ShopCouponDetail(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.detailData = data
                    state.visible3 = true
                } else {
                    message.error(res.msg)
                }
            }

            const getGoods = async () => {
                const params = {page_no: state.page3, page_size: 10, type: '1',goods_name:state.goods_name}
                const res = await ApiUser.getGoodList(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.goodList = data.lists
                    state.total3 = data.count
                } else {
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const handleTableChange2= (pages: any) => {
                state.searches2.limit = pages.pageSize
                state.searches2.page = pages.current
                getRecord(state.searches2)
            };
            const handleTableChange3= (pages: any) => {
                state.page3 = pages.current
                getGoods()
            };

            const addApply = () => {
                router.push({
                    path: '/addCoupon',
                    query: {type:1}
                })
            }
            const edit = (val) => {
                    router.push({
                        path: '/addCoupon',
                        query: {detail: JSON.stringify(val),type:2}
                    })
            }

            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const onDel = async (item) => {
                const params = {id: item.id}
                const res = await apiMember.CategoryDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }


            const onSelect = (val1,val2) =>{
                state.multipleSelection = val1;
                state.formState.goods_ids = state.multipleSelection
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                uploadHeaders,
                uploadAction,
                onSearch,
                onSelect,
                getGoods,
                onReset,
                uploadFormData,
                pagination,
                columns,
                edit,
                onDel,
                formRef,
                handleChange,
                dayjs,
                addApply,
                getRecord,
                handleTableChange,
                pagination2,
                columns2,
                handleTableChange2,
                getDetail,
                columns3,
                pagination3,
                handleTableChange3,
                rules
            }
        }
    })
