import {appId, isDev} from "@/utils/config";

class SocketService {
    // 和服务端连接的socket对象
    ws: any = null;
    // 存储回调函数
    // 标识是否连接成功
    connected = false;
    // 记录重试的次数
    sendRetryCount = 0;
    // 重新连接尝试的次数
    connectRetryCount = 0;

    //  定义连接服务器的方法
    connect = (uid: any) => {
        if(this.connected){
            return 
        }
        // 连接服务器
        if (!window.WebSocket) {
            return console.log('您的浏览器不支持WebSocket');
        }

        // let url = 'wss://api.yngxccwl.com/wss';
        // let url = isDev ? 'ws://47.108.170.70:2348' : 'wss://api.yngxccwl.com/wss';
        let url = isDev ? 'ws://47.108.170.70:2348' : 'wss://www.ynjgyy.cn/wss';
        this.ws = new WebSocket(url);
        // 连接成功的事件
        this.ws.onopen = () => {
            console.log('连接服务端成功了');
            this.send({
                "type": "login",
                "is_admin": 1, // 0是用户1是管理员
                "uid": uid, //用户id
                "app_id": appId
            })
            this.connected = true;
            // 重置重新连接的次数
            this.connectRetryCount = 0;
        };
        // 1.连接服务端失败
        // 2.当连接成功之后, 服务器关闭的情况
        this.ws.onclose = () => {
            console.log('连接服务端失败');
            this.connected = false;
            this.connectRetryCount++;
            setTimeout(() => {
                this.connect(uid);
            }, 500 * this.connectRetryCount);
        };
        // 接收数据
        // this.ws.onmessage = (msg: any) => {
        //     try {
        //         const data = msg.data ? JSON.parse(msg.data) : null
        //         if(['login', 'message', 'ping'].includes(data.type)){
        //             return
        //         }
        //         console.log(data)
        //         getMsg(data)
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };
    }

    // 接收数据
    onMessage = (getMsg: (data: any) => void) => {
        if(this.ws){
            this.ws.onmessage = (msg: any) => {
                try {
                    const data = msg.data ? JSON.parse(msg.data) : null
                    if(['login', 'message', 'ping'].includes(data.type)){
                        return
                    }
                    getMsg(data)
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }

    // 发送数据的方法
    send(data: any) {
        // 判断此时此刻有没有连接成功
        if (this.connected) {
            this.sendRetryCount = 0;
            try {
                this.ws.send(JSON.stringify(data));
            } catch (e) {
                this.ws.send(data);
            }
        } else {
            this.sendRetryCount++;
            setTimeout(() => {
                this.send(data);
            }, this.sendRetryCount * 500);
        }
    }

    // 销毁
    destroy(){
        if(this.connected){
            this.ws.onclose()
            this.connected = false
            this.connectRetryCount = 0
            this.sendRetryCount = 0
        }
    }
}

export const baseSocket = new SocketService()
