
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import dayjs, {Dayjs} from 'dayjs';
    import apiMember from "@/request/apis/api_member";

    interface IState {
        list: any,
        name: any,
        searches: any,
        visible: any,
        editForm: any
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '修改时间',
            dataIndex: 'update_time',
            key: 'update_time',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 350,
            fixed: 'right'
        },
    ]

    export default ({
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    name: ""
                },
                visible: false,
                editForm: {
                    id: "",
                    name: ""
                },
                name: "",
            })

            const addIndustry = async () => {
                const params = {name: state.name}
                const res = await apiMember.industryAdd(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    state.name = ""
                    await getList()
                }
            }

            const getList = async () => {
                const params = {page: 1, limit: 100, name: state.searches.name}
                const res = await apiMember.industryLists(params)
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.list = data.lists
                }
            }

            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await apiMember.industryDel(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    await getList()

                }
            }

            const handleOk = async (val) => {
                const params = {id: state.editForm.id, name: state.editForm.name}
                const res = await apiMember.industryEdit(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    state.visible = false
                    getList()
                }
            }

            const setSortEditItem = (val) => {
                state.editForm.id = val.id
                state.editForm.name = val.name
                state.visible = true
            }
            const resetSearch = () => {
                state.searches.name = ""
                getList()
            }


            onMounted(() => {
                // initSearch()
                getList()
            })

            return {
                ...toRefs(state),
                addIndustry,
                getList,
                columns,
                resetSearch,
                onDel,
                handleOk,
                setSortEditItem
            }
        }
    })
