import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8466707e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "mycharts",
  ref: "myRef",
  class: "chart-box",
  style: {"width":"100%","height":"650px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, {
      title: _ctx.访问分析 
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, null, 512)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}