
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import EditorComponent from '@/components/editor/Editor.vue'

import {
  IBannerPositionItem,
  linkTypes,
  terminalTypes,
} from "@/models/banner_model"
import ApiBanner from "@/request/apis/api_banner"
import ApiSetting from "@/request/apis/api_setting";
import {IDictionaryItem} from "@/models/setting_model";
import {IMemberUpdate, IRoleItem} from "@/models/party_model";
import ApiParty from "@/request/apis/api_party";
import {menuText} from "@/utils/config";

interface IState {
  roles: IRoleItem[]
  loading: boolean,
  pictureExtra: string//图片建议
}

// 新增编辑文章
export default defineComponent({
  name: "MemberSet",
  components: {UploadComponent, EditorComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any
    const editorComponentRef = ref()
    const formRef = ref()
    const formState: UnwrapRef<IMemberUpdate> = reactive({
      "name": '', //姓名
      "phone": '', //电话
      "role_id": [], //角色id
      "gender": 0, //性别 0未知 1男 2女
      "avatar": '', //头像
      "introduction": ''
    })

    // 校验
    const rules = {
      name: [{required: true, message: "请输入姓名"}],
      mobile: [{required: false, message: "请输入手机号"}],
      url: [{required: true, message: "请输入跳转链接"}],
      pid: [{required: true, message: "请选择广告位"}],
      image: [{required: true, message: "请上传图片"}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            params.introduction = editorComponentRef.value.getHtmlText()
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: string) => (formState.avatar = url)

    const state: IState = reactive({
      roles: [],
      loading: false,
      pictureExtra: ''
    })


    //获取角色
    const getRoleList = async () => {
      let params = {
        page_no: 1,
        page_size: 100,
      }
      state.loading = true
      const res = await ApiParty.getRoleList(params)
      state.loading = false
      console.log(res.data)
      if (res && res.error_code === 0) {
        state.roles = res.data?.data || []
      }
    }

    // 获取详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiParty.getMemberDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          console.log(data)
          formState.name = data.name
          formState.phone = data.phone //电话
          formState.role_id = data.roles.map(e => e.id) //角色id
          formState.gender = data.gender //性别 0未知 1男 2女
          formState.avatar = data.avatar //头像
          formState.introduction = data.introduction
        }
      }
    }

    // 新增
    const onAdd = async (params: IMemberUpdate) => {
      state.loading = true
      const res = await ApiParty.addMember(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: IMemberUpdate) => {
      state.loading = true
      const res = await ApiParty.editMember(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(async () => {
      await getRoleList()
      await getDetail()
    })

    // select搜索
    const filterOption = (input: string, option: any) => {
      console.log(input)
      console.log(option)
      return option.label.includes(input)
    };

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      editorComponentRef,
      onSubmit,
      resetForm,
      onImageChange,
      goBack,
      filterOption,
      terminalTypes,
      linkTypes,
      menuText,
      ...toRefs(state),
    }
  },
})
