
    import { onMounted, reactive, toRefs, computed, ref } from "vue";
    import EditorComponent from "@/components/editor/Editor.vue";
    import ApiMember from "@/request/apis/api_member";
    import { message } from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import { baseUrl } from "@/utils/config";
    interface IState {
        list: any;
        areaList:any;
        searches: any;
        total: any;
        visible: any;
        title: any;
        visible2:boolean;
        formState: any;
        type: any;
        typeList: any;
        suppose:any;
        examId:any;
        orderData:any
    }

    export default {
        name: "ArticleList",
        components: { EditorComponent },
        setup() {
            const state: IState = reactive({
                list: [],
                suppose:'',
                examId:"",
                orderData:'',
                typeList: [],
                areaList :[],
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    goods_name: "",
                    activity_area:undefined,
                    type: "",
                },
                total: 0,
                title: "",
                visible: false,
                visible2:false,
                formState: {},
                type: "",
            });

            const columns = [
                {
                    title: "ID",
                    dataIndex: "id",
                    key: "id",
                    align: "center",
                },
                {
                    title: "专区名称",
                    dataIndex: "activity_area_name",
                    key: "activity_area_name",
                    align: "center",
                },
                {
                    title: "商品名称",
                    dataIndex: "name",
                    key: "name",
                    align: "center",
                },
                {
                    title: "封面图",
                    dataIndex: "image",
                    key: "image",
                    align: "center",
                },
                {
                    title: "最高价",
                    dataIndex: "max_price",
                    key: "max_price",
                    align: "center",
                },
                {
                    title: "最低价",
                    dataIndex: "min_price",
                    key: "min_price",
                    align: "center",
                },
                {
                    title: "状态",
                    dataIndex: "audit_status",
                    key: "audit_status",
                    align: "center",
                },
                {
                    title: "审核意见",
                    dataIndex: "audit_remark",
                    key: "audit_remark",
                    align: "center",
                },
                {
                    title: "操作",
                    key: "action",
                    align: 'center',
                    width: 200,
                },
            ];

            const columns2 = [
                {
                    title: "ID",
                    dataIndex: "id",
                    key: "id",
                    align: "center",
                },
                {
                    title: "专区名称",
                    dataIndex: "spec_value_str",
                    key: "spec_value_str",
                    align: "center",
                },
                {
                    title: "图片",
                    dataIndex: "image",
                    key: "image",
                    align: "center",
                },
                {
                    title: "库存",
                    dataIndex: "stock",
                    key: "stock",
                    align: "center",
                },
                {
                    title: "标价",
                    dataIndex: "market_price",
                    key: "market_price",
                    align: "center",
                },
            ];
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async () => {
                const params = { ...state.searches };
                const res = await ApiMember.areaGoodsLists(params);
                if (res.error_code == 0) {
                    const data: any = res.data;
                    state.list = data.lists;
                    state.total = data.count;
                } else {
                    message.error(res.msg);
                }
            };

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize;
                state.searches.page = pages.current;
                getList();
            };


            const formRef = ref();
            const uploadHeaders = UploadUtil.uploadHeaders();

            const onSubmit = async (status) => {
                    const params = {review_status:status,description:state.suppose,id:state.examId };
                    const res = await ApiMember.AreaGoodsAudit(params)
                    if(res.error_code == 0){
                        state.visible = false
                        getType()
                        // getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
            };

            const onDel = async (item) => {
                const params = { id: item.id };
                // const res = await ApiSetting.AreaDel(params)
                // if (res) {
                //     message.destroy()
                //     if (res.error_code === 0) {
                //         message.success(res.msg)
                //         await getList()
                //         return
                //     }
                //     message.error(res.msg)
                // }
            };

            const getType = async () => {
                const res = await ApiMember.activityAreGoodsnum();
                if (res) {
                    if (res.error_code == 0) {
                        state.typeList = res.data;
                        state.searches.type = state.typeList[0].type;
                        getList();
                    }
                }
            };
            const getArea = async () => {
                const params = {page:1,limit:999};
                const res = await ApiMember.AreaLists(params);
                if (res) {
                    if (res.error_code == 0) {
                        const data:any = res.data
                        state.areaList = data.lists;
                    }
                }
            };
            const onSearch = () => {
                state.searches.page = 1;
                state.searches.limit = PageUtil.pageSize;
                getList();
            };
            const onReset = () => {
                state.searches.page = 1;
                state.searches.goods_name = '';
                state.searches.activity_area = undefined;
                state.searches.limit = PageUtil.pageSize;
                getList();
            };
            const tabChange = (val) => {
                state.searches.page = 1;
                state.searches.goods_name = '';
                state.searches.activity_area = undefined;
                state.searches.type = val;
                state.searches.limit = PageUtil.pageSize;
                getList();
            };

            const examine = (val) => {
                state.examId = val.id
                state.suppose =''
                state.visible = true
            }

            const getDetail = async(val) => {
                state.visible2 = true
                const params = {id:val.id}
                const res = await ApiMember.GoodsDetail(params);
                if (res) {
                    if (res.error_code == 0) {
                        const data:any = res.data
                        state.orderData = val
                    }
                }
            }
            onMounted(() => {
                getType();
                getArea();
            });

            return {
                ...toRefs(state),
                getList,
                getDetail,
                examine,
                getType,
                getArea,
                uploadHeaders,
                onSearch,
                onReset,
                pagination,
                columns,
                onDel,
                formRef,
                columns2,
                onSubmit,
                tabChange,
                handleTableChange,
            };
        },
    };
