
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import PageUtil from "@/utils/page_util";
    interface IState {
        visible:any,
        formState:any,
        type:any
    }
    export default {
        setup() {

            const state: IState = reactive({
                visible:false,
                formState:{
                    username: '',
                    password: '',
                    remember: true
                },
                type:''
            });

            const addQuail = () => {
                state.type = 1
                state.visible = true
            }

            return {
                ...toRefs(state),
                addQuail,
                dataSource: [],
                columns: [
                    {title: 'ID', dataIndex: 'id',key: 'id',align:'center'},
                    {title: '质保名称', dataIndex: 'name',key: 'name',align:'center'},
                    {title: '价格', dataIndex: 'name',key: 'name',align:'center'},
                    {title: '内容', dataIndex: 'name',key: 'name',align:'center'},
                    {title: '说明', dataIndex: 'name',key: 'name',align:'center'},
                    {title: '操作', dataIndex: 'action',key: 'action',align:'center'}
                ],
            };
        },
    };
