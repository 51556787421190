
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import ApiLogistics from "@/request/apis/api_logistics";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import {Dayjs} from 'dayjs';
    import countTo from '../components/vue-count-to/vue-countTo.vue';
    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        course_num: any,
        article_num: any,
        xData: any,
        yData: any,
        xData2: any,
        yData2: any,
        article_sum: any,
        course_sum: any,
        cpc_member_sum: any,
        organization_sum: any,
        user_day_sum: any,
        user_sum: any,
        visit_num: any,
        costomser_sum: any,
        costomser_num: any,
        birthData: any
    }
    export default defineComponent({
        name: 'Home',
        components: {UserOutlined, countTo},
        setup() {
            let state = reactive({
                updata_time: "",
                course_num: [],
                appId: appId,
                article_num: [] as any [],
                xData: [] as any [],
                yData: [] as any [],
                xData2: [] as any [],
                yData2: [] as any [],
                birthData: [] as any [],
                article_sum: '',
                course_sum: '',
                cpc_member_sum: '',
                organization_sum: '',
                user_day_sum: '',
                user_sum: '',
                costomser_sum: '',
                visit_num: '',
                costomser_num: [] as any [],
            })
            // @ts-ignore 
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const getDate = () => {
                let dateTime
                let yy = new Date().getFullYear()
                let mm = new Date().getMonth() + 1
                let dd = new Date().getDate()
                let hh = new Date().getHours()
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
                dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
                state.updata_time = dateTime
            }
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                const myChart2 = proxy.$echarts.init(document.getElementById('mycharts2'));
                myChart.setOption({
                    title: {
                        text: '文章数量分类统计图统计',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        splitLine: {
                            show: true
                        },
                        axisTick: {
                            "show": false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            height: 24, //这里可以设置dataZoom的尺寸
                            start: 0,  //初始化时，滑动条宽度开始标度
                            end: 20, //初始化时，滑动条宽度结束标度
                        },
                        {
                            type: "slider", //内置滑动，随鼠标滚轮展示
                            realtime: true,
                            start: 0,
                            end: 20
                        },

                    ],
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '70',
                        top: '15%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "数量",
                            data: state.yData,
                            type: 'bar',
                            barWidth: 50,//固定柱子宽度

                        }
                    ]
                });

                myChart2.setOption({
                    title: {
                        text: '各行业会员数量统计',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: state.xData2,
                        splitLine: {
                            show: true
                        },
                        axisTick: {
                            "show": false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '70',
                        top: '15%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "数量",
                            data: state.yData2,
                            type: 'bar',
                            barWidth: 30,//固定柱子宽度
                        }
                    ]
                });
            };
            const getdata = async () => {
                const params = {}
                const res = await ApiLogistics.HomeIndex(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.article_sum = data.article_sum
                    state.course_sum = data.course_sum
                    state.cpc_member_sum = data.cpc_member_sum
                    state.organization_sum = data.organization_sum
                    state.user_day_sum = data.user_day_sum
                    state.user_sum = data.user_sum
                    state.visit_num = data.visit_num
                    state.costomser_sum = data.costomser_sum
                    state.course_num = data.course_num
                    state.article_num = data.article_num
                    state.article_num = state.article_num.filter((item: any) => {
                        return item.category !== null
                    })

                    state.article_num.forEach((item: any) => {
                        state.yData.push(item.article_num)
                        state.xData.push(item.category.catname)
                    })


                    state.costomser_num = data.costomser_num
                    if (state.costomser_num && state.costomser_num.length > 0) {
                        state.costomser_num = state.costomser_num.filter((item: any) => {
                            return item.Industrydata !== null
                        })
                        state.costomser_num.forEach((item: any) => {
                            state.yData2.push(item.costomser_num)
                            state.xData2.push(item.Industrydata.name)
                        })
                        state.course_num.forEach((item: any, index) => {
                            item.name = item.media_type
                            item.value = item.course_num
                            delete item.media_type
                            delete item.course_num
                        })
                    }
                    initT()
                }
            }

            const getListData = (value: Dayjs) => {
                const data = [
                    {
                        date: 2,
                        data: [{type: 'warning', content: 'This is warning event.'}, {
                            type: 'success',
                            content: 'This is usual event.'
                        }]
                    },
                    {date: 10, data: [{type: 'warning', content: '123'}, {type: 'success', content: '123'}]},
                ]
                data.forEach((item, index) => {
                    switch (value.date()) {
                        case item.date :
                            listData = item.data;
                            break;
                        default:
                    }
                })
                let listData;
                // return listData || [];
            };

            const getMonthData = (value: Dayjs) => {
                if (value.month() === 8) {
                    return 1394;
                }
            };
            onMounted(() => {

                getDate()
                getdata()
            })

            return {
                ...toRefs(state),
                appTitle,
                getMonthData,
                getListData,
                myRef,

            }
        }
    });
