
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import {IArticleItem} from "@/models/article_model";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import ApiBanner from "@/request/apis/api_banner";
    import {IBannerItem, IBannerPositionItem, linkTypes, linkTypesArr, terminalTypes} from "@/models/banner_model";
    import * as dayjs from 'dayjs'
    import StorageUtil from "@/utils/storage_util";
    import ImageView from '@/components/ImageView.vue'
    import PageUtil from "@/utils/page_util";
    import {CheckOutlined} from "@ant-design/icons-vue";

    interface IState {
        list: IBannerItem[],
        total: number;
        loading: boolean;
        position: IBannerPositionItem[]
        searches: any
        editItem?: IBannerItem
    }

    // 表格
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: "center"
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            align: "center"
        },
        {
            title: '选中图标',
            dataIndex: 'selected_icon',
            key: 'selected_icon',
            align: "center"
        },
        {
            title: '未选中图标',
            dataIndex: 'un_selected_icon',
            key: 'un_selected_icon',
            align: "center"
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: "center"
        },
    ];

    export default defineComponent({
        name: 'BannerList',
        components: {ImageView, CheckOutlined},
        setup() {
            const state: IState = reactive({
                list: [],
                total: 0,
                loading: false,
                position: [],
                searches: {
                    terminal: '', //终端
                    pid: '', // 广告位
                    link_type: '', // 链接类型
                    status: '', // 是否开启1,0
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                },
                editItem: undefined
            })

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            // 列表当前页更改
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            //获取列表
            const getList = async () => {
                StorageUtil.setParams(state.searches)
                state.loading = true
                const res = await ApiBanner.bottomNavigation({...state.searches})
                state.loading = false
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.total = data.total
                    state.list = data.lists
                }
            }

            // 获取广告位
            const getBannerPosition = async () => {
                const res = await ApiBanner.getBannerPositionList()
                if (res && res.error_code === 0) {
                    state.position = res.data || []
                }
            }

            // 删除
            const onDel = async (id: number) => {
                const res = await ApiBanner.delBanner(id)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            // 排序
            const onSort = async (sort: any) => {
                if (!state.editItem) return
                const res = await ApiBanner.setBannerSort(state.editItem.id, Number(sort))
                if (res) {
                    state.editItem = undefined
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            // 设置排序item
            const setSortEditItem = (item: IBannerItem) => {
                if (state.editItem && item.id === state.editItem.id) {
                    state.editItem = undefined
                } else {
                    state.editItem = item
                }
            }

            // 显示隐藏
            const setShow = async (item: IBannerItem) => {
                const res = await ApiBanner.showBanner(item.id, Number(!Boolean(item.status)))
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            // 去新增编辑
            const goSet = (item?: IBannerItem) => {
                let params = {}
                if (item) {
                    params = {id: item.id}
                }
                router.push({path: '/banner/set', query: params}).then()
            }
            // 详情
            const goDetail = (item: IArticleItem) => {
                router.push({path: '/article/detail', query: {id: item.id}}).then()
            }

            // 搜索
            const onSearch = (key: string, value: any, e: any) => {
                state.searches[key] = value
                state.searches.page = PageUtil.pageNo
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const resetSearch = () => {
                for (let key in state.searches) {
                    state.searches[key] = ''
                }
                state.searches.page = PageUtil.pageNo
                state.searches.limit = PageUtil.pageSize
                getList()
            }

            const initSearch = () => {
                const res = StorageUtil.getParams()
                if (res) {
                    state.searches = {...state.searches, ...res}
                }
            }

            onMounted(() => {
                initSearch()
                getBannerPosition()
                getList()
            })

            return {
                ...toRefs(state),
                pagination,
                goSet,
                onDel,
                setShow,
                goDetail,
                handleTableChange,
                dayjs,
                onSearch,
                resetSearch,
                onSort,
                setSortEditItem,
                columns,
                linkTypesArr,
                linkTypes,
                terminalTypes,
            }
        }
    })
