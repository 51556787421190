//物流订单状态
export const logisticsOrderStatus = [
    {id: 0, name: '待接单', color: 'pink'},
    {id: 1, name: '待付款', color: 'cyan'},
    {id: 101, name: '已取消', color: 'gray'},
    {id: 102, name: '司机已取消', color: 'gray'},
    {id: 201, name: '待上门', color: 'blue'},
    {id: 202, name: '已上门', color: 'blue'},
    {id: 203, name: '装车中', color: 'blue'},
    {id: 204, name: '已装车', color: 'blue'},
    {id: 301, name: '运输中', color: 'blue'},
    {id: 302, name: '转递', color: 'blue'},
    {id: 303, name: '转投', color: 'blue'},
    {id: 401, name: '投递', color: 'blue'},
    {id: 402, name: '投站', color: 'blue'},
    {id: 403, name: '卸货', color: 'blue'},
    {id: 404, name: '已卸货', color: 'blue'},
    {id: 501, name: '本人签收', color: 'green'},
    {id: 502, name: '投递异常后签收', color: 'green'},
    {id: 503, name: '代签', color: 'green'},
    {id: 504, name: '站签', color: 'green'},
    {id: 505, name: '已完成', color: 'green'},
    {id: 601, name: '已销单', color: 'red'},
    {id: 602, name: '拒签', color: 'red'},
    {id: 603, name: '退回中', color: 'red'},
    {id: 604, name: '已退回', color: 'red'},
    {id: 701, name: '超时未签收', color: 'red'},
    {id: 702, name: '超时未更新', color: 'red'},
    {id: 703, name: '投递异常', color: 'red'},
    {id: 704, name: '超时未取', color: 'red'},
    {id: 705, name: '无法联系', color: 'red'},
    {id: 706, name: '超区', color: 'red'},
    {id: 707, name: '破损', color: 'red'},
    {id: 708, name: '丢件', color: 'red'},
]

export const getLogisticsOrderStatus = (status: number) => logisticsOrderStatus.find(e => e.id === status)

// 支付状态
export const payStatus = [
    {id: 0, name: '待付款', color: 'red'},
    {id: 1, name: '已付款', color: 'green'},
    {id: 2, name: '已退款', color: 'orange'}
]

export const getPayStatus = (status: number) => payStatus.find(e => e.id === status)

// 支付方式
export const payMethod = [
    {id: 1, name: '寄付现结', color: 'red'},
    {id: 2, name: '到付', color: 'green'},
]

export const getPayMethod = (status: number) => payMethod.find(e => e.id === status)

// 寄件方式
export const shipMethod = [
    {id: 1, name: '自送', color: 'red'},
    {id: 2, name: '上门取货', color: 'green'},
]

export const getShipMethod = (status: number) => shipMethod.find(e => e.id === status)

// 订单模式
export const orderMode = [
    {id: 1, name: '报单', color: 'red'},
    {id: 2, name: '抢单', color: 'green'},
]

export const getOrderMode = (status: number) => orderMode.find(e => e.id === status)

// 余额变动类型 1订单收入 2提现申请 3管理员添加 4管理员扣减 5提现失败返还 6投诉理赔
export const balanceType = [
    {id: 1, name: '订单收入', color: 'red'},
    {id: 2, name: '提现申请', color: 'red'},
    {id: 3, name: '管理员添加', color: 'red'},
    {id: 4, name: '管理员扣减', color: 'red'},
    {id: 5, name: '提现失败返还', color: 'red'},
    {id: 6, name: '投诉理赔', color: 'red'},
]

export const getBalanceType = (type: number) => balanceType.find(e => e.id === type)

