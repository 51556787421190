import {baseRequestClient} from "@/request/request_client";
import {IMemberDetail, IMemberItem, IMemberUpdate, IOrganizationItem, IRoleItem} from "@/models/party_model";

const requestClient = baseRequestClient


export default class apiTalent {

    static optionList = async (data: any) => {
        return await requestClient.get('/admin/activity.option/index', data)
    }
    static optionAdd = async (data: any) => {
        return await requestClient.post('/admin/activity.option/store', data)
    }
    static optionDestroy = async (data: any) => {
        return await requestClient.post('/admin/activity.option/destroy', data)
    }
    static templateStore = async (data: any) => {
        return await requestClient.post('/admin/activity.template/store', data)
    }
    static templateIndex = async (data: any) => {
        return await requestClient.get('/admin/activity.template/index', data)
    }
    static templateDestroy = async (data: any) => {
        return await requestClient.post('/admin/activity.template/destroy', data)
    }
    static disableAndEnable = async (data: any) => {
        return await requestClient.post('/admin/activity.template/disableAndEnable', data)
    }
    static activityStore = async (data: any) => {
        return await requestClient.post('/admin/activity.activity/store', data)
    }
    static activityIndex = async (data: any) => {
        return await requestClient.get('/admin/activity.activity/index', data)
    }
    static activitydestroy = async (data: any) => {
        return await requestClient.post('/admin/activity.activity/destroy', data)
    }
    static activityDisableAndEnable = async (data: any) => {
        return await requestClient.post('/admin/activity.activity/disableAndEnable', data)
    }
    static activityShow = async (data: any) => {
        return await requestClient.get('/admin/activity.activity/show', data)
    }
    static articleForm = async (data: any) => {
        return await requestClient.post('/admin/party.article/form', data)
    }
    static tagsStore = async (data: any) => {
        return await requestClient.post('/admin/tags.tags/store', data)
    }
    static tagsIndex = async (data: any) => {
        return await requestClient.get('/admin/tags.tags/index', data)
    }
    static tagdisableAndEnable = async (data: any) => {
        return await requestClient.post('/admin/tags.tags/disableAndEnable', data)
    }
    static liveList = async (data: any) => {
        return await requestClient.get('/admin/lv.live/list', data)
    }
    static liveDisable = async (data: any) => {
        return await requestClient.post('/admin/lv.live/disable', data)
    }
    static liveAble = async (data: any) => {
        return await requestClient.post('/admin/lv.live/enable', data)
    }
    }