
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    interface IState {
        info:any,
        content:any,
        data:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                info:'',
                content:'',
                enclosure: [],
                data:''
            })
            const editorComponentRef = ref()

            const getData = async() => {
                const res = await ApiMember.TreatyIndex()
                if(res.error_code == 0){
                    const data:any = res.data
                    state.data = data
                }else{
                    message.error(res.msg)
                }
                // editorComponentRef.value.getHtmlText()
                // editorComponentRef.value.getExtraFile()
            }

            const updateAgrement = async() =>{
                state.data.content = editorComponentRef.value.getHtmlText()
                const res = await ApiMember.TreatyIndex2(state.data)
                if(res.error_code == 0){
                    message.success("修改成功")
                    state.data.content = ''
                    // getData()
                }else{
                    message.error(res.msg)
                }
            }
            onMounted(() => {
                getData()
            })

            return {
                ...toRefs(state),
                getData,
                updateAgrement,
                editorComponentRef
            }
        }
    })
