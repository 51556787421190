
// 设置banner
import {defineComponent, reactive, ref, toRaw, toRefs, watch} from 'vue';
import type {UnwrapRef} from 'vue';
import ApiBanner from "@/request/apis/api_banner";
import {message} from "ant-design-vue/es";
import UploadComponent from "@/components/UploadComponent.vue";
import {IArticleCategory} from "@/models/article_model";
import {IBannerPositionUpdate, terminalTypes} from "@/models/banner_model";

interface IState {
  loading: boolean;
  editItem?: IArticleCategory;
}

export default defineComponent({
  props: ['editItem', 'reset'],
  name: "BannerPositionSet",
  components: {UploadComponent},
  setup(props) {
    const state: IState = reactive({
      loading: false,
      editItem: props.editItem,
    })

    const formRef = ref();
    const formState: UnwrapRef<IBannerPositionUpdate> = reactive({
      name: '',
      attr: 1,
      size: '',
      terminal: 1,
      tag: ''
    });


    watch(() => props.editItem, (newValue, oldValue) => {
      state.editItem = props.editItem
      formState.name = newValue?.name || '';
      formState.subtitle = newValue?.subtitle || '';
      formState.attr = newValue?.attr || 1;
      formState.size = newValue?.size || '';
      formState.terminal = newValue?.terminal || 1
      formState.tag = newValue?.tag || ''
    })

    const rules = {
      name: [{required: true, message: '请输入广告位名称'}],
      size: [{required: true, message: '请输入广告位尺寸（如：480*320）'}],
      tag: [{required: true, message: '请输入广告位标签'}],
    };

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            if (props.editItem) {
              onEdit()
            } else {
              onAdd()
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      if (props.reset) {
        props.reset()
      }
    };

    // 添加
    const onAdd = async () => {
      state.loading = true
      const res = await ApiBanner.addBannerPosition(toRaw(formState))
      state.loading = false
      if (res) {
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async () => {
      state.loading = true
      const res = await ApiBanner.editBannerPosition({ ...toRaw(formState), id: props.editItem.id})
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('编辑分类成功')
          resetForm()
          return
        }
        message.error(res.msg)
      }
    }

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 16},
      other: '',
      terminalTypes,
      formState,
      rules,
      onSubmit,
      resetForm,
      onAdd,
      onEdit,
    }
  }
})
