
    import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
    import ApiSetting from "@/request/apis/api_setting";
    import {message} from "ant-design-vue/es";
    interface IState {
        value:any
    }


    export default defineComponent({
        name: "DriverList",
        setup() {
            const state: IState = reactive({
                value:""
            });

            const submit = async () => {
                const params = {payment_limit_time:state.value}
                const res = await ApiSetting.BargainSet(params);
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            }


            onMounted(() => {

            });

            return {
                ...toRefs(state),
                submit
            };
        },
    });
