
    import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue';
    import apiSetting from "@/request/apis/api_setting";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import EditorComponent from '@/components/editor/Editor.vue'
    interface IState {
        list: any,
        visible: any,
        modaltitle: any,
        name: any,
        total: any,
        searches: any,
        editId: any,
        type: any,
        is_show:any,
        formState:any,
        typelist:any,
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {EditorComponent},
        setup() {
            let state: IState = reactive({
                visible: false,
                modaltitle: '',
                name: '',
                list: [],
                total: 0,
                searches: {
                    keywords: '',
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                },
                editId: '',
                type: '',
                is_show:undefined,
                typelist:[],
                formState: {
                    "cid":  undefined,
                    "title": "",
                    "image": "",
                    "content": "",
                    "visit": "",
                    "likes": "",
                    "sort": "",
                    "is_notice":  undefined,
                    "is_show": undefined
                }
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '内容',
                    dataIndex: 'content',
                    key: 'content',
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '图片',
                    dataIndex: 'image',
                    key: 'image',
                },
                {
                    title: '状态',
                    dataIndex: 'is_show',
                    key: 'is_show',
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                },
                {
                    title: '分类',
                    dataIndex: 'category',
                    key: 'category',
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '最后修改时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 200
                },
            ]

            const rules = {
                title: [{required: true, message: "请输入文章标题"},],
                image: [{required: false, message: "请上传图片"},],
                content: [{required: true, message: "请输入文章内容"},],
                sort: [{required: true, message: "请输入文章排序"},],
                is_show: [{required: false, message: "请选择"},],
                cid: [{required: true, message: "请选择"},],
                is_notice: [{required: false, message: "请选择"},],
            }
            const formRef = ref()
            const add = () => {
                state.type = 1
                state.modaltitle = '新增帮助文章'
                state.formState={
                    "cid":  undefined,
                        "title": "",
                        "image": "",
                        "content": "",
                        "visit": "",
                        "likes": "",
                        "sort": "",
                        "is_notice":  undefined,
                        "is_show": undefined
                }
                // state.fileList =[]
                state.visible = true
            }

            const edit = (val) => {
                state.type = 2
                state.modaltitle = '编辑帮助文章'
                state.formState={
                    "id":val.id,
                    "cid": val.cid,
                    "title": val.title,
                    "image": val.image,
                    "content":val.content,
                    "visit": val.visit,
                    "likes": val.likes,
                    "sort": val.sort,
                    "is_notice": val.is_notice,
                    "is_show": val.is_show
                }
                state.visible = true
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiSetting.HelpLists(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                }
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    const params = {...state.formState}
                    params.content = editorComponentRef.value.getHtmlText()
                    const res = await apiSetting.HelpAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }

                if (state.type == 2) {
                    const params = {...state.formState}
                    params.content = editorComponentRef.value.getHtmlText()
                    const res = await apiSetting.HelpEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    } else {
                        message.error(res.msg)
                    }
                }
            }


            const onDel = async (val, val2) => {
                const params = {id: val.id}
                const res = await apiSetting.HelpHide(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onDelete = async (val, val2) => {
                const params = {id: val.id}
                const res = await apiSetting.HelpDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                getList()
            }

            const onReset = () => {
                state.searches.limit = PageUtil.pageSize
                state.searches.page = 1
                state.searches.keywords=""
                getList()
            }

            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.formState.image = data.uri
                    }
                }
            }

            const getType = async () => {
                const params = {page:1,limit:99999}
                const res = await apiSetting.HelpCategoryLists(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.typelist = data.lists
                }
            }
            const editorComponentRef = ref()
            onMounted(() => {
                getList()
                getType()
            })

            return {
                ...toRefs(state),
                columns,
                handleOk,
                formRef,
                uploadHeaders,
                getType,
                editorComponentRef,
                uploadAction,
                uploadFormData,
                handleChange,
                rules,
                onDelete,
                onSearch,
                onReset,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
