
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import router from "@/router";
    import {message} from "ant-design-vue/es";
    import dayjs, {Dayjs} from 'dayjs';
    import apiMember from "@/request/apis/api_member";

    interface IState {
        list: any,
        name: any,
        searches: any,
        visible: any,
        editForm: any,
        formState: any,
        visible2: any
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '等级',
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '修改时间',
            dataIndex: 'update_time',
            key: 'update_time',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 350,
            fixed: 'right'
        },
    ]

    export default ({
        setup() {
            const state: IState = reactive({
                list: [],
                searches: {
                    keywords: "",
                    industry: "",
                    level: "",
                    fee_status: "",
                    time: ""
                },
                visible: false,
                editForm: {
                    id: "",
                    name: "",
                    level: undefined,
                    sort: ''
                },
                name: "",
                formState: {
                    name: "",
                    level: undefined,
                    sort: ""
                },
                visible2: false
            })

            const addIndustry = async () => {

                state.visible2 = true
            }

            const handleOk2 = async () => {
                const params = {name: state.formState.name, level: state.formState.level, sort: state.formState.sort}
                const res = await apiMember.levelAdd(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    state.visible2 = false
                    getList()
                }
            }

            const getList = async () => {
                const params = {page: 1, limit: 100, name: state.searches.name}
                const res = await apiMember.levelLists(params)
                if (res && res.error_code === 0) {
                    const data = res.data
                    state.list = data
                }
            }
            let Level = [...Array(26).keys()].map(i => String.fromCharCode(i + 65))
            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await apiMember.levelDel(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    getList()

                }
            }

            const handleOk = async (val) => {
                const params = {
                    id: state.editForm.id,
                    name: state.editForm.name,
                    level: state.editForm.level,
                    sort: state.editForm.sort
                }
                const res = await apiMember.levelEdit(params)
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                    state.visible = false
                    getList()
                }
            }

            const setSortEditItem = (val) => {
                state.editForm.id = val.id
                state.editForm.name = val.name
                state.editForm.level = val.level
                state.editForm.sort = val.sort
                state.visible = true
            }

            const resetSearch = () => {
                state.searches.name = ""
                getList()
            }


            onMounted(() => {
                // initSearch()
                getList()
            })

            return {
                ...toRefs(state),
                addIndustry,
                getList,
                columns,
                Level,
                onDel,
                resetSearch,
                handleOk2,
                handleOk,
                setSortEditItem
            }
        }
    })
