
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import {message} from "ant-design-vue/es";
import BannerPositionSet from '@/components/BannerPositionSet.vue'
import {IBannerPositionItem, IBannerPositionUpdate} from "@/models/banner_model";
import ApiBanner from "@/request/apis/api_banner";

interface IState {
  list: IBannerPositionItem[];
  name: string;
  loading: boolean;
  editItem?: IBannerPositionUpdate;
  expands: number[]
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '副标题',
    dataIndex: 'subtitle',
    key: 'subtitle',
  },
  {
    title: '标签',
    dataIndex: 'tag',
    key: 'tag',
  },
  {
    title: '平台',
    dataIndex: 'terminal',
    key: 'terminal',
  },
  {
    title: '建议尺寸',
    dataIndex: 'width',
    key: 'width',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    key: 'action',
  },
]

export default defineComponent({
  name: 'BannerPosition',
  components: {BannerPositionSet},
  setup() {
    const state: IState = reactive({
      list: [],
      name: '', // 新增分类名称
      loading: false,
      editItem: undefined,
      expands: []
    })

    // 获取列表
    const getList = async () => {
      const res = await ApiBanner.getBannerPositionList()
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    // 删除分类
    const onDel = async (item: IBannerPositionItem) => {
      const res = await ApiBanner.delBannerPosition(item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }
    // 启用禁用分类
    const setShow = async (item: IBannerPositionItem) => {
      const res = await ApiBanner.showBannerPosition(item.id, Number(!Boolean(item.status)))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    const setExpand = (id: number) => {
      if (state.expands.includes(id)) {
        state.expands = state.expands.filter(e => e != id)
      } else {
        state.expands = [...state.expands, id]
      }
    }

    const setEdit = (item: IBannerPositionItem) => {
      state.editItem = {
        id: item.id,
        name: item.name, // 广告位名称
        subtitle: item.subtitle, // 广告位副标题
        attr: item.attr, // 广告位属性：0-系统默认；1-自定义
        size: `${item.width}*${item.height}`, // 广告位尺寸
        terminal: item.terminal, // 终端：1-移动端；2-PC端
        tag: item.tag || '', //标签
      }
    }

    const reset = () => {
      state.editItem = undefined
      getList()
    }

    onMounted(() => {
      getList()
    })


    return {
      ...toRefs(state),
      columns,
      onDel,
      setEdit,
      reset,
      setShow,
      setExpand,
    }
  }
})
