// 资讯分类
export interface IArticleCategory {
  id: number
  pid: number
  name: string
  image: string
  remark: null
  sort: any
  is_show: number
  del: number
  link:any
  market_price:any
  create_time: null
  update_time: string
  app_id: string
  children: IArticleCategory[];
  if_project:any
}

// 资讯列表item
export interface IArticleItem {
  id: number
  cid: number
  title: string
  image: string
  content: string
  market_price:any
  type: number
  visit: number
  likes: number
  sort: number
  is_notice: number
  is_show: number
  is_recommend: number
  del: number
  create_time: string
  update_time: string
  intro: string
  source: string
  edit_user: string
  uid: number
  zid: number
  sid: number
  examine_time: number
  album_id: number
  author: string
  app_id: string
  category: {
    catname: string
    id: number
  }
  tag: string// 标签
  extract_image: string//图片
  is_intro: number;// 是否显示简介1-显示，0-不显示, 默认1
  url: string; // 外链
  enclosure: string; // 附件
  if_project:any
}

export interface IArticleUpdate {
  id?: any
  title: string
  content: string // 根据type
  cid: any
  source: string
  edit_user: string
  image: string
  image_app: string
  type: 0 | 1 | 2 // 0文章，1视频，2音频
  sort?: number
  intro: string
  isFree:any,
  author: string
  price:string
  market_price:any
  media_url: string
  media_image: string
  tag: string// 标签
  extract_image: string//图片
  is_intro: number;// 是否显示简介1-显示，0-不显示, 默认1
  url: string; // 外链
  enclosure: any; // 附件
  location_province_code:any;
  location_province:any;
  location_city:any;
  location_city_code:any;
  if_project:any
}

// 资讯详情
export interface IArticleDetail {
  id: number
  cid: number
  pid: number
  title: string
  location_province:any
  location_city:any
  image: string
  price:string
  isFree:any
  market_price:any
  image_app: string
  content: string
  type: 0 | 1 | 2 // 0文章，1视频，2音频
  visit: number
  likes: number
  sort: number
  is_notice: number
  is_show: number
  del: number
  create_time: string
  update_time: string
  intro: string
  source: string
  edit_user: string
  uid: number
  zid: number
  sid: number
  examine_time: number
  album_id: number
  author: string
  app_id: string
  media_url: string
  media_image: string
  tag: string// 标签
  extract_image: string//图片
  is_intro: number;// 是否显示简介1-显示，0-不显示, 默认1
  url: string; // 外链
  enclosure: string; // 附件
  location_province_code:any,
  location_city_code:any,
  if_project:any,
  ISectionItem:any
}

export const articleTypes = [{id: 0, name:'文章'},{id: 1, name:'视频'},{id: 2, name:'音频'}]
