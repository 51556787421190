import {IMenu} from "@/models/menu_model"
import {appId, homeRoute} from "@/utils/config"

let menus: IMenu[] = []

// 智慧商协会
if (appId.includes('1000618860')) {
    menus = [
        {
            path: '/',
            name: '首页',
            key: '0',
            children: [
                {
                    path: "/associationHome",
                    name: "数据统计",
                    key: "/associationHome",
                },
            ],
        },
        {
            path: "",
            name: "文章管理",
            key: "article",
            children: [
                {
                    path: "/article/category",
                    name: "文章分类",
                    key: "/article/category",
                },
                {
                    path: "/article/list",
                    name: "文章列表",
                    key: "/article/list",
                },
            ],
        },
        {
            path: "",
            name: "广告管理",
            key: "banner",
            children: [
                {
                    path: "/banner/position",
                    name: "广告位",
                    key: "/banner/position",
                },
                {
                    path: "/banner/list",
                    name: "广告列表",
                    key: "/banner/list",
                },
            ],
        },
        {
            path: "",
            name: "协会管理", //组织架构管理
            key: "party",
            children: [
                {
                    path: "/party/organization",
                    name: "组织架构管理",
                    key: "/party/organization",
                },
                {
                    path: "/party/role",
                    name: "职务管理",
                    key: "/party/role",
                },
                {
                    path: "/party/member",
                    name: "人员管理",
                    key: "/party/member",
                },
            ],
        },
        {
            path: "",
            name: "会员管理",
            key: "vip",
            children: [
                {
                    path: "/vip/vipManage",
                    name: "会员管理",
                    key: "/vip/vipManage",
                },
                {
                    path: "/vip/industryManage",
                    name: "行业管理",
                    key: "/vip/industryManage",
                },
                {
                    path: "/vip/levelManage",
                    name: "级别管理",
                    key: "/vip/levelManage",
                },

            ],
        },
        {
            path: "",
            name: "基础配置",
            key: "config",
            children: [
                {
                    path: "/dictionary/list",
                    name: "页面配置",
                    key: "/dictionary/list",
                },
                {
                    path: "/menu/list",
                    name: "菜单配置",
                    key: "/menu/list",
                },
            ],
        },
    ]
}

// 智慧党建
if (appId.includes('1000618870')) {
    menus = [
        {
            path: "",
            name: "首页",
            key: "home",
            children: [
                {
                    path: homeRoute,
                    name: "数据统计",
                    key: homeRoute,
                },
            ],
        },
        {
            path: "",
            name: "文章管理",
            key: "article",
            children: [
                {
                    path: "/article/category",
                    name: "文章分类",
                    key: "/article/category",
                },
                {
                    path: "/article/list",
                    name: "文章列表",
                    key: "/article/list",
                },
            ],
        },
        {
            path: "",
            name: "广告管理",
            key: "banner",
            children: [
                {
                    path: "/banner/position",
                    name: "广告位",
                    key: "/banner/position",
                },
                {
                    path: "/banner/list",
                    name: "广告列表",
                    key: "/banner/list",
                },
            ],
        },
        {
            path: "",
            name: "党建管理", //组织架构管理
            key: "party",
            children: [
                {
                    path: "/party/organization",
                    name: "组织管理",
                    key: "/party/organization",
                },
                {
                    path: "/party/role",
                    name: "角色管理",
                    key: "/party/role",
                },
                {
                    path: "/party/member",
                    name: "党员管理",
                    key: "/party/member",
                },
            ],
        },

        {
            path: "",
            name: "课程管理",
            key: "course",
            children: [
                {
                    path: "/course/teacher",
                    name: "导师管理",
                    key: "/course/teacher",
                },
                {
                    path: "/course/list",
                    name: "课程列表",
                    key: "/course/list",
                },
            ],
        },
        {
            path: "",
            name: "基本配置",
            key: "config",
            children: [
                {
                    path: "/dictionary/list",
                    name: "页面配置",
                    key: "/dictionary/list",
                },
                {
                    path: "/menu/list",
                    name: "菜单配置",
                    key: "/menu/list",
                },
            ],
        },
    ]
}

// 物流
if (appId.includes('1000618880')) {
    menus = [
        {
            path: "",
            name: "货主管理",
            key: "12",
            children: [
                {
                    path: "/logistics_order/order_list",
                    name: "货主列表",
                    key: "12-0",
                },
            ],
        },
        {
            path: "",
            name: "司机管理",
            key: "member",
            children: [
                {
                    path: "/member/user",
                    name: "司机列表",
                    key: "/member/user",
                },
                {
                    path: "/member/driver",
                    name: "司机管理",
                    key: "/member/driver",
                },
            ],
        },
        {
            path: "",
            name: "审核管理",
            key: "audit",
            children: [
                {
                    path: "/audit/real_name_list",
                    name: "实名认证审核",
                    key: "/audit/real_name_list",
                },
                {
                    path: "/audit/driving_licence",
                    name: "驾驶证审核",
                    key: "/audit/driving_licence",
                },
                {
                    path: "/audit/licence",
                    name: "行驶证/物流运输证审核",
                    key: "/audit/licence",
                },
            ],
        },
        {
            path: "",
            name: "财务管理",
            key: "cash",
            children: [
                {
                    path: "/cash/out_list",
                    name: "提现管理",
                    key: "/cash/out_list",
                },
            ],
        },
        {
            path: "",
            name: "投诉管理",
            key: "complaint",
            children: [
                {
                    path: "/complaint/list",
                    name: "投诉管理",
                    key: "/complaint/list",
                },
            ],
        },
        {
            path: "",
            name: "售后管理",
            key: "afterSell",
            children: [
                {
                    path: "/afterSell/record",
                    name: "售后记录",
                    key: "/afterSell/record",
                },
            ],
        },
        {
            path: "",
            name: "物流订单",
            key: "logistics_order",
            children: [
                {
                    path: "/logistics_order/order_list",
                    name: "物流订单列表",
                    key: "/logistics_order/order_list",
                },
            ],
        },
        {
            path: "",
            name: "消息管理",
            key: "message",
            children: [
                {
                    path: "/message/list",
                    name: "消息发送与记录",
                    key: "/message/list",
                },
            ],
        },
        {
            path: "",
            name: "意见反馈",
            key: "feedback",
            children: [
                {
                    path: "/feedback/list",
                    name: "反馈列表",
                    key: "/feedback/list",
                },
            ],
        },
        {
            path: "",
            name: "广告",
            key: "banner",
            children: [
                {
                    path: "/banner/position",
                    name: "广告位",
                    key: "/banner/position",
                },
                {
                    path: "/banner/list",
                    name: "广告列表",
                    key: "/banner/list",
                },
            ],
        },
        {
            path: "",
            name: "协议配置",
            key: "protocol",
            children: [
                {
                    path: "/protocol/faq",
                    name: "常见问题",
                    key: "/protocol/faq",
                },
                {
                    path: "/protocol/user",
                    name: "用户协议",
                    key: "/protocol/user",
                },
                {
                    path: "/protocol/private",
                    name: "隐私政策",
                    key: "/protocol/private",
                },
                {
                    path: "/protocol/user_weal",
                    name: "用户福利",
                    key: "/protocol/user_weal",
                },
            ],
        },
        {
            path: "",
            name: "参数管理",
            key: "parameter",
            children: [
                {
                    path: "/parameter/car",
                    name: "车辆类型",
                    key: "/parameter/car_type",
                },
                {
                    path: "/parameter/material",
                    name: "物品类型",
                    key: "/parameter/material",
                },
                {
                    path: "/parameter/express",
                    name: "物流类型",
                    key: "/parameter/express",
                },
                {
                    path: "/parameter/service",
                    name: "客服配置",
                    key: "/parameter/service",
                },
            ],
        },
    ]
}


if (appId === "1000618811") {
    menus = [
        {
            path: "",
            name: "首页",
            key: "basic",
            children: [
                {
                    path: "/index/shopIndex",
                    name: "首页",
                    key: "/index/shopIndex",
                },
                {
                    path: "/index/brench",
                    name: "工作台",
                    key: "/index/brench",
                },
                {
                    path: "/index/personal",
                    name: "个人中心",
                    key: "/index/personal",
                },
            ],
        },
        {
            path: "",
            name: "店铺管理",
            key: "basic",
            children: [
                {
                    path: "/shop/shopManage",
                    name: "店铺信息",
                    key: "/shop/shopManage",
                },
                {
                    path: "/shop/shopSetting",
                    name: "店铺设置",
                    key: "/shop/shopSetting",
                },
                {
                    path: "/shop/concatUs",
                    name: "联系我们",
                    key: "/shop/concatUs",
                },
                {
                    path: "/shop/quailityService",
                    name: "质保服务",
                    key: "/shop/quailityService",
                },
            ],
        },
        {
            path: "",
            name: "商品管理",
            key: "basic",
            children: [
                {
                    path: "/commodity/commodityManage",
                    name: "商品管理",
                    key: "/commodity/commodityManage",
                },
                {
                    path: "/commodity/giftManage",
                    name: "赠品管理",
                    key: "/commodity/giftManage",
                },
            ],
        },
    ]
}


export {menus}
