
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import {IArticleItem} from "@/models/article_model";
import PageUtil from "@/utils/page_util";
import router from "@/router";
import {message} from "ant-design-vue/es";
import {IBannerItem, IBannerPositionItem, linkTypes, linkTypesArr} from "@/models/banner_model";
import * as dayjs from 'dayjs'
import ApiSetting from "@/request/apis/api_setting";
import {dictionaryTerminal, IDictionaryItem} from "@/models/setting_model";

interface IState {
  list: IDictionaryItem[],
  total: number;
  loading: boolean;
  position: IBannerPositionItem[]
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  // {
  //   title: '跳转类型',
  //   dataIndex: 'link_type',
  //   key: 'link_type',
  // },
  {
    title: '链接',
    dataIndex: 'link',
    key: 'link',

  },
  {
    title: '终端',
    dataIndex: 'terminal',
    key: 'terminal',
  },
  {
    title: '是否启用',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '修改时间',
    dataIndex: 'update_time',
    key: 'update_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 260
  },
];

export default defineComponent({
  name: 'DictionaryList',
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      position: [],
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        name: '', // 关键字搜索
        terminal: '', //终端
        link_type: '', // 链接类型
        status: '', // 是否启用1,0
      }
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      state.loading = true
      const res = await ApiSetting.getDictionaryList({...state.searches})
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.total || 0
        state.list = res.data?.data || []
      }
    }

    // 删除
    const onDel = async (id: number) => {
      const res = await ApiSetting.delDictionary(id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '操作成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 启用禁用
    const onSetStatus = async (id: number, status: number) => {
      const res = await ApiSetting.setDictionaryStatus(id, Number(status !== 1))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '操作成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 去新增编辑
    const goSet = (item?: IBannerItem) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/dictionary/set', query: params}).then()
    }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'name') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      } else {
        state.searches.name = ''
      }
      state.searches[key] = value
      state.searches.page = PageUtil.pageNo
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    onMounted(() => {
      getList()
    })

    // 参数
    const renderParams = (params: any) => {
      if (params) {
        let str = '?'
        for (let key in params) {
          str += `${key}=${params[key]}&`
        }
        return str.substr(0, str.length - 1)
      }
    }

    return {
      ...toRefs(state),
      columns,
      pagination,
      linkTypesArr,
      linkTypes,
      dictionaryTerminal,
      goSet,
      onDel,
      onSetStatus,
      goDetail,
      handleTableChange,
      dayjs,
      onSearch,
      resetSearch,
      renderParams,
    }
  }
})
