
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiCourse from "@/request/apis/api_course";
import router from "@/router";
import {message} from "ant-design-vue/es";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import {courseTypes, ISectionItem} from "@/models/course_model";
import {useRoute} from "vue-router";

interface IState {
  list: ISectionItem[],
  loading: boolean;
  title: string;
  course_id?: any;
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '章节名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '时长',
    dataIndex: 'duration',
    key: 'duration',
    align: 'right'
  },
  {
    title: '类型',
    dataIndex: 'media_type',
    key: 'media_type',
    align: 'right'
  },
  {
    title: '阅读',
    dataIndex: 'views',
    key: 'views',
    align: 'right',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'right',
    width: 150
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
  },
];

export default defineComponent({
  name: 'CourseSection',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const route = useRoute()
    const state: IState = reactive({
      list: [],
      loading: false,
      title: '',
      course_id: undefined,
    })

    //获取列表
    const getList = async () => {
      const {id} = route.query
      if(!id) return;
      state.course_id = id
      state.loading = true
      const res = await ApiCourse.getSectionList({ course_id: id})
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = res.data || []
      }
    }

    // 删除
    const onDel = async (item: any) => {
      const res = await ApiCourse.delSection(state.course_id, item.id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success('删除成功')
          await getList()
          return
        }
        message.error('删除失败')
      }
    }

    // 去新增编辑
    const goSet = (item?: any) => {
      const {name = '', type = ''}: any = route.query
      let params: any = {
        course_id: state.course_id,
        type,
        name,
      }
      if (item) {
        params.section_id = item.id
      }
      router.push({path: '/course/section/set', query: params}).then()
    }
    // 详情
    const goDetail = (item: any) => {
      router.push({path: '/course/section/detail', query: {id: item.id,course_id:item.course_id}}).then()
    }

    // 类型
    const renderType = (type: number) => {
      return courseTypes.find(e => e.id === type)?.name;
    }

    onMounted(() => {
      getList()
      const {name = ''}: any = route.query
      state.title = name
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      onDel,
      goDetail,
      renderType,
    }
  }
})
