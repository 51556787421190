
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message, notification} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import ImageView from "@/components/ImageView.vue";
import DriverSet from "@/components/DriverSet.vue";
import PageUtil from "@/utils/page_util";
import {IDriverItem, IEditDriver} from "@/models/logistics_member";
import ApiSetting from "@/request/apis/api_setting";
import UploadUtil from "@/utils/upload_util";
import {baseUrl} from "@/utils/config";

interface IState {
  list: any;
  total: number;
  loading: boolean;
  searches: any;
  visible: boolean;
  iframeSrc: any;
  visible2: boolean,
  editItem?: IEditDriver;
  commentDetail: any,
  detailData: any,
  jobData: any;
  page2: any,
  coutotal: any,
  name: any,
  height: any
}

// 表格
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    align: "center"
  },
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center"
  },
  {
    title: "薪资",
    dataIndex: "wages",
    key: "wages",
    align: "center"
  },
  {
    title: "学历",
    dataIndex: "degree_text",
    key: "degree_text",
    align: "center"
  },
  {
    title: "工作经验",
    dataIndex: "work_age",
    key: "work_age",
    align: "center"
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
    align: "center"
  },
  {
    title: "期望工作",
    dataIndex: "expectWorks",
    key: "expectWorks",
    align: "center"
  },
  {
    title: "发布时间",
    dataIndex: "create_time",
    key: "create_time",
    align: "center"
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    align: "center"
  },
];

export default defineComponent({
  name: "DriverList",
  components: {
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    ImageView,
    DriverSet,
  },
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      height: 0,
      loading: false,
      commentDetail: '',
      visible: false,
      visible2: false,
      jobData: [],
      coutotal: 0,
      page2: 1,
      iframeSrc: '',
      detailData: '',
      name: '',
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        position_id: undefined,
        name: '',
        education: undefined,
        position_name: '',
        work_age: undefined,
        wages_start: '',
        wages_end: ''
      },
    });

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize;
      state.searches.page = pages.current;
      getList();
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches};
      StorageUtil.setParams(params);
      state.loading = true;
      const res = await ApiSetting.resumeList(params);
      state.loading = false;
      if (res && res.error_code === 0) {
        state.total = res.data?.total || 0;
        state.list = res.data?.data || [];
      }
    };

    const onSearch = () => {
      getList()
    }

    const onReset = () => {
      state.searches = {
        page: 1,
        limit: PageUtil.pageSize,
        position_id: undefined,
        name: '',
        education: undefined,
        position_name: '',
        work_age: undefined,
        wages_start: '',
        wages_end: ''
      }
      getList()
    }

    const getJob = async () => {
      const params = {page: state.page2, limit: 10, name: state.name}
      const res = await ApiSetting.postList(params);
      if (res && res.error_code === 0) {
        const data: any = res.data
        state.coutotal = data.total
        state.jobData = state.jobData.concat(data.data)
      }
    };
    const handlePopupScroll = (e) => {
      const {target} = e
      const rmHeight = target.scrollHeight - target.scrollTop
      const clHeight = target.clientHeight
      if (rmHeight === 0 && clHeight === 0) {
      } else {
        if (rmHeight < clHeight + 1) {
          if (state.coutotal > state.jobData.length) {
            state.page2 = state.page2 + 1
            getJob()
          } else {
            return false
          }
        } else {
          return false
        }
      }
    }

    const getDetail = (val) => {
      state.visible = true
      state.detailData = val
    }
    const filterOption = (input: string, option: any) => {
      console.log(option, 123)
      return option.key.indexOf(input) >= 0;
    };

    // const Change = () => {
    //     console.log(123)
    // }
    const handleChange = (value) => {
      console.log(value, 1123)
      state.jobData = []
      state.name = value
      state.page2 = 1
      getJob()
    }

    const upResume = () => {
      state.visible2 = true
    }
    const uploadHeaders = UploadUtil.uploadHeaders()
    let uploadAction = baseUrl + '/admin/share_talents.resume/import'
    let uploadFormData = {type: 'zhilian'}
    const handleChange2 = ({file}: any) => {
      if (file.status === 'done' && file.response) {
        const res = file.response
        if (res && res.error_code === 0) {
          message.success(res.msg)
          getList()
          state.visible2 = false
        } else {
          notification.error({
            message: '提示',
            duration: 0,
            description: res.msg
          });
        }
      }
    }
    const preview = async (val) => {
      const params = {id: val.id}
      const res = await ApiSetting.resumePreview(params);
      if (res && res.error_code === 0) {
        const data: any = res.data
        window.open(data.url, '简历预览', 'width=960px,height=1080px,top=0px,left=960px')

      } else {
        message.error(res.msg)
      }
    };


    const exportResume = async (val) => {
      const params = {id: val.id}
      const res = await ApiSetting.resumeExport(params);
      if (res && res.error_code === 0) {
        const data: any = res.data
        window.open(data.url)
      } else {
        message.error(res.msg)
      }
    };
    let iframeRef: any = ref(null)
    const iframeload = () => {
      let aWindow = iframeRef._value.contentWindow
      let iframeWin = iframeRef._value.contentWindow || iframeRef._value.contentDocument.parentWindow
    }

    // 删除
    const onDel = async (item) => {

    }

    onMounted(() => {
      getList();
      getJob()
    });

    return {
      ...toRefs(state),
      columns,
      preview,
      pagination,
      iframeload,
      iframeRef,
      uploadHeaders,
      uploadAction,
      uploadFormData,
      getDetail,
      exportResume,
      upResume,
      handlePopupScroll,
      handleChange,
      handleChange2,
      filterOption,
      onSearch,
      getJob,
      onReset,
      handleTableChange,
      onDel
    };
  },
});
