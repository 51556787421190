
    import {defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref} from 'vue';
    import {appTitle} from "@/utils/config";
    import {UserOutlined} from '@ant-design/icons-vue';
    import {IUserInfo} from "@/models/user_models";
    import {appId} from "@/utils/config"
    import apiMember from "@/request/apis/api_member";

    interface IHeaderData {
        userInfo: IUserInfo | undefined,
        updata_time: any,
        echart: any,
        xData: any,
        yData: any,
        echarts_add:any,
        echarts_count:any
    }


    export default defineComponent({
        name: 'Home',
        components: {UserOutlined},
        setup() {
            let state = reactive({
                appId: appId,
                xData: [] as any [],
                echarts_add: [] as any [],
                echarts_count: [] as any [],
            })


            // @ts-ignore
            const {proxy} = getCurrentInstance();
            const myRef = ref(null);
            const initT = () => {
                const myChart = proxy.$echarts.init(document.getElementById('mycharts'));
                myChart.setOption({
                    title: {
                        text: '用户访问统计图',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: state.xData,
                        splitLine: {
                            show: true
                        },
                        axisTick: {
                            "show": false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#DDE3EE'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '70',
                        top: '15%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            name: "新增数量",
                            data: state.echarts_add,
                            type: 'line',
                            smooth:true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00FFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00FFFF' //改变折线颜色
                                    }
                                }
                            },
                        },
                        {
                            name: "访问数量",
                            data: state.echarts_count,
                            type: 'line',
                            smooth:true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {offset: 0,color: 'rgba(44,254,239,1)'},
                                        {offset: 0.3,color: 'rgba(44,254,239,0.6)'},
                                        {offset: 0.7, color: 'rgba(44,254,239,0.2)'},
                                        {offset: 1,color: 'rgba(44,254,239,0)'}
                                    ],
                                    global: false // 缺省为 false
                                }
                            },
                            itemStyle : {
                                normal : {
                                    color:'#00BFFF', //改变折线点的颜色
                                    lineStyle:{
                                        color:'#00BFFF' //改变折线颜色
                                    }
                                }
                            },

                        }
                    ]
                });


            };
            const getdata = async () => {
                const params = {}
                const res = await apiMember.StatisticsVisit(params);
                if (res && res.error_code === 0) {
                    const data: any = res.data
                    state.xData = data.days
                    state.echarts_add = data.echarts_add
                    state.echarts_count = data.echarts_count
                    initT()
                }
            }
            onMounted(() => {
                getdata()
            })

            return {
                ...toRefs(state),
                appTitle,
                myRef,

            }
        }
    });
