
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiArticle from "@/request/apis/api_article";
import {IArticleDetail} from "@/models/article_model";
import {useRoute} from "vue-router";
import ApiCourse from "@/request/apis/api_course";

interface IState {
  detail?: IArticleDetail
}

export default defineComponent({
  name: "ArticleDetail",
  setup() {
    let editId: any;
    const route = useRoute()

    const state: IState = reactive({
      detail: undefined
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id,course_id} = route.query
      //IArticleDetail
      if (id) {
        editId = id
        const res = await ApiCourse.getSectionDetail(course_id,id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data:any = res.data
          state.detail = data
        }
      }
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state)
    }
  }
})
