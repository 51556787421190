
    import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
    import type {UnwrapRef} from "vue"
    import UploadComponent from "@/components/UploadComponent.vue"
    import {message} from "ant-design-vue/es"
    import {useRoute, useRouter} from "vue-router"
    import {permissionTypes} from "@/models/menu_model";
    import {AuthMenu} from "@/models/menu_model";
    import ApiAdmin from "@/request/apis/api_admin";

    interface IState {
        loading: boolean;
        allMenu: AuthMenu[]
    }

    // 新增编辑文章
    export default defineComponent({
        name: "AdminPermissionSet",
        components: {UploadComponent},
        setup() {
            const route = useRoute()
            const router = useRouter()
            let editId: any;
            const formRef = ref()
            const editorComponentRef = ref()
            const formState: UnwrapRef<AuthMenu> = reactive({
                "title": "",
                "type": 1,
                "sort": 1,
                "front_url":'',
                "uri": "",
                "component":"",
                "disable": 0,
                pid: 0,
                icon: ""
            })
            const rules = {
                title: [{required: true, message: "请输入菜单标题"},],
                type: [{required: true, message: "请选择菜单类型"},],
                pid: [{required: true, message: "请选择上级菜单"}]
            }

            const onSubmit = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        let params = {...toRaw(formState)}
                        params.sort = Number(params.sort)
                        if (editId) {
                            params.id = editId
                            onEdit(params)
                        } else {
                            onAdd(params)
                        }
                    })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }
            const resetForm = () => {
                formRef.value.resetFields()
            }

            const state: IState = reactive({
                loading: false,
                allMenu: [{
                    "title": "顶级菜单",
                    "type": 1,
                    "sort": 0,
                    "uri": "",
                    "front_url":"",
                    "component":"",
                    "disable": 0,
                    pid: 0,
                    id:0,
                    sub: []
                }]
            })

            // 获取全部菜单
            const getAllMenu = async () => {
                state.loading = true
                const res = await ApiAdmin.getAllMenus2({page: 1, limit: 1000});
                state.loading = false
                if (res && res.error_code === 0) {
                    const data:any = res.data
                    state.allMenu[0].sub = data
                    state.allMenu[0].sub = exchangeListData(data);

                }
            };

            // 转换筛选list数据
            const exchangeListData = (list:AuthMenu[]) => {
                list.map(item => {
                    item.sub = item.sub && item.sub.length ? item.sub : undefined;
                    if (item.sub && item.sub.length) {
                        item.sub = exchangeListData(item.sub);
                    }
                })
                return list;
            }

            // 获取详情
            const getDetail = async () => {
                const {id, data} = route.query
                //IArticleDetail
                if (id && data) {
                    editId = id
                    let datas = JSON.parse(data as string);
                    formState.id = parseInt(id as string);
                    formState.title = datas.title;
                    formState.type = datas.type;
                    formState.name = datas.name;
                    formState.sort = datas.sort;
                    formState.icon = datas.icon;
                    formState.uri = datas.uri;
                    formState.component = datas.component;
                    formState.disable = datas.disable == 0 ? 'on' : 1;
                    formState.pid = datas.pid;
                }
            }

            // 新增
            const onAdd = async (params: any) => {
                params.name = params.title;
                state.loading = true
                console.log(params)
                const res = await ApiAdmin.addMenu2(params)
                state.loading = false
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        resetForm()
                        router.back()
                        return
                    }
                    message.error(res.msg)
                }
            }

            // 编辑
            const onEdit = async (params: any) => {
                params.name = params.title;
                state.loading = true
                const res = await ApiAdmin.updateMenu2(params)
                state.loading = false
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        resetForm()
                        router.back()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const goBack = () => {
                router.back()
            }

            onMounted(() => {
                getAllMenu();
                getDetail()
            })

            return {
                formRef,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                other: "",
                formState,
                rules,
                onSubmit,
                resetForm,
                goBack,
                permissionTypes,
                ...toRefs(state),
                editorComponentRef,
            }
        },
    })
