import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca4189c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "header",
  style: {"color":"#fff"}
}
const _hoisted_2 = { class: "user_info" }
const _hoisted_3 = {
  key: 2,
  class: "avatar_info"
}
const _hoisted_4 = { style: {"padding-left":"8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chat = _resolveComponent("Chat")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args))),
      class: "header_title"
    }, _toDisplayString(_ctx.appTitle) + "后台管理系统 ", 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.userInfo)
        ? (_openBlock(), _createBlock(_component_Chat, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.userInfo)
        ? (_openBlock(), _createBlock(_component_a_divider, {
            key: 1,
            type: "vertical",
            style: {"border-color":"#666666"}
          }))
        : _createCommentVNode("", true),
      (_ctx.userInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!_ctx.userInfo || !_ctx.userInfo?.avatar)
              ? (_openBlock(), _createBlock(_component_a_avatar, {
                  key: 0,
                  style: {"background-color":"#87d068"}
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_UserOutlined)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_a_image, {
                  key: 1,
                  style: {"object-fit":"contain","position":"absolute","border-radius":"50%"},
                  preview: false,
                  width: 40,
                  height: 40,
                  src: _ctx.getImageUrl(_ctx.userInfo?.avatar)
                }, null, 8, ["src"])),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userInfo?.name), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_divider, {
        type: "vertical",
        style: {"border-color":"#666666"}
      }),
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exitLogin && _ctx.exitLogin(...args)))
      }, "[退出登录]")
    ])
  ]))
}