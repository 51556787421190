import {baseRequestClient} from "@/request/request_client";
import {IMemberDetail, IMemberItem, IMemberUpdate, IOrganizationItem, IRoleItem} from "@/models/party_model";

const requestClient = baseRequestClient

/// 党组织管理
export default class ApiParty {
    // GET 组织列表
    static getOrganizationList = async () => {
        return await requestClient.get<IOrganizationItem[]>('/admin/party.Organization/list')
    }

    // POST 组织添加
    static addOrganization = async (data: any) => {
        return await requestClient.post('/admin/party.Organization/store', data)
    }

    // POST 组织编辑
    static editOrganization = async (data: any) => {
        return await requestClient.post('/admin/party.Organization/update', data)
    }

    // POST 组织删除
    static delOrganization = async (id: number) => {
        return await requestClient.post('/admin/party.Organization/destroy', {id})
    }

    /// 角色
    // GET 角色列表
    static getRoleList = async (params: any) => {
        return await requestClient.get<{ count: number; data: IRoleItem[] }>('/admin/party.Role/list', params)
    }

    // POST 角色添加
    static addRole = async (data: any) => {
        return await requestClient.post('/admin/party.Role/store', data)
    }

    // POST 角色编辑
    static editRole = async (data: any) => {
        return await requestClient.post('/admin/party.Role/update', data)
    }

    // POST 角色删除
    static delRole = async (id: number) => {
        return await requestClient.post('/admin/party.Role/destroy', {id})
    }

    /// 人员
    // GET 人员列表
    static getMemberList = async (params: any) => {
        for(let key in params){
            if(!params[key]) delete params[key]
        }
        return await requestClient.get<{count: number; data: IMemberItem[]}>('/admin/party.CpcMember/index', params)
    }

    // GET 人员详情
    static getMemberDetail = async (id: any) => {
        return await requestClient.get<IMemberDetail>('/admin/party.CpcMember/show', {id})
    }

    // POST 人员添加
    static addMember = async (data: IMemberUpdate) => {
        return await requestClient.post('/admin/party.CpcMember/store', data)
    }

    // POST 人员编辑
    static editMember = async (data: any) => {
        return await requestClient.post('/admin/party.CpcMember/update', data)
    }

    // POST 人员删除
    static delMember = async (id: number) => {
        return await requestClient.post('/admin/party.CpcMember/destroy', {id})
    }

    // POST 党员审核
    static CpcMemberExamine = async (data: any) => {
        return await requestClient.post('/admin/party.CpcMember/examine', data)
    }
}