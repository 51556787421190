
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import router from "@/router";
import {message} from "ant-design-vue/es";
import {IBannerItem, IBannerPositionItem, linkTypes, linkTypesArr, terminalTypes} from "@/models/banner_model";
import * as dayjs from 'dayjs'
import ApiParty from "@/request/apis/api_party";
import {IMemberItem, IOrganizationItem, IRoleItem} from "@/models/party_model";
import StorageUtil from "@/utils/storage_util";
import {menuText} from "@/utils/config";
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";

interface IState {
  list: IMemberItem[];
  roleList: IRoleItem[];
  organizationList: IOrganizationItem[];
  total: number;
  loading: boolean;
  position: IBannerPositionItem[]
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '图片',
    dataIndex: 'avatar',
    key: 'avatar',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '性别',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: '角色',
    dataIndex: 'roles',
    key: 'roles',
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '状态',
    dataIndex: 'examine',
    key: 'examine',
  },
  {
    title: '是否删除',
    dataIndex: 'is_deleted',
    key: 'is_deleted',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 260
  },
];

export default defineComponent({
  name: 'MemberList',
  components: {ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      roleList: [],
      organizationList: [],
      total: 0,
      loading: false,
      position: [],
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
        organization_id: '',// 组织
        gender: '',//性别
        name: '', //姓名搜索
        phone: '',//
        role_id: ''
      }
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否启用pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };


    const exAmine = async(item1,item2) =>{
      const params :any = {id:item1.id,status:item2}
      const res = await ApiParty.CpcMemberExamine(params)
      if (res.error_code === 0) {
        message.success(res.msg)
        await getList()
        return
      }
    }
    //获取列表
    const getList = async () => {
      StorageUtil.setParams(state.searches)
      state.loading = true
      const res = await ApiParty.getMemberList({...state.searches})
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.data || []
      }
    }

    // 获取组织列表
    const getOrganizationList = async () => {
      const res = await ApiParty.getOrganizationList()
      if (res && res.error_code === 0) {
        state.organizationList = res.data || []
      }
    }

    // 获取角色列表
    const getRoleList = async () => {
      const res = await ApiParty.getRoleList({page_no: 1, page_size: 100})
      if (res && res.error_code === 0) {
        state.roleList = res.data?.data || []
      }
    }

    // 删除
    const onDel = async (id: number) => {
      const res = await ApiParty.delMember(id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 去新增编辑
    const goSet = (item?: IBannerItem) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/party/member/set', query: params}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'name') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      }
      if (key === 'phone') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      }
      state.searches[key] = value
      state.searches.page_no = PageUtil.pageNo
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = PageUtil.pageNo
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res: any = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    const renderRole = (item: IMemberItem) => {
      if(item.roles && item.roles.length){
        let text = ''
        item.roles.map(e => text += `${e.name}、`)
        return text.substr(0, text.length - 1)
      }
      return ''
    }

    onMounted(async () => {
      initSearch()
      await getOrganizationList()
      await getRoleList()
      await getList()
    })

    return {
      ...toRefs(state),
      handleTableChange,
      dayjs,
      goSet,
      onDel,
      onSearch,
      resetSearch,
      exAmine,
      renderRole,
      pagination,
      columns,
      linkTypesArr,
      linkTypes,
      terminalTypes,
      menuText,
    }
  }
})
