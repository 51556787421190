
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import PageUtil from "@/utils/page_util";
import router from "@/router";
import {message} from "ant-design-vue/es";
import {IBannerItem, IBannerPositionItem, linkTypes, linkTypesArr} from "@/models/banner_model";
import * as dayjs from 'dayjs'
import ApiSetting from "@/request/apis/api_setting";
import {dictionaryTerminal} from "@/models/setting_model";
import StorageUtil from "@/utils/storage_util";
import {getMenuType, IMenuItem, menuType} from "@/models/menu_model";
import ImageView from "@/components/ImageView.vue";
import {CheckOutlined} from '@ant-design/icons-vue'

interface IState {
  list: IMenuItem[],
  total: number;
  loading: boolean;
  position: IBannerPositionItem[]
  searches: any
  editItem?: IMenuItem
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '菜单图标',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '菜单名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '菜单副标题',
    dataIndex: 'subtitle',
    key: 'subtitle',
  },
  {
    title: '菜单等级',
    dataIndex: 'pid',
    key: 'pid',
  },
  {
    title: '位置',
    dataIndex: 'decorate_type',
    key: 'decorate_type',
  },
  // {
  //   title: '跳转类型',
  //   dataIndex: 'link_type',
  //   key: 'link_type',
  // },
  {
    title: '链接',
    dataIndex: 'link_address',
    key: 'link_address',
  },
  {
    title: '终端',
    dataIndex: 'is_pc',
    key: 'is_pc',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '是否启用',
    dataIndex: 'is_show',
    key: 'is_show',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width: 150,
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 260
  },
];

export default defineComponent({
  name: 'MenuList',
  components: {ImageView, CheckOutlined},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      position: [],
      searches: {
        limit: PageUtil.pageSize,
        page: PageUtil.pageNo,
        is_pc: '',
        is_show: '',
        type: ''
      },
      editItem: undefined
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (params.is_pc === '') {
        delete params.is_pc
      }
      StorageUtil.setParams(state.searches)
      state.loading = true
      const res = await ApiSetting.getMenuList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.lists || []
      }
    }

    // 删除
    const onDel = async (id: number) => {
      const res = await ApiSetting.delMenu(id)
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '删除成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 启用禁用
    const onSetStatus = async (id: number, is_show: number) => {
      const res = await ApiSetting.setMenuStatus(id, Number(is_show !== 1))
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg || '操作成功')
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 排序
    const onSort = async (sort: any) => {
      if (!state.editItem) return
      const res = await ApiSetting.setMenuSort(state.editItem.id, Number(sort))
      if (res) {
        state.editItem = undefined
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          await getList()
          return
        }
        message.error(res.msg)
      }
    }

    // 设置排序item
    const setSortEditItem = (item: IMenuItem) => {
      if (state.editItem && item.id === state.editItem.id) {
        state.editItem = undefined
      } else {
        state.editItem = item
      }
    }

    // 去新增编辑
    const goSet = (item?: IBannerItem) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/menu/set', query: params}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'name') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      } else {
        state.searches.name = ''
      }
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    // 参数
    const renderParams = (params: any) => {
      if (params) {
        let str = '?'
        for (let key in params) {
          str += `${key}=${params[key]}&`
        }
        return str.substr(0, str.length - 1)
      }
    }

    return {
      ...toRefs(state),
      columns,
      pagination,
      linkTypesArr,
      linkTypes,
      dictionaryTerminal,
      menuType,
      goSet,
      onDel,
      onSetStatus,
      handleTableChange,
      dayjs,
      onSearch,
      resetSearch,
      renderParams,
      getMenuType,
      onSort,
      setSortEditItem,
    }
  }
})
