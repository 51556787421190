
    import {defineComponent, onMounted, reactive, toRefs} from 'vue';
    import {message} from "ant-design-vue/es";
    import apiSetting from "@/request/apis/api_setting";
    interface IState {
        config:any,
        phone:any
    }
    export default defineComponent({
        setup() {
            const state: IState = reactive({
                config:'',
                phone:'',
            });

            const getData = async()=>{
                const res = await apiSetting.BasicShare();
                if (res && res.error_code === 0) {
                    state.config = res.data.config
                    state.phone = state.config.h5
                }else{
                    message.error(res.msg)
                }
            }

            const onFinish = async(values: any) => {
                const params = {
                    "h5_share_title" : state.phone.h5_share_title,
                    "h5_share_intro" : state.phone.h5_share_intro,
                    "h5_share_image" : state.phone.h5_share_image,
                    "mnp_share_title" :''
                }
                const res = await apiSetting.BasicSetShare(params);
                if (res && res.error_code === 0) {
                    message.success(res.msg)
                }else{
                    message.error(res.msg)
                }
            };

            onMounted(() => {
                getData();
            });

            return {
                ...toRefs(state),
                getData,
                onFinish
            };
        },
    });
